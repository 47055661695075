import SyncIcon from '@mui/icons-material/Sync';
import React from 'react';
import { Theme } from '../theme/Theme';

export function Spinner(): React.ReactElement {
  return (
    <SyncIcon
      sx={{
        fontSize: `3rem`,
        animation: `spin infinite 1800ms linear`,
        color: Theme.loading.fontColor,
      }}
    />
  );
}
