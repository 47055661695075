import { Action } from '@auto/dango-store';
import { Unit } from '@auto/dango-util';
import React from 'react';

type DispatchArgs<State, Args> =
  | [(data: State) => State]
  | [Action<State, Args>, Args];

export type Dispatch<State> = {
  (action: (data: State) => State): Unit;
  <Args>(action: Action<State, Args>, args: Args): Unit;
};

export type ReducerState<State> = {
  readonly state: State;
  readonly dispatch: Dispatch<State>;
};

type Reducer<State> = (state: State, action: (s: State) => State) => State;

function reducer<State>(state: State, action: (s: State) => State): State {
  return action(state);
}

export function emptyDispatch<State>(): Dispatch<State> {
  return <Args>(..._args: DispatchArgs<State, Args>) => Unit;
}

export function useReducer<State>(initialState: State): ReducerState<State> {
  const [state, d] = React.useReducer<Reducer<State>>(reducer, initialState);
  const dispatch = React.useCallback<Dispatch<State>>(
    <Args>(...args: DispatchArgs<State, Args>) => {
      d((_: State) => {
        if (args.length === 1) return args[0](_);
        else return args[0](_, args[1]);
      });
      return Unit;
    },
    [d],
  );
  return { state, dispatch };
}
