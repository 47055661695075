import { Lazy } from '@auto/dango-util';
import { PrimitiveType } from 'intl-messageformat';
import React from 'react';
import * as intl from 'react-intl';
import { BuildOpt } from '../config/BuildOpt';
import { Messages as en_daihatsu } from '../generated/i18n/en_daihatsu';
import { Messages as en_perodua } from '../generated/i18n/en_perodua';
import { Messages as ja_daihatsu } from '../generated/i18n/ja_daihatsu';
import { Messages as ja_perodua } from '../generated/i18n/ja_perodua';
import { Lang, Locale } from './Locale';
import { MessageKeys, MessageTable } from './Message';

export interface Intl {
  readonly formatMessageAsNode: (
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType>,
  ) => React.ReactNode;

  readonly formatMessageAsString: (
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType>,
  ) => string;

  readonly formatDate: (date: Date, options?: intl.FormatDateOptions) => string;

  readonly formatDateToPars: (
    date: Date,
  ) => Array<{ type: string; value: string }>;

  readonly formatTime: (date: Date, options?: intl.FormatDateOptions) => string;

  readonly formatTimeToPars: (
    date: Date,
  ) => Array<{ type: string; value: string }>;

  readonly formatDateTime: (
    date: Date,
    options?: intl.FormatDateOptions,
  ) => string;
}

export interface MessageDescriptor {
  readonly id: MessageKeys;
}

export function getMessages(lang: Lang): MessageTable {
  const ja = Lazy(() =>
    BuildOpt.byProduct({
      daihatsu: () => ja_daihatsu,
      perodua: () => ja_perodua,
    }),
  );

  const en = Lazy(() =>
    BuildOpt.byProduct({
      daihatsu: () => en_daihatsu,
      perodua: () => en_perodua,
    }),
  );

  console.log(en_daihatsu['vehicleDetailLocationErrorMsg']);

  switch (lang) {
    case 'en':
      return en.get();
    case 'ja':
      return ja.get();
    default:
      return en.get();
  }
}

function toReactNode(lines: string | string[]): React.ReactNode {
  const xs = typeof lines === 'string' ? [lines] : lines;
  return React.createElement(
    React.Fragment,
    null,
    ...xs.map((line, index) => {
      if (index === 0) return React.createElement(React.Fragment, null, line);
      else
        return React.createElement(
          React.Fragment,
          null,
          React.createElement('br'),
          line,
        );
    }),
  );
}

export function useIntl(locale: Locale): Intl {
  return React.useMemo(() => {
    const self = intl.createIntl(
      {
        locale: `${locale.lang}-${locale.region}`,
        messages: getMessages(locale.lang),
      },
      intl.createIntlCache(),
    );
    return {
      formatMessageAsNode: (
        descriptor: MessageDescriptor,
        values?: Record<string, PrimitiveType>,
      ) => {
        return toReactNode(
          self.formatMessage({ id: descriptor.id }, values).split('\n'),
        );
      },

      formatMessageAsString: (
        descriptor: MessageDescriptor,
        values?: Record<string, PrimitiveType>,
      ) => self.formatMessage({ id: descriptor.id }, values).replace('\n', ' '),

      formatDate: (date: Date, options?: intl.FormatDateOptions) =>
        self.formatDate(date, options),

      formatDateToPars: (date: Date) => self.formatDateToParts(date),

      formatTime: (date: Date, options?: intl.FormatDateOptions) =>
        self.formatTime(date, options),

      formatTimeToPars: (date: Date) => self.formatTimeToParts(date),

      formatDateTime: (date: Date, options?: intl.FormatDateOptions) => {
        const d = self.formatDate(date, options);
        const t = self.formatTime(date, options);
        switch (locale.lang) {
          case 'ja':
            return `${d} ${t}`;
          default:
            return `${t} ${d}`;
        }
      },
    };
  }, [locale]);
}
