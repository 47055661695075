import { Option, Unit } from '@auto/dango-util';
import { Config } from '../config/Config';
import { deleteAuthInfo, setAuthInfo } from '../storage/Storage';

export type AuthToken = Readonly<{
  id_token: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: 'Bearer';
}>;

export class AuthError extends Error {
  readonly code: string;
  constructor(code: string, message: string) {
    super(message);
    this.code = code;
  }
}

export function signIn(
  _: Readonly<{
    email: string;
    password: string;
  }>,
): Promise<Unit> {
  setAuthInfo({
    id_token: '',
    access_token: '',
    refresh_token: '',
    expires_in: 3600,
    token_type: 'Bearer',
  });
  return Promise.resolve(Unit);
}

export function codeFlow(): void {
  const a = Config.auth;
  const path = `${a.url}/oauth2/authorize?response_type=${a.response_type}&client_id=${a.client_id}&redirect_uri=${a.redirect_uri}&state=${a.state}&scope=${a.scope}`;
  window.location.href = path;
}

export function getCode(): Option<string> {
  const params = new URLSearchParams(window.location.search);
  return Option(params.get('code'));
}

export async function getAuthToken(code: string): Promise<AuthToken> {
  const a = Config.auth;
  const params = {
    code,
    client_id: a.client_id,
    client_secret: a.client_secret,
    redirect_uri: a.redirect_uri,
    grant_type: a.grant_type,
  };
  const body = new URLSearchParams(params).toString();
  const res = await fetch(`${a.url}/oauth2/token`, {
    method: `POST`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body,
  });
  return res.json() as Promise<AuthToken>;
}

export function signOut(): void {
  const a = Config.auth;
  deleteAuthInfo();
  const query = new URLSearchParams({
    client_id: a.client_id,
    logout_uri: a.logout_uri,
  }).toString();
  window.location.href = `${a.url}/logout?${query}`;
}
