/* eslint-disable no-irregular-whitespace */
import { MessageIds, MessagesType } from './Types';

export type Messages = MessagesType;

export const Messages: Messages = {
  footerCopyright: `Copyright ©Daihatsu & Perodua`,
  headerTitle: `Local Center`,
  homeAirBagTitle: `Air Bag Deployment`,
  homeButtonTitle: `As of now {count} Units`,
  homeSOSTitle: `SOS`,
  homeTheftAlertTitle: `Theft Alert (DCM Removal, Immobilizer Alerm)`,
  homeVehicleWarningTitle: `Vehicle Warning`,
  signOut: `Sign Out`,
  signinButtonTitle: `Login`,
  signinPanelTitle: `Login`,
  signinPlaceholderPassword: `Password`,
  signinPlaceholderUserId: `ID`,
  toolbarGoToTop: `Go to TOP page`,
  toolbarPrintDashboard: `Print Dash board`,
  toolbarPrintSRO: `Print SRO`,
  toolbarRefreshTitle: `Refresh`,
  toolbarSelectReg: `Registration No.`,
  toolbarSelectVin: `VIN`,
  vehicleDetailBasicInfoTitle: `<Basic Information>`,
  vehicleDetailBodyColor: `Body color :`,
  vehicleDetailChipDriver: `Driver`,
  vehicleDetailChipOwner: `Owner`,
  vehicleDetailCusId: `Customer ID :`,
  vehicleDetailDTCHeaderDTCID: `DTC ID`,
  vehicleDetailDTCHeaderDate: `date`,
  vehicleDetailDTCHeaderDetail: `Detail`,
  vehicleDetailDTCHeaderECU: `ECU`,
  vehicleDetailDTCHeaderFFD: `FFD`,
  vehicleDetailDTCNew: `New`,
  vehicleDetailDTCTitle: `<DTC/FFD List>`,
  vehicleDetailDtcCodeUndefined: `未定義({ecuCode}_{dtcCode})`,
  vehicleDetailEcuCodeUndefined: `未定義({ecuCode})`,
  vehicleDetailLocationAsOf: `As of`,
  vehicleDetailLocationErrorMsg: `Unable to get vehicle location`,
  vehicleDetailLocationTitle: `<Latest location>`,
  vehicleDetailModel: `Model :`,
  vehicleDetailName: `Name : `,
  vehicleDetailPAA: `PAA status :`,
  vehicleDetailPhone: `Phone No. :`,
  vehicleDetailRegNo: `Registration No. :`,
  vehicleDetailRegYear: `Registration Year :`,
  vehicleDetailTitle: `Vehicle Warning`,
  vehicleDetailVehicleStatusAve: `Ave. Fuel Consumption :`,
  vehicleDetailVehicleStatusDistance: `Distance Traveled :`,
  vehicleDetailVehicleStatusFuel: `Fuel level :`,
  vehicleDetailVehicleStatusRange: `Driving Range :`,
  vehicleDetailVehicleStatusTitle: `<Vehicle Status>`,
  vehicleDetailVin: `VIN :`,
  vehicleDetailWarningLampHeaderDate: `date`,
  vehicleDetailWarningLampHeaderDetail: `Detail`,
  vehicleDetailWarningLampHeaderTime: `time`,
  vehicleDetailWarningLampTitle: `<Warning Lamp Status>`,
  vehicleListCustomerIC: `Customer IC`,
  vehicleListCustomerInfo: `Customer Info.`,
  vehicleListCustomerName: `Name`,
  vehicleListCustomerPhone: `Phone`,
  vehicleListModel: `Model`,
  vehicleListPAA: `PAA Stats`,
  vehicleListPAAMember: `Member`,
  vehicleListPAANonMember: `Non-Member`,
  vehicleListRegNo: `Registration No.`,
  vehicleListTime: `Time Stamp`,
  vehicleListTitle: `<Vehicle List>`,
  vehicleListVin: `VIN`,
  vehicleListWarning: `Warning Status`,
  vehicleListWarningUndefined: `未定義({eventId})`,

  Event_completeCarRegistrationEventName: `おクルマの登録完了`,
  Event_completeCarRegistrationWarningTitle: `-`,
  Event_completeCarRegistrationWarningHint: `-`,
  Event_completeCarRegistrationNotificationTitle: `リモートアクセス`,
  Event_completeCarRegistrationNotificationBody: `おクルマの登録が完了しました。`,
  Event_completeCarRegistrationHome: `-`,
  Event_completeCarRegistrationDialogTitle: `おクルマの登録完了`,
  Event_completeCarRegistrationDialogBody: `{model}の登録が完了しました。「{userType}」で利用できます。`,
  Event_completeCarRegistrationDialogAction: `-`,
  Event_completeCarRegistrationDialogAction2: `-`,
  Event_completeCarRegistrationDialogClose: `OK`,
  Event_completeCarRegistrationAlertTitle: `-`,
  Event_completeCarRegistrationAlertBody: `-`,
  Event_completeCarRegistrationLocation: `-`,
  Event_completeCarRegistrationRemoteControl: `-`,
  Event_completeCarRegistrationDashboard: `-`,
  Event_waitingForCarRegistrationEventName: `おクルマの登録許可待ち`,
  Event_waitingForCarRegistrationWarningTitle: `-`,
  Event_waitingForCarRegistrationWarningHint: `-`,
  Event_waitingForCarRegistrationNotificationTitle: `-`,
  Event_waitingForCarRegistrationNotificationBody: `-`,
  Event_waitingForCarRegistrationHome: `-`,
  Event_waitingForCarRegistrationDialogTitle: `おクルマの登録許可待ち`,
  Event_waitingForCarRegistrationDialogBody: `おクルマのオーナーに「共有ユーザー追加」を許可してもらうと登録が完了します。\n（24時間以内の許可が必要です）`,
  Event_waitingForCarRegistrationDialogAction: `-`,
  Event_waitingForCarRegistrationDialogAction2: `-`,
  Event_waitingForCarRegistrationDialogClose: `OK`,
  Event_waitingForCarRegistrationAlertTitle: `-`,
  Event_waitingForCarRegistrationAlertBody: `-`,
  Event_waitingForCarRegistrationLocation: `-`,
  Event_waitingForCarRegistrationRemoteControl: `-`,
  Event_waitingForCarRegistrationDashboard: `-`,
  Event_addSharedUserEventName: `共有ユーザー追加`,
  Event_addSharedUserWarningTitle: `-`,
  Event_addSharedUserWarningHint: `-`,
  Event_addSharedUserNotificationTitle: `リモートアクセス`,
  Event_addSharedUserNotificationBody: `共有ユーザーを追加します。共有を許可してください。`,
  Event_addSharedUserHome: `-`,
  Event_addSharedUserDialogTitle: `共有ユーザー追加`,
  Event_addSharedUserDialogBody: `{name}さんに{carName}を「{userType}」の権限で共有します。許可しますか？`,
  Event_addSharedUserDialogAction: `許可する`,
  Event_addSharedUserDialogAction2: `許可しない`,
  Event_addSharedUserDialogClose: `-`,
  Event_addSharedUserAlertTitle: `-`,
  Event_addSharedUserAlertBody: `-`,
  Event_addSharedUserLocation: `-`,
  Event_addSharedUserRemoteControl: `-`,
  Event_addSharedUserDashboard: `-`,
  Event_sosCallManualEventName: `緊急通報(手動)`,
  Event_sosCallManualWarningTitle: `緊急通報(手動)`,
  Event_sosCallManualWarningHint: `-`,
  Event_sosCallManualNotificationTitle: `車両からのお知らせ`,
  Event_sosCallManualNotificationBody: `緊急通報(手動)を発信しました。`,
  Event_sosCallManualHome: `緊急通報(手動)を発信しました。`,
  Event_sosCallManualDialogTitle: `車両からのお知らせ`,
  Event_sosCallManualDialogBody: `{model}の緊急通報(手動)を発信しました。`,
  Event_sosCallManualDialogAction: `車両からのお知らせを開く`,
  Event_sosCallManualDialogAction2: `-`,
  Event_sosCallManualDialogClose: `OK`,
  Event_sosCallManualAlertTitle: `緊急通報(手動)を発信しました。`,
  Event_sosCallManualAlertBody: `緊急通報(手動)を発信しました。`,
  Event_sosCallManualLocation: `-`,
  Event_sosCallManualRemoteControl: `-`,
  Event_sosCallManualDashboard: `緊急通報(手動)`,
  Event_sosCallAutoEventName: `緊急通報(自動)`,
  Event_sosCallAutoWarningTitle: `緊急通報(自動)`,
  Event_sosCallAutoWarningHint: `-`,
  Event_sosCallAutoNotificationTitle: `車両からのお知らせ`,
  Event_sosCallAutoNotificationBody: `緊急通報(自動)を発信しました。`,
  Event_sosCallAutoHome: `緊急通報(自動)を発信しました。`,
  Event_sosCallAutoDialogTitle: `車両からのお知らせ`,
  Event_sosCallAutoDialogBody: `{model}の緊急通報(自動)を発信しました。`,
  Event_sosCallAutoDialogAction: `車両からのお知らせを開く`,
  Event_sosCallAutoDialogAction2: `-`,
  Event_sosCallAutoDialogClose: `OK`,
  Event_sosCallAutoAlertTitle: `緊急通報(自動)を発信しました。`,
  Event_sosCallAutoAlertBody: `緊急通報(自動)を発信しました。`,
  Event_sosCallAutoLocation: `-`,
  Event_sosCallAutoRemoteControl: `-`,
  Event_sosCallAutoDashboard: `緊急通報(自動)`,
  Event_lowDriveBatteryAlert3EventName: `駆動用電池の機能低下`,
  Event_lowDriveBatteryAlert3WarningTitle: `駆動用電池の機能低下`,
  Event_lowDriveBatteryAlert3WarningHint: `ただちにPeroduaサービス工場で点検を受けてください。`,
  Event_lowDriveBatteryAlert3NotificationTitle: `車両からのお知らせ`,
  Event_lowDriveBatteryAlert3NotificationBody: `駆動用電池の機能低下を検知しました。`,
  Event_lowDriveBatteryAlert3Home: `駆動用電池の機能低下を検知しました。`,
  Event_lowDriveBatteryAlert3DialogTitle: `車両からのお知らせ`,
  Event_lowDriveBatteryAlert3DialogBody: `{model}の駆動用電池の機能低下を検知しました。`,
  Event_lowDriveBatteryAlert3DialogAction: `車両からのお知らせを開く`,
  Event_lowDriveBatteryAlert3DialogAction2: `-`,
  Event_lowDriveBatteryAlert3DialogClose: `OK`,
  Event_lowDriveBatteryAlert3AlertTitle: `駆動用電池の機能低下を検知しました。`,
  Event_lowDriveBatteryAlert3AlertBody: `駆動用電池の機能低下を検知しました。`,
  Event_lowDriveBatteryAlert3Location: `-`,
  Event_lowDriveBatteryAlert3RemoteControl: `-`,
  Event_lowDriveBatteryAlert3Dashboard: `駆動用電池の機能低下`,
  Event_lowDriveBatteryAlert2EventName: `駆動用電池の機能低下`,
  Event_lowDriveBatteryAlert2WarningTitle: `駆動用電池の機能低下`,
  Event_lowDriveBatteryAlert2WarningHint: `ただちにPeroduaサービス工場で点検を受けてください。`,
  Event_lowDriveBatteryAlert2NotificationTitle: `車両からのお知らせ`,
  Event_lowDriveBatteryAlert2NotificationBody: `駆動用電池の機能低下を検知しました。`,
  Event_lowDriveBatteryAlert2Home: `駆動用電池の機能低下を検知しました。`,
  Event_lowDriveBatteryAlert2DialogTitle: `車両からのお知らせ`,
  Event_lowDriveBatteryAlert2DialogBody: `{model}の駆動用電池の機能低下を検知しました。`,
  Event_lowDriveBatteryAlert2DialogAction: `車両からのお知らせを開く`,
  Event_lowDriveBatteryAlert2DialogAction2: `-`,
  Event_lowDriveBatteryAlert2DialogClose: `OK`,
  Event_lowDriveBatteryAlert2AlertTitle: `駆動用電池の機能低下を検知しました。`,
  Event_lowDriveBatteryAlert2AlertBody: `駆動用電池の機能低下を検知しました。`,
  Event_lowDriveBatteryAlert2Location: `-`,
  Event_lowDriveBatteryAlert2RemoteControl: `-`,
  Event_lowDriveBatteryAlert2Dashboard: `駆動用電池の機能低下`,
  Event_lowDriveBatteryAlert1EventName: `駆動用電池の機能低下`,
  Event_lowDriveBatteryAlert1WarningTitle: `駆動用電池の機能低下`,
  Event_lowDriveBatteryAlert1WarningHint: `ただちにPeroduaサービス工場で点検を受けてください。`,
  Event_lowDriveBatteryAlert1NotificationTitle: `車両からのお知らせ`,
  Event_lowDriveBatteryAlert1NotificationBody: `駆動用電池の機能低下を検知しました。`,
  Event_lowDriveBatteryAlert1Home: `駆動用電池の機能低下を検知しました。`,
  Event_lowDriveBatteryAlert1DialogTitle: `車両からのお知らせ`,
  Event_lowDriveBatteryAlert1DialogBody: `{model}の駆動用電池の機能低下を検知しました。`,
  Event_lowDriveBatteryAlert1DialogAction: `車両からのお知らせを開く`,
  Event_lowDriveBatteryAlert1DialogAction2: `-`,
  Event_lowDriveBatteryAlert1DialogClose: `OK`,
  Event_lowDriveBatteryAlert1AlertTitle: `駆動用電池の機能低下を検知しました。`,
  Event_lowDriveBatteryAlert1AlertBody: `駆動用電池の機能低下を検知しました。`,
  Event_lowDriveBatteryAlert1Location: `-`,
  Event_lowDriveBatteryAlert1RemoteControl: `-`,
  Event_lowDriveBatteryAlert1Dashboard: `駆動用電池の機能低下`,
  Event_backSensorAlertEventName: `バックソナー故障`,
  Event_backSensorAlertWarningTitle: `バックソナー故障`,
  Event_backSensorAlertWarningHint: `Peroduaサービス工場で点検を受けてください。`,
  Event_backSensorAlertNotificationTitle: `車両からのお知らせ`,
  Event_backSensorAlertNotificationBody: `バックソナー故障を検知しました。`,
  Event_backSensorAlertHome: `バックソナー故障を検知しました。`,
  Event_backSensorAlertDialogTitle: `車両からのお知らせ`,
  Event_backSensorAlertDialogBody: `{model}のバックソナー故障を検知しました。`,
  Event_backSensorAlertDialogAction: `車両からのお知らせを開く`,
  Event_backSensorAlertDialogAction2: `-`,
  Event_backSensorAlertDialogClose: `OK`,
  Event_backSensorAlertAlertTitle: `バックソナー故障を検知しました。`,
  Event_backSensorAlertAlertBody: `バックソナー故障を検知しました。`,
  Event_backSensorAlertLocation: `-`,
  Event_backSensorAlertRemoteControl: `-`,
  Event_backSensorAlertDashboard: `バックソナー故障`,
  Event_cornerSensorFrontAlertEventName: `コーナーセンサー故障`,
  Event_cornerSensorFrontAlertWarningTitle: `コーナーセンサー故障`,
  Event_cornerSensorFrontAlertWarningHint: `Peroduaサービス工場で点検を受けてください。`,
  Event_cornerSensorFrontAlertNotificationTitle: `車両からのお知らせ`,
  Event_cornerSensorFrontAlertNotificationBody: `コーナ―センサー故障を検知しました。`,
  Event_cornerSensorFrontAlertHome: `コーナ―センサー故障を検知しました。`,
  Event_cornerSensorFrontAlertDialogTitle: `車両からのお知らせ`,
  Event_cornerSensorFrontAlertDialogBody: `{model}のコーナ―センサー故障を検知しました。`,
  Event_cornerSensorFrontAlertDialogAction: `車両からのお知らせを開く`,
  Event_cornerSensorFrontAlertDialogAction2: `-`,
  Event_cornerSensorFrontAlertDialogClose: `OK`,
  Event_cornerSensorFrontAlertAlertTitle: `コーナ―センサー故障を検知しました。`,
  Event_cornerSensorFrontAlertAlertBody: `コーナ―センサー故障を検知しました。`,
  Event_cornerSensorFrontAlertLocation: `-`,
  Event_cornerSensorFrontAlertRemoteControl: `-`,
  Event_cornerSensorFrontAlertDashboard: `コーナーセンサー故障`,
  Event_cornerSensorRearAlertEventName: `コーナーセンサー故障`,
  Event_cornerSensorRearAlertWarningTitle: `コーナーセンサー故障`,
  Event_cornerSensorRearAlertWarningHint: `Peroduaサービス工場で点検を受けてください。`,
  Event_cornerSensorRearAlertNotificationTitle: `車両からのお知らせ`,
  Event_cornerSensorRearAlertNotificationBody: `コーナ―センサー故障を検知しました。`,
  Event_cornerSensorRearAlertHome: `コーナ―センサー故障を検知しました。`,
  Event_cornerSensorRearAlertDialogTitle: `車両からのお知らせ`,
  Event_cornerSensorRearAlertDialogBody: `{model}のコーナ―センサー故障を検知しました。`,
  Event_cornerSensorRearAlertDialogAction: `車両からのお知らせを開く`,
  Event_cornerSensorRearAlertDialogAction2: `-`,
  Event_cornerSensorRearAlertDialogClose: `OK`,
  Event_cornerSensorRearAlertAlertTitle: `コーナ―センサー故障を検知しました。`,
  Event_cornerSensorRearAlertAlertBody: `コーナ―センサー故障を検知しました。`,
  Event_cornerSensorRearAlertLocation: `-`,
  Event_cornerSensorRearAlertRemoteControl: `-`,
  Event_cornerSensorRearAlertDashboard: `コーナーセンサー故障`,
  Event_cornerSensorAllAlertEventName: `コーナーセンサー故障`,
  Event_cornerSensorAllAlertWarningTitle: `コーナーセンサー故障`,
  Event_cornerSensorAllAlertWarningHint: `Peroduaサービス工場で点検を受けてください。`,
  Event_cornerSensorAllAlertNotificationTitle: `車両からのお知らせ`,
  Event_cornerSensorAllAlertNotificationBody: `コーナ―センサー故障を検知しました。`,
  Event_cornerSensorAllAlertHome: `コーナ―センサー故障を検知しました。`,
  Event_cornerSensorAllAlertDialogTitle: `車両からのお知らせ`,
  Event_cornerSensorAllAlertDialogBody: `{model}のコーナ―センサー故障を検知しました。`,
  Event_cornerSensorAllAlertDialogAction: `車両からのお知らせを開く`,
  Event_cornerSensorAllAlertDialogAction2: `-`,
  Event_cornerSensorAllAlertDialogClose: `OK`,
  Event_cornerSensorAllAlertAlertTitle: `コーナ―センサー故障を検知しました。`,
  Event_cornerSensorAllAlertAlertBody: `コーナ―センサー故障を検知しました。`,
  Event_cornerSensorAllAlertLocation: `-`,
  Event_cornerSensorAllAlertRemoteControl: `-`,
  Event_cornerSensorAllAlertDashboard: `コーナーセンサー故障`,
  Event_acousticVehicleSystemAlertEventName: `車両接近通報スピーカー回路故障`,
  Event_acousticVehicleSystemAlertWarningTitle: `車両接近通報スピーカー回路故障`,
  Event_acousticVehicleSystemAlertWarningHint: `Peroduaサービス工場で点検を受けてください。`,
  Event_acousticVehicleSystemAlertNotificationTitle: `車両からのお知らせ`,
  Event_acousticVehicleSystemAlertNotificationBody: `車両接近通報装置故障を検知しました。`,
  Event_acousticVehicleSystemAlertHome: `車両接近通報装置故障を検知しました。`,
  Event_acousticVehicleSystemAlertDialogTitle: `車両からのお知らせ`,
  Event_acousticVehicleSystemAlertDialogBody: `{model}の車両接近通報装置故障を検知しました。`,
  Event_acousticVehicleSystemAlertDialogAction: `車両からのお知らせを開く`,
  Event_acousticVehicleSystemAlertDialogAction2: `-`,
  Event_acousticVehicleSystemAlertDialogClose: `OK`,
  Event_acousticVehicleSystemAlertAlertTitle: `車両接近通報装置故障を検知しました。`,
  Event_acousticVehicleSystemAlertAlertBody: `車両接近通報装置故障を検知しました。`,
  Event_acousticVehicleSystemAlertLocation: `-`,
  Event_acousticVehicleSystemAlertRemoteControl: `-`,
  Event_acousticVehicleSystemAlertDashboard: `車両接近通報スピーカー回路故障`,
  Event_bsmAlertEventName: `BSM故障`,
  Event_bsmAlertWarningTitle: `BSM故障`,
  Event_bsmAlertWarningHint: `Peroduaサービス工場で点検を受けてください。`,
  Event_bsmAlertNotificationTitle: `車両からのお知らせ`,
  Event_bsmAlertNotificationBody: `BSM故障を検知しました。`,
  Event_bsmAlertHome: `BSM故障を検知しました。`,
  Event_bsmAlertDialogTitle: `車両からのお知らせ`,
  Event_bsmAlertDialogBody: `{model}のBSM故障を検知しました。`,
  Event_bsmAlertDialogAction: `車両からのお知らせを開く`,
  Event_bsmAlertDialogAction2: `-`,
  Event_bsmAlertDialogClose: `OK`,
  Event_bsmAlertAlertTitle: `BSM故障を検知しました。`,
  Event_bsmAlertAlertBody: `BSM故障を検知しました。`,
  Event_bsmAlertLocation: `-`,
  Event_bsmAlertRemoteControl: `-`,
  Event_bsmAlertDashboard: `BSM故障`,
  Event_lkcSystemAlertEventName: `LKCシステム故障`,
  Event_lkcSystemAlertWarningTitle: `LKCシステム故障`,
  Event_lkcSystemAlertWarningHint: `Peroduaサービス工場で点検を受けてください。`,
  Event_lkcSystemAlertNotificationTitle: `車両からのお知らせ`,
  Event_lkcSystemAlertNotificationBody: `LKCの異常を検知しました。`,
  Event_lkcSystemAlertHome: `LKCの異常を検知しました。`,
  Event_lkcSystemAlertDialogTitle: `車両からのお知らせ`,
  Event_lkcSystemAlertDialogBody: `{model}のLKCの異常を検知しました。`,
  Event_lkcSystemAlertDialogAction: `車両からのお知らせを開く`,
  Event_lkcSystemAlertDialogAction2: `-`,
  Event_lkcSystemAlertDialogClose: `OK`,
  Event_lkcSystemAlertAlertTitle: `LKCの異常を検知しました。`,
  Event_lkcSystemAlertAlertBody: `LKCの異常を検知しました。`,
  Event_lkcSystemAlertLocation: `-`,
  Event_lkcSystemAlertRemoteControl: `-`,
  Event_lkcSystemAlertDashboard: `LKCシステム故障`,
  Event_accBreakAlertEventName: `ACCブレーキ保持中故障`,
  Event_accBreakAlertWarningTitle: `ACCブレーキ保持中故障`,
  Event_accBreakAlertWarningHint: `Peroduaサービス工場で点検を受けてください。`,
  Event_accBreakAlertNotificationTitle: `車両からのお知らせ`,
  Event_accBreakAlertNotificationBody: `ACCの停車保持中のシステム異常を検知しました。`,
  Event_accBreakAlertHome: `ACCの停車保持中のシステム異常を検知しました。`,
  Event_accBreakAlertDialogTitle: `車両からのお知らせ`,
  Event_accBreakAlertDialogBody: `{model}のACCの停車保持中のシステム異常を検知しました。`,
  Event_accBreakAlertDialogAction: `車両からのお知らせを開く`,
  Event_accBreakAlertDialogAction2: `-`,
  Event_accBreakAlertDialogClose: `OK`,
  Event_accBreakAlertAlertTitle: `ACCの停車保持中のシステム異常を検知しました。`,
  Event_accBreakAlertAlertBody: `ACCの停車保持中のシステム異常を検知しました。`,
  Event_accBreakAlertLocation: `-`,
  Event_accBreakAlertRemoteControl: `-`,
  Event_accBreakAlertDashboard: `ACCブレーキ保持中故障`,
  Event_rearDiffLockAlertEventName: `リアデフロック異常`,
  Event_rearDiffLockAlertWarningTitle: `リアデフロック異常`,
  Event_rearDiffLockAlertWarningHint: `Peroduaサービス工場で点検を受けてください。`,
  Event_rearDiffLockAlertNotificationTitle: `車両からのお知らせ`,
  Event_rearDiffLockAlertNotificationBody: `リアデフロック異常を検知しました。`,
  Event_rearDiffLockAlertHome: `リアデフロック異常を検知しました。`,
  Event_rearDiffLockAlertDialogTitle: `車両からのお知らせ`,
  Event_rearDiffLockAlertDialogBody: `{model}のリアデフロック異常を検知しました。`,
  Event_rearDiffLockAlertDialogAction: `車両からのお知らせを開く`,
  Event_rearDiffLockAlertDialogAction2: `-`,
  Event_rearDiffLockAlertDialogClose: `OK`,
  Event_rearDiffLockAlertAlertTitle: `リアデフロック異常を検知しました。`,
  Event_rearDiffLockAlertAlertBody: `リアデフロック異常を検知しました。`,
  Event_rearDiffLockAlertLocation: `-`,
  Event_rearDiffLockAlertRemoteControl: `-`,
  Event_rearDiffLockAlertDashboard: `リアデフロック異常`,
  Event_dummyAirbagAlertEventName: `ダミーエアバッグ展開`,
  Event_dummyAirbagAlertWarningTitle: `-`,
  Event_dummyAirbagAlertWarningHint: `-`,
  Event_dummyAirbagAlertNotificationTitle: `車両からのお知らせ`,
  Event_dummyAirbagAlertNotificationBody: `[ダミー]エアバッグの作動を検知しました。`,
  Event_dummyAirbagAlertHome: `[ダミー]エアバッグの作動を検知しました。`,
  Event_dummyAirbagAlertDialogTitle: `車両からのお知らせ`,
  Event_dummyAirbagAlertDialogBody: `{model}のエアバッグの作動を検知しました。`,
  Event_dummyAirbagAlertDialogAction: `車両からのお知らせを開く`,
  Event_dummyAirbagAlertDialogAction2: `-`,
  Event_dummyAirbagAlertDialogClose: `OK`,
  Event_dummyAirbagAlertAlertTitle: `[ダミー]エアバッグの作動を検知しました。`,
  Event_dummyAirbagAlertAlertBody: `[ダミー]エアバッグの作動を検知しました。`,
  Event_dummyAirbagAlertLocation: `-`,
  Event_dummyAirbagAlertRemoteControl: `-`,
  Event_dummyAirbagAlertDashboard: `[ダミー]エアバッグの作動を検知しました。`,
  Event_remoteCmdFailedConditionNotSatisfiedEventName: `リモートコマンド条件非成立`,
  Event_remoteCmdFailedConditionNotSatisfiedWarningTitle: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedWarningHint: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedNotificationTitle: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedNotificationBody: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedHome: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedDialogTitle: `リモートコマンド`,
  Event_remoteCmdFailedConditionNotSatisfiedDialogBody: `遠隔操作が実行可能な状態ではなかったため、操作を中断しました。操作可能な状態になってから再度操作ください`,
  Event_remoteCmdFailedConditionNotSatisfiedDialogAction: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedDialogAction2: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedDialogClose: `OK`,
  Event_remoteCmdFailedConditionNotSatisfiedAlertTitle: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedAlertBody: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedLocation: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedRemoteControl: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedDashboard: `-`,
  Event_remoteCmdAcceptedEventName: `リモートコマンド受付完了`,
  Event_remoteCmdAcceptedWarningTitle: `-`,
  Event_remoteCmdAcceptedWarningHint: `-`,
  Event_remoteCmdAcceptedNotificationTitle: `-`,
  Event_remoteCmdAcceptedNotificationBody: `-`,
  Event_remoteCmdAcceptedHome: `-`,
  Event_remoteCmdAcceptedDialogTitle: `コマンド受付完了`,
  Event_remoteCmdAcceptedDialogBody: `コマンドの実行完了には、数秒から数分かかることがあります。`,
  Event_remoteCmdAcceptedDialogAction: `-`,
  Event_remoteCmdAcceptedDialogAction2: `-`,
  Event_remoteCmdAcceptedDialogClose: `OK`,
  Event_remoteCmdAcceptedAlertTitle: `-`,
  Event_remoteCmdAcceptedAlertBody: `-`,
  Event_remoteCmdAcceptedLocation: `-`,
  Event_remoteCmdAcceptedRemoteControl: `-`,
  Event_remoteCmdAcceptedDashboard: `-`,
  Event_commonNetworkErrorEventName: `通信エラー`,
  Event_commonNetworkErrorWarningTitle: `-`,
  Event_commonNetworkErrorWarningHint: `-`,
  Event_commonNetworkErrorNotificationTitle: `-`,
  Event_commonNetworkErrorNotificationBody: `-`,
  Event_commonNetworkErrorHome: `-`,
  Event_commonNetworkErrorDialogTitle: `-`,
  Event_commonNetworkErrorDialogBody: `通信エラーが発生しました。時間をおいて再度お試しください。`,
  Event_commonNetworkErrorDialogAction: `-`,
  Event_commonNetworkErrorDialogAction2: `-`,
  Event_commonNetworkErrorDialogClose: `OK`,
  Event_commonNetworkErrorAlertTitle: `-`,
  Event_commonNetworkErrorAlertBody: `-`,
  Event_commonNetworkErrorLocation: `-`,
  Event_commonNetworkErrorRemoteControl: `-`,
  Event_commonNetworkErrorDashboard: `-`,
  Event_getGpsErrorEventName: `位置情報取得エラー`,
  Event_getGpsErrorWarningTitle: `-`,
  Event_getGpsErrorWarningHint: `-`,
  Event_getGpsErrorNotificationTitle: `-`,
  Event_getGpsErrorNotificationBody: `-`,
  Event_getGpsErrorHome: `-`,
  Event_getGpsErrorDialogTitle: `位置情報サービス`,
  Event_getGpsErrorDialogBody: `スマートフォンの位置情報が取得できませんでした。再度取得しますか？`,
  Event_getGpsErrorDialogAction: `再取得する`,
  Event_getGpsErrorDialogAction2: `-`,
  Event_getGpsErrorDialogClose: `閉じる`,
  Event_getGpsErrorAlertTitle: `-`,
  Event_getGpsErrorAlertBody: `-`,
  Event_getGpsErrorLocation: `-`,
  Event_getGpsErrorRemoteControl: `-`,
  Event_getGpsErrorDashboard: `-`,
  Event_remoteCmdCenterFailEventName: `リモートコマンド受付エラー`,
  Event_remoteCmdCenterFailWarningTitle: `-`,
  Event_remoteCmdCenterFailWarningHint: `-`,
  Event_remoteCmdCenterFailNotificationTitle: `-`,
  Event_remoteCmdCenterFailNotificationBody: `-`,
  Event_remoteCmdCenterFailHome: `-`,
  Event_remoteCmdCenterFailDialogTitle: `リモートコマンド`,
  Event_remoteCmdCenterFailDialogBody: `操作を受付できませんでした。時間をおいてから再度操作ください。`,
  Event_remoteCmdCenterFailDialogAction: `-`,
  Event_remoteCmdCenterFailDialogAction2: `-`,
  Event_remoteCmdCenterFailDialogClose: `OK`,
  Event_remoteCmdCenterFailAlertTitle: `-`,
  Event_remoteCmdCenterFailAlertBody: `-`,
  Event_remoteCmdCenterFailLocation: `-`,
  Event_remoteCmdCenterFailRemoteControl: `-`,
  Event_remoteCmdCenterFailDashboard: `-`,
  Event_remoteCmdDuplicatedEventName: `リモートコマンド重複エラー`,
  Event_remoteCmdDuplicatedWarningTitle: `-`,
  Event_remoteCmdDuplicatedWarningHint: `-`,
  Event_remoteCmdDuplicatedNotificationTitle: `-`,
  Event_remoteCmdDuplicatedNotificationBody: `-`,
  Event_remoteCmdDuplicatedHome: `-`,
  Event_remoteCmdDuplicatedDialogTitle: `リモートコマンド`,
  Event_remoteCmdDuplicatedDialogBody: `他の操作を受付しているため操作を中断しました。時間をおいてから再度操作ください。`,
  Event_remoteCmdDuplicatedDialogAction: `-`,
  Event_remoteCmdDuplicatedDialogAction2: `-`,
  Event_remoteCmdDuplicatedDialogClose: `OK`,
  Event_remoteCmdDuplicatedAlertTitle: `-`,
  Event_remoteCmdDuplicatedAlertBody: `-`,
  Event_remoteCmdDuplicatedLocation: `-`,
  Event_remoteCmdDuplicatedRemoteControl: `-`,
  Event_remoteCmdDuplicatedDashboard: `-`,
  Event_serviceReminderAlertTireEventName: `メンテナンス(タイヤ空気圧)`,
  Event_serviceReminderAlertTireWarningTitle: `-`,
  Event_serviceReminderAlertTireWarningHint: `-`,
  Event_serviceReminderAlertTireNotificationTitle: `サービスリマインダー`,
  Event_serviceReminderAlertTireNotificationBody: `タイヤ空気圧の点検・調整が必要です。`,
  Event_serviceReminderAlertTireHome: `-`,
  Event_serviceReminderAlertTireDialogTitle: `サービスリマインダー`,
  Event_serviceReminderAlertTireDialogBody: `タイヤ空気圧の点検・調整が必要です。`,
  Event_serviceReminderAlertTireDialogAction: `サービスリマインダーを開く`,
  Event_serviceReminderAlertTireDialogAction2: `-`,
  Event_serviceReminderAlertTireDialogClose: `OK`,
  Event_serviceReminderAlertTireAlertTitle: `-`,
  Event_serviceReminderAlertTireAlertBody: `-`,
  Event_serviceReminderAlertTireLocation: `-`,
  Event_serviceReminderAlertTireRemoteControl: `-`,
  Event_serviceReminderAlertTireDashboard: `-`,
  Event_serviceReminderAlertBrakeRrEventName: `メンテナンス(ブレーキパッドＲr)`,
  Event_serviceReminderAlertBrakeRrWarningTitle: `-`,
  Event_serviceReminderAlertBrakeRrWarningHint: `-`,
  Event_serviceReminderAlertBrakeRrNotificationTitle: `サービスリマインダー`,
  Event_serviceReminderAlertBrakeRrNotificationBody: `リアブレーキパッドの点検・交換が必要です。`,
  Event_serviceReminderAlertBrakeRrHome: `-`,
  Event_serviceReminderAlertBrakeRrDialogTitle: `サービスリマインダー`,
  Event_serviceReminderAlertBrakeRrDialogBody: `リアブレーキパッドの点検・交換が必要です。`,
  Event_serviceReminderAlertBrakeRrDialogAction: `サービスリマインダーを開く`,
  Event_serviceReminderAlertBrakeRrDialogAction2: `-`,
  Event_serviceReminderAlertBrakeRrDialogClose: `OK`,
  Event_serviceReminderAlertBrakeRrAlertTitle: `-`,
  Event_serviceReminderAlertBrakeRrAlertBody: `-`,
  Event_serviceReminderAlertBrakeRrLocation: `-`,
  Event_serviceReminderAlertBrakeRrRemoteControl: `-`,
  Event_serviceReminderAlertBrakeRrDashboard: `-`,
  Event_serviceReminderAlertBrakeFrEventName: `メンテナンス(ブレーキパッドFr)`,
  Event_serviceReminderAlertBrakeFrWarningTitle: `-`,
  Event_serviceReminderAlertBrakeFrWarningHint: `-`,
  Event_serviceReminderAlertBrakeFrNotificationTitle: `サービスリマインダー`,
  Event_serviceReminderAlertBrakeFrNotificationBody: `フロントブレーキパッドの点検・交換が必要です。`,
  Event_serviceReminderAlertBrakeFrHome: `-`,
  Event_serviceReminderAlertBrakeFrDialogTitle: `サービスリマインダー`,
  Event_serviceReminderAlertBrakeFrDialogBody: `フロントブレーキパッドの点検・交換が必要です。`,
  Event_serviceReminderAlertBrakeFrDialogAction: `サービスリマインダーを開く`,
  Event_serviceReminderAlertBrakeFrDialogAction2: `-`,
  Event_serviceReminderAlertBrakeFrDialogClose: `OK`,
  Event_serviceReminderAlertBrakeFrAlertTitle: `-`,
  Event_serviceReminderAlertBrakeFrAlertBody: `-`,
  Event_serviceReminderAlertBrakeFrLocation: `-`,
  Event_serviceReminderAlertBrakeFrRemoteControl: `-`,
  Event_serviceReminderAlertBrakeFrDashboard: `-`,
  Event_serviceReminderAlertBrakeOilEventName: `メンテナンス(ブレーキオイル)`,
  Event_serviceReminderAlertBrakeOilWarningTitle: `-`,
  Event_serviceReminderAlertBrakeOilWarningHint: `-`,
  Event_serviceReminderAlertBrakeOilNotificationTitle: `サービスリマインダー`,
  Event_serviceReminderAlertBrakeOilNotificationBody: `ブレーキオイルの点検・交換が必要です。`,
  Event_serviceReminderAlertBrakeOilHome: `-`,
  Event_serviceReminderAlertBrakeOilDialogTitle: `サービスリマインダー`,
  Event_serviceReminderAlertBrakeOilDialogBody: `ブレーキオイルの点検・交換が必要です。`,
  Event_serviceReminderAlertBrakeOilDialogAction: `サービスリマインダーを開く`,
  Event_serviceReminderAlertBrakeOilDialogAction2: `-`,
  Event_serviceReminderAlertBrakeOilDialogClose: `OK`,
  Event_serviceReminderAlertBrakeOilAlertTitle: `-`,
  Event_serviceReminderAlertBrakeOilAlertBody: `-`,
  Event_serviceReminderAlertBrakeOilLocation: `-`,
  Event_serviceReminderAlertBrakeOilRemoteControl: `-`,
  Event_serviceReminderAlertBrakeOilDashboard: `-`,
  Event_serviceReminderAlertBatteryEventName: `メンテナンス(バッテリー)`,
  Event_serviceReminderAlertBatteryWarningTitle: `-`,
  Event_serviceReminderAlertBatteryWarningHint: `-`,
  Event_serviceReminderAlertBatteryNotificationTitle: `サービスリマインダー`,
  Event_serviceReminderAlertBatteryNotificationBody: `バッテリーの点検・交換が必要です。`,
  Event_serviceReminderAlertBatteryHome: `-`,
  Event_serviceReminderAlertBatteryDialogTitle: `サービスリマインダー`,
  Event_serviceReminderAlertBatteryDialogBody: `バッテリーの点検・交換が必要です。`,
  Event_serviceReminderAlertBatteryDialogAction: `サービスリマインダーを開く`,
  Event_serviceReminderAlertBatteryDialogAction2: `-`,
  Event_serviceReminderAlertBatteryDialogClose: `OK`,
  Event_serviceReminderAlertBatteryAlertTitle: `-`,
  Event_serviceReminderAlertBatteryAlertBody: `-`,
  Event_serviceReminderAlertBatteryLocation: `-`,
  Event_serviceReminderAlertBatteryRemoteControl: `-`,
  Event_serviceReminderAlertBatteryDashboard: `-`,
  Event_serviceReminderAlertEngOilEventName: `メンテナンス(エンジンオイル)`,
  Event_serviceReminderAlertEngOilWarningTitle: `-`,
  Event_serviceReminderAlertEngOilWarningHint: `-`,
  Event_serviceReminderAlertEngOilNotificationTitle: `サービスリマインダー`,
  Event_serviceReminderAlertEngOilNotificationBody: `エンジンオイルの点検・交換が必要です。`,
  Event_serviceReminderAlertEngOilHome: `-`,
  Event_serviceReminderAlertEngOilDialogTitle: `サービスリマインダー`,
  Event_serviceReminderAlertEngOilDialogBody: `エンジンオイルの点検・交換が必要です。`,
  Event_serviceReminderAlertEngOilDialogAction: `サービスリマインダーを開く`,
  Event_serviceReminderAlertEngOilDialogAction2: `-`,
  Event_serviceReminderAlertEngOilDialogClose: `OK`,
  Event_serviceReminderAlertEngOilAlertTitle: `-`,
  Event_serviceReminderAlertEngOilAlertBody: `-`,
  Event_serviceReminderAlertEngOilLocation: `-`,
  Event_serviceReminderAlertEngOilRemoteControl: `-`,
  Event_serviceReminderAlertEngOilDashboard: `-`,
  Event_serviceReminderAlertEventName: `点検`,
  Event_serviceReminderAlertWarningTitle: `-`,
  Event_serviceReminderAlertWarningHint: `-`,
  Event_serviceReminderAlertNotificationTitle: `サービスリマインダー`,
  Event_serviceReminderAlertNotificationBody: `点検が近づいています。`,
  Event_serviceReminderAlertHome: `-`,
  Event_serviceReminderAlertDialogTitle: `サービスリマインダー`,
  Event_serviceReminderAlertDialogBody: `点検が近づいています。`,
  Event_serviceReminderAlertDialogAction: `サービスリマインダーを開く`,
  Event_serviceReminderAlertDialogAction2: `-`,
  Event_serviceReminderAlertDialogClose: `OK`,
  Event_serviceReminderAlertAlertTitle: `-`,
  Event_serviceReminderAlertAlertBody: `-`,
  Event_serviceReminderAlertLocation: `-`,
  Event_serviceReminderAlertRemoteControl: `-`,
  Event_serviceReminderAlertDashboard: `-`,
  Event_engineProhibitEventName: `エンジン再始動不可の状態`,
  Event_engineProhibitWarningTitle: `-`,
  Event_engineProhibitWarningHint: `-`,
  Event_engineProhibitNotificationTitle: `-`,
  Event_engineProhibitNotificationBody: `-`,
  Event_engineProhibitHome: `エンジン再始動不可になっています。`,
  Event_engineProhibitDialogTitle: `-`,
  Event_engineProhibitDialogBody: `-`,
  Event_engineProhibitDialogAction: `-`,
  Event_engineProhibitDialogAction2: `-`,
  Event_engineProhibitDialogClose: `-`,
  Event_engineProhibitAlertTitle: `-`,
  Event_engineProhibitAlertBody: `-`,
  Event_engineProhibitLocation: `-`,
  Event_engineProhibitRemoteControl: `-`,
  Event_engineProhibitDashboard: `-`,
  Event_trackingOnEventName: `追跡モードがONの状態`,
  Event_trackingOnWarningTitle: `-`,
  Event_trackingOnWarningHint: `-`,
  Event_trackingOnNotificationTitle: `-`,
  Event_trackingOnNotificationBody: `-`,
  Event_trackingOnHome: `追跡モードがオンになっています。`,
  Event_trackingOnDialogTitle: `-`,
  Event_trackingOnDialogBody: `-`,
  Event_trackingOnDialogAction: `-`,
  Event_trackingOnDialogAction2: `-`,
  Event_trackingOnDialogClose: `-`,
  Event_trackingOnAlertTitle: `-`,
  Event_trackingOnAlertBody: `-`,
  Event_trackingOnLocation: `-`,
  Event_trackingOnRemoteControl: `-`,
  Event_trackingOnDashboard: `-`,
  Event_ukkariDoorEventName: `うっかり通知　ドアロック`,
  Event_ukkariDoorWarningTitle: `-`,
  Event_ukkariDoorWarningHint: `-`,
  Event_ukkariDoorNotificationTitle: `うっかり通知`,
  Event_ukkariDoorNotificationBody: `ドアが開錠しています。`,
  Event_ukkariDoorHome: `-`,
  Event_ukkariDoorDialogTitle: `うっかり通知`,
  Event_ukkariDoorDialogBody: `{model}のドアが開錠しています。`,
  Event_ukkariDoorDialogAction: `車両状況を開く`,
  Event_ukkariDoorDialogAction2: `-`,
  Event_ukkariDoorDialogClose: `OK`,
  Event_ukkariDoorAlertTitle: `-`,
  Event_ukkariDoorAlertBody: `-`,
  Event_ukkariDoorLocation: `-`,
  Event_ukkariDoorRemoteControl: `-`,
  Event_ukkariDoorDashboard: `-`,
  Event_ukkariLightEventName: `うっかり通知　ヘッドライト`,
  Event_ukkariLightWarningTitle: `-`,
  Event_ukkariLightWarningHint: `-`,
  Event_ukkariLightNotificationTitle: `うっかり通知`,
  Event_ukkariLightNotificationBody: `ヘッドライトがついています。`,
  Event_ukkariLightHome: `-`,
  Event_ukkariLightDialogTitle: `うっかり通知`,
  Event_ukkariLightDialogBody: `{model}のヘッドライトがついています。`,
  Event_ukkariLightDialogAction: `車両状況を開く`,
  Event_ukkariLightDialogAction2: `-`,
  Event_ukkariLightDialogClose: `OK`,
  Event_ukkariLightAlertTitle: `-`,
  Event_ukkariLightAlertBody: `-`,
  Event_ukkariLightLocation: `-`,
  Event_ukkariLightRemoteControl: `-`,
  Event_ukkariLightDashboard: `-`,
  Event_ukkariEngProhibitEventName: `うっかり通知　エンジン再始動不可`,
  Event_ukkariEngProhibitWarningTitle: `-`,
  Event_ukkariEngProhibitWarningHint: `-`,
  Event_ukkariEngProhibitNotificationTitle: `うっかり通知`,
  Event_ukkariEngProhibitNotificationBody: `エンジン再始動が不可になっています。`,
  Event_ukkariEngProhibitHome: `-`,
  Event_ukkariEngProhibitDialogTitle: `うっかり通知`,
  Event_ukkariEngProhibitDialogBody: `{model}のエンジン再始動が不可になっています。`,
  Event_ukkariEngProhibitDialogAction: `車両状況を開く`,
  Event_ukkariEngProhibitDialogAction2: `-`,
  Event_ukkariEngProhibitDialogClose: `OK`,
  Event_ukkariEngProhibitAlertTitle: `-`,
  Event_ukkariEngProhibitAlertBody: `-`,
  Event_ukkariEngProhibitLocation: `-`,
  Event_ukkariEngProhibitRemoteControl: `-`,
  Event_ukkariEngProhibitDashboard: `-`,
  Event_ukkariHazardEventName: `うっかり通知　ハザードランプ`,
  Event_ukkariHazardWarningTitle: `-`,
  Event_ukkariHazardWarningHint: `-`,
  Event_ukkariHazardNotificationTitle: `うっかり通知`,
  Event_ukkariHazardNotificationBody: `ハザードランプがついています。`,
  Event_ukkariHazardHome: `-`,
  Event_ukkariHazardDialogTitle: `うっかり通知`,
  Event_ukkariHazardDialogBody: `{model}のハザードランプがついています。`,
  Event_ukkariHazardDialogAction: `車両状況を開く`,
  Event_ukkariHazardDialogAction2: `-`,
  Event_ukkariHazardDialogClose: `OK`,
  Event_ukkariHazardAlertTitle: `-`,
  Event_ukkariHazardAlertBody: `-`,
  Event_ukkariHazardLocation: `-`,
  Event_ukkariHazardRemoteControl: `-`,
  Event_ukkariHazardDashboard: `-`,
  Event_ukkariAirconEventName: `うっかり通知　エアコン`,
  Event_ukkariAirconWarningTitle: `-`,
  Event_ukkariAirconWarningHint: `-`,
  Event_ukkariAirconNotificationTitle: `うっかり通知`,
  Event_ukkariAirconNotificationBody: `エアコンがついています。`,
  Event_ukkariAirconHome: `-`,
  Event_ukkariAirconDialogTitle: `うっかり通知`,
  Event_ukkariAirconDialogBody: `{model}のエアコンがついています。`,
  Event_ukkariAirconDialogAction: `車両状況を開く`,
  Event_ukkariAirconDialogAction2: `-`,
  Event_ukkariAirconDialogClose: `OK`,
  Event_ukkariAirconAlertTitle: `-`,
  Event_ukkariAirconAlertBody: `-`,
  Event_ukkariAirconLocation: `-`,
  Event_ukkariAirconRemoteControl: `-`,
  Event_ukkariAirconDashboard: `-`,
  Event_ukkariEngineEventName: `うっかり通知　エンジン`,
  Event_ukkariEngineWarningTitle: `-`,
  Event_ukkariEngineWarningHint: `-`,
  Event_ukkariEngineNotificationTitle: `うっかり通知`,
  Event_ukkariEngineNotificationBody: `エンジンがついています。`,
  Event_ukkariEngineHome: `-`,
  Event_ukkariEngineDialogTitle: `うっかり通知`,
  Event_ukkariEngineDialogBody: `{model}のエンジンがついています。`,
  Event_ukkariEngineDialogAction: `車両状況を開く`,
  Event_ukkariEngineDialogAction2: `-`,
  Event_ukkariEngineDialogClose: `OK`,
  Event_ukkariEngineAlertTitle: `-`,
  Event_ukkariEngineAlertBody: `-`,
  Event_ukkariEngineLocation: `-`,
  Event_ukkariEngineRemoteControl: `-`,
  Event_ukkariEngineDashboard: `-`,
  Event_ukkariEventName: `うっかり通知`,
  Event_ukkariWarningTitle: `-`,
  Event_ukkariWarningHint: `-`,
  Event_ukkariNotificationTitle: `うっかり通知`,
  Event_ukkariNotificationBody: `操作忘れがあります。`,
  Event_ukkariHome: `-`,
  Event_ukkariDialogTitle: `うっかり通知`,
  Event_ukkariDialogBody: `{model}の操作忘れがあります。`,
  Event_ukkariDialogAction: `車両状況を開く`,
  Event_ukkariDialogAction2: `-`,
  Event_ukkariDialogClose: `OK`,
  Event_ukkariAlertTitle: `-`,
  Event_ukkariAlertBody: `-`,
  Event_ukkariLocation: `-`,
  Event_ukkariRemoteControl: `-`,
  Event_ukkariDashboard: `-`,
  Event_remoteEngineProhibitOffFailEventName: `エンジン再始動不可の解除に失敗。`,
  Event_remoteEngineProhibitOffFailWarningTitle: `-`,
  Event_remoteEngineProhibitOffFailWarningHint: `-`,
  Event_remoteEngineProhibitOffFailNotificationTitle: `リモート操作`,
  Event_remoteEngineProhibitOffFailNotificationBody: `エンジン再始動不可の解除に失敗しました。`,
  Event_remoteEngineProhibitOffFailHome: `-`,
  Event_remoteEngineProhibitOffFailDialogTitle: `リモート操作`,
  Event_remoteEngineProhibitOffFailDialogBody: `{model}のエンジン再始動不可の解除に失敗しました。`,
  Event_remoteEngineProhibitOffFailDialogAction: `車両状況を開く`,
  Event_remoteEngineProhibitOffFailDialogAction2: `-`,
  Event_remoteEngineProhibitOffFailDialogClose: `OK`,
  Event_remoteEngineProhibitOffFailAlertTitle: `-`,
  Event_remoteEngineProhibitOffFailAlertBody: `-`,
  Event_remoteEngineProhibitOffFailLocation: `-`,
  Event_remoteEngineProhibitOffFailRemoteControl: `エンジン再始動不可を解除できませんでした。`,
  Event_remoteEngineProhibitOffFailDashboard: `-`,
  Event_remoteEngineProhibitOffSuccessEventName: `エンジン再始動不可を解除する。`,
  Event_remoteEngineProhibitOffSuccessWarningTitle: `-`,
  Event_remoteEngineProhibitOffSuccessWarningHint: `-`,
  Event_remoteEngineProhibitOffSuccessNotificationTitle: `リモート操作`,
  Event_remoteEngineProhibitOffSuccessNotificationBody: `エンジン再始動不可を解除しました。`,
  Event_remoteEngineProhibitOffSuccessHome: `-`,
  Event_remoteEngineProhibitOffSuccessDialogTitle: `-`,
  Event_remoteEngineProhibitOffSuccessDialogBody: `-`,
  Event_remoteEngineProhibitOffSuccessDialogAction: `-`,
  Event_remoteEngineProhibitOffSuccessDialogAction2: `-`,
  Event_remoteEngineProhibitOffSuccessDialogClose: `-`,
  Event_remoteEngineProhibitOffSuccessAlertTitle: `-`,
  Event_remoteEngineProhibitOffSuccessAlertBody: `-`,
  Event_remoteEngineProhibitOffSuccessLocation: `-`,
  Event_remoteEngineProhibitOffSuccessRemoteControl: `エンジン再始動不可を解除しました。`,
  Event_remoteEngineProhibitOffSuccessDashboard: `-`,
  Event_remoteEngineProhibitOnFailEventName: `エンジン再始動不可に失敗`,
  Event_remoteEngineProhibitOnFailWarningTitle: `-`,
  Event_remoteEngineProhibitOnFailWarningHint: `-`,
  Event_remoteEngineProhibitOnFailNotificationTitle: `リモート操作`,
  Event_remoteEngineProhibitOnFailNotificationBody: `エンジン再始動不可に失敗しました。`,
  Event_remoteEngineProhibitOnFailHome: `-`,
  Event_remoteEngineProhibitOnFailDialogTitle: `リモート操作`,
  Event_remoteEngineProhibitOnFailDialogBody: `{model}のエンジン再始動不可に失敗しました。`,
  Event_remoteEngineProhibitOnFailDialogAction: `車両状況を開く`,
  Event_remoteEngineProhibitOnFailDialogAction2: `-`,
  Event_remoteEngineProhibitOnFailDialogClose: `OK`,
  Event_remoteEngineProhibitOnFailAlertTitle: `-`,
  Event_remoteEngineProhibitOnFailAlertBody: `-`,
  Event_remoteEngineProhibitOnFailLocation: `-`,
  Event_remoteEngineProhibitOnFailRemoteControl: `エンジン再始動不可にできませんでした。`,
  Event_remoteEngineProhibitOnFailDashboard: `-`,
  Event_remoteEngineProhibitOnSuccessEventName: `エンジン再始動不可にする。`,
  Event_remoteEngineProhibitOnSuccessWarningTitle: `-`,
  Event_remoteEngineProhibitOnSuccessWarningHint: `-`,
  Event_remoteEngineProhibitOnSuccessNotificationTitle: `リモート操作`,
  Event_remoteEngineProhibitOnSuccessNotificationBody: `エンジン再始動不可になりました。`,
  Event_remoteEngineProhibitOnSuccessHome: `-`,
  Event_remoteEngineProhibitOnSuccessDialogTitle: `-`,
  Event_remoteEngineProhibitOnSuccessDialogBody: `-`,
  Event_remoteEngineProhibitOnSuccessDialogAction: `-`,
  Event_remoteEngineProhibitOnSuccessDialogAction2: `-`,
  Event_remoteEngineProhibitOnSuccessDialogClose: `-`,
  Event_remoteEngineProhibitOnSuccessAlertTitle: `-`,
  Event_remoteEngineProhibitOnSuccessAlertBody: `-`,
  Event_remoteEngineProhibitOnSuccessLocation: `-`,
  Event_remoteEngineProhibitOnSuccessRemoteControl: `エンジン再始動不可にしました。`,
  Event_remoteEngineProhibitOnSuccessDashboard: `-`,
  Event_remoteWifiResetFailEventName: `Wi-Fi設定のリセットに失敗。`,
  Event_remoteWifiResetFailWarningTitle: `-`,
  Event_remoteWifiResetFailWarningHint: `-`,
  Event_remoteWifiResetFailNotificationTitle: `リモート操作`,
  Event_remoteWifiResetFailNotificationBody: `SSID、パスワードの初期化に失敗しました。`,
  Event_remoteWifiResetFailHome: `-`,
  Event_remoteWifiResetFailDialogTitle: `リモート操作`,
  Event_remoteWifiResetFailDialogBody: `{model}のSSID、パスワードの初期化に失敗しました。`,
  Event_remoteWifiResetFailDialogAction: `Wi-Fiモニターを開く`,
  Event_remoteWifiResetFailDialogAction2: `-`,
  Event_remoteWifiResetFailDialogClose: `OK`,
  Event_remoteWifiResetFailAlertTitle: `-`,
  Event_remoteWifiResetFailAlertBody: `-`,
  Event_remoteWifiResetFailLocation: `-`,
  Event_remoteWifiResetFailRemoteControl: `SSID、パスワードの初期化に失敗しました。`,
  Event_remoteWifiResetFailDashboard: `-`,
  Event_remoteWifiResetSuccessEventName: `Wi-Fi設定をリセットする。`,
  Event_remoteWifiResetSuccessWarningTitle: `-`,
  Event_remoteWifiResetSuccessWarningHint: `-`,
  Event_remoteWifiResetSuccessNotificationTitle: `-`,
  Event_remoteWifiResetSuccessNotificationBody: `-`,
  Event_remoteWifiResetSuccessHome: `-`,
  Event_remoteWifiResetSuccessDialogTitle: `-`,
  Event_remoteWifiResetSuccessDialogBody: `-`,
  Event_remoteWifiResetSuccessDialogAction: `-`,
  Event_remoteWifiResetSuccessDialogAction2: `-`,
  Event_remoteWifiResetSuccessDialogClose: `-`,
  Event_remoteWifiResetSuccessAlertTitle: `-`,
  Event_remoteWifiResetSuccessAlertBody: `-`,
  Event_remoteWifiResetSuccessLocation: `-`,
  Event_remoteWifiResetSuccessRemoteControl: `-`,
  Event_remoteWifiResetSuccessDashboard: `-`,
  Event_remoteWifiOffFailEventName: `Wi-Fi設定をOFFに失敗。`,
  Event_remoteWifiOffFailWarningTitle: `-`,
  Event_remoteWifiOffFailWarningHint: `-`,
  Event_remoteWifiOffFailNotificationTitle: `リモート操作`,
  Event_remoteWifiOffFailNotificationBody: `Wi-Fi OFFに失敗しました。`,
  Event_remoteWifiOffFailHome: `-`,
  Event_remoteWifiOffFailDialogTitle: `リモート操作`,
  Event_remoteWifiOffFailDialogBody: `{model}のWi-Fi OFFに失敗しました。`,
  Event_remoteWifiOffFailDialogAction: `Wi-Fiモニターを開く`,
  Event_remoteWifiOffFailDialogAction2: `-`,
  Event_remoteWifiOffFailDialogClose: `OK`,
  Event_remoteWifiOffFailAlertTitle: `-`,
  Event_remoteWifiOffFailAlertBody: `-`,
  Event_remoteWifiOffFailLocation: `-`,
  Event_remoteWifiOffFailRemoteControl: `Wi-Fi OFFに失敗しました。`,
  Event_remoteWifiOffFailDashboard: `-`,
  Event_remoteWifiOffSuccessEventName: `Wi-Fi設定をOFFにする。`,
  Event_remoteWifiOffSuccessWarningTitle: `-`,
  Event_remoteWifiOffSuccessWarningHint: `-`,
  Event_remoteWifiOffSuccessNotificationTitle: `-`,
  Event_remoteWifiOffSuccessNotificationBody: `-`,
  Event_remoteWifiOffSuccessHome: `-`,
  Event_remoteWifiOffSuccessDialogTitle: `-`,
  Event_remoteWifiOffSuccessDialogBody: `-`,
  Event_remoteWifiOffSuccessDialogAction: `-`,
  Event_remoteWifiOffSuccessDialogAction2: `-`,
  Event_remoteWifiOffSuccessDialogClose: `-`,
  Event_remoteWifiOffSuccessAlertTitle: `-`,
  Event_remoteWifiOffSuccessAlertBody: `-`,
  Event_remoteWifiOffSuccessLocation: `-`,
  Event_remoteWifiOffSuccessRemoteControl: `Wi-Fi OFFにしました。`,
  Event_remoteWifiOffSuccessDashboard: `-`,
  Event_remoteWifiOnFailEventName: `Wi-Fi設定をONに失敗。`,
  Event_remoteWifiOnFailWarningTitle: `-`,
  Event_remoteWifiOnFailWarningHint: `-`,
  Event_remoteWifiOnFailNotificationTitle: `リモート操作`,
  Event_remoteWifiOnFailNotificationBody: `Wi-Fi ONに失敗しました。`,
  Event_remoteWifiOnFailHome: `-`,
  Event_remoteWifiOnFailDialogTitle: `リモート操作`,
  Event_remoteWifiOnFailDialogBody: `{model}のWi-Fi ONに失敗しました。`,
  Event_remoteWifiOnFailDialogAction: `Wi-Fiモニターを開く`,
  Event_remoteWifiOnFailDialogAction2: `-`,
  Event_remoteWifiOnFailDialogClose: `OK`,
  Event_remoteWifiOnFailAlertTitle: `-`,
  Event_remoteWifiOnFailAlertBody: `-`,
  Event_remoteWifiOnFailLocation: `-`,
  Event_remoteWifiOnFailRemoteControl: `Wi-Fi ONに失敗しました。`,
  Event_remoteWifiOnFailDashboard: `-`,
  Event_remoteWifiOnSuccessEventName: `Wi-Fi設定をONにする。`,
  Event_remoteWifiOnSuccessWarningTitle: `-`,
  Event_remoteWifiOnSuccessWarningHint: `-`,
  Event_remoteWifiOnSuccessNotificationTitle: `-`,
  Event_remoteWifiOnSuccessNotificationBody: `-`,
  Event_remoteWifiOnSuccessHome: `-`,
  Event_remoteWifiOnSuccessDialogTitle: `-`,
  Event_remoteWifiOnSuccessDialogBody: `-`,
  Event_remoteWifiOnSuccessDialogAction: `-`,
  Event_remoteWifiOnSuccessDialogAction2: `-`,
  Event_remoteWifiOnSuccessDialogClose: `-`,
  Event_remoteWifiOnSuccessAlertTitle: `-`,
  Event_remoteWifiOnSuccessAlertBody: `-`,
  Event_remoteWifiOnSuccessLocation: `-`,
  Event_remoteWifiOnSuccessRemoteControl: `Wi-Fi ONにしました。`,
  Event_remoteWifiOnSuccessDashboard: `-`,
  Event_remoteGeofenceOffFailEventName: `ジオフェンスを解除に失敗。`,
  Event_remoteGeofenceOffFailWarningTitle: `-`,
  Event_remoteGeofenceOffFailWarningHint: `-`,
  Event_remoteGeofenceOffFailNotificationTitle: `リモート操作`,
  Event_remoteGeofenceOffFailNotificationBody: `ジオフェンス解除に失敗しました。`,
  Event_remoteGeofenceOffFailHome: `-`,
  Event_remoteGeofenceOffFailDialogTitle: `リモート操作`,
  Event_remoteGeofenceOffFailDialogBody: `{model}のジオフェンス解除に失敗しました。`,
  Event_remoteGeofenceOffFailDialogAction: `車両位置を開く`,
  Event_remoteGeofenceOffFailDialogAction2: `-`,
  Event_remoteGeofenceOffFailDialogClose: `OK`,
  Event_remoteGeofenceOffFailAlertTitle: `-`,
  Event_remoteGeofenceOffFailAlertBody: `-`,
  Event_remoteGeofenceOffFailLocation: `-`,
  Event_remoteGeofenceOffFailRemoteControl: `ジオフェンス解除に失敗しました。`,
  Event_remoteGeofenceOffFailDashboard: `-`,
  Event_remoteGeofenceOffSuccessEventName: `ジオフェンスを解除する。`,
  Event_remoteGeofenceOffSuccessWarningTitle: `-`,
  Event_remoteGeofenceOffSuccessWarningHint: `-`,
  Event_remoteGeofenceOffSuccessNotificationTitle: `-`,
  Event_remoteGeofenceOffSuccessNotificationBody: `-`,
  Event_remoteGeofenceOffSuccessHome: `-`,
  Event_remoteGeofenceOffSuccessDialogTitle: `-`,
  Event_remoteGeofenceOffSuccessDialogBody: `-`,
  Event_remoteGeofenceOffSuccessDialogAction: `-`,
  Event_remoteGeofenceOffSuccessDialogAction2: `-`,
  Event_remoteGeofenceOffSuccessDialogClose: `-`,
  Event_remoteGeofenceOffSuccessAlertTitle: `-`,
  Event_remoteGeofenceOffSuccessAlertBody: `-`,
  Event_remoteGeofenceOffSuccessLocation: `-`,
  Event_remoteGeofenceOffSuccessRemoteControl: `-`,
  Event_remoteGeofenceOffSuccessDashboard: `-`,
  Event_remoteGeofenceChangeFailEventName: `ジオフェンスの設定を変更に失敗。`,
  Event_remoteGeofenceChangeFailWarningTitle: `-`,
  Event_remoteGeofenceChangeFailWarningHint: `-`,
  Event_remoteGeofenceChangeFailNotificationTitle: `リモート操作`,
  Event_remoteGeofenceChangeFailNotificationBody: `ジオフェンス設定変更に失敗しました。`,
  Event_remoteGeofenceChangeFailHome: `-`,
  Event_remoteGeofenceChangeFailDialogTitle: `リモート操作`,
  Event_remoteGeofenceChangeFailDialogBody: `{model}のジオフェンス設定変更に失敗しました。`,
  Event_remoteGeofenceChangeFailDialogAction: `車両位置を開く`,
  Event_remoteGeofenceChangeFailDialogAction2: `-`,
  Event_remoteGeofenceChangeFailDialogClose: `OK`,
  Event_remoteGeofenceChangeFailAlertTitle: `-`,
  Event_remoteGeofenceChangeFailAlertBody: `-`,
  Event_remoteGeofenceChangeFailLocation: `-`,
  Event_remoteGeofenceChangeFailRemoteControl: `ジオフェンス設定変更に失敗しました。`,
  Event_remoteGeofenceChangeFailDashboard: `-`,
  Event_remoteGeofenceChangeSuccessEventName: `ジオフェンスの設定を変更する。`,
  Event_remoteGeofenceChangeSuccessWarningTitle: `-`,
  Event_remoteGeofenceChangeSuccessWarningHint: `-`,
  Event_remoteGeofenceChangeSuccessNotificationTitle: `-`,
  Event_remoteGeofenceChangeSuccessNotificationBody: `-`,
  Event_remoteGeofenceChangeSuccessHome: `-`,
  Event_remoteGeofenceChangeSuccessDialogTitle: `-`,
  Event_remoteGeofenceChangeSuccessDialogBody: `-`,
  Event_remoteGeofenceChangeSuccessDialogAction: `-`,
  Event_remoteGeofenceChangeSuccessDialogAction2: `-`,
  Event_remoteGeofenceChangeSuccessDialogClose: `-`,
  Event_remoteGeofenceChangeSuccessAlertTitle: `-`,
  Event_remoteGeofenceChangeSuccessAlertBody: `-`,
  Event_remoteGeofenceChangeSuccessLocation: `-`,
  Event_remoteGeofenceChangeSuccessRemoteControl: `-`,
  Event_remoteGeofenceChangeSuccessDashboard: `-`,
  Event_remoteGeofenceOnFailEventName: `ジオフェンスを設定に失敗`,
  Event_remoteGeofenceOnFailWarningTitle: `-`,
  Event_remoteGeofenceOnFailWarningHint: `-`,
  Event_remoteGeofenceOnFailNotificationTitle: `リモート操作`,
  Event_remoteGeofenceOnFailNotificationBody: `ジオフェンス設定に失敗しました。`,
  Event_remoteGeofenceOnFailHome: `-`,
  Event_remoteGeofenceOnFailDialogTitle: `リモート操作`,
  Event_remoteGeofenceOnFailDialogBody: `{model}のジオフェンス設定に失敗しました。`,
  Event_remoteGeofenceOnFailDialogAction: `車両位置を開く`,
  Event_remoteGeofenceOnFailDialogAction2: `-`,
  Event_remoteGeofenceOnFailDialogClose: `OK`,
  Event_remoteGeofenceOnFailAlertTitle: `-`,
  Event_remoteGeofenceOnFailAlertBody: `-`,
  Event_remoteGeofenceOnFailLocation: `-`,
  Event_remoteGeofenceOnFailRemoteControl: `ジオフェンス設定に失敗しました。`,
  Event_remoteGeofenceOnFailDashboard: `-`,
  Event_remoteGeofenceOnSuccessEventName: `ジオフェンスを設定する。`,
  Event_remoteGeofenceOnSuccessWarningTitle: `-`,
  Event_remoteGeofenceOnSuccessWarningHint: `-`,
  Event_remoteGeofenceOnSuccessNotificationTitle: `-`,
  Event_remoteGeofenceOnSuccessNotificationBody: `-`,
  Event_remoteGeofenceOnSuccessHome: `-`,
  Event_remoteGeofenceOnSuccessDialogTitle: `-`,
  Event_remoteGeofenceOnSuccessDialogBody: `-`,
  Event_remoteGeofenceOnSuccessDialogAction: `-`,
  Event_remoteGeofenceOnSuccessDialogAction2: `-`,
  Event_remoteGeofenceOnSuccessDialogClose: `-`,
  Event_remoteGeofenceOnSuccessAlertTitle: `-`,
  Event_remoteGeofenceOnSuccessAlertBody: `-`,
  Event_remoteGeofenceOnSuccessLocation: `-`,
  Event_remoteGeofenceOnSuccessRemoteControl: `-`,
  Event_remoteGeofenceOnSuccessDashboard: `-`,
  Event_remoteTrackingOffFailEventName: `追跡モードオフ失敗`,
  Event_remoteTrackingOffFailWarningTitle: `-`,
  Event_remoteTrackingOffFailWarningHint: `-`,
  Event_remoteTrackingOffFailNotificationTitle: `リモート操作`,
  Event_remoteTrackingOffFailNotificationBody: `追跡モードをオフにできませんでした。`,
  Event_remoteTrackingOffFailHome: `-`,
  Event_remoteTrackingOffFailDialogTitle: `リモート操作`,
  Event_remoteTrackingOffFailDialogBody: `{model}の追跡モードをオフにできませんでした。`,
  Event_remoteTrackingOffFailDialogAction: `車両位置を開く`,
  Event_remoteTrackingOffFailDialogAction2: `-`,
  Event_remoteTrackingOffFailDialogClose: `OK`,
  Event_remoteTrackingOffFailAlertTitle: `-`,
  Event_remoteTrackingOffFailAlertBody: `-`,
  Event_remoteTrackingOffFailLocation: `-`,
  Event_remoteTrackingOffFailRemoteControl: `追跡モードをオフにできませんでした。`,
  Event_remoteTrackingOffFailDashboard: `-`,
  Event_remoteTrackingOffSuccessEventName: `追跡モードオフ`,
  Event_remoteTrackingOffSuccessWarningTitle: `-`,
  Event_remoteTrackingOffSuccessWarningHint: `-`,
  Event_remoteTrackingOffSuccessNotificationTitle: `リモート操作`,
  Event_remoteTrackingOffSuccessNotificationBody: `追跡モードをオフにしました。`,
  Event_remoteTrackingOffSuccessHome: `-`,
  Event_remoteTrackingOffSuccessDialogTitle: `-`,
  Event_remoteTrackingOffSuccessDialogBody: `-`,
  Event_remoteTrackingOffSuccessDialogAction: `-`,
  Event_remoteTrackingOffSuccessDialogAction2: `-`,
  Event_remoteTrackingOffSuccessDialogClose: `-`,
  Event_remoteTrackingOffSuccessAlertTitle: `-`,
  Event_remoteTrackingOffSuccessAlertBody: `-`,
  Event_remoteTrackingOffSuccessLocation: `-`,
  Event_remoteTrackingOffSuccessRemoteControl: `追跡モードをオフにしました。`,
  Event_remoteTrackingOffSuccessDashboard: `-`,
  Event_remoteTrackingOnFailEventName: `追跡モードオン失敗`,
  Event_remoteTrackingOnFailWarningTitle: `-`,
  Event_remoteTrackingOnFailWarningHint: `-`,
  Event_remoteTrackingOnFailNotificationTitle: `リモート操作`,
  Event_remoteTrackingOnFailNotificationBody: `追跡モードをオンにできませんでした。`,
  Event_remoteTrackingOnFailHome: `-`,
  Event_remoteTrackingOnFailDialogTitle: `リモート操作`,
  Event_remoteTrackingOnFailDialogBody: `{model}の追跡モードをオンにできませんでした。`,
  Event_remoteTrackingOnFailDialogAction: `車両位置を開く`,
  Event_remoteTrackingOnFailDialogAction2: `-`,
  Event_remoteTrackingOnFailDialogClose: `OK`,
  Event_remoteTrackingOnFailAlertTitle: `-`,
  Event_remoteTrackingOnFailAlertBody: `-`,
  Event_remoteTrackingOnFailLocation: `-`,
  Event_remoteTrackingOnFailRemoteControl: `追跡モードをオンにできませんでした。`,
  Event_remoteTrackingOnFailDashboard: `-`,
  Event_remoteTrackingOnSuccessEventName: `追跡モードオン`,
  Event_remoteTrackingOnSuccessWarningTitle: `-`,
  Event_remoteTrackingOnSuccessWarningHint: `-`,
  Event_remoteTrackingOnSuccessNotificationTitle: `リモート操作`,
  Event_remoteTrackingOnSuccessNotificationBody: `追跡モードをオンにしました。`,
  Event_remoteTrackingOnSuccessHome: `-`,
  Event_remoteTrackingOnSuccessDialogTitle: `-`,
  Event_remoteTrackingOnSuccessDialogBody: `-`,
  Event_remoteTrackingOnSuccessDialogAction: `-`,
  Event_remoteTrackingOnSuccessDialogAction2: `-`,
  Event_remoteTrackingOnSuccessDialogClose: `-`,
  Event_remoteTrackingOnSuccessAlertTitle: `-`,
  Event_remoteTrackingOnSuccessAlertBody: `-`,
  Event_remoteTrackingOnSuccessLocation: `-`,
  Event_remoteTrackingOnSuccessRemoteControl: `追跡モードをオンにしました。`,
  Event_remoteTrackingOnSuccessDashboard: `-`,
  Event_remoteEngineOffFailEventName: `エンジンオフ失敗`,
  Event_remoteEngineOffFailWarningTitle: `-`,
  Event_remoteEngineOffFailWarningHint: `-`,
  Event_remoteEngineOffFailNotificationTitle: `リモート操作`,
  Event_remoteEngineOffFailNotificationBody: `エンジンを消せませんでした。`,
  Event_remoteEngineOffFailHome: `-`,
  Event_remoteEngineOffFailDialogTitle: `リモート操作`,
  Event_remoteEngineOffFailDialogBody: `{model}のエンジンを消せませんでした。`,
  Event_remoteEngineOffFailDialogAction: `車両状況を開く`,
  Event_remoteEngineOffFailDialogAction2: `-`,
  Event_remoteEngineOffFailDialogClose: `OK`,
  Event_remoteEngineOffFailAlertTitle: `-`,
  Event_remoteEngineOffFailAlertBody: `-`,
  Event_remoteEngineOffFailLocation: `-`,
  Event_remoteEngineOffFailRemoteControl: `エンジンを消せませんでした。`,
  Event_remoteEngineOffFailDashboard: `-`,
  Event_remoteEngineOffSuccessEventName: `エンジンオフ`,
  Event_remoteEngineOffSuccessWarningTitle: `-`,
  Event_remoteEngineOffSuccessWarningHint: `-`,
  Event_remoteEngineOffSuccessNotificationTitle: `リモート操作`,
  Event_remoteEngineOffSuccessNotificationBody: `エンジンを消しました。`,
  Event_remoteEngineOffSuccessHome: `-`,
  Event_remoteEngineOffSuccessDialogTitle: `-`,
  Event_remoteEngineOffSuccessDialogBody: `-`,
  Event_remoteEngineOffSuccessDialogAction: `-`,
  Event_remoteEngineOffSuccessDialogAction2: `-`,
  Event_remoteEngineOffSuccessDialogClose: `-`,
  Event_remoteEngineOffSuccessAlertTitle: `-`,
  Event_remoteEngineOffSuccessAlertBody: `-`,
  Event_remoteEngineOffSuccessLocation: `-`,
  Event_remoteEngineOffSuccessRemoteControl: `エンジンを消しました。`,
  Event_remoteEngineOffSuccessDashboard: `-`,
  Event_remoteEngineOnFailEventName: `エンジンオン失敗`,
  Event_remoteEngineOnFailWarningTitle: `-`,
  Event_remoteEngineOnFailWarningHint: `-`,
  Event_remoteEngineOnFailNotificationTitle: `リモート操作`,
  Event_remoteEngineOnFailNotificationBody: `エンジンをつけられませんでした。`,
  Event_remoteEngineOnFailHome: `-`,
  Event_remoteEngineOnFailDialogTitle: `リモート操作`,
  Event_remoteEngineOnFailDialogBody: `{model}のエンジンをつけられませんでした。`,
  Event_remoteEngineOnFailDialogAction: `車両状況を開く`,
  Event_remoteEngineOnFailDialogAction2: `-`,
  Event_remoteEngineOnFailDialogClose: `OK`,
  Event_remoteEngineOnFailAlertTitle: `-`,
  Event_remoteEngineOnFailAlertBody: `-`,
  Event_remoteEngineOnFailLocation: `-`,
  Event_remoteEngineOnFailRemoteControl: `エンジンをつけられませんでした。`,
  Event_remoteEngineOnFailDashboard: `-`,
  Event_remoteEngineOnSuccessEventName: `エンジンオン`,
  Event_remoteEngineOnSuccessWarningTitle: `-`,
  Event_remoteEngineOnSuccessWarningHint: `-`,
  Event_remoteEngineOnSuccessNotificationTitle: `リモート操作`,
  Event_remoteEngineOnSuccessNotificationBody: `エンジンをつけました。`,
  Event_remoteEngineOnSuccessHome: `-`,
  Event_remoteEngineOnSuccessDialogTitle: `-`,
  Event_remoteEngineOnSuccessDialogBody: `-`,
  Event_remoteEngineOnSuccessDialogAction: `-`,
  Event_remoteEngineOnSuccessDialogAction2: `-`,
  Event_remoteEngineOnSuccessDialogClose: `-`,
  Event_remoteEngineOnSuccessAlertTitle: `-`,
  Event_remoteEngineOnSuccessAlertBody: `-`,
  Event_remoteEngineOnSuccessLocation: `-`,
  Event_remoteEngineOnSuccessRemoteControl: `エンジンをつけました。`,
  Event_remoteEngineOnSuccessDashboard: `-`,
  Event_remoteACOffFailEventName: `エアコンオフ失敗`,
  Event_remoteACOffFailWarningTitle: `-`,
  Event_remoteACOffFailWarningHint: `-`,
  Event_remoteACOffFailNotificationTitle: `リモート操作`,
  Event_remoteACOffFailNotificationBody: `エアコンを消せませんでした。`,
  Event_remoteACOffFailHome: `-`,
  Event_remoteACOffFailDialogTitle: `リモート操作`,
  Event_remoteACOffFailDialogBody: `{model}のエアコンを消せませんでした。`,
  Event_remoteACOffFailDialogAction: `車両状況を開く`,
  Event_remoteACOffFailDialogAction2: `-`,
  Event_remoteACOffFailDialogClose: `OK`,
  Event_remoteACOffFailAlertTitle: `-`,
  Event_remoteACOffFailAlertBody: `-`,
  Event_remoteACOffFailLocation: `-`,
  Event_remoteACOffFailRemoteControl: `エアコンを消せませんでした。`,
  Event_remoteACOffFailDashboard: `-`,
  Event_remoteACOffSuccessEventName: `エアコンオフ`,
  Event_remoteACOffSuccessWarningTitle: `-`,
  Event_remoteACOffSuccessWarningHint: `-`,
  Event_remoteACOffSuccessNotificationTitle: `リモート操作`,
  Event_remoteACOffSuccessNotificationBody: `エアコンを消しました。`,
  Event_remoteACOffSuccessHome: `-`,
  Event_remoteACOffSuccessDialogTitle: `-`,
  Event_remoteACOffSuccessDialogBody: `-`,
  Event_remoteACOffSuccessDialogAction: `-`,
  Event_remoteACOffSuccessDialogAction2: `-`,
  Event_remoteACOffSuccessDialogClose: `-`,
  Event_remoteACOffSuccessAlertTitle: `-`,
  Event_remoteACOffSuccessAlertBody: `-`,
  Event_remoteACOffSuccessLocation: `-`,
  Event_remoteACOffSuccessRemoteControl: `エアコンを消しました。`,
  Event_remoteACOffSuccessDashboard: `-`,
  Event_remoteACOnFailEventName: `エアコンオン失敗`,
  Event_remoteACOnFailWarningTitle: `-`,
  Event_remoteACOnFailWarningHint: `-`,
  Event_remoteACOnFailNotificationTitle: `リモート操作`,
  Event_remoteACOnFailNotificationBody: `エアコンをつけられませんでした。`,
  Event_remoteACOnFailHome: `-`,
  Event_remoteACOnFailDialogTitle: `リモート操作`,
  Event_remoteACOnFailDialogBody: `{model}のエアコンをつけられませんでした。`,
  Event_remoteACOnFailDialogAction: `車両状況を開く`,
  Event_remoteACOnFailDialogAction2: `-`,
  Event_remoteACOnFailDialogClose: `OK`,
  Event_remoteACOnFailAlertTitle: `-`,
  Event_remoteACOnFailAlertBody: `-`,
  Event_remoteACOnFailLocation: `-`,
  Event_remoteACOnFailRemoteControl: `エアコンをつけられませんでした。`,
  Event_remoteACOnFailDashboard: `-`,
  Event_remoteACOnSuccessEventName: `エアコンオン`,
  Event_remoteACOnSuccessWarningTitle: `-`,
  Event_remoteACOnSuccessWarningHint: `-`,
  Event_remoteACOnSuccessNotificationTitle: `リモート操作`,
  Event_remoteACOnSuccessNotificationBody: `エアコンをつけました。`,
  Event_remoteACOnSuccessHome: `-`,
  Event_remoteACOnSuccessDialogTitle: `-`,
  Event_remoteACOnSuccessDialogBody: `-`,
  Event_remoteACOnSuccessDialogAction: `-`,
  Event_remoteACOnSuccessDialogAction2: `-`,
  Event_remoteACOnSuccessDialogClose: `-`,
  Event_remoteACOnSuccessAlertTitle: `-`,
  Event_remoteACOnSuccessAlertBody: `-`,
  Event_remoteACOnSuccessLocation: `-`,
  Event_remoteACOnSuccessRemoteControl: `エアコンをつけました。`,
  Event_remoteACOnSuccessDashboard: `-`,
  Event_remoteHazardOffFailEventName: `ハザードランプ消灯失敗`,
  Event_remoteHazardOffFailWarningTitle: `-`,
  Event_remoteHazardOffFailWarningHint: `-`,
  Event_remoteHazardOffFailNotificationTitle: `リモート操作`,
  Event_remoteHazardOffFailNotificationBody: `ハザードランプの消灯に失敗しました。`,
  Event_remoteHazardOffFailHome: `-`,
  Event_remoteHazardOffFailDialogTitle: `リモート操作`,
  Event_remoteHazardOffFailDialogBody: `{model}のハザードランプの消灯に失敗しました。`,
  Event_remoteHazardOffFailDialogAction: `車両状況を開く`,
  Event_remoteHazardOffFailDialogAction2: `-`,
  Event_remoteHazardOffFailDialogClose: `OK`,
  Event_remoteHazardOffFailAlertTitle: `-`,
  Event_remoteHazardOffFailAlertBody: `-`,
  Event_remoteHazardOffFailLocation: `-`,
  Event_remoteHazardOffFailRemoteControl: `ハザードランプを消灯に失敗しました。`,
  Event_remoteHazardOffFailDashboard: `-`,
  Event_remoteHazardOffSuccessEventName: `ハザードランプ消灯`,
  Event_remoteHazardOffSuccessWarningTitle: `-`,
  Event_remoteHazardOffSuccessWarningHint: `-`,
  Event_remoteHazardOffSuccessNotificationTitle: `リモート操作`,
  Event_remoteHazardOffSuccessNotificationBody: `ハザードランプを消しました。`,
  Event_remoteHazardOffSuccessHome: `-`,
  Event_remoteHazardOffSuccessDialogTitle: `-`,
  Event_remoteHazardOffSuccessDialogBody: `-`,
  Event_remoteHazardOffSuccessDialogAction: `-`,
  Event_remoteHazardOffSuccessDialogAction2: `-`,
  Event_remoteHazardOffSuccessDialogClose: `-`,
  Event_remoteHazardOffSuccessAlertTitle: `-`,
  Event_remoteHazardOffSuccessAlertBody: `-`,
  Event_remoteHazardOffSuccessLocation: `-`,
  Event_remoteHazardOffSuccessRemoteControl: `ハザードランプを消しました。`,
  Event_remoteHazardOffSuccessDashboard: `-`,
  Event_remoteHazardOnFailEventName: `ハザードランプ点灯失敗`,
  Event_remoteHazardOnFailWarningTitle: `-`,
  Event_remoteHazardOnFailWarningHint: `-`,
  Event_remoteHazardOnFailNotificationTitle: `リモート操作`,
  Event_remoteHazardOnFailNotificationBody: `ハザードランプの点灯に失敗しました。`,
  Event_remoteHazardOnFailHome: `-`,
  Event_remoteHazardOnFailDialogTitle: `リモート操作`,
  Event_remoteHazardOnFailDialogBody: `{model}のハザードランプの点灯に失敗しました。`,
  Event_remoteHazardOnFailDialogAction: `車両状況を開く`,
  Event_remoteHazardOnFailDialogAction2: `-`,
  Event_remoteHazardOnFailDialogClose: `OK`,
  Event_remoteHazardOnFailAlertTitle: `-`,
  Event_remoteHazardOnFailAlertBody: `-`,
  Event_remoteHazardOnFailLocation: `-`,
  Event_remoteHazardOnFailRemoteControl: `ハザードランプを点灯に失敗しました。`,
  Event_remoteHazardOnFailDashboard: `-`,
  Event_remoteHazardOnSuccessEventName: `ハザードランプ点灯`,
  Event_remoteHazardOnSuccessWarningTitle: `-`,
  Event_remoteHazardOnSuccessWarningHint: `-`,
  Event_remoteHazardOnSuccessNotificationTitle: `リモート操作`,
  Event_remoteHazardOnSuccessNotificationBody: `ハザードランプをつけました。`,
  Event_remoteHazardOnSuccessHome: `-`,
  Event_remoteHazardOnSuccessDialogTitle: `-`,
  Event_remoteHazardOnSuccessDialogBody: `-`,
  Event_remoteHazardOnSuccessDialogAction: `-`,
  Event_remoteHazardOnSuccessDialogAction2: `-`,
  Event_remoteHazardOnSuccessDialogClose: `-`,
  Event_remoteHazardOnSuccessAlertTitle: `-`,
  Event_remoteHazardOnSuccessAlertBody: `-`,
  Event_remoteHazardOnSuccessLocation: `-`,
  Event_remoteHazardOnSuccessRemoteControl: `ハザードランプをつけました。`,
  Event_remoteHazardOnSuccessDashboard: `-`,
  Event_remoteHeadLightOffFailEventName: `ヘッドライト消灯失敗`,
  Event_remoteHeadLightOffFailWarningTitle: `-`,
  Event_remoteHeadLightOffFailWarningHint: `-`,
  Event_remoteHeadLightOffFailNotificationTitle: `リモート操作`,
  Event_remoteHeadLightOffFailNotificationBody: `ヘッドライトの消灯に失敗しました。`,
  Event_remoteHeadLightOffFailHome: `-`,
  Event_remoteHeadLightOffFailDialogTitle: `リモート操作`,
  Event_remoteHeadLightOffFailDialogBody: `{model}のヘッドライトの消灯に失敗しました。`,
  Event_remoteHeadLightOffFailDialogAction: `車両状況を開く`,
  Event_remoteHeadLightOffFailDialogAction2: `-`,
  Event_remoteHeadLightOffFailDialogClose: `OK`,
  Event_remoteHeadLightOffFailAlertTitle: `-`,
  Event_remoteHeadLightOffFailAlertBody: `-`,
  Event_remoteHeadLightOffFailLocation: `-`,
  Event_remoteHeadLightOffFailRemoteControl: `ヘッドライトを消灯に失敗しました。`,
  Event_remoteHeadLightOffFailDashboard: `-`,
  Event_remoteHeadLightOffSuccessEventName: `ヘッドライト消灯`,
  Event_remoteHeadLightOffSuccessWarningTitle: `-`,
  Event_remoteHeadLightOffSuccessWarningHint: `-`,
  Event_remoteHeadLightOffSuccessNotificationTitle: `リモート操作`,
  Event_remoteHeadLightOffSuccessNotificationBody: `ヘッドライトを消しました。`,
  Event_remoteHeadLightOffSuccessHome: `-`,
  Event_remoteHeadLightOffSuccessDialogTitle: `-`,
  Event_remoteHeadLightOffSuccessDialogBody: `-`,
  Event_remoteHeadLightOffSuccessDialogAction: `-`,
  Event_remoteHeadLightOffSuccessDialogAction2: `-`,
  Event_remoteHeadLightOffSuccessDialogClose: `-`,
  Event_remoteHeadLightOffSuccessAlertTitle: `-`,
  Event_remoteHeadLightOffSuccessAlertBody: `-`,
  Event_remoteHeadLightOffSuccessLocation: `-`,
  Event_remoteHeadLightOffSuccessRemoteControl: `ヘッドライトを消しました。`,
  Event_remoteHeadLightOffSuccessDashboard: `-`,
  Event_remoteHeadLightOnFailEventName: `ヘッドライト点灯失敗`,
  Event_remoteHeadLightOnFailWarningTitle: `-`,
  Event_remoteHeadLightOnFailWarningHint: `-`,
  Event_remoteHeadLightOnFailNotificationTitle: `リモート操作`,
  Event_remoteHeadLightOnFailNotificationBody: `ヘッドライトの点灯に失敗しました。`,
  Event_remoteHeadLightOnFailHome: `-`,
  Event_remoteHeadLightOnFailDialogTitle: `リモート操作`,
  Event_remoteHeadLightOnFailDialogBody: `{model}のヘッドライトの点灯に失敗しました。`,
  Event_remoteHeadLightOnFailDialogAction: `車両状況を開く`,
  Event_remoteHeadLightOnFailDialogAction2: `-`,
  Event_remoteHeadLightOnFailDialogClose: `OK`,
  Event_remoteHeadLightOnFailAlertTitle: `-`,
  Event_remoteHeadLightOnFailAlertBody: `-`,
  Event_remoteHeadLightOnFailLocation: `-`,
  Event_remoteHeadLightOnFailRemoteControl: `ヘッドライトの点灯に失敗しました。`,
  Event_remoteHeadLightOnFailDashboard: `-`,
  Event_remoteHeadLightOnSuccessEventName: `ヘッドライト点灯`,
  Event_remoteHeadLightOnSuccessWarningTitle: `-`,
  Event_remoteHeadLightOnSuccessWarningHint: `-`,
  Event_remoteHeadLightOnSuccessNotificationTitle: `リモート操作`,
  Event_remoteHeadLightOnSuccessNotificationBody: `ヘッドライトをつけました。`,
  Event_remoteHeadLightOnSuccessHome: `-`,
  Event_remoteHeadLightOnSuccessDialogTitle: `-`,
  Event_remoteHeadLightOnSuccessDialogBody: `-`,
  Event_remoteHeadLightOnSuccessDialogAction: `-`,
  Event_remoteHeadLightOnSuccessDialogAction2: `-`,
  Event_remoteHeadLightOnSuccessDialogClose: `-`,
  Event_remoteHeadLightOnSuccessAlertTitle: `-`,
  Event_remoteHeadLightOnSuccessAlertBody: `-`,
  Event_remoteHeadLightOnSuccessLocation: `-`,
  Event_remoteHeadLightOnSuccessRemoteControl: `ヘッドライトをつけました。`,
  Event_remoteHeadLightOnSuccessDashboard: `-`,
  Event_remoteDoorLockedFailEventName: `ドアの解除失敗`,
  Event_remoteDoorLockedFailWarningTitle: `-`,
  Event_remoteDoorLockedFailWarningHint: `-`,
  Event_remoteDoorLockedFailNotificationTitle: `リモート操作`,
  Event_remoteDoorLockedFailNotificationBody: `ドアの施錠に失敗しました。`,
  Event_remoteDoorLockedFailHome: `-`,
  Event_remoteDoorLockedFailDialogTitle: `リモート操作`,
  Event_remoteDoorLockedFailDialogBody: `{model}のドアの施錠に失敗しました。`,
  Event_remoteDoorLockedFailDialogAction: `車両状況を開く`,
  Event_remoteDoorLockedFailDialogAction2: `-`,
  Event_remoteDoorLockedFailDialogClose: `OK`,
  Event_remoteDoorLockedFailAlertTitle: `-`,
  Event_remoteDoorLockedFailAlertBody: `-`,
  Event_remoteDoorLockedFailLocation: `-`,
  Event_remoteDoorLockedFailRemoteControl: `ドアの施錠に失敗しました。`,
  Event_remoteDoorLockedFailDashboard: `-`,
  Event_remoteDoorLockedSuccessEventName: `ドアを施錠`,
  Event_remoteDoorLockedSuccessWarningTitle: `-`,
  Event_remoteDoorLockedSuccessWarningHint: `-`,
  Event_remoteDoorLockedSuccessNotificationTitle: `リモート操作`,
  Event_remoteDoorLockedSuccessNotificationBody: `ドアを施錠しました。`,
  Event_remoteDoorLockedSuccessHome: `-`,
  Event_remoteDoorLockedSuccessDialogTitle: `-`,
  Event_remoteDoorLockedSuccessDialogBody: `-`,
  Event_remoteDoorLockedSuccessDialogAction: `-`,
  Event_remoteDoorLockedSuccessDialogAction2: `-`,
  Event_remoteDoorLockedSuccessDialogClose: `-`,
  Event_remoteDoorLockedSuccessAlertTitle: `-`,
  Event_remoteDoorLockedSuccessAlertBody: `-`,
  Event_remoteDoorLockedSuccessLocation: `-`,
  Event_remoteDoorLockedSuccessRemoteControl: `ドアを施錠しました。`,
  Event_remoteDoorLockedSuccessDashboard: `-`,
  Event_carResgitrationFailEventName: `車の登録に失敗。`,
  Event_carResgitrationFailWarningTitle: `-`,
  Event_carResgitrationFailWarningHint: `-`,
  Event_carResgitrationFailNotificationTitle: `車の登録`,
  Event_carResgitrationFailNotificationBody: `車の登録に失敗しました。`,
  Event_carResgitrationFailHome: `-`,
  Event_carResgitrationFailDialogTitle: `車の登録`,
  Event_carResgitrationFailDialogBody: `{model}の車の登録に失敗しました。`,
  Event_carResgitrationFailDialogAction: `マイカー管理を開く`,
  Event_carResgitrationFailDialogAction2: `-`,
  Event_carResgitrationFailDialogClose: `OK`,
  Event_carResgitrationFailAlertTitle: `-`,
  Event_carResgitrationFailAlertBody: `-`,
  Event_carResgitrationFailLocation: `-`,
  Event_carResgitrationFailRemoteControl: `車の登録に失敗しました。`,
  Event_carResgitrationFailDashboard: `-`,
  Event_carResgitrationSuccessEventName: `車を登録する。`,
  Event_carResgitrationSuccessWarningTitle: `-`,
  Event_carResgitrationSuccessWarningHint: `-`,
  Event_carResgitrationSuccessNotificationTitle: `-`,
  Event_carResgitrationSuccessNotificationBody: `-`,
  Event_carResgitrationSuccessHome: `-`,
  Event_carResgitrationSuccessDialogTitle: `おクルマの登録完了`,
  Event_carResgitrationSuccessDialogBody: `おクルマの登録が完了しました。エンジンは停止させてかまいません。`,
  Event_carResgitrationSuccessDialogAction: `-`,
  Event_carResgitrationSuccessDialogAction2: `-`,
  Event_carResgitrationSuccessDialogClose: `OK`,
  Event_carResgitrationSuccessAlertTitle: `-`,
  Event_carResgitrationSuccessAlertBody: `-`,
  Event_carResgitrationSuccessLocation: `-`,
  Event_carResgitrationSuccessRemoteControl: `-`,
  Event_carResgitrationSuccessDashboard: `-`,
  Event_keyFreeAlert4EventName: `キーフリー警告灯点滅４`,
  Event_keyFreeAlert4WarningTitle: `キーの電池残量わずか`,
  Event_keyFreeAlert4WarningHint: `電池交換の方法は取扱説明書をご覧ください。エンジン始動が出来なくなる恐れがありますので、早急な交換をおすすめします。`,
  Event_keyFreeAlert4NotificationTitle: `車両からのお知らせ`,
  Event_keyFreeAlert4NotificationBody: `キーの電池残量がわずかになりました。`,
  Event_keyFreeAlert4Home: `キーの電池残量がわずかになりました。`,
  Event_keyFreeAlert4DialogTitle: `車両からのお知らせ`,
  Event_keyFreeAlert4DialogBody: `{model}のキーの電池残量がわずかになりました。`,
  Event_keyFreeAlert4DialogAction: `車両からのお知らせを開く`,
  Event_keyFreeAlert4DialogAction2: `-`,
  Event_keyFreeAlert4DialogClose: `OK`,
  Event_keyFreeAlert4AlertTitle: `キーの電池残量がわずかになりました。`,
  Event_keyFreeAlert4AlertBody: `キーの電池残量がわずかになりました。`,
  Event_keyFreeAlert4Location: `-`,
  Event_keyFreeAlert4RemoteControl: `-`,
  Event_keyFreeAlert4Dashboard: `キーの電池残量わずか`,
  Event_towAwayAlertEventName: `レッカー移動検知`,
  Event_towAwayAlertWarningTitle: `-`,
  Event_towAwayAlertWarningHint: `-`,
  Event_towAwayAlertNotificationTitle: `車両からのお知らせ`,
  Event_towAwayAlertNotificationBody: `車のレッカー移動(運搬移動)を検知しました。`,
  Event_towAwayAlertHome: `車のレッカー移動(運搬移動)を検知しました。`,
  Event_towAwayAlertDialogTitle: `車両からのお知らせ`,
  Event_towAwayAlertDialogBody: `{model}の車のレッカー移動(運搬移動)を検知しました。`,
  Event_towAwayAlertDialogAction: `車両からのお知らせを開く`,
  Event_towAwayAlertDialogAction2: `-`,
  Event_towAwayAlertDialogClose: `OK`,
  Event_towAwayAlertAlertTitle: `車のレッカー移動(運搬移動)を検知しました。`,
  Event_towAwayAlertAlertBody: `車のレッカー移動(運搬移動)を検知しました。`,
  Event_towAwayAlertLocation: `-`,
  Event_towAwayAlertRemoteControl: `-`,
  Event_towAwayAlertDashboard: `-`,
  Event_geofenceInAlertEventName: `ジオフェンス逸脱帰還`,
  Event_geofenceInAlertWarningTitle: `-`,
  Event_geofenceInAlertWarningHint: `-`,
  Event_geofenceInAlertNotificationTitle: `車両からのお知らせ`,
  Event_geofenceInAlertNotificationBody: `ジオフェンス内に戻りました。`,
  Event_geofenceInAlertHome: `ジオフェンス内に戻りました。`,
  Event_geofenceInAlertDialogTitle: `車両からのお知らせ`,
  Event_geofenceInAlertDialogBody: `{model}がジオフェンス内に戻りました。`,
  Event_geofenceInAlertDialogAction: `車両からのお知らせを開く`,
  Event_geofenceInAlertDialogAction2: `-`,
  Event_geofenceInAlertDialogClose: `OK`,
  Event_geofenceInAlertAlertTitle: `ジオフェンス内に戻りました。`,
  Event_geofenceInAlertAlertBody: `ジオフェンス内に戻りました。`,
  Event_geofenceInAlertLocation: `-`,
  Event_geofenceInAlertRemoteControl: `-`,
  Event_geofenceInAlertDashboard: `-`,
  Event_geofenceOutAlertEventName: `ジオフェンス逸脱`,
  Event_geofenceOutAlertWarningTitle: `-`,
  Event_geofenceOutAlertWarningHint: `-`,
  Event_geofenceOutAlertNotificationTitle: `車両からのお知らせ`,
  Event_geofenceOutAlertNotificationBody: `ジオフェンス逸脱を検知しました。`,
  Event_geofenceOutAlertHome: `ジオフェンス逸脱を検知しました。`,
  Event_geofenceOutAlertDialogTitle: `車両からのお知らせ`,
  Event_geofenceOutAlertDialogBody: `{model}のジオフェンス逸脱を検知しました。`,
  Event_geofenceOutAlertDialogAction: `車両からのお知らせを開く`,
  Event_geofenceOutAlertDialogAction2: `-`,
  Event_geofenceOutAlertDialogClose: `OK`,
  Event_geofenceOutAlertAlertTitle: `ジオフェンス逸脱を検知しました。`,
  Event_geofenceOutAlertAlertBody: `ジオフェンス逸脱を検知しました。`,
  Event_geofenceOutAlertLocation: `範囲を逸脱しています`,
  Event_geofenceOutAlertRemoteControl: `-`,
  Event_geofenceOutAlertDashboard: `-`,
  Event_removeDcmAlertEventName: `盗難検知DCM取り外し`,
  Event_removeDcmAlertWarningTitle: `-`,
  Event_removeDcmAlertWarningHint: `-`,
  Event_removeDcmAlertNotificationTitle: `車両からのお知らせ`,
  Event_removeDcmAlertNotificationBody: `DCMの取り外しを検知しました。車が盗難にあった可能性があります。`,
  Event_removeDcmAlertHome: `DCMの取り外しを検知しました。車が盗難にあった可能性があります。`,
  Event_removeDcmAlertDialogTitle: `車両からのお知らせ`,
  Event_removeDcmAlertDialogBody: `{model}のDCMの取り外しを検知しました。車が盗難にあった可能性があります。`,
  Event_removeDcmAlertDialogAction: `車両からのお知らせを開く`,
  Event_removeDcmAlertDialogAction2: `-`,
  Event_removeDcmAlertDialogClose: `OK`,
  Event_removeDcmAlertAlertTitle: `DCMの取り外しを検知しました。車が盗難にあった可能性があります。`,
  Event_removeDcmAlertAlertBody: `DCMの取り外しを検知しました。車が盗難にあった可能性があります。`,
  Event_removeDcmAlertLocation: `-`,
  Event_removeDcmAlertRemoteControl: `-`,
  Event_removeDcmAlertDashboard: `DCMの取り外しを検知しました。車が盗難にあった可能性があります。`,
  Event_alarmAlertEventName: `盗難検知アラーム発砲`,
  Event_alarmAlertWarningTitle: `-`,
  Event_alarmAlertWarningHint: `-`,
  Event_alarmAlertNotificationTitle: `車両からのお知らせ`,
  Event_alarmAlertNotificationBody: `盗難検知のアラームが検知されました。`,
  Event_alarmAlertHome: `盗難検知のアラームが検知されました。`,
  Event_alarmAlertDialogTitle: `車両からのお知らせ`,
  Event_alarmAlertDialogBody: `{model}の盗難検知のアラームが検知されました。`,
  Event_alarmAlertDialogAction: `車両からのお知らせを開く`,
  Event_alarmAlertDialogAction2: `-`,
  Event_alarmAlertDialogClose: `OK`,
  Event_alarmAlertAlertTitle: `盗難検知のアラームが検知されました。`,
  Event_alarmAlertAlertBody: `盗難検知のアラームが検知されました。`,
  Event_alarmAlertLocation: `-`,
  Event_alarmAlertRemoteControl: `-`,
  Event_alarmAlertDashboard: `盗難検知のアラームが検知されました。`,
  Event_systemIGOffAlertEventName: `システムIG SW ON`,
  Event_systemIGOffAlertWarningTitle: `-`,
  Event_systemIGOffAlertWarningHint: `-`,
  Event_systemIGOffAlertNotificationTitle: `-`,
  Event_systemIGOffAlertNotificationBody: `-`,
  Event_systemIGOffAlertHome: `-`,
  Event_systemIGOffAlertDialogTitle: `-`,
  Event_systemIGOffAlertDialogBody: `-`,
  Event_systemIGOffAlertDialogAction: `-`,
  Event_systemIGOffAlertDialogAction2: `-`,
  Event_systemIGOffAlertDialogClose: `-`,
  Event_systemIGOffAlertAlertTitle: `-`,
  Event_systemIGOffAlertAlertBody: `-`,
  Event_systemIGOffAlertLocation: `-`,
  Event_systemIGOffAlertRemoteControl: `-`,
  Event_systemIGOffAlertDashboard: `-`,
  Event_systemIGOnAlertEventName: `システムIG SW ON`,
  Event_systemIGOnAlertWarningTitle: `-`,
  Event_systemIGOnAlertWarningHint: `-`,
  Event_systemIGOnAlertNotificationTitle: `-`,
  Event_systemIGOnAlertNotificationBody: `-`,
  Event_systemIGOnAlertHome: `-`,
  Event_systemIGOnAlertDialogTitle: `-`,
  Event_systemIGOnAlertDialogBody: `-`,
  Event_systemIGOnAlertDialogAction: `-`,
  Event_systemIGOnAlertDialogAction2: `-`,
  Event_systemIGOnAlertDialogClose: `-`,
  Event_systemIGOnAlertAlertTitle: `-`,
  Event_systemIGOnAlertAlertBody: `-`,
  Event_systemIGOnAlertLocation: `-`,
  Event_systemIGOnAlertRemoteControl: `-`,
  Event_systemIGOnAlertDashboard: `-`,
  Event_userIGOffAlertEventName: `ユーザ　IG SW OFF`,
  Event_userIGOffAlertWarningTitle: `-`,
  Event_userIGOffAlertWarningHint: `-`,
  Event_userIGOffAlertNotificationTitle: `-`,
  Event_userIGOffAlertNotificationBody: `-`,
  Event_userIGOffAlertHome: `-`,
  Event_userIGOffAlertDialogTitle: `-`,
  Event_userIGOffAlertDialogBody: `-`,
  Event_userIGOffAlertDialogAction: `-`,
  Event_userIGOffAlertDialogAction2: `-`,
  Event_userIGOffAlertDialogClose: `-`,
  Event_userIGOffAlertAlertTitle: `-`,
  Event_userIGOffAlertAlertBody: `-`,
  Event_userIGOffAlertLocation: `-`,
  Event_userIGOffAlertRemoteControl: `-`,
  Event_userIGOffAlertDashboard: `-`,
  Event_userIGOnAlertEventName: `ユーザ IG SW ON`,
  Event_userIGOnAlertWarningTitle: `-`,
  Event_userIGOnAlertWarningHint: `-`,
  Event_userIGOnAlertNotificationTitle: `車両からのお知らせ`,
  Event_userIGOnAlertNotificationBody: `イグニッションがオンになりました。`,
  Event_userIGOnAlertHome: `イグニッションがオンになりました。`,
  Event_userIGOnAlertDialogTitle: `車両からのお知らせ`,
  Event_userIGOnAlertDialogBody: `{model}のイグニッションがオンになりました。`,
  Event_userIGOnAlertDialogAction: `車両からのお知らせを開く`,
  Event_userIGOnAlertDialogAction2: `-`,
  Event_userIGOnAlertDialogClose: `OK`,
  Event_userIGOnAlertAlertTitle: `イグニッションがオンになりました。`,
  Event_userIGOnAlertAlertBody: `イグニッションがオンになりました。`,
  Event_userIGOnAlertLocation: `-`,
  Event_userIGOnAlertRemoteControl: `-`,
  Event_userIGOnAlertDashboard: `-`,
  Event_elePowSteOreAlertEventName: `電動パワーステアリング警告灯点灯（オレンジ）`,
  Event_elePowSteOreAlertWarningTitle: `パワーステアリング機能低下`,
  Event_elePowSteOreAlertWarningHint: `パワーステアリング制御が制限され、ハンドル操作が重くなることがあります。しばらくハンドル操作を控えてください。
ハンドル操作しないまま約10分たつと、通常の重さに戻ります。消灯しない場合、繰り返し点灯する場合はPeroduaサービス工場で点検を受けてください。`,
  Event_elePowSteOreAlertNotificationTitle: `車両からのお知らせ`,
  Event_elePowSteOreAlertNotificationBody: `パワーステアリング機能低下を検知しました。`,
  Event_elePowSteOreAlertHome: `パワーステアリング機能低下を検知しました。`,
  Event_elePowSteOreAlertDialogTitle: `車両からのお知らせ`,
  Event_elePowSteOreAlertDialogBody: `{model}のパワーステアリング機能低下を検知しました。`,
  Event_elePowSteOreAlertDialogAction: `車両からのお知らせを開く`,
  Event_elePowSteOreAlertDialogAction2: `-`,
  Event_elePowSteOreAlertDialogClose: `OK`,
  Event_elePowSteOreAlertAlertTitle: `パワーステアリング機能低下を検知しました。`,
  Event_elePowSteOreAlertAlertBody: `パワーステアリング機能低下を検知しました。`,
  Event_elePowSteOreAlertLocation: `-`,
  Event_elePowSteOreAlertRemoteControl: `-`,
  Event_elePowSteOreAlertDashboard: `パワーステアリング機能低下`,
  Event_afsWarnAlretEventName: `AFSウォーニング情報`,
  Event_afsWarnAlretWarningTitle: `ライト制御（AFS）異常`,
  Event_afsWarnAlretWarningHint: `AFS（アダプティブフロントライティングシステム）異常を検知しました。曲がる方向に向かってライトを照らす機能が働きません。また、ヘッドランプの方向が通常より下向きになることがあります。ただちにPeroduaサービス工場で点検を受けてください。`,
  Event_afsWarnAlretNotificationTitle: `車両からのお知らせ`,
  Event_afsWarnAlretNotificationBody: `AFS（アダプティブフロントライティングシステム）異常を検知しました。`,
  Event_afsWarnAlretHome: `AFS（アダプティブフロントライティングシステム）異常を検知しました。`,
  Event_afsWarnAlretDialogTitle: `車両からのお知らせ`,
  Event_afsWarnAlretDialogBody: `{model}のAFS（アダプティブフロントライティングシステム）異常を検知しました。`,
  Event_afsWarnAlretDialogAction: `車両からのお知らせを開く`,
  Event_afsWarnAlretDialogAction2: `-`,
  Event_afsWarnAlretDialogClose: `OK`,
  Event_afsWarnAlretAlertTitle: `AFS（アダプティブフロントライティングシステム）異常を検知しました。`,
  Event_afsWarnAlretAlertBody: `AFS（アダプティブフロントライティングシステム）異常を検知しました。`,
  Event_afsWarnAlretLocation: `-`,
  Event_afsWarnAlretRemoteControl: `-`,
  Event_afsWarnAlretDashboard: `ライト制御（AFS）異常`,
  Event_fourWheelSysAlertEventName: `4WDシステム異常`,
  Event_fourWheelSysAlertWarningTitle: `4WDシステム異常`,
  Event_fourWheelSysAlertWarningHint: `ただちにPeroduaサービス工場で点検を受けてください。`,
  Event_fourWheelSysAlertNotificationTitle: `車両からのお知らせ`,
  Event_fourWheelSysAlertNotificationBody: `4WDシステム異常を検知しました。`,
  Event_fourWheelSysAlertHome: `4WDシステム異常を検知しました。`,
  Event_fourWheelSysAlertDialogTitle: `車両からのお知らせ`,
  Event_fourWheelSysAlertDialogBody: `{model}の4WDシステム異常を検知しました。`,
  Event_fourWheelSysAlertDialogAction: `車両からのお知らせを開く`,
  Event_fourWheelSysAlertDialogAction2: `-`,
  Event_fourWheelSysAlertDialogClose: `OK`,
  Event_fourWheelSysAlertAlertTitle: `4WDシステム異常を検知しました。`,
  Event_fourWheelSysAlertAlertBody: `4WDシステム異常を検知しました。`,
  Event_fourWheelSysAlertLocation: `-`,
  Event_fourWheelSysAlertRemoteControl: `-`,
  Event_fourWheelSysAlertDashboard: `4WDシステム異常`,
  Event_autoHighBeamAlertEventName: `オートハイビーム故障`,
  Event_autoHighBeamAlertWarningTitle: `オートハイビーム故障`,
  Event_autoHighBeamAlertWarningHint: `オートハイビームは作動しません。手動に切り替えることで通常走行には支障はありませんが、Peroduaサービス工場で点検を受けてください。`,
  Event_autoHighBeamAlertNotificationTitle: `車両からのお知らせ`,
  Event_autoHighBeamAlertNotificationBody: `オートハイビーム故障を検知しました。`,
  Event_autoHighBeamAlertHome: `オートハイビーム故障を検知しました。`,
  Event_autoHighBeamAlertDialogTitle: `車両からのお知らせ`,
  Event_autoHighBeamAlertDialogBody: `{model}のオートハイビーム故障を検知しました。`,
  Event_autoHighBeamAlertDialogAction: `車両からのお知らせを開く`,
  Event_autoHighBeamAlertDialogAction2: `-`,
  Event_autoHighBeamAlertDialogClose: `OK`,
  Event_autoHighBeamAlertAlertTitle: `オートハイビーム故障を検知しました。`,
  Event_autoHighBeamAlertAlertBody: `オートハイビーム故障を検知しました。`,
  Event_autoHighBeamAlertLocation: `-`,
  Event_autoHighBeamAlertRemoteControl: `-`,
  Event_autoHighBeamAlertDashboard: `オートハイビーム故障`,
  Event_keyFreeAlertEventName: `キーフリー警告灯点灯`,
  Event_keyFreeAlertWarningTitle: `キーフリー故障`,
  Event_keyFreeAlertWarningHint: `車両/キーフリーリモコンが正常に作動しない恐れがあります。ただちにPeroduaサービス工場で点検を受けてください。`,
  Event_keyFreeAlertNotificationTitle: `車両からのお知らせ`,
  Event_keyFreeAlertNotificationBody: `キーフリー故障を検知しました。`,
  Event_keyFreeAlertHome: `キーフリー故障を検知しました。`,
  Event_keyFreeAlertDialogTitle: `車両からのお知らせ`,
  Event_keyFreeAlertDialogBody: `{model}のキーフリー故障を検知しました。`,
  Event_keyFreeAlertDialogAction: `車両からのお知らせを開く`,
  Event_keyFreeAlertDialogAction2: `-`,
  Event_keyFreeAlertDialogClose: `OK`,
  Event_keyFreeAlertAlertTitle: `キーフリー故障を検知しました。`,
  Event_keyFreeAlertAlertBody: `キーフリー故障を検知しました。`,
  Event_keyFreeAlertLocation: `-`,
  Event_keyFreeAlertRemoteControl: `-`,
  Event_keyFreeAlertDashboard: `キーフリー故障`,
  Event_ecoIdelOffAlertEventName: `eco IDLE OFF表示灯点灯１`,
  Event_ecoIdelOffAlertWarningTitle: `アイドリングストップ解除`,
  Event_ecoIdelOffAlertWarningHint: `通常走行に支障はありませんが、アイドリングストップが作動しない場合があります。Peroduaサービス工場で点検を受けてください。`,
  Event_ecoIdelOffAlertNotificationTitle: `車両からのお知らせ`,
  Event_ecoIdelOffAlertNotificationBody: `アイドリングストップ故障を検知しました。`,
  Event_ecoIdelOffAlertHome: `アイドリングストップ故障を検知しました。`,
  Event_ecoIdelOffAlertDialogTitle: `車両からのお知らせ`,
  Event_ecoIdelOffAlertDialogBody: `{model}のアイドリングストップ故障を検知しました。`,
  Event_ecoIdelOffAlertDialogAction: `車両からのお知らせを開く`,
  Event_ecoIdelOffAlertDialogAction2: `-`,
  Event_ecoIdelOffAlertDialogClose: `OK`,
  Event_ecoIdelOffAlertAlertTitle: `アイドリングストップ故障を検知しました。`,
  Event_ecoIdelOffAlertAlertBody: `アイドリングストップ故障を検知しました。`,
  Event_ecoIdelOffAlertLocation: `-`,
  Event_ecoIdelOffAlertRemoteControl: `-`,
  Event_ecoIdelOffAlertDashboard: `アイドリングストップ解除`,
  Event_brakeHoldAlertEventName: `ブレーキホールド警告`,
  Event_brakeHoldAlertWarningTitle: `ブレーキホールド警告`,
  Event_brakeHoldAlertWarningHint: `ただちにPeroduaサービス工場で点検を受けてください。`,
  Event_brakeHoldAlertNotificationTitle: `車両からのお知らせ`,
  Event_brakeHoldAlertNotificationBody: `ブレーキホールド警告を検知しました。`,
  Event_brakeHoldAlertHome: `ブレーキホールド警告を検知しました。`,
  Event_brakeHoldAlertDialogTitle: `車両からのお知らせ`,
  Event_brakeHoldAlertDialogBody: `{model}のブレーキホールド警告を検知しました。`,
  Event_brakeHoldAlertDialogAction: `車両からのお知らせを開く`,
  Event_brakeHoldAlertDialogAction2: `-`,
  Event_brakeHoldAlertDialogClose: `OK`,
  Event_brakeHoldAlertAlertTitle: `ブレーキホールド警告を検知しました。`,
  Event_brakeHoldAlertAlertBody: `ブレーキホールド警告を検知しました。`,
  Event_brakeHoldAlertLocation: `-`,
  Event_brakeHoldAlertRemoteControl: `-`,
  Event_brakeHoldAlertDashboard: `ブレーキホールド警告`,
  Event_smaashiErrAlertEventName: `スマアシ故障点灯`,
  Event_smaashiErrAlertWarningTitle: `スマートアシスト故障`,
  Event_smaashiErrAlertWarningHint: `通常走行に支障はありませんが、スマートアシストが必要時に作動しない恐れがあります。Peroduaサービス工場で点検を受けてください。`,
  Event_smaashiErrAlertNotificationTitle: `車両からのお知らせ`,
  Event_smaashiErrAlertNotificationBody: `スマートアシスト故障を検知しました。`,
  Event_smaashiErrAlertHome: `スマートアシスト故障を検知しました。`,
  Event_smaashiErrAlertDialogTitle: `車両からのお知らせ`,
  Event_smaashiErrAlertDialogBody: `{model}のスマートアシスト故障を検知しました。`,
  Event_smaashiErrAlertDialogAction: `車両からのお知らせを開く`,
  Event_smaashiErrAlertDialogAction2: `-`,
  Event_smaashiErrAlertDialogClose: `OK`,
  Event_smaashiErrAlertAlertTitle: `スマートアシスト故障を検知しました。`,
  Event_smaashiErrAlertAlertBody: `スマートアシスト故障を検知しました。`,
  Event_smaashiErrAlertLocation: `-`,
  Event_smaashiErrAlertRemoteControl: `-`,
  Event_smaashiErrAlertDashboard: `スマートアシスト故障`,
  Event_lowBeamLevelErrAlertEventName: `ロービーム・オートレベリング警告灯点灯`,
  Event_lowBeamLevelErrAlertWarningTitle: `ヘッドランプ光軸異常`,
  Event_lowBeamLevelErrAlertWarningHint: `ロービーム・オートレベリング機能の異常を検知しました。ただちに安全な場所に停車し、いったんエンジンを止めてください。再度電源ONしたとき、警告灯が数秒間点灯したあとに消灯すれば、そのまま走行することができます。
消灯しないときや、再び点灯するときは、ただちにPerodua サービス工場で点検を受けてください。`,
  Event_lowBeamLevelErrAlertNotificationTitle: `車両からのお知らせ`,
  Event_lowBeamLevelErrAlertNotificationBody: `ヘッドランプ光軸異常を検知しました。`,
  Event_lowBeamLevelErrAlertHome: `ヘッドランプ光軸異常を検知しました。`,
  Event_lowBeamLevelErrAlertDialogTitle: `車両からのお知らせ`,
  Event_lowBeamLevelErrAlertDialogBody: `{model}のヘッドランプ光軸異常を検知しました。`,
  Event_lowBeamLevelErrAlertDialogAction: `車両からのお知らせを開く`,
  Event_lowBeamLevelErrAlertDialogAction2: `-`,
  Event_lowBeamLevelErrAlertDialogClose: `OK`,
  Event_lowBeamLevelErrAlertAlertTitle: `ヘッドランプ光軸異常を検知しました。`,
  Event_lowBeamLevelErrAlertAlertBody: `ヘッドランプ光軸異常を検知しました。`,
  Event_lowBeamLevelErrAlertLocation: `-`,
  Event_lowBeamLevelErrAlertRemoteControl: `-`,
  Event_lowBeamLevelErrAlertDashboard: `ヘッドランプ光軸異常`,
  Event_headLampSysAlertEventName: `ヘッドランプシステム警告（ADB故障）`,
  Event_headLampSysAlertWarningTitle: `ヘッドランプシステム故障`,
  Event_headLampSysAlertWarningHint: `ADB( 配光可変ヘッドランプ)は作動しません。手動に切り替えることで通常走行には支障はありませんが、Peroduaサービス工場で点検を受けてください。`,
  Event_headLampSysAlertNotificationTitle: `車両からのお知らせ`,
  Event_headLampSysAlertNotificationBody: `ヘッドランプシステム故障を検知しました。`,
  Event_headLampSysAlertHome: `ヘッドランプシステム故障を検知しました。`,
  Event_headLampSysAlertDialogTitle: `車両からのお知らせ`,
  Event_headLampSysAlertDialogBody: `{model}のヘッドランプシステム故障を検知しました。`,
  Event_headLampSysAlertDialogAction: `車両からのお知らせを開く`,
  Event_headLampSysAlertDialogAction2: `-`,
  Event_headLampSysAlertDialogClose: `OK`,
  Event_headLampSysAlertAlertTitle: `ヘッドランプシステム故障を検知しました。`,
  Event_headLampSysAlertAlertBody: `ヘッドランプシステム故障を検知しました。`,
  Event_headLampSysAlertLocation: `-`,
  Event_headLampSysAlertRemoteControl: `-`,
  Event_headLampSysAlertDashboard: `ヘッドランプシステム故障`,
  Event_bosErrAlertEventName: `BOS故障`,
  Event_bosErrAlertWarningTitle: `BOS故障`,
  Event_bosErrAlertWarningHint: `ただちにPeroduaサービス工場で点検を受けてください。`,
  Event_bosErrAlertNotificationTitle: `車両からのお知らせ`,
  Event_bosErrAlertNotificationBody: `BOSの異常を検知しました。`,
  Event_bosErrAlertHome: `BOSの異常を検知しました。`,
  Event_bosErrAlertDialogTitle: `車両からのお知らせ`,
  Event_bosErrAlertDialogBody: `{model}のBOSの異常を検知しました。`,
  Event_bosErrAlertDialogAction: `車両からのお知らせを開く`,
  Event_bosErrAlertDialogAction2: `-`,
  Event_bosErrAlertDialogClose: `OK`,
  Event_bosErrAlertAlertTitle: `BOSの異常を検知しました。`,
  Event_bosErrAlertAlertBody: `BOSの異常を検知しました。`,
  Event_bosErrAlertLocation: `-`,
  Event_bosErrAlertRemoteControl: `-`,
  Event_bosErrAlertDashboard: `BOS故障`,
  Event_dscErrAlertEventName: `DSC故障`,
  Event_dscErrAlertWarningTitle: `DSC故障`,
  Event_dscErrAlertWarningHint: `ただちにPeroduaサービス工場で点検を受けてください。`,
  Event_dscErrAlertNotificationTitle: `車両からのお知らせ`,
  Event_dscErrAlertNotificationBody: `DSCの異常を検知しました。`,
  Event_dscErrAlertHome: `DSCの異常を検知しました。`,
  Event_dscErrAlertDialogTitle: `車両からのお知らせ`,
  Event_dscErrAlertDialogBody: `{model}のDSCの異常を検知しました。`,
  Event_dscErrAlertDialogAction: `車両からのお知らせを開く`,
  Event_dscErrAlertDialogAction2: `-`,
  Event_dscErrAlertDialogClose: `OK`,
  Event_dscErrAlertAlertTitle: `DSCの異常を検知しました。`,
  Event_dscErrAlertAlertBody: `DSCの異常を検知しました。`,
  Event_dscErrAlertLocation: `-`,
  Event_dscErrAlertRemoteControl: `-`,
  Event_dscErrAlertDashboard: `DSC故障`,
  Event_vscTscAlertEventName: `VSC&TRC警告灯点灯`,
  Event_vscTscAlertWarningTitle: `VSC故障`,
  Event_vscTscAlertWarningHint: `通常走行に支障はありませんが、VSCが必要時に作動しない恐れがあります。Peroduaサービス工場で点検を受けてください。`,
  Event_vscTscAlertNotificationTitle: `車両からのお知らせ`,
  Event_vscTscAlertNotificationBody: `VSC（横滑り防止システム）故障を検知しました。`,
  Event_vscTscAlertHome: `VSC（横滑り防止システム）故障を検知しました。`,
  Event_vscTscAlertDialogTitle: `車両からのお知らせ`,
  Event_vscTscAlertDialogBody: `{model}のVSC（横滑り防止システム）故障を検知しました。`,
  Event_vscTscAlertDialogAction: `車両からのお知らせを開く`,
  Event_vscTscAlertDialogAction2: `-`,
  Event_vscTscAlertDialogClose: `OK`,
  Event_vscTscAlertAlertTitle: `VSC（横滑り防止システム）故障を検知しました。`,
  Event_vscTscAlertAlertBody: `VSC（横滑り防止システム）故障を検知しました。`,
  Event_vscTscAlertLocation: `-`,
  Event_vscTscAlertRemoteControl: `-`,
  Event_vscTscAlertDashboard: `VSC故障`,
  Event_absAlertEventName: `ABS警告灯点灯`,
  Event_absAlertWarningTitle: `ABS故障`,
  Event_absAlertWarningHint: `通常のブレーキとしての性能は確保されていますが、ABSとしての作動はしません。強いブレーキを踏んだとき、ロックしてスリップする可能性があります。Peroduaサービス工場で点検を受けてください。`,
  Event_absAlertNotificationTitle: `車両からのお知らせ`,
  Event_absAlertNotificationBody: `ABS（ブレーキ時タイヤロック防止）システム異常を検知しました。`,
  Event_absAlertHome: `ABS（ブレーキ時タイヤロック防止）システム異常を検知しました。`,
  Event_absAlertDialogTitle: `車両からのお知らせ`,
  Event_absAlertDialogBody: `{model}のABS（ブレーキ時タイヤロック防止）システム異常を検知しました。`,
  Event_absAlertDialogAction: `車両からのお知らせを開く`,
  Event_absAlertDialogAction2: `-`,
  Event_absAlertDialogClose: `OK`,
  Event_absAlertAlertTitle: `ABS（ブレーキ時タイヤロック防止）システム異常を検知しました。`,
  Event_absAlertAlertBody: `ABS（ブレーキ時タイヤロック防止）システム異常を検知しました。`,
  Event_absAlertLocation: `-`,
  Event_absAlertRemoteControl: `-`,
  Event_absAlertDashboard: `ABS故障`,
  Event_parkBrakeErrorEventName: `パーキングブレーキ故障`,
  Event_parkBrakeErrorWarningTitle: `パーキングブレーキ故障`,
  Event_parkBrakeErrorWarningHint: `ただちにPeroduaサービス工場で点検を受けてください。`,
  Event_parkBrakeErrorNotificationTitle: `車両からのお知らせ`,
  Event_parkBrakeErrorNotificationBody: `パーキングブレーキの異常を検知しました。`,
  Event_parkBrakeErrorHome: `パーキングブレーキの異常を検知しました。`,
  Event_parkBrakeErrorDialogTitle: `車両からのお知らせ`,
  Event_parkBrakeErrorDialogBody: `{model}のパーキングブレーキの異常を検知しました。`,
  Event_parkBrakeErrorDialogAction: `車両からのお知らせを開く`,
  Event_parkBrakeErrorDialogAction2: `-`,
  Event_parkBrakeErrorDialogClose: `OK`,
  Event_parkBrakeErrorAlertTitle: `パーキングブレーキの異常を検知しました。`,
  Event_parkBrakeErrorAlertBody: `パーキングブレーキの異常を検知しました。`,
  Event_parkBrakeErrorLocation: `-`,
  Event_parkBrakeErrorRemoteControl: `-`,
  Event_parkBrakeErrorDashboard: `パーキングブレーキ故障`,
  Event_cvtAlertEventName: `CVT警告灯点滅`,
  Event_cvtAlertWarningTitle: `CVTシステム異常`,
  Event_cvtAlertWarningHint: `正常に変速しない可能性があります。ただちにPeroduaサービス工場で点検を受けてください。`,
  Event_cvtAlertNotificationTitle: `車両からのお知らせ`,
  Event_cvtAlertNotificationBody: `CVTシステム異常を検知しました。`,
  Event_cvtAlertHome: `CVTシステム異常を検知しました。`,
  Event_cvtAlertDialogTitle: `車両からのお知らせ`,
  Event_cvtAlertDialogBody: `{model}のCVTシステム異常を検知しました。`,
  Event_cvtAlertDialogAction: `車両からのお知らせを開く`,
  Event_cvtAlertDialogAction2: `-`,
  Event_cvtAlertDialogClose: `OK`,
  Event_cvtAlertAlertTitle: `CVTシステム異常を検知しました。`,
  Event_cvtAlertAlertBody: `CVTシステム異常を検知しました。`,
  Event_cvtAlertLocation: `-`,
  Event_cvtAlertRemoteControl: `-`,
  Event_cvtAlertDashboard: `CVTシステム異常`,
  Event_Engine1AlertEventName: `エンジン警告点滅①`,
  Event_Engine1AlertWarningTitle: `エンジンシステム異常`,
  Event_Engine1AlertWarningHint: `このまま使用すると、さらに大きな故障につながる可能性があります。ただちにPeroduaサービス工場で点検を受けてください。`,
  Event_Engine1AlertNotificationTitle: `車両からのお知らせ`,
  Event_Engine1AlertNotificationBody: `エンジンシステム異常を検知しました。`,
  Event_Engine1AlertHome: `エンジンシステム異常を検知しました。`,
  Event_Engine1AlertDialogTitle: `車両からのお知らせ`,
  Event_Engine1AlertDialogBody: `{model}のエンジンシステム異常を検知しました。`,
  Event_Engine1AlertDialogAction: `車両からのお知らせを開く`,
  Event_Engine1AlertDialogAction2: `-`,
  Event_Engine1AlertDialogClose: `OK`,
  Event_Engine1AlertAlertTitle: `エンジンシステム異常を検知しました。`,
  Event_Engine1AlertAlertBody: `エンジンシステム異常を検知しました。`,
  Event_Engine1AlertLocation: `-`,
  Event_Engine1AlertRemoteControl: `-`,
  Event_Engine1AlertDashboard: `エンジンシステム異常`,
  Event_srsAirbagAlertEventName: `SRSエアバッグ警告灯点灯`,
  Event_srsAirbagAlertWarningTitle: `SRSエアバッグ故障`,
  Event_srsAirbagAlertWarningHint: `エアバッグが必要時に作動しない恐れがあります。ただちにPeroduaサービス工場で点検を受けてください。`,
  Event_srsAirbagAlertNotificationTitle: `車両からのお知らせ`,
  Event_srsAirbagAlertNotificationBody: `SRSエアバッグ故障を検知しました。`,
  Event_srsAirbagAlertHome: `SRSエアバッグ故障を検知しました。`,
  Event_srsAirbagAlertDialogTitle: `車両からのお知らせ`,
  Event_srsAirbagAlertDialogBody: `{model}のSRSエアバッグ故障を検知しました。`,
  Event_srsAirbagAlertDialogAction: `車両からのお知らせを開く`,
  Event_srsAirbagAlertDialogAction2: `-`,
  Event_srsAirbagAlertDialogClose: `OK`,
  Event_srsAirbagAlertAlertTitle: `SRSエアバッグ故障を検知しました。`,
  Event_srsAirbagAlertAlertBody: `SRSエアバッグ故障を検知しました。`,
  Event_srsAirbagAlertLocation: `-`,
  Event_srsAirbagAlertRemoteControl: `-`,
  Event_srsAirbagAlertDashboard: `SRSエアバッグ故障`,
  Event_elePowSteRedAlertEventName: `電動パワースタリング警告灯点灯（赤色）`,
  Event_elePowSteRedAlertWarningTitle: `パワーステアリング故障`,
  Event_elePowSteRedAlertWarningHint: `ハンドル操作が非常に重くなります。ただちにPeroduaサービス工場で点検を受けてください。`,
  Event_elePowSteRedAlertNotificationTitle: `車両からのお知らせ`,
  Event_elePowSteRedAlertNotificationBody: `電動パワーステアリング 故障を検知しました。`,
  Event_elePowSteRedAlertHome: `電動パワーステアリング 故障を検知しました。`,
  Event_elePowSteRedAlertDialogTitle: `車両からのお知らせ`,
  Event_elePowSteRedAlertDialogBody: `{model}の電動パワーステアリング 故障を検知しました。`,
  Event_elePowSteRedAlertDialogAction: `車両からのお知らせを開く`,
  Event_elePowSteRedAlertDialogAction2: `-`,
  Event_elePowSteRedAlertDialogClose: `OK`,
  Event_elePowSteRedAlertAlertTitle: `電動パワーステアリング 故障を検知しました。`,
  Event_elePowSteRedAlertAlertBody: `電動パワーステアリング 故障を検知しました。`,
  Event_elePowSteRedAlertLocation: `-`,
  Event_elePowSteRedAlertRemoteControl: `-`,
  Event_elePowSteRedAlertDashboard: `パワーステアリング故障`,
  Event_batteryAlertEventName: `充電警告灯点灯`,
  Event_batteryAlertWarningTitle: `充電システム故障`,
  Event_batteryAlertWarningHint: `このまま使用すると、バッテリーあがり/電圧低下等で車が正常に使用できなくなる恐れがあります。ただちに安全な場所に停車し、Peroduaサービス工場へ連絡してください。`,
  Event_batteryAlertNotificationTitle: `車両からのお知らせ`,
  Event_batteryAlertNotificationBody: `充電システム異常を検知しました。`,
  Event_batteryAlertHome: `充電システム異常を検知しました。`,
  Event_batteryAlertDialogTitle: `車両からのお知らせ`,
  Event_batteryAlertDialogBody: `{model}の充電システム異常を検知しました。`,
  Event_batteryAlertDialogAction: `車両からのお知らせを開く`,
  Event_batteryAlertDialogAction2: `-`,
  Event_batteryAlertDialogClose: `OK`,
  Event_batteryAlertAlertTitle: `充電システム異常を検知しました。`,
  Event_batteryAlertAlertBody: `充電システム異常を検知しました。`,
  Event_batteryAlertLocation: `-`,
  Event_batteryAlertRemoteControl: `-`,
  Event_batteryAlertDashboard: `充電システム故障`,
  Event_waterTempAlertEventName: `水温警告灯（赤色）点滅`,
  Event_waterTempAlertWarningTitle: `エンジン冷却水高温`,
  Event_waterTempAlertWarningHint: `オーバーヒートの可能性があります。ただちに安全な場所に停車してください。取扱説明書を参照し、処置を行ってください。`,
  Event_waterTempAlertNotificationTitle: `車両からのお知らせ`,
  Event_waterTempAlertNotificationBody: `オーバーヒートの可能性があります。`,
  Event_waterTempAlertHome: `オーバーヒートの可能性があります。`,
  Event_waterTempAlertDialogTitle: `車両からのお知らせ`,
  Event_waterTempAlertDialogBody: `{model}のオーバーヒートの可能性があります。`,
  Event_waterTempAlertDialogAction: `車両からのお知らせを開く`,
  Event_waterTempAlertDialogAction2: `-`,
  Event_waterTempAlertDialogClose: `OK`,
  Event_waterTempAlertAlertTitle: `オーバーヒートの可能性があります。`,
  Event_waterTempAlertAlertBody: `オーバーヒートの可能性があります。`,
  Event_waterTempAlertLocation: `-`,
  Event_waterTempAlertRemoteControl: `-`,
  Event_waterTempAlertDashboard: `エンジン冷却水高温`,
  Event_oilAlertEventName: `油圧警告灯点灯`,
  Event_oilAlertWarningTitle: `エンジン油圧低下`,
  Event_oilAlertWarningHint: `そのまま走行するとエンジンが故障するおそれがあります。ただちにPeroduaサービス工場で点検を受けてください。`,
  Event_oilAlertNotificationTitle: `車両からのお知らせ`,
  Event_oilAlertNotificationBody: `エンジンオイルの油圧低下を検知しました。`,
  Event_oilAlertHome: `エンジンオイルの油圧低下を検知しました。`,
  Event_oilAlertDialogTitle: `車両からのお知らせ`,
  Event_oilAlertDialogBody: `{model}のエンジンオイルの油圧低下を検知しました。`,
  Event_oilAlertDialogAction: `車両からのお知らせを開く`,
  Event_oilAlertDialogAction2: `-`,
  Event_oilAlertDialogClose: `OK`,
  Event_oilAlertAlertTitle: `エンジンオイルの油圧低下を検知しました。`,
  Event_oilAlertAlertBody: `エンジンオイルの油圧低下を検知しました。`,
  Event_oilAlertLocation: `-`,
  Event_oilAlertRemoteControl: `-`,
  Event_oilAlertDashboard: `エンジン油圧低下`,
  Event_brakeSysAlertEventName: `ブレーキシステム異常`,
  Event_brakeSysAlertWarningTitle: `ブレーキシステム故障`,
  Event_brakeSysAlertWarningHint: `ただちに安全な場所に停車し、Peroduaサービス工場へ連絡してください。`,
  Event_brakeSysAlertNotificationTitle: `車両からのお知らせ`,
  Event_brakeSysAlertNotificationBody: `ブレーキシステム故障を検知しました。`,
  Event_brakeSysAlertHome: `ブレーキシステム故障を検知しました。`,
  Event_brakeSysAlertDialogTitle: `車両からのお知らせ`,
  Event_brakeSysAlertDialogBody: `{model}のブレーキシステム故障を検知しました。`,
  Event_brakeSysAlertDialogAction: `車両からのお知らせを開く`,
  Event_brakeSysAlertDialogAction2: `-`,
  Event_brakeSysAlertDialogClose: `OK`,
  Event_brakeSysAlertAlertTitle: `ブレーキシステム故障を検知しました。`,
  Event_brakeSysAlertAlertBody: `ブレーキシステム故障を検知しました。`,
  Event_brakeSysAlertLocation: `-`,
  Event_brakeSysAlertRemoteControl: `-`,
  Event_brakeSysAlertDashboard: `ブレーキシステム故障`,
  Event_brakeFluAlertEventName: `ブレーキ液液量不足`,
  Event_brakeFluAlertWarningTitle: `ブレーキ液量低下`,
  Event_brakeFluAlertWarningHint: `ブレーキの効きが悪くなっているおそれがあります。効きが悪いときは、ブレーキペダルを強く踏み、ただちに安全な場所に停車し、Peroduaサービス工場へ連絡してください。`,
  Event_brakeFluAlertNotificationTitle: `車両からのお知らせ`,
  Event_brakeFluAlertNotificationBody: `ブレーキ液量低下を検知しました。`,
  Event_brakeFluAlertHome: `ブレーキ液量低下を検知しました。`,
  Event_brakeFluAlertDialogTitle: `車両からのお知らせ`,
  Event_brakeFluAlertDialogBody: `{model}のブレーキ液量低下を検知しました。`,
  Event_brakeFluAlertDialogAction: `車両からのお知らせを開く`,
  Event_brakeFluAlertDialogAction2: `-`,
  Event_brakeFluAlertDialogClose: `OK`,
  Event_brakeFluAlertAlertTitle: `ブレーキ液量低下を検知しました。`,
  Event_brakeFluAlertAlertBody: `ブレーキ液量低下を検知しました。`,
  Event_brakeFluAlertLocation: `-`,
  Event_brakeFluAlertRemoteControl: `-`,
  Event_brakeFluAlertDashboard: `ブレーキ液量低下`,
  Event_epbAlertEventName: `EPB緊急停止`,
  Event_epbAlertWarningTitle: `EPB緊急停止検知`,
  Event_epbAlertWarningHint: `EPB(電動パーキングブレーキ）により車両が停止しました。`,
  Event_epbAlertNotificationTitle: `車両からのお知らせ`,
  Event_epbAlertNotificationBody: `緊急停止を検知しました。`,
  Event_epbAlertHome: `緊急停止を検知しました。`,
  Event_epbAlertDialogTitle: `車両からのお知らせ`,
  Event_epbAlertDialogBody: `{model}の緊急停止を検知しました。`,
  Event_epbAlertDialogAction: `車両からのお知らせを開く`,
  Event_epbAlertDialogAction2: `-`,
  Event_epbAlertDialogClose: `OK`,
  Event_epbAlertAlertTitle: `緊急停止を検知しました。`,
  Event_epbAlertAlertBody: `緊急停止を検知しました。`,
  Event_epbAlertLocation: `-`,
  Event_epbAlertRemoteControl: `-`,
  Event_epbAlertDashboard: `EPB緊急停止検知`,
  Event_airbagAlertEventName: `エアバッグ展開`,
  Event_airbagAlertWarningTitle: `-`,
  Event_airbagAlertWarningHint: `-`,
  Event_airbagAlertNotificationTitle: `車両からのお知らせ`,
  Event_airbagAlertNotificationBody: `エアバッグの作動を検知しました。`,
  Event_airbagAlertHome: `エアバッグの作動を検知しました。`,
  Event_airbagAlertDialogTitle: `車両からのお知らせ`,
  Event_airbagAlertDialogBody: `{model}のエアバッグの作動を検知しました。`,
  Event_airbagAlertDialogAction: `車両からのお知らせを開く`,
  Event_airbagAlertDialogAction2: `-`,
  Event_airbagAlertDialogClose: `OK`,
  Event_airbagAlertAlertTitle: `エアバッグの作動を検知しました。`,
  Event_airbagAlertAlertBody: `エアバッグの作動を検知しました。`,
  Event_airbagAlertLocation: `-`,
  Event_airbagAlertRemoteControl: `-`,
  Event_airbagAlertDashboard: `エアバッグの作動を検知しました。`,
};

export function getMessage(messageId: MessageIds): string {
  type IndexedMessages = {
    readonly [key: string]: string;
  };
  const ms = Messages as unknown as IndexedMessages;
  return ms[messageId];
}
