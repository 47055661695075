import { VehicleEvent } from '@auto/monaka-client/dist/dashboard';
import { Box } from '@mui/system';
import React from 'react';
import { Chip } from '../../components/Chip';
import { IntlText } from '../../components/Intl';
import { EventId } from '../../generated/i18n/Types';
import { useIntl } from '../../i18n/Intl';
import { defaultLocale } from '../../i18n/Locale';
import { MessageId, MessageKeys } from '../../i18n/Message';
import { MUIStyle, Style } from '../../style/Style';
import { Theme } from '../../theme/Theme';
import { isToday } from '../../utils/Date';
import { getEventTitleIcon } from '../../utils/EventTitleIcon';

const styles = Style({
  container: {},

  table: {
    display: 'grid',
    borderTop: `1px solid ${Theme.vehicleList.table.border}`,
    borderLeft: `1px solid ${Theme.vehicleList.table.border}`,

    '& .header-item': {
      padding: `4px`,
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      borderBottom: `1px solid ${Theme.vehicleDetail.table.border}`,
      borderRight: `1px solid ${Theme.vehicleDetail.table.border}`,
      backgroundColor: Theme.vehicleDetail.table.header.backgroundColor,
      color: `white`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& .data-item': {
      position: `relative`,
      padding: `4px`,
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      borderBottom: `1px solid ${Theme.vehicleList.table.border}`,
      borderRight: `1px solid ${Theme.vehicleList.table.border}`,
      display: 'flex',
    },
    '& .data-item-center': {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

  headerDate: {
    gridRow: `1`,
    gridColumn: `1`,
  },

  headerTime: {
    gridRow: `1`,
    gridColumn: `2`,
  },

  headerDetail: {
    gridRow: `1`,
    gridColumn: `3`,
  },

  itemDetail: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  newChip: {
    position: `absolute`,
    top: 0,
    left: 0,
    padding: `2px`,
    color: Theme.vehicleDetail.chip.new.fontColor,
    backgroundColor: Theme.vehicleDetail.chip.new.backgroundColor,
  },
  newChipText: {
    padding: 0,
    fontSize: `0.8em`,
  },
});

const headers: Array<[MUIStyle, MessageKeys]> = [
  [styles.headerDate, MessageId.vehicleDetailWarningLampHeaderDate],
  [styles.headerTime, MessageId.vehicleDetailWarningLampHeaderTime],
  [styles.headerDetail, MessageId.vehicleDetailWarningLampHeaderDetail],
];

export function WarningLamp({
  vehicleEvents,
}: {
  vehicleEvents: VehicleEvent[];
}): React.ReactElement {
  const intl = useIntl(defaultLocale);
  return (
    <Box sx={styles.container}>
      <IntlText id={MessageId.vehicleDetailWarningLampTitle} />
      <Box sx={styles.table}>
        {headers.map(([style, msgId], key) => (
          <Box key={key} className='header-item' sx={style}>
            <IntlText id={msgId} />
          </Box>
        ))}

        {vehicleEvents.flatMap((evt, i) => {
          const titleIcon = getEventTitleIcon(evt.eventId as EventId, intl);
          return titleIcon.title.unwrap(
            title =>
              [
                intl.formatDate(evt.timestamp),
                intl.formatTime(evt.timestamp),
                <Box key={`detail-${i}`} sx={styles.itemDetail}>
                  <img src={titleIcon.icon.getOrUndefined()} />
                  {title}
                </Box>,
              ].map((t, j) => (
                <Box
                  className={
                    j === 2 ? 'data-item' : 'data-item data-item-center'
                  }
                  key={`row-${i}-${j}`}
                  sx={{
                    gridRow: `${3 + i}`,
                    gridColumn: j + 1,
                  }}
                >
                  <Box>{t}</Box>
                  {j === 0 && isToday(evt.timestamp) ? (
                    <Chip
                      label={MessageId.vehicleDetailDTCNew}
                      sx={styles.newChip}
                      textSx={styles.newChipText}
                    />
                  ) : null}
                </Box>
              )),
            () => [],
          );
        })}
      </Box>
    </Box>
  );
}
