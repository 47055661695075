import { None, Option } from '@auto/dango-util';
import { MessageId, MessageKeys } from '../i18n/Message';
import {
  SortOff,
  SortOn,
  Sortable,
  VehicleListHeaderKeys,
} from '../screens/vehicle-list/VehicleList';

export type AppState = Readonly<{
  toolbar: Readonly<{
    type: SearchType;
    value: Option<string>;
    isError: boolean;
  }>;
  search: Option<SearchParams>;
  vehicleList: Readonly<{
    title: MessageKeys;
    sort: VehicleListSort;
  }>;
}>;

export type SearchType = 'Vin' | 'RegNo';

export const SearchType: Readonly<Record<SearchType, SearchType>> = {
  Vin: 'Vin',
  RegNo: 'RegNo',
};

export type SearchParams = Readonly<{
  type: 'Vin' | 'RegNo';
  value: string;
}>;

export type VehicleListSort = Readonly<{
  [key in VehicleListHeaderKeys]: Sortable;
}>;

export const defaultSearchType = SearchType.Vin;

export const initialAppState: AppState = {
  toolbar: {
    type: defaultSearchType,
    value: None(),
    isError: false,
  },
  search: None(),
  vehicleList: {
    title: MessageId.homeVehicleWarningTitle,
    sort: {
      vin: SortOn('Ascend'),
      regNo: SortOff('Ascend'),
      customerInfo: 'None',
      customerName: 'None',
      customerPhone: 'None',
      customerIc: SortOff('Ascend'),
      model: SortOff('Ascend'),
      warning: 'None',
      time: SortOff('Ascend'),
      paa: 'None',
    },
  },
};
