import { None, Scope, Some } from '@auto/dango-util';
import { ServiceTelemetry } from '@auto/monaka-client/dist/dashboard';
import { Box } from '@mui/system';
import React from 'react';
//import ic_gas_gauge_base from '../../assets/ic_gas_gauge_base.svg';
import { IntlText } from '../../components/Intl';
import { MessageId } from '../../i18n/Message';
import * as Icons from '../../icons/Icons';
import { Style } from '../../style/Style';
import { Row } from './Row';

const styles = Style({
  container: {},
  rows: {
    fontWeight: `800`,
    lineHeight: `1.8em`,
  },
  gauge: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  gaugeLevelContainer: {
    position: 'relative',
  },
  gaugeLevel: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  hgap: {
    width: `0.5em`,
  },
  vgap: {
    height: `1em`,
  },
});

function GasGaugeLevel({
  level,
}: Readonly<{ level: number }>): React.ReactElement {
  const gaugeLevelIcon = Scope(() => {
    switch (level) {
      case 1:
        return Some(Icons.ic_gas_gauge_1);
      case 2:
        return Some(Icons.ic_gas_gauge_2);
      case 3:
        return Some(Icons.ic_gas_gauge_3);
      case 4:
        return Some(Icons.ic_gas_gauge_4);
      case 5:
        return Some(Icons.ic_gas_gauge_5);
      case 6:
        return Some(Icons.ic_gas_gauge_6);
      case 7:
        return Some(Icons.ic_gas_gauge_7);
      case 8:
        return Some(Icons.ic_gas_gauge_8);
      default:
        return None<string>();
    }
  });
  return (
    <Box sx={styles.gaugeLevelContainer}>
      <Box>
        <img src={Icons.ic_gas_gauge_base} />
      </Box>
      <Box sx={styles.gaugeLevel}>
        {gaugeLevelIcon.unwrap(
          _ => (
            <img src={_} />
          ),
          () => (
            <></>
          ),
        )}
      </Box>
    </Box>
  );
}

export function VehicleStatus({
  serviceTelemetry,
}: Readonly<{
  serviceTelemetry: ServiceTelemetry;
}>): React.ReactElement {
  return (
    <Box>
      <IntlText id={MessageId.vehicleDetailVehicleStatusTitle} />
      <Box sx={styles.vgap} />
      <Box sx={styles.rows}>
        <Row>
          <IntlText id={MessageId.vehicleDetailVehicleStatusFuel} />
          <Box sx={styles.hgap} />
          <Box sx={styles.gauge}>
            <img src={Icons.ic_gas_black} />
            <GasGaugeLevel level={serviceTelemetry.flSeg} />
          </Box>
        </Row>
        <Row>
          <IntlText id={MessageId.vehicleDetailVehicleStatusRange} />
          <Box sx={styles.hgap} />
          {serviceTelemetry.range.toLocaleString('en-US', {
            maximumFractionDigits: 0,
          })}
          km
        </Row>
        <Row>
          <IntlText id={MessageId.vehicleDetailVehicleStatusDistance} />
          <Box sx={styles.hgap} />
          {serviceTelemetry.odo.toLocaleString('en-US', {
            maximumFractionDigits: 0,
          })}
          km
        </Row>
        <Row>
          <IntlText id={MessageId.vehicleDetailVehicleStatusAve} />
          <Box sx={styles.hgap} />
          {serviceTelemetry.toFc.toLocaleString('en-US', {
            maximumFractionDigits: 1,
          })}
          km/L
        </Row>
      </Box>
    </Box>
  );
}
