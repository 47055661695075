import { Box } from '@mui/system';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { signOut } from '../backend/Auth';
import { TextButton } from '../components/Button';
import { Header } from '../components/Header';
import { useIntl } from '../i18n/Intl';
import { defaultLocale } from '../i18n/Locale';
import { MessageId } from '../i18n/Message';
import { Style } from '../style/Style';
import { Theme } from '../theme/Theme';

// You need to use View so that it makes maxHeight work,
// while Box's maxHeight does not work.
const styles = StyleSheet.create({
  container: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    overflow: 'hidden',
  },
  body: {
    flex: 1,
    maxWidth: `100%`,
    maxHeight: `100%`,
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    padding: `8px`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopWidth: 0.5,
    borderTopColor: Theme.footer.borderColor,
    backgroundColor: Theme.footer.backgroundColor,
  },
});

const sxStyles = Style({
  signOutButton: {
    position: 'absolute',
    bottom: `4px`,
    right: `4px`,
    backgroundColor: 'white',
    display: 'flex',
  },
});

export function AppFrame({
  children,
}: Readonly<{
  children: React.ReactNode;
}>): React.ReactElement {
  const intl = useIntl(defaultLocale);
  const Text = Box;
  const Container = View;
  const Body = View;
  const Footer = View;
  return (
    <Container style={styles.container}>
      <Header />
      <Body style={styles.body}>{children}</Body>
      <Footer style={styles.footer}>
        <Text>Copyright ©Daihatsu & Perodua {new Date().getFullYear()}</Text>
      </Footer>
      <Box sx={sxStyles.signOutButton}>
        <TextButton
          title={intl.formatMessageAsString({ id: MessageId.signOut })}
          onClick={_ => signOut()}
        />
      </Box>
    </Container>
  );
}
