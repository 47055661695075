export type DtcLang = 'en' | 'ja';

export type DtcCode =
  | 'ﾒｰﾀ_U0235'
  | 'ERS_P0573'
  | 'EFI_P2759'
  | 'EFI_B1901'
  | 'EFI_C1A50'
  | 'ｽﾃﾚｵｶﾒﾗ_U0074'
  | 'CVT_P1403'
  | 'EFI_P0966'
  | 'ERS_P0743'
  | 'EFI_B1927'
  | 'A/B_C1581'
  | 'BSMﾏｽﾀ_B3014'
  | 'EFI_C1AC1'
  | 'BSMｽﾚｰﾌﾞ_P0571'
  | 'A/B_B1632'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2769'
  | 'EFI_C1336'
  | 'ERS_P0713'
  | 'EFI_B1837'
  | 'EFI_U0155'
  | 'ABS/VSC_U0126'
  | 'EFI_C1275'
  | 'EFI_B1923'
  | 'ﾎﾞﾃﾞｰ_U0121'
  | 'ABS/VSC_P0304'
  | 'EPS_B2894'
  | 'EFI_P0107'
  | 'ﾎﾞﾃﾞｰ_P0350'
  | 'EPS_B2895'
  | 'ﾎﾞﾃﾞｰ_P1780'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2A00'
  | 'ﾎﾞﾃﾞｰ_B2414'
  | 'A/B_B1808'
  | 'EFI_C13A5'
  | 'EFI_P2238'
  | 'ﾎﾞﾃﾞｰ_U1002'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2716'
  | 'ERS_P060B'
  | 'A/B_B1635'
  | 'EFI_P0017'
  | 'EPS_C2143'
  | 'ABS/VSC_P0138'
  | 'ｿﾅｰ_B24E2'
  | 'EFI_P0116'
  | 'ﾎﾞﾃﾞｰ_U0101'
  | 'EFI_P0015'
  | 'EFI_P099B'
  | 'ｽﾃﾚｵｶﾒﾗ_U045A'
  | 'ﾎﾞﾃﾞｰ_U0166'
  | 'EFI_C1AB4'
  | 'ERS_P0131'
  | 'ｽﾃﾚｵｶﾒﾗ_C1AE6'
  | 'ｿﾅｰ_B2435'
  | 'A/B_B163F'
  | 'ERS_P0627'
  | 'A/B_P0806'
  | 'EFI_P2127'
  | 'ﾎﾞﾃﾞｰ_P1602'
  | 'EFI_P0335'
  | 'A/B_B163E'
  | 'EFI_C1320'
  | 'CVT_P21DF'
  | 'A/B_U0121'
  | 'EPS_C2126'
  | 'A/B_C2330'
  | 'ﾎﾞﾃﾞｰ_P0325'
  | 'ERS_P0628'
  | 'EFI_P084B'
  | 'EFI_C1512'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2875'
  | 'EFI_C1202'
  | 'EFI_C1237'
  | 'EFI_U1103'
  | 'A/B_P0AC0'
  | 'ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2789'
  | 'ｽﾃﾚｵｶﾒﾗ_U1104'
  | 'A/B_U023A'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P287A'
  | 'A/B_B1805'
  | 'EFI_B1918'
  | 'ABS/VSC_B2451'
  | 'ABS/VSC_U0101'
  | 'ﾎﾞﾃﾞｰ_P0268'
  | 'EFI_C1327'
  | 'EFI_C1396'
  | 'EFI_C1207'
  | 'EFI_P0135'
  | 'BSMﾏｽﾀ_C1A41'
  | 'A/B_C1554'
  | 'CVT_P1349'
  | 'EFI_B1825'
  | 'EFI_P0010'
  | 'BSMﾏｽﾀ_P0564'
  | 'ﾎﾞﾃﾞｰ_P1546'
  | 'EFI_B1910'
  | 'EFI_P0340'
  | 'EFI_P0AC0'
  | 'A/B_B1610'
  | 'EPS_C2181'
  | 'EFI_P0711'
  | 'A/B_P1520'
  | 'ｿﾅｰ_B2441'
  | 'ｽﾃﾚｵｶﾒﾗ_U0100'
  | 'BSMｽﾚｰﾌﾞ_P061B'
  | 'EPS_C2113'
  | 'ERS_P0532'
  | 'ERS_P0717'
  | 'ﾎﾞﾃﾞｰ_P0140'
  | 'CVT_P1300'
  | 'A/B_B1628'
  | 'EFI_C1AB2'
  | 'EFI_C1231'
  | 'ﾎﾞﾃﾞｰ_U0164'
  | 'ｽﾃﾚｵｶﾒﾗ_U0101'
  | 'EFI_P0218'
  | 'A/B_P0705'
  | 'ABS/VSC_U0155'
  | 'ﾒｰﾀ_U0243'
  | 'CVT_P1297'
  | 'ｽﾃﾚｵｶﾒﾗ_U0423'
  | 'EFI_P0798'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2770'
  | 'ﾎﾞﾃﾞｰ_P0343'
  | 'EFI_C13B4'
  | 'ｽﾃﾚｵｶﾒﾗ_B1000'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2307'
  | 'ERS_P0657'
  | 'ﾒｰﾀ_U1000'
  | 'ABS/VSC_P0137'
  | 'EFI_P0710'
  | 'ﾒｰﾀ_U1112'
  | 'EFI_C13A6'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P21E1'
  | 'BSMｽﾚｰﾌﾞ_P0593'
  | 'ｽﾃﾚｵｶﾒﾗ_U023A'
  | 'A/B_C1571'
  | 'EFI_C13B0'
  | 'ﾎﾞﾃﾞｰ_P1862'
  | 'EFI_C1531'
  | 'EFI_B1926'
  | 'EFI_P0038'
  | 'EFI_C1534'
  | 'ERS_P0105'
  | 'ABS/VSC_P0200'
  | 'EFI_C1244'
  | 'ABS/VSC_P0171'
  | 'ﾒｰﾀ_B2286'
  | 'EFI_P0070'
  | 'EFI_P084C'
  | 'EFI_C1A47'
  | 'ｽﾃﾚｵｶﾒﾗ_B1004'
  | 'A/B_B1623'
  | 'EFI_B1902'
  | 'EFI_B1867'
  | 'A/B_C2121'
  | 'ｽﾃﾚｵｶﾒﾗ_U0401'
  | 'ERS_P0730'
  | 'ﾎﾞﾃﾞｰ_P2226'
  | 'ﾒｰﾀ_U1100'
  | 'EFI_P0500'
  | 'EFI_P0957'
  | 'ﾎﾞﾃﾞｰ_B1504'
  | 'EFI_P2128'
  | 'ｽﾃﾚｵｶﾒﾗ_U0075'
  | 'EFI_P0031'
  | 'ﾎﾞﾃﾞｰ_P0190'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0131'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0233'
  | 'ﾎﾞﾃﾞｰ_B1506'
  | 'ﾎﾞﾃﾞｰ_B1503'
  | 'A/B_B1801'
  | 'ERS_P0110'
  | 'ﾒｰﾀ_B2283'
  | 'ERS_C1263'
  | 'EFI_P0351'
  | 'ﾎﾞﾃﾞｰ_P0368'
  | 'A/B_B1620'
  | 'ｽﾃﾚｵｶﾒﾗ_B1011'
  | 'ABS/VSC_P0202'
  | 'ｽﾃﾚｵｶﾒﾗ_C1AF0'
  | 'A/B_B1615'
  | 'EFI_B1823'
  | 'ﾒｰﾀ_B2291'
  | 'ERS_P0572'
  | 'EFI_P0014'
  | 'CVT_P1399'
  | 'EFI_P0606'
  | 'A/B_C1573'
  | 'CVT_P2196'
  | 'ﾎﾞﾃﾞｰ_P0339'
  | 'EFI_C1210'
  | 'A/B_B1613'
  | 'EFI_C1379'
  | 'ｽﾃﾚｵｶﾒﾗ_U0305'
  | 'ERS_P287B'
  | 'A/B_B162E'
  | 'A/B_C1246'
  | 'ﾎﾞﾃﾞｰ_P0195'
  | 'ﾎﾞﾃﾞｰ_P1857'
  | 'BSMﾏｽﾀ_C1A50'
  | 'EFI_P102D'
  | 'EFI_B1833'
  | 'ERS_P0130'
  | 'ｽﾃﾚｵｶﾒﾗ_C1AE8'
  | 'EFI_P0336'
  | 'EFI_B1900'
  | 'ERS_P0758'
  | 'ﾎﾞﾃﾞｰ_P0337'
  | 'ﾎﾞﾃﾞｰ_P1855'
  | 'ERS_P0773'
  | 'ｽﾃﾚｵｶﾒﾗ_U0418'
  | 'ｽﾃﾚｵｶﾒﾗ_U0038'
  | 'EFI_B1908'
  | 'ﾎﾞﾃﾞｰ_P0400'
  | 'EPS_B2890'
  | 'A/B_P0810'
  | 'ERS_P0705'
  | 'ｽﾃﾚｵｶﾒﾗ_C1AE9'
  | 'ﾎﾞﾃﾞｰ_U0126'
  | 'ﾒｰﾀ_B124B'
  | 'EFI_C0051'
  | 'EFI_P282B'
  | 'EFI_C0205'
  | 'A/B_P0830'
  | 'EFI_U0151'
  | 'ｽﾃﾚｵｶﾒﾗ_U0073'
  | 'BSMｽﾚｰﾌﾞ_P0579'
  | 'ｽﾃﾚｵｶﾒﾗ_C1AE1'
  | 'EFI_P0843'
  | 'EFI_B1866'
  | 'EPS_C2111'
  | 'EFI_C1513'
  | 'ERS_P0741'
  | 'ｽﾃﾚｵｶﾒﾗ_B2451'
  | 'EFI_U0235'
  | 'EFI_P2226'
  | 'EFI_C1276'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0232'
  | 'EFI_C1281'
  | 'ABS/VSC_P0301'
  | 'EPS_B2893'
  | 'EFI_B1903'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P21E0'
  | 'ﾎﾞﾃﾞｰ_B2420'
  | 'CVT_P2109'
  | 'A/B_P1115'
  | 'ABS/VSC_P0327'
  | 'CVT_P1780'
  | 'A/B_U0126'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0156'
  | 'EFI_C1236'
  | 'ｽﾃﾚｵｶﾒﾗ_B1007'
  | 'EPS_C2125'
  | 'EFI_C1393'
  | 'ABS/VSC_U0100'
  | 'EFI_C0200'
  | 'EFI_C1249'
  | 'CVT_P2101'
  | 'ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2880'
  | 'A/B_U0243'
  | 'CVT_P2100'
  | 'ﾎﾞﾃﾞｰ_B1501'
  | 'EFI_C1251'
  | 'EFI_B1917'
  | 'EFI_B1830'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2300'
  | 'EFI_P0123'
  | 'ERS_P0753'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0121'
  | 'EFI_P0117'
  | 'ﾒｰﾀ_B2292'
  | 'ﾒｰﾀ_U0181'
  | 'EFI_C1AB0'
  | 'EFI_C13AA'
  | 'ERS_P0620'
  | 'CVT_P161B'
  | 'A/B_B1633'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2879'
  | 'EFI_C0278'
  | 'EFI_P2767'
  | 'ﾎﾞﾃﾞｰ_U0202'
  | 'ﾎﾞﾃﾞｰ_U0156'
  | 'A/B_P0335'
  | 'EFI_P161A'
  | 'EFI_P0132'
  | 'EFI_P0724'
  | 'BSMﾏｽﾀ_B3105'
  | 'EFI_C1321'
  | 'ERS_P0036'
  | 'CVT_P1602'
  | 'EFI_C1323'
  | 'EFI_C1380'
  | 'EFI_C1328'
  | 'EFI_B1913'
  | 'CVT_P1511'
  | 'ﾒｰﾀ_B2788'
  | 'ﾎﾞﾃﾞｰ_P0265'
  | 'ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2881'
  | 'ｽﾃﾚｵｶﾒﾗ_B1002'
  | 'A/B_P0850'
  | 'ﾎﾞﾃﾞｰ_P0180'
  | 'ABS/VSC_P0354'
  | 'ERS_P0533'
  | 'EFI_C0279'
  | 'EFI_C1ABC'
  | 'EFI_P0962'
  | 'EFI_C1AB7'
  | 'EFI_C1277'
  | 'ERS_U0100'
  | 'ｽﾃﾚｵｶﾒﾗ_U0131'
  | 'EFI_P0575'
  | 'ﾎﾞﾃﾞｰ_B2430'
  | 'EFI_U0101'
  | 'ERS_U1103'
  | 'EFI_C1238'
  | 'CVT_P2118'
  | 'ERS_P0458'
  | 'EFI_P1603'
  | 'EPS_C2185'
  | 'BSMｽﾚｰﾌﾞ_P0566'
  | 'ﾒｰﾀ_B2781'
  | 'ｽﾃﾚｵｶﾒﾗ_U0076'
  | 'EFI_P0725'
  | 'EPS_C2198'
  | 'ｿﾅｰ_B24DB'
  | 'A/B_P1535'
  | 'EFI_P0535'
  | 'EFI_U0126'
  | 'EFI_C0236'
  | 'EFI_P0037'
  | 'BSMﾏｽﾀ_C1A61'
  | 'ﾎﾞﾃﾞｰ_U0146'
  | 'EFI_C1AB1'
  | 'ｿﾅｰ_B24D4'
  | 'EFI_C1225'
  | 'ﾎﾞﾃﾞｰ_U0142'
  | 'ｽﾃﾚｵｶﾒﾗ_U1000'
  | 'CVT_P2121'
  | 'ﾒｰﾀ_B2295'
  | 'EFI_C1417'
  | 'ﾒｰﾀ_U0167'
  | 'ABS/VSC_U0164'
  | 'EFI_P0011'
  | 'EFI_U0142'
  | 'EFI_P0557'
  | 'EFI_P1115'
  | 'A/B_P0070'
  | 'CVT_P21DC'
  | 'A/B_C2123'
  | 'ﾎﾞﾃﾞｰ_U0111'
  | 'EFI_P0108'
  | 'EFI_B1922'
  | 'EFI_P0122'
  | 'ABS/VSC_P0136'
  | 'EFI_P2111'
  | 'ﾎﾞﾃﾞｰ_P0262'
  | 'EFI_P21D1'
  | 'EFI_C1322'
  | 'EFI_C1245'
  | 'EFI_C1A45'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2878'
  | 'ERS_U0129'
  | 'ERS_P0512'
  | 'BSMｽﾚｰﾌﾞ_U0123'
  | 'ｽﾃﾚｵｶﾒﾗ_U0122'
  | 'A/B_C1553'
  | 'CVT_P1190'
  | 'BSMﾏｽﾀ_C1207'
  | 'ABS/VSC_B1003'
  | 'ｽﾃﾚｵｶﾒﾗ_U0129'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2303'
  | 'A/B_P1510'
  | 'BSMｽﾚｰﾌﾞ_P0581'
  | 'EFI_C1272'
  | 'CVT_P1400'
  | 'EFI_P21CF'
  | 'ﾎﾞﾃﾞｰ_P323A'
  | 'ｿﾅｰ_B2439'
  | 'EFI_C1A46'
  | 'EPS_C2177'
  | 'CVT_P1585'
  | 'ERS_P0629'
  | 'A/B_B1821'
  | 'ｿﾅｰ_B24D2'
  | 'ﾎﾞﾃﾞｰ_P0308'
  | 'A/B_B1625'
  | 'EFI_P1550'
  | 'EPS_C2142'
  | 'EFI_P1130'
  | 'ｽﾃﾚｵｶﾒﾗ_C1AED'
  | 'ｽﾃﾚｵｶﾒﾗ_U1111'
  | 'ｽﾃﾚｵｶﾒﾗ_B1001'
  | 'ERS_P0768'
  | 'ｽﾃﾚｵｶﾒﾗ_U0159'
  | 'EFI_C1203'
  | 'ﾒｰﾀ_U0202'
  | 'A/B_B162F'
  | 'ERS_P0748'
  | 'EPS_C2182'
  | 'A/B_P0555'
  | 'ﾒｰﾀ_B2278'
  | 'BSMｽﾚｰﾌﾞ_P0589'
  | 'ﾎﾞﾃﾞｰ_P1550'
  | 'A/B_B1612'
  | 'ERS_P0763'
  | 'A/B_C1552'
  | 'BSMｽﾚｰﾌﾞ_P0567'
  | 'EFI_P101D'
  | 'A/B_B1617'
  | 'CVT_P1601'
  | 'ｿﾅｰ_B2452'
  | 'EFI_P0963'
  | 'ﾒｰﾀ_U1101'
  | 'ｽﾃﾚｵｶﾒﾗ_B1244'
  | 'EFI_C1273'
  | 'ﾎﾞﾃﾞｰ_P3030'
  | 'EFI_B1826'
  | 'ｽﾃﾚｵｶﾒﾗ_C1AE4'
  | 'ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2431'
  | 'ﾎﾞﾃﾞｰ_U0123'
  | 'ｽﾃﾚｵｶﾒﾗ_U1110'
  | 'EFI_P0012'
  | 'EPS_C2114'
  | 'EFI_C0365'
  | 'ﾎﾞﾃﾞｰ_U0155'
  | 'ABS/VSC_P0328'
  | 'ｽﾃﾚｵｶﾒﾗ_C1A79'
  | 'BSMｽﾚｰﾌﾞ_U0100'
  | 'EFI_B1912'
  | 'A/B_P1511'
  | 'ERS_C1264'
  | 'ERS_P060D'
  | 'EFI_P2138'
  | 'EFI_C1361'
  | 'EFI_P0118'
  | 'ｿﾅｰ_B24D1'
  | 'EFI_P0722'
  | 'EFI_P2253'
  | 'ｿﾅｰ_B24D6'
  | 'EFI_B1915'
  | 'A/B_P0515'
  | 'EFI_C1381'
  | 'ｿﾅｰ_B24D3'
  | 'BSMﾏｽﾀ_C1A79'
  | 'ｿﾅｰ_B24DA'
  | 'ｽﾃﾚｵｶﾒﾗ_U1103'
  | 'EFI_C1394'
  | 'A/B_B1010'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0115'
  | 'ﾎﾞﾃﾞｰ_P0261'
  | 'EPS_C2141'
  | 'EFI_C1329'
  | 'A/B_B161F'
  | 'A/B_C1555'
  | 'EFI_P0713'
  | 'EFI_P1607'
  | 'EFI_P1604'
  | 'EFI_P0942'
  | 'EFI_P0842'
  | 'BSMｽﾚｰﾌﾞ_U0074'
  | 'ﾎﾞﾃﾞｰ_P0338'
  | 'BSMｽﾚｰﾌﾞ_P0565'
  | 'EFI_B1907'
  | 'ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2793'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U1000'
  | 'ｽﾃﾚｵｶﾒﾗ_U0090'
  | 'BSMﾏｽﾀ_B24E4'
  | 'ﾎﾞﾃﾞｰ_P1548'
  | 'A/B_U0416'
  | 'CVT_P16A1'
  | 'EFI_B1921'
  | 'ERS_P0030'
  | 'EFI_P0558'
  | 'A/B_B1803'
  | 'EFI_C0226'
  | 'ERS_P060E'
  | 'EFI_P0830'
  | 'CVT_P1730'
  | 'BSMﾏｽﾀ_B3100'
  | 'EPS_C2184'
  | 'EFI_C1523'
  | 'ERS_P0103'
  | 'ｽﾃﾚｵｶﾒﾗ_U0300'
  | 'EFI_P2123'
  | 'ﾒｰﾀ_U0182'
  | 'EFI_U1101'
  | 'EFI_U0164'
  | 'EPS_C2115'
  | 'ｽﾃﾚｵｶﾒﾗ_U0405'
  | 'EFI_B1865'
  | 'ABS/VSC_P0353'
  | 'ｽﾃﾚｵｶﾒﾗ_C1AE3'
  | 'A/B_B1800'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2304'
  | 'BSMﾏｽﾀ_C1A60'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2822'
  | 'EFI_P0420'
  | 'A/B_B1000'
  | 'EFI_C1AB6'
  | 'ｽﾃﾚｵｶﾒﾗ_U0142'
  | 'ﾎﾞﾃﾞｰ_B2785'
  | 'A/B_B161E'
  | 'ERS_U1116'
  | 'EFI_C1ABB'
  | 'A/B_B1652'
  | 'CVT_P1351'
  | 'EPS_B2896'
  | 'EFI_C1246'
  | 'EFI_B1916'
  | 'EFI_P0828'
  | 'ｿﾅｰ_B24D5'
  | 'ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2795'
  | 'CVT_P1560'
  | 'ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2792'
  | 'ﾎﾞﾃﾞｰ_U0129'
  | 'A/B_U0100'
  | 'ABS/VSC_P0302'
  | 'ﾒｰﾀ_B2782'
  | 'EFI_B1868'
  | 'ﾒｰﾀ_B2536'
  | 'EFI_C1239'
  | 'EFI_P0133'
  | 'CVT_P1749'
  | 'A/B_B1638'
  | 'ﾎﾞﾃﾞｰ_P3052'
  | 'EPS_B2882'
  | 'EFI_P0850'
  | 'EFI_B1836'
  | 'ABS/VSC_P0172'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2764'
  | 'EFI_B1832'
  | 'ﾎﾞﾃﾞｰ_P1545'
  | 'EFI_U0166'
  | 'EFI_C1270'
  | 'EFI_P2119'
  | 'EFI_C0256'
  | 'ﾒｰﾀ_B2430'
  | 'A/B_C1231'
  | 'CVT_P1552'
  | 'BSMｽﾚｰﾌﾞ_U0122'
  | 'EFI_P2135'
  | 'ｿﾅｰ_B24D0'
  | 'ﾎﾞﾃﾞｰ_U0201'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2877'
  | 'EFI_P21D0'
  | 'BSMﾏｽﾀ_C1A22'
  | 'EFI_P0603'
  | 'ﾒｰﾀ_U1119'
  | 'ﾎﾞﾃﾞｰ_B278C'
  | 'A/B_C1244'
  | 'EFI_C0356'
  | 'ABS/VSC_P0203'
  | 'A/B_U0155'
  | 'ﾒｰﾀ_B2271'
  | 'ﾒｰﾀ_B2272'
  | 'EFI_B1906'
  | 'EFI_P0705'
  | 'A/B_U1103'
  | 'EFI_C1233'
  | 'EFI_P0805'
  | 'ﾎﾞﾃﾞｰ_P0220'
  | 'ﾎﾞﾃﾞｰ_P3053'
  | 'EFI_C1325'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2763'
  | 'ABS/VSC_P0300'
  | 'A/B_B1807'
  | 'EFI_P2102'
  | 'EFI_P0113'
  | 'ABS/VSC_P0141'
  | 'EFI_P1546'
  | 'ERS_C2124'
  | 'CVT_P2120'
  | 'EFI_P2252'
  | 'EFI_P0967'
  | 'ﾎﾞﾃﾞｰ_P0221'
  | 'ERS_P0102'
  | 'EPS_C2183'
  | 'A/B_B1822'
  | 'ERS_P062F'
  | 'ABS/VSC_P0352'
  | 'ｽﾃﾚｵｶﾒﾗ_C1AEC'
  | 'EFI_C1326'
  | 'EFI_B1905'
  | 'ｿﾅｰ_B2440'
  | 'EFI_P0032'
  | 'EFI_P0443'
  | 'ERS_P060A'
  | 'A/B_B1820'
  | 'CVT_P1510'
  | 'EFI_B1827'
  | 'EFI_C1511'
  | 'ﾒｰﾀ_U0159'
  | 'CVT_P21DE'
  | 'BSMﾏｽﾀ_C1A7A'
  | 'EFI_P2237'
  | 'EFI_P0622'
  | 'ERS_P0630'
  | 'EFI_P1105'
  | 'A/B_B1806'
  | 'EFI_C13A7'
  | 'EPS_C2144'
  | 'EFI_C1235'
  | 'EFI_P0530'
  | 'A/B_B1627'
  | 'EFI_P0013'
  | 'EFI_P0841'
  | 'BSMｽﾚｰﾌﾞ_P1576'
  | 'EFI_B1911'
  | 'EFI_C1241'
  | 'ERS_P0402'
  | 'ABS/VSC_P0365'
  | 'ﾎﾞﾃﾞｰ_P0267'
  | 'A/B_P1540'
  | 'ABS/VSC_P0223'
  | 'EFI_P2122'
  | 'ﾎﾞﾃﾞｰ_B2786'
  | 'ﾎﾞﾃﾞｰ_P1549'
  | 'A/B_C1557'
  | 'EFI_C1533'
  | 'ﾎﾞﾃﾞｰ_P1541'
  | 'CVT_P1402'
  | 'BSMｽﾚｰﾌﾞ_P056B'
  | 'BSMﾏｽﾀ_B24E3'
  | 'A/B_P0719'
  | 'CVT_P2112'
  | 'BSMｽﾚｰﾌﾞ_P0568'
  | 'EFI_C1274'
  | 'EFI_U0129'
  | 'A/B_P1105'
  | 'CVT_P1401'
  | 'ｽﾃﾚｵｶﾒﾗ_C1AE2'
  | 'EFI_B1838'
  | 'ERS_P0604'
  | 'ﾒｰﾀ_B2786'
  | 'EFI_B1920'
  | 'EFI_P0607'
  | 'CVT_P2125'
  | 'A/B_P0500'
  | 'EFI_B1861'
  | 'ｿﾅｰ_B24DC'
  | 'EPS_C2145'
  | 'ｽﾃﾚｵｶﾒﾗ_U1112'
  | 'EFI_B1860'
  | 'ﾎﾞﾃﾞｰ_P0264'
  | 'EFI_C1AB3'
  | 'ERS_P0720'
  | 'ﾒｰﾀ_B2281'
  | 'ﾒｰﾀ_B2287'
  | 'EFI_B1928'
  | 'EFI_P2239'
  | 'ABS/VSC_P0401'
  | 'EFI_P0112'
  | 'EFI_P2103'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2501'
  | 'EFI_P0560'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2876'
  | 'EFI_C1278'
  | 'ﾎﾞﾃﾞｰ_U0100'
  | 'ｽﾃﾚｵｶﾒﾗ_C1AE7'
  | 'EFI_C0210'
  | 'ERS_P0100'
  | 'EFI_C1514'
  | 'EFI_C1300'
  | 'ﾒｰﾀ_B2294'
  | 'EFI_C1248'
  | 'ﾎﾞﾃﾞｰ_B1505'
  | 'ﾒｰﾀ_U0201'
  | 'EFI_P0403'
  | 'ﾎﾞﾃﾞｰ_U0131'
  | 'ERS_P0505'
  | 'EFI_P0016'
  | 'ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2794'
  | 'A/B_B1622'
  | 'ABS/VSC_P0201'
  | 'EFI_B1925'
  | 'ABS/VSC_U0151'
  | 'ｽﾃﾚｵｶﾒﾗ_U0155'
  | 'ﾒｰﾀ_U0241'
  | 'CVT_P1731'
  | 'CVT_P1600'
  | 'CVT_P1551'
  | 'EFI_B1828'
  | 'EFI_P0515'
  | 'ﾎﾞﾃﾞｰ_P1543'
  | 'ERS_P0715'
  | 'EFI_P0778'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2301'
  | 'EFI_B1862'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0100'
  | 'EFI_B1863'
  | 'A/B_B1618'
  | 'EFI_C1271'
  | 'CVT_P1346'
  | 'ERS_P0459'
  | 'BSMﾏｽﾀ_C1A40'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0073'
  | 'EFI_P0741'
  | 'EFI_C0215'
  | 'EPS_B2892'
  | 'A/B_B1651'
  | 'CVT_P21DB'
  | 'CVT_P1656'
  | 'ERS_P0617'
  | 'EFI_P0134'
  | 'EFI_C1279'
  | 'ｿﾅｰ_B2450'
  | 'ﾎﾞﾃﾞｰ_U1207'
  | 'EFI_U0167'
  | 'ERS_P000A'
  | 'ｿﾅｰ_B24DD'
  | 'ﾎﾞﾃﾞｰ_U0235'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0001'
  | 'A/B_C1541'
  | 'EPS_C2171'
  | 'EFI_P0642'
  | 'EFI_C0246'
  | 'A/B_B1802'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0123'
  | 'A/B_C2122'
  | 'ﾎﾞﾃﾞｰ_B1500'
  | 'ABS/VSC_U1103'
  | 'BSMﾏｽﾀ_C1A23'
  | 'ABS/VSC_P0204'
  | 'ﾎﾞﾃﾞｰ_P1544'
  | 'A/B_B1637'
  | 'EFI_C0273'
  | 'A/B_B1630'
  | 'ﾎﾞﾃﾞｰ_U0114'
  | 'EFI_P0706'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2874'
  | 'EFI_C1AB5'
  | 'A/B_P0512'
  | 'CVT_P2195'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2747'
  | 'EFI_P084D'
  | 'EFI_C1524'
  | 'EFI_B1835'
  | 'EFI_P1605'
  | 'EFI_B1831'
  | 'ERS_P0571'
  | 'EPS_B2891'
  | 'ABS/VSC_P0303'
  | 'EFI_P0120'
  | 'EFI_C1532'
  | 'A/B_C1336'
  | 'EFI_C1AC2'
  | 'EPS_C2112'
  | 'ｽﾃﾚｵｶﾒﾗ_C1AEF'
  | 'CVT_P1703'
  | 'EFI_C1227'
  | 'ﾎﾞﾃﾞｰ_P0342'
  | 'EFI_P0121'
  | 'ﾎﾞﾃﾞｰ_U0243'
  | 'ERS_P0712'
  | 'ﾒｰﾀ_U0232'
  | 'EFI_P0504'
  | 'EFI_P0562'
  | 'ABS/VSC_U0073'
  | 'ｿﾅｰ_B243A'
  | 'EFI_P099C'
  | 'EFI_P0712'
  | 'ﾒｰﾀ_B2296'
  | 'ﾎﾞﾃﾞｰ_B1502'
  | 'ﾒｰﾀ_U023A'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2306'
  | 'ﾒｰﾀ_B2431'
  | 'EFI_P0115'
  | 'ABS/VSC_P0222'
  | 'ﾎﾞﾃﾞｰ_P0367';

export type DtcConverter = Readonly<{
  [lang in DtcLang]: Readonly<{
    [code in DtcCode]: string;
  }>;
}>;

export const DtcConverter: DtcConverter = {
  ja: {
    ﾒｰﾀ_U1119: '運転支援ECU通信途絶',
    ﾒｰﾀ_U1112: 'ｺﾝﾋﾞSW間CAN通信異常',
    ﾒｰﾀ_U1101: '運転支援ECU通信途絶',
    ﾒｰﾀ_U1100: 'PSB通信途絶',
    ﾒｰﾀ_U1000: 'CAN通信異常(ﾚｼﾞｽﾀ固着)',
    ﾒｰﾀ_U0243: 'ﾘﾔｿﾅｰ通信途絶',
    ﾒｰﾀ_U0241: 'ﾚﾍﾞﾘﾝｸﾞECU間CAN通信異常',
    ﾒｰﾀ_U023A: 'ｽﾏｱｼECU間CAN通信異常',
    ﾒｰﾀ_U0235: 'LSR/ｽﾃﾚｵｶﾒﾗ通信途絶',
    ﾒｰﾀ_U0232: 'BSM通信途絶',
    ﾒｰﾀ_U0202: 'PSL通信異常',
    ﾒｰﾀ_U0201: 'PSR通信異常',
    ﾒｰﾀ_U0182: 'AFS通信途絶',
    ﾒｰﾀ_U0181: 'H/Lﾚﾍﾞﾘﾝｸﾞ通信途絶',
    ﾒｰﾀ_U0167: 'ｷｰﾌﾘｰ/ｲﾓﾋﾞ通信異常',
    ﾒｰﾀ_U0159: 'ﾌﾛﾝﾄｿﾅｰ通信途絶',
    ﾒｰﾀ_B2788: 'EFI ECU通信不良(ｺｰﾄﾞ不一致)',
    ﾒｰﾀ_B2786: 'ｽﾃｱﾘﾝｸﾞﾛｯｸ　ﾀﾞｲｱｸﾞ通信応答異常',
    ﾒｰﾀ_B2782: '給電回路異常',
    ﾒｰﾀ_B2781: 'ｽﾃｱﾘﾝｸﾞﾛｯｸECUﾄﾞﾗｲﾊﾞ異常',
    ﾒｰﾀ_B2536: 'ﾗｹﾞｰｼﾞSW入力異常',
    ﾒｰﾀ_B2431: '右LEDﾍｯﾄﾞﾗﾝﾌﾟ異常',
    ﾒｰﾀ_B2430: '左LEDﾍｯﾄﾞﾗﾝﾌﾟ異常',
    ﾒｰﾀ_B2296: 'IG2異常',
    ﾒｰﾀ_B2295: 'IG1異常',
    ﾒｰﾀ_B2294: 'ACC異常',
    ﾒｰﾀ_B2292: 'Lｺｰﾄﾞ登録異常',
    ﾒｰﾀ_B2291: 'ｸﾗｯﾁSW異常',
    ﾒｰﾀ_B2287: 'ｽﾃｱﾘﾝｸﾞﾛｯｸ通信異常',
    ﾒｰﾀ_B2286: 'ｴﾝｼﾞﾝ回転信号異常',
    ﾒｰﾀ_B2283: '車速ｾﾝｻ故障検出',
    ﾒｰﾀ_B2281: 'P検出SW異常',
    ﾒｰﾀ_B2278: 'ﾌﾟｯｼｭSW異常',
    ﾒｰﾀ_B2272: 'IGﾘﾚｰ異常',
    ﾒｰﾀ_B2271: 'IGｲﾆｼｬﾙﾁｪｯｸ異常',
    ﾒｰﾀ_B124B: 'ｵｰﾄﾊｲﾋﾞｰﾑ故障',
    ﾎﾞﾃﾞｰ_U1207: 'AT/CVT ECU通信異常',
    ﾎﾞﾃﾞｰ_U1002: 'CAN通信',
    ﾎﾞﾃﾞｰ_U0243: 'ｴｺﾗﾝ-CMR通信異常',
    ﾎﾞﾃﾞｰ_U0235: 'ｴｺﾗﾝ-LSR/ｽﾃﾚｵｶﾒﾗ通信異常',
    ﾎﾞﾃﾞｰ_U0202: 'ｴｺﾗﾝ-PSL通信異常',
    ﾎﾞﾃﾞｰ_U0201: 'ｴｺﾗﾝ-PSR通信異常',
    ﾎﾞﾃﾞｰ_U0166: 'ｴｺﾗﾝ-PTCﾋｰﾀ通信異常',
    ﾎﾞﾃﾞｰ_U0164: 'ｴｺﾗﾝ-ｵｰﾄA/C通信異常',
    ﾎﾞﾃﾞｰ_U0156: 'ｴｺﾗﾝ-ﾒｰﾀ通信異常',
    ﾎﾞﾃﾞｰ_U0155: 'ﾒｰﾀ間CAN通信異常',
    ﾎﾞﾃﾞｰ_U0146: 'ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ通信途絶',
    ﾎﾞﾃﾞｰ_U0142: 'ｴｺﾗﾝ-ﾎﾞﾃﾞｰ通信異常',
    ﾎﾞﾃﾞｰ_U0131: 'ｴｺﾗﾝ-EPS通信異常',
    ﾎﾞﾃﾞｰ_U0129: 'ABS/VSC通信異常',
    ﾎﾞﾃﾞｰ_U0126: 'ｴｺﾗﾝ-舵角ｾﾝｻ通信異常',
    ﾎﾞﾃﾞｰ_U0123: 'YAW/Gｾﾝｻ通信途絶',
    ﾎﾞﾃﾞｰ_U0121: 'ABS/VSC ECU間CAN通信異常',
    ﾎﾞﾃﾞｰ_U0114: 'E4WD通信途絶',
    ﾎﾞﾃﾞｰ_U0111: 'ｻﾌﾞﾊﾞｯﾃﾘECU通信異常',
    ﾎﾞﾃﾞｰ_U0101: 'ｴｺﾗﾝ-CVT/AT通信異常',
    ﾎﾞﾃﾞｰ_U0100: 'ｴｺﾗﾝ-EFI通信異常',
    ﾎﾞﾃﾞｰ_P323A: 'ﾊﾞｯｸｱｯﾌﾟ電源(BBC)出力系統異常',
    ﾎﾞﾃﾞｰ_P3053: 'ｻﾌﾞﾊﾞｯﾃﾘ端子外れ異常',
    ﾎﾞﾃﾞｰ_P3052: 'ｻﾌﾞﾊﾞｯﾃﾘﾊﾟｯｸ異常',
    ﾎﾞﾃﾞｰ_P3030: 'ｻﾌﾞﾊﾞｯﾃﾘ異常',
    ﾎﾞﾃﾞｰ_P2226: '大気圧ｾﾝｻ故障(断線/短絡)',
    ﾎﾞﾃﾞｰ_P1862: 'ｸﾗｯﾁｽｲｯﾁ異常',
    ﾎﾞﾃﾞｰ_P1857: 'ｸﾗｯﾁ系異常',
    ﾎﾞﾃﾞｰ_P1855: 'ｸﾗｯﾁｽﾄﾛｰｸｾﾝｻ異常',
    ﾎﾞﾃﾞｰ_P1780: 'ｼﾌﾄﾎﾟｼﾞｼｮﾝ入力異常',
    ﾎﾞﾃﾞｰ_P1602: '始動時電源電圧低下異常',
    ﾎﾞﾃﾞｰ_P1550: '電流ｾﾝｻ系統(断線/短絡/ﾚﾝｼﾞはずれ)',
    ﾎﾞﾃﾞｰ_P1549: 'ｽﾀｰﾀ出力回路電源異常',
    ﾎﾞﾃﾞｰ_P1548: '始動ｼｽﾃﾑ異常',
    ﾎﾞﾃﾞｰ_P1546: 'ｽﾀｰﾀ出力系統異常',
    ﾎﾞﾃﾞｰ_P1545: 'ｽﾀｰﾀ寿命判定',
    ﾎﾞﾃﾞｰ_P1544: 'ｴｺｱｲﾄﾞﾙOFFｽｲｯﾁ入力系統異常',
    ﾎﾞﾃﾞｰ_P1543: 'ｴﾝｼﾞﾝﾌｰﾄﾞｽｲｯﾁ入力系統異常',
    ﾎﾞﾃﾞｰ_P1541: 'ﾌﾞﾚｰｷ負圧ｾﾝｻ固着',
    ﾎﾞﾃﾞｰ_P0400: 'EGR機能故障',
    ﾎﾞﾃﾞｰ_P0368: '排気ｶﾑ角ｾﾝｻ（High）',
    ﾎﾞﾃﾞｰ_P0367: '排気ｶﾑ角ｾﾝｻ（Low）',
    ﾎﾞﾃﾞｰ_P0350: '点火一次系統',
    ﾎﾞﾃﾞｰ_P0343: '吸気ｶﾑ角ｾﾝｻ系統（High）',
    ﾎﾞﾃﾞｰ_P0342: '吸気ｶﾑ角ｾﾝｻ系統（Low）',
    ﾎﾞﾃﾞｰ_P0339: 'ｸﾗﾝｸ角ｾﾝｻNe瞬断',
    ﾎﾞﾃﾞｰ_P0338: 'ｸﾗﾝｸ角ｾﾝｻ系統(High)',
    ﾎﾞﾃﾞｰ_P0337: 'ｸﾗﾝｸ角ｾﾝｻ系統(Low)',
    ﾎﾞﾃﾞｰ_P0325: 'ﾉｯｸｾﾝｻ信号系統',
    ﾎﾞﾃﾞｰ_P0308: '失火検出',
    ﾎﾞﾃﾞｰ_P0268: 'ｲﾝｼﾞｪｸﾀ ﾊﾞｯﾃﾘｼｮｰﾄ(3気筒目)',
    ﾎﾞﾃﾞｰ_P0267: 'ｲﾝｼﾞｪｸﾀ GNDｼｮｰﾄ(3気筒目)',
    ﾎﾞﾃﾞｰ_P0265: 'ｲﾝｼﾞｪｸﾀ ﾊﾞｯﾃﾘｼｮｰﾄ(2気筒目)',
    ﾎﾞﾃﾞｰ_P0264: 'ｲﾝｼﾞｪｸﾀ GNDｼｮｰﾄ(2気筒目)',
    ﾎﾞﾃﾞｰ_P0262: 'ｲﾝｼﾞｪｸﾀ ﾊﾞｯﾃﾘｼｮｰﾄ(1気筒目)',
    ﾎﾞﾃﾞｰ_P0261: 'ｲﾝｼﾞｪｸﾀ GNDｼｮｰﾄ(1気筒目)',
    ﾎﾞﾃﾞｰ_P0221: 'ｻﾌﾞｽﾛｯﾄﾙｾﾝｻ信号系統',
    ﾎﾞﾃﾞｰ_P0220: 'ｽﾛｯﾄﾙｾﾝｻNo.2断線',
    ﾎﾞﾃﾞｰ_P0195: 'ｴﾝｼﾞﾝ油温ｾﾝｻ信号系統',
    ﾎﾞﾃﾞｰ_P0190: 'ｶﾞｽ圧ｾﾝｻ',
    ﾎﾞﾃﾞｰ_P0180: 'ｶﾞｽ温ｾﾝｻ',
    ﾎﾞﾃﾞｰ_P0140: 'ﾘﾔO2ｾﾝｻ断線',
    ﾎﾞﾃﾞｰ_B278C: '電源ECU通信異常',
    ﾎﾞﾃﾞｰ_B2786: 'ｽﾃｱﾘﾝｸﾞﾛｯｸ通信異常',
    ﾎﾞﾃﾞｰ_B2785: 'LIN通信異常',
    ﾎﾞﾃﾞｰ_B2430: '左ﾗｲﾄｺﾝﾄﾛｰﾙｺﾝﾋﾟｭｰﾀ異常',
    ﾎﾞﾃﾞｰ_B2420: 'ﾚﾍﾞﾘﾝｸﾞECU異常',
    ﾎﾞﾃﾞｰ_B2414: '舵角ｾﾝｻ信号異常',
    ﾎﾞﾃﾞｰ_B1506: 'ｱｰｽﾌﾞﾗｹｯﾄ断線',
    ﾎﾞﾃﾞｰ_B1505: 'ｵｲﾙﾌﾟﾚｯｼｬｰSW断線',
    ﾎﾞﾃﾞｰ_B1504: 'ｽﾃｱﾘﾝｸﾞSW通信途絶',
    ﾎﾞﾃﾞｰ_B1503: '排気熱回収警告',
    ﾎﾞﾃﾞｰ_B1502: 'ﾏﾙﾁﾌｧﾝｸｼｮﾝSW断線',
    ﾎﾞﾃﾞｰ_B1501: 'ｻﾌﾞﾌｭｰｴﾙｾﾝﾀﾞ断線',
    ﾎﾞﾃﾞｰ_B1500: 'ﾌｭｰｴﾙｾﾝﾀﾞｹﾞｰｼﾞ系統',
    ｿﾅｰ_B24E2: 'ｽﾃﾚｵｶﾒﾗ学習情報不一致',
    ｿﾅｰ_B24DD: '右ﾌﾛﾝﾄﾀｰﾝ光源異常',
    ｿﾅｰ_B24DC: '左ﾌﾛﾝﾄﾀｰﾝ光源異常',
    ｿﾅｰ_B24DB: '左右ﾍｯﾄﾞﾗﾝﾌﾟ情報不一致',
    ｿﾅｰ_B24DA: 'ｺﾝﾋﾞｽｲｯﾁ異常',
    ｿﾅｰ_B24D6: 'VSC信号異常',
    ｿﾅｰ_B24D5: 'ﾚﾍﾞﾘﾝｸﾞﾓｰﾀ信号異常',
    ｿﾅｰ_B24D4: 'ﾚﾍﾞﾘﾝｸﾞﾓｰﾀ電源異常',
    ｿﾅｰ_B24D3: '初期化学習異常',
    ｿﾅｰ_B24D2: '車輪速ｾﾝｻ異常',
    ｿﾅｰ_B24D1: '車速ﾃﾞｰﾀ異常',
    ｿﾅｰ_B24D0: '加速度ｾﾝｻ異常',
    ｿﾅｰ_B2452: '初期化時ﾃﾞｰﾀ異常',
    ｿﾅｰ_B2450: 'ｾﾝｻ初期化未実施',
    ｿﾅｰ_B2441: '右ﾗｲﾄｺﾝﾄﾛｰﾙｺﾝﾋﾟｭｰﾀ間通信異常',
    ｿﾅｰ_B2440: '左ﾗｲﾄｺﾝﾄﾛｰﾙｺﾝﾋﾟｭｰﾀ間通信異常',
    ｿﾅｰ_B243A: '右ﾍｯﾄﾞﾗﾝﾌﾟ光源異常',
    ｿﾅｰ_B2439: '左ﾍｯﾄﾞﾗﾝﾌﾟ光源異常',
    ｿﾅｰ_B2435: 'ｽﾃﾚｵｶﾒﾗ異常',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2881: '電子ｶｰﾄﾞｷｰﾛｰﾘﾝｸﾞｺｰﾄﾞ異常',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2880: '電子ｶｰﾄﾞｷｰｽﾏｰﾄｺｰﾄﾞ不一致',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2795: 'ﾄﾗﾝｽﾎﾟﾝﾀﾞｺｰﾄﾞ不一致',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2794: 'ﾄﾗﾝｽﾎﾟﾝﾀﾞ不良',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2793: 'ｲﾓﾋﾞｱﾝﾌﾟ異常',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2792: 'ｲﾓﾋﾞｺｲﾙ異常',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2789: 'EFI ECU通信不良(返信無し)',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2431: '右ﾗｲﾄｺﾝﾄﾛｰﾙｺﾝﾋﾟｭｰﾀ異常',
    ｽﾃﾚｵｶﾒﾗ_U1112: 'ｺﾝﾋﾞｽｲｯﾁ間通信異常',
    ｽﾃﾚｵｶﾒﾗ_U1111: 'FrｿﾅｰECU通信異常',
    ｽﾃﾚｵｶﾒﾗ_U1110: 'SNR ECU通信異常',
    ｽﾃﾚｵｶﾒﾗ_U1104: 'PCS信号異常',
    ｽﾃﾚｵｶﾒﾗ_U1103: 'ｴｺﾗﾝ通信異常',
    ｽﾃﾚｵｶﾒﾗ_U1000: 'CAN通信異常(ﾚｼﾞｽﾀ固着)',
    ｽﾃﾚｵｶﾒﾗ_U045A: 'IPA学習未完了',
    ｽﾃﾚｵｶﾒﾗ_U0423: 'ﾒｰﾀ車速異常',
    ｽﾃﾚｵｶﾒﾗ_U0418: 'ACC学習未完了',
    ｽﾃﾚｵｶﾒﾗ_U0405: 'ACC SW内部異常',
    ｽﾃﾚｵｶﾒﾗ_U0401: 'EFI信号異常',
    ｽﾃﾚｵｶﾒﾗ_U0305: 'ACC内部CPU異常',
    ｽﾃﾚｵｶﾒﾗ_U0300: 'ACCﾊﾞﾘｱﾝﾄ異常',
    ｽﾃﾚｵｶﾒﾗ_U023A: 'ｽﾃﾚｵｶﾒﾗ間通信途絶',
    ｽﾃﾚｵｶﾒﾗ_U0159: 'ﾓﾆﾀｶﾒﾗECU通信異常',
    ｽﾃﾚｵｶﾒﾗ_U0155: 'ﾒｰﾀ通信異常',
    ｽﾃﾚｵｶﾒﾗ_U0142: 'ﾎﾞﾃﾞｰECU間通信異常',
    ｽﾃﾚｵｶﾒﾗ_U0131: 'EPS通信異常',
    ｽﾃﾚｵｶﾒﾗ_U0129: 'ABS/VSC間通信異常',
    ｽﾃﾚｵｶﾒﾗ_U0122: 'VSC通信異常',
    ｽﾃﾚｵｶﾒﾗ_U0101: 'AT/CVT通信異常',
    ｽﾃﾚｵｶﾒﾗ_U0100: 'EFI通信異常',
    ｽﾃﾚｵｶﾒﾗ_U0090: 'CANﾊﾞｽｽﾘｰﾌﾟ異常',
    ｽﾃﾚｵｶﾒﾗ_U0076: 'CANｹﾞｰﾄｳｪｲBUS6通信異常(ﾊﾞｽｵﾌ)',
    ｽﾃﾚｵｶﾒﾗ_U0075: 'CANｹﾞｰﾄｳｪｲBUS5通信異常(ﾊﾞｽｵﾌ)',
    ｽﾃﾚｵｶﾒﾗ_U0074: 'CANｹﾞｰﾄｳｪｲBUS4通信異常(ﾊﾞｽｵﾌ)',
    ｽﾃﾚｵｶﾒﾗ_U0073: 'CANｹﾞｰﾄｳｪｲBUS1通信異常(ﾊﾞｽｵﾌ)',
    ｽﾃﾚｵｶﾒﾗ_U0038: 'CANｹﾞｰﾄｳｪｲBUS2通信異常(ﾊﾞｽｵﾌ)',
    ｽﾃﾚｵｶﾒﾗ_C1AF0: 'ｿﾅｰ検査未完(後方)',
    ｽﾃﾚｵｶﾒﾗ_C1AEF: 'ｿﾅｰ検査未完(前方)',
    ｽﾃﾚｵｶﾒﾗ_C1AED: '後方ｾﾝｻ通信異常',
    ｽﾃﾚｵｶﾒﾗ_C1AEC: '前方ｾﾝｻ通信異常',
    ｽﾃﾚｵｶﾒﾗ_C1AE9: 'RRｾﾝｻ異常',
    ｽﾃﾚｵｶﾒﾗ_C1AE8: 'RRCｾﾝｻ異常',
    ｽﾃﾚｵｶﾒﾗ_C1AE7: 'RLCｾﾝｻ異常',
    ｽﾃﾚｵｶﾒﾗ_C1AE6: 'RLｾﾝｻ異常',
    ｽﾃﾚｵｶﾒﾗ_C1AE4: 'FRｾﾝｻ異常',
    ｽﾃﾚｵｶﾒﾗ_C1AE3: 'FRCｾﾝｻ異常',
    ｽﾃﾚｵｶﾒﾗ_C1AE2: 'FLCｾﾝｻ異常',
    ｽﾃﾚｵｶﾒﾗ_C1AE1: 'FLｾﾝｻ異常',
    ｽﾃﾚｵｶﾒﾗ_C1A79: 'ｿﾅｰECU異常',
    ｽﾃﾚｵｶﾒﾗ_B2451: '車両型式未確定状態',
    ｽﾃﾚｵｶﾒﾗ_B1244: 'ｵｰﾄﾗｲﾄ/日射ｾﾝｻ異常',
    ｽﾃﾚｵｶﾒﾗ_B1011: 'ﾀｰﾝﾗﾝﾌﾟ情報未学習',
    ｽﾃﾚｵｶﾒﾗ_B1007: 'RF IC異常',
    ｽﾃﾚｵｶﾒﾗ_B1004: 'CANｹﾞｰﾄｳｪｲ起動異常',
    ｽﾃﾚｵｶﾒﾗ_B1002: 'LF ASIC発熱異常',
    ｽﾃﾚｵｶﾒﾗ_B1001: 'LF ASIC通信異常',
    ｽﾃﾚｵｶﾒﾗ_B1000: 'ECU内部異常',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U1000: 'EAT通信(送信)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0233: 'ﾏｽﾀ/ｽﾚｰﾌﾞ間通信異常',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0232: 'ｽﾚｰﾌﾞ/ﾏｽﾀ間通信異常',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0156: 'ﾒｰﾀ通信(受信)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0131: 'EPS通信(受信)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0123: 'ﾖｰﾚｰﾄｾﾝｻ通信異常',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0121: 'ABS通信(受信)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0115: 'ION通信(受信)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0100: 'EFI ECU通信異常',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0073: 'CAN通信異常',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0001: 'CAN通信',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2A00: 'A/FｾﾝｻB1S1応答劣化',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P287A: '変速制御異常',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2879: 'ﾘﾊﾞｰｽﾌﾞﾚｰｷOFF制御異常',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2878: 'ﾌｫﾜｰﾄﾞｸﾗｯﾁ(C1)OFF制御異常',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2877: 'ﾌｫﾜｰﾄﾞｸﾗｯﾁ(C2)OFF制御異常',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2876: 'ﾘﾊﾞｰｽﾌﾞﾚｰｷON制御異常',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2875: 'ﾌｫﾜｰﾄﾞｸﾗｯﾁ(C1)ON制御異常',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2874: 'ﾌｫﾜｰﾄﾞｸﾗｯﾁ(C2)ON制御異常',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2822: 'SLPｿﾚﾉｲﾄﾞﾌｪｲﾙ',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2770: 'SLｿﾚﾉｲﾄﾞﾌｪｲﾙ(High断線)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2769: 'SLｿﾚﾉｲﾄﾞﾌｪｲﾙ(Lowｼｮｰﾄ)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2764: 'DSUｿﾚﾉｲﾄﾞﾌｪｲﾙ(Low)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2763: 'DSUｿﾚﾉｲﾄﾞﾌｪｲﾙ(High)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2747: 'ｾｶﾝﾀﾞﾘﾌﾟｰﾘ回転ｾﾝｻﾌｪｲﾙ',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2716: 'SLTｿﾚﾉｲﾄﾞ異常',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2501: 'ｵﾙﾀﾈｰﾀ異常',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2307: 'IGｺｲﾙ ﾊﾞｯﾃﾘｼｮｰﾄ(IG3)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2306: 'IGｺｲﾙ GNDｼｮｰﾄ(IG1)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2304: 'IGｺｲﾙ ﾊﾞｯﾃﾘｼｮｰﾄ(IG2)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2303: 'IGｺｲﾙ GNDｼｮｰﾄ(IG1)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2301: 'IGｺｲﾙ ﾊﾞｯﾃﾘｼｮｰﾄ(IG1)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2300: 'IGｺｲﾙ GNDｼｮｰﾄ(IG1)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P21E1: 'ｲﾝｼﾞｪｸﾀ ﾊﾞｯﾃﾘｼｮｰﾄ(3気筒目)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P21E0: 'ｲﾝｼﾞｪｸﾀ GNDｼｮｰﾄ(3気筒目)',
    ERS_U1116: 'BODY ECU通信異常',
    ERS_U1103: 'ｴｺﾗﾝ間受信ﾌｪｲﾙ',
    ERS_U0129: 'ABS間受信ﾌｪｲﾙ',
    ERS_U0100: 'EFI間受信ﾌｪｲﾙ',
    ERS_P287B: 'クラッチ切替異常',
    ERS_P0773: 'A/Tﾛｯｸｱｯﾌﾟｿﾚﾉｲﾄﾞ断線、ｼｮｰﾄ',
    ERS_P0768: 'A/Tﾃﾞｭｰﾃｨｿﾚﾉｲﾄﾞ断線、ｼｮｰﾄ',
    ERS_P0763: 'A/Tｿﾚﾉｲﾄﾞ3断線、ｼｮｰﾄ',
    ERS_P0758: 'A/Tｿﾚﾉｲﾄﾞ2断線、ｼｮｰﾄ',
    ERS_P0753: 'A/Tｿﾚﾉｲﾄﾞ1断線、ｼｮｰﾄ',
    ERS_P0748: 'ﾘﾆｱｿﾚﾉｲﾄﾞNo.1異常',
    ERS_P0743: 'ﾛｯｸｱｯﾌﾟｿﾚﾉｲﾄﾞ異常',
    ERS_P0741: 'ﾛｯｸｱｯﾌﾟ中回転数異常',
    ERS_P0730: 'ｷﾞﾔ比異常',
    ERS_P0720: 'A/Tｱｳﾄﾌﾟｯﾄ回転ｾﾝｻ異常',
    ERS_P0717: 'ﾀｰﾋﾞﾝ回転ｾﾝｻ異常',
    ERS_P0715: 'A/Tﾀｰﾋﾞﾝ回転入力断線、ｼｮｰﾄ',
    ERS_P0713: '油温ｾﾝｻﾌｪｲﾙ(High断線)',
    ERS_P0712: '油温ｾﾝｻﾌｪｲﾙ(Lowｼｮｰﾄ)',
    ERS_P0705: 'T/M選択SW不良',
    ERS_P0657: 'ｱｸﾁｭｴｰﾀ電源系統',
    ERS_P0630: 'VIN/車台番号未登録',
    ERS_P062F: '内部EEPROM異常',
    ERS_P0629: 'ﾌｭｰｴﾙﾎﾟﾝﾌﾟ異常(ﾊﾞｯﾃﾘｼｮｰﾄ)',
    ERS_P0628: 'ﾌｭｰｴﾙﾎﾟﾝﾌﾟ異常(GNDｼｮｰﾄ)',
    ERS_P0627: 'ﾌｭｰｴﾙﾎﾟﾝﾌﾟ異常(断線)',
    ERS_P0620: 'ｵﾙﾀﾈｰﾀC端子ﾃﾞｭｰﾃｨ異常',
    ERS_P0617: 'ｽﾀｰﾀ系統 (High)',
    ERS_P060E: 'ｽﾛｯﾄﾙｾﾝｻﾌﾞﾚｰｷSW入力回路異常',
    ERS_P060D: 'ｱｸｾﾙｾﾝｻ入力回路異常',
    ERS_P060B: '周辺回路異常',
    ERS_P060A: '監視CPU異常',
    ERS_P0604: 'ﾉｰﾏﾙRAM異常',
    ERS_P0573: 'ﾌﾞﾚｰｷﾍﾟﾀﾞﾙSW 系統(High)',
    ERS_P0572: 'ﾌﾞﾚｰｷﾍﾟﾀﾞﾙSW系統(Low)',
    ERS_P0571: 'ﾌﾞﾚｰｷ信号異常',
    ERS_P0533: '冷媒圧ｾﾝｻ異常(HI側)',
    ERS_P0532: '冷媒圧ｾﾝｻ異常(LO側)',
    ERS_P0512: 'ｽﾀｰﾀ信号系統',
    ERS_P0505: 'ISCﾊﾞﾙﾌﾞ系統',
    ERS_P0459: 'ｴﾊﾞﾎﾟﾊﾟｰｼﾞVSV(ﾊﾞｯﾃﾘｼｮｰﾄ)',
    ERS_P0458: 'ｴﾊﾞﾎﾟﾊﾟｰｼﾞVSV(GNDｼｮｰﾄ)',
    ERS_P0402: 'EGR流量超過',
    ERS_P0131: 'ﾒｲﾝO2ｾﾝｻGNDｼｮｰﾄ',
    ERS_P0130: 'ﾌﾛﾝﾄO2ｾﾝｻ信号系統',
    ERS_P0110: '吸気温ｾﾝｻ信号系統',
    ERS_P0105: '吸気管圧力ｾﾝｻ信号系統',
    ERS_P0103: 'ｴｱﾌﾛｰｾﾝｻ系統(High)',
    ERS_P0102: 'ｴｱﾌﾛｰｾﾝｻ系統(Low)',
    ERS_P0100: 'ｴｱﾌﾛﾒｰﾀ信号系統',
    ERS_P0036: 'O2ｾﾝｻﾋｰﾀｵｰﾌﾟﾝ故障',
    ERS_P0030: 'O2（A/F）ｾﾝｻﾋｰﾀｵｰﾌﾟﾝ故障',
    ERS_P000A: 'DVVT機能ﾁｪｯｸ',
    ERS_C2124: 'ID4送信ﾃﾞｰﾀ未受信',
    ERS_C1264: 'ｴｾﾞｸﾀVSV(断線)',
    ERS_C1263: 'ｴｾﾞｸﾀVSV(GNDｼｮｰﾄ、ﾊﾞｯﾃﾘｼｮｰﾄ)',
    EPS_C2198: '初期化SW異常ﾃｽﾄ',
    EPS_C2185: 'ID5送信ﾃﾞｰﾀ未受信ﾃｽﾄ',
    EPS_C2184: 'ID4送信ﾃﾞｰﾀ未受信ﾃｽﾄ',
    EPS_C2183: 'ID3送信ﾃﾞｰﾀ未受信ﾃｽﾄ',
    EPS_C2182: 'ID2送信ﾃﾞｰﾀ未受信ﾃｽﾄ',
    EPS_C2181: 'ID1送信ﾃﾞｰﾀ未受信ﾃｽﾄ',
    EPS_C2177: '初期化未完了',
    EPS_C2171: 'ＩDｺｰﾄﾞ未登録',
    EPS_C2145: 'ID5送信機異常',
    EPS_C2144: 'ID4送信機異常',
    EPS_C2143: 'ID3送信機異常',
    EPS_C2142: 'ID2送信機異常',
    EPS_C2141: 'ID1送信機異常',
    EPS_C2126: 'ＩDｺｰﾄﾞ照合中',
    EPS_C2125: 'ID5送信ﾃﾞｰﾀ未受信',
    EPS_C2115: 'ID5送信機停止',
    EPS_C2114: 'ID4送信機停止',
    EPS_C2113: 'ID3送信機停止',
    EPS_C2112: 'ID2送信機停止',
    EPS_C2111: 'ID1送信機停止',
    EPS_B2896: 'ﾘﾓｰﾄｴﾝｼﾞﾝｽﾀｰﾀ通信不良',
    EPS_B2895: '車室内ｱﾝﾃﾅ(中央)不良',
    EPS_B2894: '車室内ｱﾝﾃﾅ(後席)不良',
    EPS_B2893: '車室内ｱﾝﾃﾅ(前席)不良',
    EPS_B2892: '車室外ｱﾝﾃﾅ(ﾊﾞｯｸﾄﾞｱ)不良',
    EPS_B2891: '車室外ｱﾝﾃﾅ(助手席)不良',
    EPS_B2890: '車室外ｱﾝﾃﾅ(運転席)不良',
    EPS_B2882: '電子ｶｰﾄﾞｷｰ異常',
    EFI_U1103: 'ｱｲﾄﾞﾙｽﾄｯﾌﾟECU通信異常(受信)',
    EFI_U1101: '運転支援ECU通信異常',
    EFI_U0235: 'LSR/ｽﾃﾚｵｶﾒﾗ通信異常(受信)',
    EFI_U0167: 'ｷｰﾌﾘｰ/ｲﾓﾋﾞﾗｲｻﾞｼｽﾃﾑ通信系統(ｺｰﾄﾞ不一致､通信ｴﾗｰ)',
    EFI_U0166: 'PTCﾋｰﾀ通信(受信)',
    EFI_U0164: 'A/C通信(受信)',
    EFI_U0155: 'MET通信異常',
    EFI_U0151: 'A/B通信異常(受信)',
    EFI_U0142: 'BDY通信異常',
    EFI_U0129: 'ABS/VSC通信異常',
    EFI_U0126: 'STR通信異常',
    EFI_U0101: 'AT/CVT通信異常',
    EFI_P282B: 'SLSｿﾚﾉｲﾄﾞﾌｪｲﾙ',
    EFI_P2767: 'ﾌﾟﾗｲﾏﾘｼｰﾌﾞ回転入力ﾌｪｲﾙ異常',
    EFI_P2759: 'SLUｿﾚﾉｲﾄﾞ異常',
    EFI_P2253: 'A/FｾﾝｻB1S1-電圧（High）',
    EFI_P2252: 'A/FｾﾝｻB1S1-電圧（Low）',
    EFI_P2239: 'A/FｾﾝｻB1S1断線（High',
    EFI_P2238: 'A/FｾﾝｻB1S1断線（Low）',
    EFI_P2237: 'A/Fｾﾝｻ断線異常B1S1',
    EFI_P2226: '大気圧ｾﾝｻ信号系統',
    EFI_P21D1: 'ｲﾝｼﾞｪｸﾀ系統異常(#3気筒)',
    EFI_P21D0: 'ｲﾝｼﾞｪｸﾀ系統異常(#2気筒)',
    EFI_P21CF: 'ｲﾝｼﾞｪｸﾀ系統異常(#1気筒)',
    EFI_P2138: 'ｱｸｾﾙｾﾝｻ特性異常',
    EFI_P2135: 'ｽﾛｯﾄﾙｾﾝｻ特性異常',
    EFI_P2128: 'ｱｸｾﾙｾﾝｻNo.2断線(High)',
    EFI_P2127: 'ｱｸｾﾙｾﾝｻNo.2断線(Low)',
    EFI_P2123: 'ｱｸｾﾙｾﾝｻNo.1断線(High)',
    EFI_P2122: 'ｱｸｾﾙｾﾝｻNo.1断線(Low)',
    EFI_P2119: '電ｽﾛｯﾄﾙｼｽﾃﾑ異常',
    EFI_P2111: 'ｽﾛｯﾄﾙﾊﾞﾙﾌﾞ開固着',
    EFI_P2103: 'ｽﾛｯﾄﾙﾓｰﾀﾘﾚｰON異常',
    EFI_P2102: 'ｽﾛｯﾄﾙﾓｰﾀﾘﾚｰOFF異常',
    EFI_P161A: 'ｵﾙﾀﾈｰﾀ通信異常',
    EFI_P1607: 'ｸﾙｰｽﾞ（制御）CPU異常',
    EFI_P1605: 'ｱｲﾄﾞﾙ不安定',
    EFI_P1604: '始動不良',
    EFI_P1603: 'ｴﾝｽﾄ検出',
    EFI_P1550: 'ﾊﾞｯﾃﾘ電流ｾﾝｻ系統',
    EFI_P1546: 'ｽﾀｰﾀ制御出力異常',
    EFI_P1130: 'A/Fｱｼﾞｬｽﾀ',
    EFI_P1115: 'A/C外気温ｾﾝｻ',
    EFI_P1105: '大気圧ｾﾝｻ信号系統',
    EFI_P102D: 'O2ｾﾝｻﾋｰﾀ回路異常(B1S2)',
    EFI_P101D: 'A/Fｾﾝｻﾋｰﾀ回路異常(B1S1)',
    EFI_P0AC0: '電流ｾﾝｻ異常',
    EFI_P099C: 'SRｿﾚﾉｲﾄﾞﾌｪｲﾙ(High)',
    EFI_P099B: 'SRｿﾚﾉｲﾄﾞﾌｪｲﾙ(Low)',
    EFI_P0967: 'DS2ｿﾚﾉｲﾄﾞﾌｪｲﾙ(High断線)',
    EFI_P0966: 'DS2ｿﾚﾉｲﾄﾞﾌｪｲﾙ(Lowｼｮｰﾄ)',
    EFI_P0963: 'DS1ｿﾚﾉｲﾄﾞﾌｪｲﾙ(High断線)',
    EFI_P0962: 'DS1ｿﾚﾉｲﾄﾞﾌｪｲﾙ(Lowｼｮｰﾄ)',
    EFI_P0957: 'ﾏﾆｭｱﾙﾓｰﾄﾞSWﾌｪｲﾙ',
    EFI_P0942: 'ｵｲﾙﾎﾟﾝﾌﾟﾓｰﾀﾌｪｲﾙ系統',
    EFI_P0850: 'PNﾚﾝｼﾞ信号系統',
    EFI_P084D: 'ﾌﾟﾗｲﾏﾘ油圧ｾﾝｻﾌｪｲﾙ(High)',
    EFI_P084C: 'ﾌﾟﾗｲﾏﾘ油圧ｾﾝｻﾌｪｲﾙ(Low)',
    EFI_P084B: 'ﾌﾟﾗｲﾏﾘ油圧ｾﾝｻ機能ﾌｪｲﾙ',
    EFI_P0843: 'ｾｶﾝﾀﾞﾘ油圧ｾﾝｻﾌｪｲﾙ(High)',
    EFI_P0842: 'ｾｶﾝﾀﾞﾘ油圧ｾﾝｻﾌｪｲﾙ(Low)',
    EFI_P0841: 'ｾｶﾝﾀﾞﾘ油圧ｾﾝｻ機能ﾌｪｲﾙ',
    EFI_P0830: 'ｸﾗｯﾁｽｲｯﾁ信号系統',
    EFI_P0828: 'ｱｯﾌﾟﾀﾞｳﾝSWﾌｪｲﾙ',
    EFI_P0805: 'ｸﾗｯﾁｽﾄﾛｰｸｾﾝｻ回路異常',
    EFI_P0798: 'ﾘﾆｱｿﾚﾉｲﾄﾞNo.3異常',
    EFI_P0778: 'SL2ｿﾚﾉｲﾄﾞﾌｪｲﾙ',
    EFI_P0741: 'ﾛｯｸｱｯﾌﾟ機能ﾌｪｲﾙ',
    EFI_P0725: 'ｴﾝｼﾞﾝ回転信号異常',
    EFI_P0724: 'STPﾗﾝﾌﾟSW ON固着',
    EFI_P0722: 'ｾｶﾝﾀﾞﾘｼｰﾌﾞ回転入力ﾌｪｲﾙ異常',
    EFI_P0713: '油温ｾﾝｻﾌｪｲﾙ(High)',
    EFI_P0712: '油温ｾﾝｻﾌｪｲﾙ(Low)',
    EFI_P0711: '油温ｾﾝｻ機能ﾌｪｲﾙ',
    EFI_P0710: '油温ｾﾝｻﾌｪｲﾙ',
    EFI_P0706: 'ｼﾌﾄﾎﾟｼﾞｼｮﾝ無入力ﾌｪｲﾙ',
    EFI_P0705: 'ｼﾌﾄﾎﾟｼﾞｼｮﾝ多重/無入力ﾌｪｲﾙ',
    EFI_P0642: 'ｾﾝｻ系電源異常',
    EFI_P0622: 'ｵﾙﾀﾌｨｰﾙﾄﾞﾃﾞｭｰﾃｨ異常　',
    EFI_P0607: 'ECU内部異常',
    EFI_P0606: 'ECU故障',
    EFI_P0603: 'KFS/IMB通信系統(ECU異常)',
    EFI_P0575: 'C/C ｽﾄｯﾌﾟﾗﾝﾌﾟｽｲｯﾁ 入力回路/ｷｬﾝｾﾙ回路異常',
    EFI_P0562: 'ﾊﾞｯﾃﾘ系ﾌｪｲﾙ',
    EFI_P0560: 'ﾊﾞｯｸｱｯﾌﾟ電源断線',
    EFI_P0558: 'ﾌﾞﾚｰｷﾌﾞｰｽﾀｾﾝｻ断線(High)',
    EFI_P0557: 'ﾌﾞﾚｰｷﾌﾞｰｽﾀｾﾝｻ断線(Low)',
    EFI_P0535: 'ｴﾊﾞ温ｾﾝｻ異常',
    EFI_P0530: '冷媒圧ｾﾝｻ異常',
    EFI_P0515: 'ﾊﾞｯﾃﾘ温度ｾﾝｻ異常',
    EFI_P0504: 'STPﾗﾝﾌﾟSW系統',
    EFI_P0500: '車速信号ｾﾝｻﾌｪｲﾙ',
    EFI_P0443: 'ｴﾊﾞﾎﾟﾊﾟｰｼﾞVSV信号系統',
    EFI_P0420: '触媒劣化',
    EFI_P0403: 'EGR断線',
    EFI_P0351: '点火1次（#1気筒）',
    EFI_P0340: '吸気ｶﾑ角ｾﾝｻ信号系統',
    EFI_P0336: 'ｸﾗﾝｸ角ｾﾝｻ(機能異常)',
    EFI_P0335: 'ｸﾗﾝｸ角ｾﾝｻ系統',
    EFI_P0218: 'CVTﾌﾙｰﾄﾞ高温異常',
    EFI_P0135: 'ﾒｲﾝO2(A/F)ｾﾝｻﾋｰﾀ系統',
    EFI_P0134: 'ﾒｲﾝO2ｾﾝｻﾌｨｰﾄﾞﾊﾞｯｸ異常',
    EFI_P0133: 'ﾌﾛﾝﾄO2ｾﾝｻ応答性',
    EFI_P0132: 'ﾒｲﾝO2ｾﾝｻﾊﾞｯﾃﾘｼｮｰﾄ',
    EFI_P0123: 'ｽﾛｯﾄﾙｾﾝｻ断線(High)',
    EFI_P0122: 'ｽﾛｯﾄﾙｾﾝｻ断線(Low)',
    EFI_P0121: 'ｽﾛｯﾄﾙｾﾝｻﾚﾝｼﾞ外れ',
    EFI_P0120: 'ｽﾛｯﾄﾙｾﾝｻ系統',
    EFI_P0118: '水温ｾﾝｻ断線(High)',
    EFI_P0117: '水温ｾﾝｻ断線(Low)',
    EFI_P0116: '水温ｾﾝｻﾚﾝｼﾞ外れ',
    EFI_P0115: '水温ｾﾝｻ信号系統',
    EFI_P0113: '吸気温ｾﾝｻ系統(High)',
    EFI_P0112: '吸気温ｾﾝｻ系統(Low)',
    EFI_P0108: '圧力ｾﾝｻ系統（High）',
    EFI_P0107: '圧力ｾﾝｻ系統（Low）',
    EFI_P0070: '外気温ｾﾝｻ異常',
    EFI_P0038: 'O2ｾﾝｻﾋｰﾀﾊﾞｯﾃﾘｼｮｰﾄ',
    EFI_P0037: 'O2ｾﾝｻﾋｰﾀGNDｼｮｰﾄ',
    EFI_P0032: 'O2（A/F）ｾﾝｻﾋｰﾀﾊﾞｯﾃﾘｼｮｰﾄ',
    EFI_P0031: 'O2（A/F）ｾﾝｻﾋｰﾀ性能低下B1S1',
    EFI_P0017: '排気DVVTｾﾝｻﾚﾝｼﾞ外れ',
    EFI_P0016: 'VVTｾﾝｻﾚﾝｼﾞ外れ',
    EFI_P0015: '排気VVT制御(遅角ﾌｪｲﾙ)',
    EFI_P0014: '排気VVT制御(進角ﾌｪｲﾙ)',
    EFI_P0013: '排気OCV制御系統',
    EFI_P0012: '吸気VVT制御(遅角ﾌｪｲﾙ)',
    EFI_P0011: 'VVT制御(進角､遅角ﾌｪｲﾙ)',
    EFI_P0010: '吸気OCV制御系統',
    EFI_C1AC2: 'BSM水平軸ずれ(ｽﾚｰﾌﾞ)',
    EFI_C1AC1: 'BSM水平軸ずれ(ﾏｽﾀ)',
    EFI_C1ABC: 'BSMﾋﾞｰﾑ軸未調整(ｽﾚｰﾌﾞ)',
    EFI_C1ABB: 'BSMﾋﾞｰﾑ軸未調整(ﾏｽﾀ)',
    EFI_C1AB7: 'BSMﾓｼﾞｭｰﾙ異常(ｽﾚｰﾌﾞ)',
    EFI_C1AB6: 'BSMﾓｼﾞｭｰﾙ異常(ﾏｽﾀ)',
    EFI_C1AB5: 'ﾐﾗｰｲﾝｼﾞｹｰﾀ断線(ｽﾚｰﾌﾞ)',
    EFI_C1AB4: 'ﾐﾗｰｲﾝｼﾞｹｰﾀ断線(ﾏｽﾀ)',
    EFI_C1AB3: 'ﾐﾗｰｲﾝｼﾞｹｰﾀ地絡(ｽﾚｰﾌﾞ)',
    EFI_C1AB2: 'ﾐﾗｰｲﾝｼﾞｹｰﾀ地絡(ﾏｽﾀ)',
    EFI_C1AB1: 'ﾐﾗｰｲﾝｼﾞｹｰﾀ天絡(ｽﾚｰﾌﾞ)',
    EFI_C1AB0: 'ﾐﾗｰｲﾝｼﾞｹｰﾀ天絡(ﾏｽﾀ)',
    EFI_C1A50: 'VSC異常',
    EFI_C1A47: 'ｽﾃｱﾘﾝｸﾞｾﾝｻ信号異常',
    EFI_C1A46: 'ﾖｰﾚｰﾄｾﾝｻ信号異常',
    EFI_C1A45: '車速ｾﾝｻ信号異常',
    EFI_C1534: 'EEPROM異常',
    EFI_C1533: '温度ｾﾝｻ異常',
    EFI_C1532: 'ECU異常',
    EFI_C1531: 'CPU異常',
    EFI_C1524: 'ﾓｰﾀ端子異常2',
    EFI_C1523: 'ﾓｰﾀ端子異常1',
    EFI_C1514: 'ﾄﾙｸｾﾝｻ電源異常',
    EFI_C1513: 'ﾄﾙｸｾﾝｻ偏差過大',
    EFI_C1512: 'ﾄﾙｸｾﾝｻ2異常検出',
    EFI_C1511: 'ﾄﾙｸｾﾝｻ1異常検出',
    EFI_C1417: 'ﾊﾞｯｸｱｯﾌﾟ電源電圧上昇異常',
    EFI_C13B4: 'EPB SW断線/ｼｮｰﾄ',
    EFI_C13B0: 'ECU異常',
    EFI_C13AA: '高温検出',
    EFI_C13A7: 'ｱｸﾁｭｴｰﾀ異常',
    EFI_C13A6: 'ﾓｰﾀ断線/ｼｮｰﾄ異常',
    EFI_C13A5: 'ﾓｰﾀ電流ﾓﾆﾀ異常',
    EFI_C1396: 'ｼﾌﾄ信号異常(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1394: '舵角ｾﾝｻ出力異常(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1393: 'ﾖｰﾚｰﾄｾﾝｻ出力異常(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1381: 'ﾖｰﾚｰﾄ/横Gｾﾝｻ電源電圧異常',
    EFI_C1380: 'ｽﾄｯﾌﾟﾗﾝﾌﾟ駆動ﾘﾚｰ系異常',
    EFI_C1379: 'DAC SWﾁｪｯｸ未完了(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1361: 'ﾓｰﾀﾌｪｰﾙｾｰﾌﾘﾚｰｼｮｰﾄ異常',
    EFI_C1336: 'Gｾﾝｻｾﾞﾛ点未取得(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1329: 'R　SWﾁｪｯｸ未完了(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1328: 'ｽﾄｯﾌﾟﾗﾝﾌﾟSWﾁｪｯｸ未完了(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1327: '横Gｾﾝｻｾﾞﾛ点未取得(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1326: 'N　SWﾁｪｯｸ未完了(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1325: 'ABH SWﾁｪｯｸ未完了(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1323: '車輪速ｾﾝｻ総合ﾁｪｯｸ未完了(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1322: '横Gｾﾝｻ出力異常(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1321: 'ﾃﾞﾌﾛｯｸSWﾁｪｯｸ未完了(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1320: '吸入ｿﾚﾉｲﾄﾞ系統異常',
    EFI_C1300: 'ECU内部異常',
    EFI_C1281: 'ﾏｽﾀ圧ｾﾝｻ出力異常(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1279: '前後Gｾﾝｻ出力電圧異常(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1278: 'RL輪周期異常(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1277: 'RR輪周期異常(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1276: 'FL輪周期異常(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1275: 'FR輪周期異常(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1274: 'RL輪出力電圧異常(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1273: 'RR輪出力電圧異常(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1272: 'FL輪出力電圧異常(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1271: 'FR輪出力電圧異常(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1270: 'ｼｽﾃﾑ情報記憶端末未実施(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1251: 'ﾓｰﾀ不回転/ﾓｰﾀ断線異常',
    EFI_C1249: 'ｽﾄｯﾌﾟﾗﾝﾌﾟSW断線',
    EFI_C1248: 'ﾃﾞﾌﾛｯｸSW断線異常',
    EFI_C1246: 'ﾏｽﾀ液圧ｾﾝｻ断線/ｼｮｰﾄ/出力異常',
    EFI_C1245: '前後Gｾﾝｻ出力異常',
    EFI_C1244: '前後Gｾﾝｻ断線/ｼｮｰﾄ',
    EFI_C1241: '電源電圧低下/上昇',
    EFI_C1239: 'RL車輪速周期系異常/ABS連続作動異常',
    EFI_C1238: 'RR車輪速周期系異常/ABS連続作動異常',
    EFI_C1237: '不特定輪周期系異常/ABS連続作動異常',
    EFI_C1236: 'FL車輪速周期系異常/ABS連続作動異常',
    EFI_C1235: 'FR車輪速周期系異常/ABS連続作動異常',
    EFI_C1233: 'ﾖｰﾚｰﾄｾﾝｻ異常',
    EFI_C1231: '舵角ｾﾝｻ断線･ｼｮｰﾄ/舵角ｾﾝｻ異常',
    EFI_C1227: 'ﾘｻﾞｰﾊﾞｶｯﾄｿﾚﾉｲﾄﾞ断線/ｼｮｰﾄ',
    EFI_C1225: 'ﾏｽﾀｶｯﾄｿﾚﾉｲﾄﾞ断線/ｼｮｰﾄ',
    EFI_C1210: 'ﾖｰﾚｰﾄｾﾝｻｾﾞﾛ点未取得(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C1207: 'ｼﾌﾄ信号異常/ﾄﾗﾝｽﾐｯｼｮﾝ通信異常',
    EFI_C1203: 'EFI/ECT ECU通信異常/識別信号異常',
    EFI_C1202: 'ﾏｽﾀﾘｻﾞｰﾊﾞﾚﾍﾞﾙ異常',
    EFI_C0365: '横Gｾﾝｻ異常',
    EFI_C0356: 'EPBﾃｽﾄﾓｰﾄﾞ中(ﾁｪｯｸﾓｰﾄﾞ)',
    EFI_C0279: 'ｿﾚﾉｲﾄﾞﾘﾚｰｼｮｰﾄ',
    EFI_C0278: 'ｿﾚﾉｲﾄﾞﾘﾚｰ断線',
    EFI_C0273: 'ﾓｰﾀﾘﾚｰ断線/ｼｮｰﾄ',
    EFI_C0256: 'ABSｿﾚﾉｲﾄﾞ異常(RL)',
    EFI_C0246: 'ABSｿﾚﾉｲﾄﾞ異常(RR)',
    EFI_C0236: 'ABSｿﾚﾉｲﾄﾞ異常(FL)',
    EFI_C0226: 'ABSｿﾚﾉｲﾄﾞ異常(FR)',
    EFI_C0215: 'RL輪車輪速ｾﾝｻ断線/ｼｮｰﾄ/電源異常',
    EFI_C0210: 'RR輪車輪速ｾﾝｻ断線/ｼｮｰﾄ/電源異常',
    EFI_C0205: 'FL輪車輪速ｾﾝｻ断線/ｼｮｰﾄ/電源異常',
    EFI_C0200: 'FR輪車輪速ｾﾝｻ断線/ｼｮｰﾄ/電源異常',
    EFI_C0051: '舵角ｾﾝｻ信号異常',
    EFI_B1928: '後席L側ﾌﾟﾘﾃﾝｼｮﾅ電源ｼｮｰﾄ',
    EFI_B1927: '後席L側ﾌﾟﾘﾃﾝｼｮﾅｱｰｽｼｮｰﾄ',
    EFI_B1926: '後席L側ﾌﾟﾘﾃﾝｼｮﾅ断線',
    EFI_B1925: '後席L側ﾌﾟﾘﾃﾝｼｮﾅ線間ｼｮｰﾄ',
    EFI_B1923: '後席R側ﾌﾟﾘﾃﾝｼｮﾅ電源ｼｮｰﾄ',
    EFI_B1922: '後席R側ﾌﾟﾘﾃﾝｼｮﾅｱｰｽｼｮｰﾄ',
    EFI_B1921: '後席R側ﾌﾟﾘﾃﾝｼｮﾅ断線',
    EFI_B1920: '後席R側ﾌﾟﾘﾃﾝｼｮﾅ線間ｼｮｰﾄ',
    EFI_B1918: 'L側ﾗｯﾌﾟｱｳﾀﾌﾟﾘﾃﾝ電源ｼｮｰﾄ',
    EFI_B1917: 'L側ﾗｯﾌﾟｱｳﾀﾌﾟﾘﾃﾝｱｰｽｼｮｰﾄ',
    EFI_B1916: 'L側ﾗｯﾌﾟｱｳﾀﾌﾟﾘﾃﾝ断線',
    EFI_B1915: 'L側ﾗｯﾌﾟｱｳﾀﾌﾟﾘﾃﾝ線間ｼｮｰﾄ',
    EFI_B1913: 'R側ﾗｯﾌﾟｱｳﾀﾌﾟﾘﾃﾝ電源ｼｮｰﾄ',
    EFI_B1912: 'R側ﾗｯﾌﾟｱｳﾀﾌﾟﾘﾃﾝｱｰｽｼｮｰﾄ',
    EFI_B1911: 'R側ﾗｯﾌﾟｱｳﾀﾌﾟﾘﾃﾝ断線',
    EFI_B1910: 'R側ﾗｯﾌﾟｱｳﾀﾌﾟﾘﾃﾝ線間ｼｮｰﾄ',
    EFI_B1908: '前席L側ﾌﾟﾘﾃﾝｼｮﾅ電源ｼｮｰﾄ',
    EFI_B1907: '前席L側ﾌﾟﾘﾃﾝｼｮﾅｱｰｽｼｮｰﾄ',
    EFI_B1906: '前席L側ﾌﾟﾘﾃﾝｼｮﾅ断線',
    EFI_B1905: '前席L側ﾌﾟﾘﾃﾝｼｮﾅ線間ｼｮｰﾄ',
    EFI_B1903: '前席R側ﾌﾟﾘﾃﾝｼｮﾅ電源ｼｮｰﾄ',
    EFI_B1902: '前席R側ﾌﾟﾘﾃﾝｼｮﾅｱｰｽｼｮｰﾄ',
    EFI_B1901: '前席R側ﾌﾟﾘﾃﾝｼｮﾅ断線',
    EFI_B1900: '前席R側ﾌﾟﾘﾃﾝｼｮﾅ線間ｼｮｰﾄ',
    EFI_B1868: 'P席ﾆｰｴｱﾊﾞｯｸﾞ電源ｼｮｰﾄ',
    EFI_B1867: 'P席ﾆｰｴｱﾊﾞｯｸﾞｱｰｽｼｮｰﾄ',
    EFI_B1866: 'P席ﾆｰｴｱﾊﾞｯｸﾞ断線',
    EFI_B1865: 'P席ﾆｰｴｱﾊﾞｯｸﾞ線間ｼｮｰﾄ',
    EFI_B1863: 'D席ﾆｰｴｱﾊﾞｯｸﾞ電源ｼｮｰﾄ',
    EFI_B1862: 'D席ﾆｰｴｱﾊﾞｯｸﾞｱｰｽｼｮｰﾄ',
    EFI_B1861: 'D席ﾆｰｴｱﾊﾞｯｸﾞ断線',
    EFI_B1860: 'D席ﾆｰｴｱﾊﾞｯｸﾞ線間ｼｮｰﾄ',
    EFI_B1838: 'L側ｶｰﾃﾝ点火系電源ｼｮｰﾄ',
    EFI_B1837: 'L側ｶｰﾃﾝ点火系ｱｰｽｼｮｰﾄ',
    EFI_B1836: 'L側ｶｰﾃﾝ点火系断線',
    EFI_B1835: 'L側ｶｰﾃﾝ点火系線間ｼｮｰﾄ',
    EFI_B1833: 'R側ｶｰﾃﾝ点火系電源ｼｮｰﾄ',
    EFI_B1832: 'R側ｶｰﾃﾝ点火系ｱｰｽｼｮｰﾄ',
    EFI_B1831: 'R側ｶｰﾃﾝ点火系断線',
    EFI_B1830: 'R側ｶｰﾃﾝ点火系線間ｼｮｰﾄ',
    EFI_B1828: 'L側ｻｲﾄﾞ点火系電源ｼｮｰﾄ',
    EFI_B1827: 'L側ｻｲﾄﾞ点火系ｱｰｽｼｮｰﾄ',
    EFI_B1826: 'L側ｻｲﾄﾞ点火系断線',
    EFI_B1825: 'L側ｻｲﾄﾞ点火系線間ｼｮｰﾄ',
    EFI_B1823: 'R側ｻｲﾄﾞ点火系電源ｼｮｰﾄ',
    CVT_P21DF: 'ｲﾝｼﾞｪｸﾀ ﾊﾞｯﾃﾘｼｮｰﾄ(2気筒目)',
    CVT_P21DE: 'ｲﾝｼﾞｪｸﾀ GNDｼｮｰﾄ(2気筒目)',
    CVT_P21DC: 'ｲﾝｼﾞｪｸﾀ ﾊﾞｯﾃﾘｼｮｰﾄ(1気筒目)',
    CVT_P21DB: 'ｲﾝｼﾞｪｸﾀ GNDｼｮｰﾄ(1気筒目)',
    CVT_P2196: 'O2(A/F)ｾﾝｻﾘｯﾁ固着B1S1',
    CVT_P2195: 'O2(A/F)ｾﾝｻﾘｰﾝ固着B1S1',
    CVT_P2125: 'ｱｸｾﾙｾﾝｻNo.2断線',
    CVT_P2121: 'ｱｸｾﾙｾﾝｻ(ﾚﾝｼﾞ外れ)',
    CVT_P2120: 'ｱｸｾﾙｾﾝｻNo.1(断線)',
    CVT_P2118: '電ｽﾛｯﾄﾙｱｸﾁｭｴｰﾀ電源異常',
    CVT_P2112: 'ｽﾛｯﾄﾙﾎﾞﾃﾞｰﾒｶ異常(閉固着)',
    CVT_P2109: 'ｽﾛｯﾄﾙ流量異常',
    CVT_P2101: 'ｽﾛｯﾄﾙﾓｰﾀ駆動回路異常',
    CVT_P2100: 'ｽﾛｯﾄﾙﾓｰﾀ故障(断線)',
    CVT_P1780: 'A/Tｽｲｯﾁｿﾚﾉｲﾄﾞ断線、ｼｮｰﾄ',
    CVT_P1749: 'ABS車速異常',
    CVT_P1731: 'A/Tｾﾝｻ系電源断線、ｼｮｰﾄ',
    CVT_P1730: 'A/Tｿﾚﾉｲﾄﾞ系電源断線、ｼｮｰﾄ',
    CVT_P1703: 'A/Tﾛｯｸｱｯﾌﾟ回転数異常',
    CVT_P16A1: 'CVT高負荷検出',
    CVT_P1656: 'OCV制御系統',
    CVT_P161B: 'ｵﾙﾀﾈｰﾀ機能異常',
    CVT_P1602: 'ﾊﾞｯﾃﾘ低電圧',
    CVT_P1601: 'ｷｰﾌﾘｰ/ｲﾓﾋﾞﾗｲｻﾞｼｽﾃﾑ通信系統(ｺｰﾄﾞ不一致､通信ｴﾗｰ)',
    CVT_P1600: 'ｷｰﾌﾘｰｼｽﾃﾑ/ｲﾓﾋﾞﾗｲｻﾞｼｽﾃﾑ通信系統(ECU異常)',
    CVT_P1585: 'Gｾﾝｻﾌｪｲﾙ',
    CVT_P1560: 'ﾊﾞｯｸｱｯﾌﾟ電源断線',
    CVT_P1552: 'ﾊﾞｯﾃﾘ電流ｾﾝｻ系統（High）',
    CVT_P1551: 'ﾊﾞｯﾃﾘ電流ｾﾝｻ系統（Low）',
    CVT_P1511: 'ｽﾀｰﾀ信号(出力)',
    CVT_P1510: 'ｽﾀｰﾀ信号系統',
    CVT_P1403: 'ｲｵﾝｼｽﾃﾑ (#4気筒)',
    CVT_P1402: 'ｲｵﾝｼｽﾃﾑ (#3気筒)',
    CVT_P1401: 'ｲｵﾝｼｽﾃﾑ (#2気筒)',
    CVT_P1400: 'ｲｵﾝｼｽﾃﾑ (#1気筒)',
    CVT_P1399: 'ｲｵﾝ電流ｼｽﾃﾑ',
    CVT_P1351: 'VVTﾊﾞﾙﾌﾞﾁｪｰﾝﾀｲﾐﾝｸﾞﾌｪｲﾙ',
    CVT_P1349: 'VVT制御(進角､遅角ﾌｪｲﾙ)',
    CVT_P1346: 'VVT制御系統(ﾊﾞﾙﾌﾞﾀｲﾐﾝｸﾞﾌｪｲﾙ)',
    CVT_P1300: 'ｲｵﾝ電流ｼｽﾃﾑ',
    CVT_P1297: 'A/Fｱｼﾞｬｽﾀ',
    CVT_P1190: 'ﾚｷﾞｭﾚｰﾀ',
    BSMﾏｽﾀ_P0564: 'LKC/DIST SW回路異常',
    BSMﾏｽﾀ_C1A7A: 'Frｿﾅｰ誤発進抑制ｼｽﾃﾑﾌｪｲﾙ',
    BSMﾏｽﾀ_C1A79: '後方誤発進防止ｼｽﾃﾑﾌｪｲﾙ',
    BSMﾏｽﾀ_C1A61: 'LKC/LDPﾄﾙｸ出力低下',
    BSMﾏｽﾀ_C1A60: 'EPS異常',
    BSMﾏｽﾀ_C1A50: 'VSC異常',
    BSMﾏｽﾀ_C1A41: 'VSCﾌﾞﾚｰｷ信号異常',
    BSMﾏｽﾀ_C1A40: 'EFI異常',
    BSMﾏｽﾀ_C1A23: 'ｽﾃﾚｵｶﾒﾗ調整未完',
    BSMﾏｽﾀ_C1A22: 'ｽﾃﾚｵｶﾒﾗ光軸ズレ',
    BSMﾏｽﾀ_C1207: 'PCS用バックランプ信号断線',
    BSMﾏｽﾀ_B3105: 'BODY ECU異常',
    BSMﾏｽﾀ_B3100: 'AT/CVT異常',
    BSMﾏｽﾀ_B3014: 'ｽﾃﾚｵｶﾒﾗECU異常',
    BSMﾏｽﾀ_B24E4: 'ﾒｰﾀ車速異常',
    BSMﾏｽﾀ_B24E3: 'ﾖｰﾚｰﾄｾﾝｻ異常',
    BSMｽﾚｰﾌﾞ_U0123: 'ﾖｰﾚｰﾄｾﾝｻ通信異常',
    BSMｽﾚｰﾌﾞ_U0122: 'VSC通信異常',
    BSMｽﾚｰﾌﾞ_U0100: 'EFI通信異常',
    BSMｽﾚｰﾌﾞ_U0074: 'Frｿﾅｰ誤発進抑制ｼｽﾃﾑ通信ﾌｪｲﾙ',
    BSMｽﾚｰﾌﾞ_P1576: '舵角ｾﾝｻ異常',
    BSMｽﾚｰﾌﾞ_P061B: 'LKC ﾄﾙｸ異常',
    BSMｽﾚｰﾌﾞ_P0593: 'LKC SET SW固着異常',
    BSMｽﾚｰﾌﾞ_P0589: 'LKC SWﾁｪｯｸ未完了',
    BSMｽﾚｰﾌﾞ_P0581: 'ACC SW固着判定',
    BSMｽﾚｰﾌﾞ_P0579: 'ACC SW多重入力異常',
    BSMｽﾚｰﾌﾞ_P0571: 'ｽﾄｯﾌﾟﾗﾝﾌﾟﾘﾚｰ異常',
    BSMｽﾚｰﾌﾞ_P056B: 'DIST SWﾁｪｯｸ未完了',
    BSMｽﾚｰﾌﾞ_P0568: 'SET SWﾁｪｯｸ未完了',
    BSMｽﾚｰﾌﾞ_P0567: 'RES SWﾁｪｯｸ未完了',
    BSMｽﾚｰﾌﾞ_P0566: 'CANCEL SWﾁｪｯｸ未完了',
    BSMｽﾚｰﾌﾞ_P0565: 'MAIN SWﾁｪｯｸ未完了',
    'ABS/VSC_U1103': 'ecoIDLE ECU間CAN通信異常',
    'ABS/VSC_U0164': 'ｵｰﾄA/C間CAN通信異常',
    'ABS/VSC_U0155': 'ﾒｰﾀ通信異常',
    'ABS/VSC_U0151': 'A/B ECU間CAN通信異常',
    'ABS/VSC_U0126': '舵角ｾﾝｻ通信異常',
    'ABS/VSC_U0101': 'AT/CVT通信異常',
    'ABS/VSC_U0100': 'EFI ECU間CAN通信異常',
    'ABS/VSC_U0073': 'CAN通信異常(ﾊﾞｽｵﾌ)',
    'ABS/VSC_P0401': 'EGR機能故障',
    'ABS/VSC_P0365': '排気ｶﾑ角ｾﾝｻ系統',
    'ABS/VSC_P0354': '点火1次（#4気筒）',
    'ABS/VSC_P0353': '点火1次（#3気筒）',
    'ABS/VSC_P0352': '点火1次（#2気筒）',
    'ABS/VSC_P0328': 'ﾉｯｸｾﾝｻ系統1（High）',
    'ABS/VSC_P0327': 'ﾉｯｸｾﾝｻ系統1（Low）',
    'ABS/VSC_P0304': '失火検出(#4気筒)',
    'ABS/VSC_P0303': '失火検出(#3気筒)',
    'ABS/VSC_P0302': '失火検出(#2気筒)',
    'ABS/VSC_P0301': '失火検出(#1気筒)',
    'ABS/VSC_P0300': '失火検出',
    'ABS/VSC_P0223': 'ｽﾛｯﾄﾙｾﾝｻNo.2断線(High)',
    'ABS/VSC_P0222': 'ｽﾛｯﾄﾙｾﾝｻNo.2断線(Low)',
    'ABS/VSC_P0204': 'ｲﾝｼﾞｪｸﾀ系統異常(#4気筒)',
    'ABS/VSC_P0203': 'ｲﾝｼﾞｪｸﾀ系統異常(#3気筒)',
    'ABS/VSC_P0202': 'ｲﾝｼﾞｪｸﾀ系統異常(#2気筒)',
    'ABS/VSC_P0201': 'ｲﾝｼﾞｪｸﾀ系統異常(#1気筒)',
    'ABS/VSC_P0200': 'ｲﾝｼﾞｪｸﾀ信号系統',
    'ABS/VSC_P0172': '燃料系(ﾘｯﾁ異常)',
    'ABS/VSC_P0171': '燃料系(ﾘｰﾝ異常)',
    'ABS/VSC_P0141': 'ﾘﾔO2ｾﾝｻﾋｰﾀ',
    'ABS/VSC_P0138': 'ｻﾌﾞ O2ｾﾝｻ ﾊﾞｯﾃﾘｼｮｰﾄ',
    'ABS/VSC_P0137': 'ｻﾌﾞ O2ｾﾝｻ GNDｼｮｰﾄ',
    'ABS/VSC_P0136': 'ﾘﾔO2ｾﾝｻ信号系統',
    'ABS/VSC_B2451': 'ｽﾃﾚｵｶﾒﾗ車種異常',
    'ABS/VSC_B1003': 'CANｹﾞｰﾄｳｪｲ内部故障',
    'A/B_U1103': 'CAN通信異常(IDS)',
    'A/B_U0416': 'VSC信号異常',
    'A/B_U0243': 'CAN通信異常(ﾓﾆﾀｶﾒﾗ)',
    'A/B_U023A': 'CAN通信異常(SCM)',
    'A/B_U0155': 'CAN通信異常(METER)',
    'A/B_U0126': 'CAN通信異常(舵角ｾﾝｻ)',
    'A/B_U0121': 'CAN通信異常(ABS/VSC)',
    'A/B_U0100': 'CAN通信異常(EFI)',
    'A/B_P1540': 'ﾌﾞﾚｰｷ負圧ｽｲｯﾁ/ｾﾝｻ入力系統異常',
    'A/B_P1535': 'ISG始動系異常',
    'A/B_P1520': 'ｽﾄｯﾌﾟﾗﾝﾌﾟｽｲｯﾁ故障',
    'A/B_P1511': 'ｽﾀｰﾀ出力異常(断線/短絡)',
    'A/B_P1510': 'ｽﾀｰﾀ入力異常',
    'A/B_P1115': '外気温ｾﾝｻ(断線/短絡)',
    'A/B_P1105': '大気圧ｾﾝｻ故障(断線/短絡)',
    'A/B_P0AC0': 'ﾊﾞｯﾃﾘ電流ｾﾝｻ入力系統異常',
    'A/B_P0850': 'TMNｽｲｯﾁ入力系統異常',
    'A/B_P0830': 'ｸﾗｯﾁｽｲｯﾁ 異常',
    'A/B_P0810': 'ｸﾗｯﾁ系 異常',
    'A/B_P0806': 'ｸﾗｯﾁｽﾄﾛｰｸｾﾝｻ入力系統異常',
    'A/B_P0719': 'ｽﾄｯﾌﾟﾗﾝﾌﾟｽｲｯﾁ入力系統異常',
    'A/B_P0705': 'ｼﾌﾄﾎﾟｼﾞｼｮﾝ信号系統多重入力異常',
    'A/B_P0555': 'ﾌﾞﾚｰｷﾌﾞｰｽﾄ圧ｾﾝｻ故障(断線/短絡)',
    'A/B_P0515': 'ﾊﾞｯﾃﾘ温度ｾﾝｻ入力系統異常',
    'A/B_P0512': 'ｽﾀｰﾀ入力系統異常',
    'A/B_P0500': '車速信号入力系統異常',
    'A/B_P0335': 'ｴﾝｼﾞﾝ回転信号入力系統異常',
    'A/B_P0070': '外気温ｾﾝｻ入力系統異常',
    'A/B_C2330': 'ﾋﾙｽﾀｰﾄ用ｿﾚﾉｲﾄﾞ異常',
    'A/B_C2123': 'ID3送信ﾃﾞｰﾀ未受信',
    'A/B_C2122': 'ID2送信ﾃﾞｰﾀ未受信',
    'A/B_C2121': 'ID1送信ﾃﾞｰﾀ未受信',
    'A/B_C1581': 'ｱｼｽﾄMAP未選択',
    'A/B_C1573': 'ｴﾝｼﾞﾝ回転ﾃｽﾄ未完了',
    'A/B_C1571': '車速ﾃｽﾄ未完了',
    'A/B_C1557': '過熱保護制御の電流制限記録',
    'A/B_C1555': 'ﾓｰﾀﾘﾚｰ異常',
    'A/B_C1554': '電源ﾘﾚｰ異常',
    'A/B_C1553': '過電圧異常',
    'A/B_C1552': 'PIG電圧異常',
    'A/B_C1541': '車速異常',
    'A/B_C1336': 'Gｾﾝｻ(傾斜ｾﾝｻ)零点補正未実施',
    'A/B_C1246': 'ﾌﾞﾚｰｷﾏｽﾀ圧ｾﾝｻ入力系統異常',
    'A/B_C1244': 'Gｾﾝｻ(傾斜ｾﾝｻ)入力系統異常',
    'A/B_C1231': '舵角ｾﾝｻ入力系統異常',
    'A/B_B1822': 'R側ｻｲﾄﾞ点火系ｱｰｽｼｮｰﾄ',
    'A/B_B1821': 'R側ｻｲﾄﾞ点火系断線',
    'A/B_B1820': 'R側ｻｲﾄﾞ点火系線間ｼｮｰﾄ',
    'A/B_B1808': 'P席前突点火系回路電源ｼｮｰﾄ',
    'A/B_B1807': 'P席前突点火系回路ｱｰｽｼｮｰﾄ',
    'A/B_B1806': 'P席前突点火系回路断線',
    'A/B_B1805': 'P席前突点火系回路線間ｼｮｰﾄ',
    'A/B_B1803': 'D席前突点火系回路電源ｼｮｰﾄ',
    'A/B_B1802': 'D席前突点火系回路ｱｰｽｼｮｰﾄ',
    'A/B_B1801': 'D席前突点火系回路断線',
    'A/B_B1800': 'D席前突点火系線間ｼｮｰﾄ',
    'A/B_B1652': 'ｺﾈｸﾀ半嵌合',
    'A/B_B1651': 'P席ｶｯﾄｵﾌｽｲｯﾁ信号異常',
    'A/B_B163F': 'L側CﾋﾟﾗｰｾﾝｻID異常',
    'A/B_B163E': 'R側CﾋﾟﾗｰｾﾝｻID異常',
    'A/B_B1638': 'L側Cﾋﾟﾗｰｾﾝｻ初期化異常',
    'A/B_B1637': 'L側Cﾋﾟﾗｰｾﾝｻ通信途絶',
    'A/B_B1635': 'L側Cﾋﾟﾗｰｾﾝｻ故障',
    'A/B_B1633': 'R側Cﾋﾟﾗｰｾﾝｻ初期化異常',
    'A/B_B1632': 'R側Cﾋﾟﾗｰｾﾝｻ通信途絶',
    'A/B_B1630': 'R側Cﾋﾟﾗｰｾﾝｻ故障',
    'A/B_B162F': 'L側BﾋﾟﾗｰｾﾝｻID異常',
    'A/B_B162E': 'R側BﾋﾟﾗｰｾﾝｻID異常',
    'A/B_B1628': 'L側Bﾋﾟﾗｰｾﾝｻ初期化異常',
    'A/B_B1627': 'L側Bﾋﾟﾗｰｾﾝｻ通信途絶',
    'A/B_B1625': 'L側Bﾋﾟﾗｰｾﾝｻ故障',
    'A/B_B1623': 'R側Bﾋﾟﾗｰｾﾝｻ初期化異常',
    'A/B_B1622': 'R側Bﾋﾟﾗｰｾﾝｻ通信途絶',
    'A/B_B1620': 'R側Bﾋﾟﾗｰｾﾝｻ故障',
    'A/B_B161F': 'L側ﾌﾛﾝﾄ電子ｾﾝｻID異常',
    'A/B_B161E': 'R側ﾌﾛﾝﾄ電子ｾﾝｻID異常',
    'A/B_B1618': 'L側ﾌﾛﾝﾄ電子ｾﾝｻ初期化異常',
    'A/B_B1617': 'L側ﾌﾛﾝﾄ電子ｾﾝｻ通信途絶',
    'A/B_B1615': 'L側ﾌﾛﾝﾄ電子ｾﾝｻ異常',
    'A/B_B1613': 'R側ﾌﾛﾝﾄ電子ｾﾝｻ初期化異常',
    'A/B_B1612': 'R側ﾌﾛﾝﾄ電子ｾﾝｻ通信途絶',
    'A/B_B1610': 'R側ﾌﾛﾝﾄ電子ｾﾝｻ異常',
    'A/B_B1010': 'A/B ECU車両照合異常',
    'A/B_B1000': 'ECU本体、ｴｱﾊﾞｯｸﾞｼｽﾃﾑ異常',
  },
  en: {
    ﾒｰﾀ_U1119: 'Communication with Cruise control ECU',
    ﾒｰﾀ_U1112: 'Combi SW CAN communications fault',
    ﾒｰﾀ_U1101: 'Communication with Cruise control ECU',
    ﾒｰﾀ_U1100: 'Communication with PSB',
    ﾒｰﾀ_U1000: 'CAN communications fault (Resister)',
    ﾒｰﾀ_U0243: 'Rear sonar communication disruption',
    ﾒｰﾀ_U0241: 'Leveling ECU communications fault',
    ﾒｰﾀ_U023A: 'ASA ECU CAN communications fault',
    ﾒｰﾀ_U0235: 'Communication with LSR',
    ﾒｰﾀ_U0232: 'BSM communication disruption',
    ﾒｰﾀ_U0202: 'Communication with PSL',
    ﾒｰﾀ_U0201: 'Communication with PSR',
    ﾒｰﾀ_U0182: 'Communication with AFS',
    ﾒｰﾀ_U0181: 'Communication with H/L leveling',
    ﾒｰﾀ_U0167: 'Communication with Key free/Immobilizer',
    ﾒｰﾀ_U0159: 'Front sonar communication disruption',
    ﾒｰﾀ_B2788: 'EFI ECU communication error (Discord)',
    ﾒｰﾀ_B2786: 'Steering lock diag communication response error',
    ﾒｰﾀ_B2782: 'Power supply circuit malfunction',
    ﾒｰﾀ_B2781: 'Steering lock ECU driver error',
    ﾒｰﾀ_B2536: 'Luggage lid courtesy SW malfunction',
    ﾒｰﾀ_B2431: 'RH LED head lamp malfunction',
    ﾒｰﾀ_B2430: 'LH LED head lamp malfunction',
    ﾒｰﾀ_B2296: 'IG2 fault',
    ﾒｰﾀ_B2295: 'IG1 fault',
    ﾒｰﾀ_B2294: 'ACC fault',
    ﾒｰﾀ_B2292: 'L code registration error',
    ﾒｰﾀ_B2291: 'Clutch SW signal error',
    ﾒｰﾀ_B2287: 'Steering lock communication error',
    ﾒｰﾀ_B2286: 'Engine RPM signal error',
    ﾒｰﾀ_B2283: 'Vehicle speed sensor fault',
    ﾒｰﾀ_B2281: 'Parking detection SW malfunction',
    ﾒｰﾀ_B2278: 'Push SW malfunction',
    ﾒｰﾀ_B2272: 'IG relay malfunction',
    ﾒｰﾀ_B2271: 'IG initial check malfunction',
    ﾒｰﾀ_B124B: 'Auto high beam fault',
    ﾎﾞﾃﾞｰ_U1207: 'Abnormal communication with AT/CVT',
    ﾎﾞﾃﾞｰ_U1002: 'Communication with CAN',
    ﾎﾞﾃﾞｰ_U0243: 'Communication with CMR',
    ﾎﾞﾃﾞｰ_U0235: 'Communication with stereo camera',
    ﾎﾞﾃﾞｰ_U0202: 'Communication with PSL',
    ﾎﾞﾃﾞｰ_U0201: 'Communication with PSR',
    ﾎﾞﾃﾞｰ_U0166: 'Communication with PTC heater',
    ﾎﾞﾃﾞｰ_U0164: 'Communication with A/C',
    ﾎﾞﾃﾞｰ_U0156: 'Communication with meter',
    ﾎﾞﾃﾞｰ_U0155: 'Meter CAN communications fault',
    ﾎﾞﾃﾞｰ_U0146: 'Communication with Central gateway',
    ﾎﾞﾃﾞｰ_U0142: 'Communication with BODY ECU',
    ﾎﾞﾃﾞｰ_U0131: 'Communication with EPS',
    ﾎﾞﾃﾞｰ_U0129: 'Communication with ABS/VSC',
    ﾎﾞﾃﾞｰ_U0126: 'Communication with Steering sensor',
    ﾎﾞﾃﾞｰ_U0123: 'Communication with YAW/G sensor',
    ﾎﾞﾃﾞｰ_U0121: 'ABS/VSC ECU CAN communications fault',
    ﾎﾞﾃﾞｰ_U0114: 'E4WD communication disruption',
    ﾎﾞﾃﾞｰ_U0111: 'Communication with Sub Battery',
    ﾎﾞﾃﾞｰ_U0101: 'Communication with AT/CVT',
    ﾎﾞﾃﾞｰ_U0100: 'Communication with EFI',
    ﾎﾞﾃﾞｰ_P323A: 'Back-up voltage (BBC) output malfunction',
    ﾎﾞﾃﾞｰ_P3053: '-',
    ﾎﾞﾃﾞｰ_P3052: '-',
    ﾎﾞﾃﾞｰ_P3030: 'Sub battery malfunction',
    ﾎﾞﾃﾞｰ_P2226: 'Barometric pressure sensor circuit malfunction',
    ﾎﾞﾃﾞｰ_P1862: 'Clutch switch circuit malfunction',
    ﾎﾞﾃﾞｰ_P1857: 'Clutch system malfunction',
    ﾎﾞﾃﾞｰ_P1855: 'Clutch stroke sensor circuit malfunction',
    ﾎﾞﾃﾞｰ_P1780: 'Shift position input malfunction',
    ﾎﾞﾃﾞｰ_P1602: 'Battery voltage drop malfunction when start',
    ﾎﾞﾃﾞｰ_P1550: 'Battery current sensor circuit malfunction',
    ﾎﾞﾃﾞｰ_P1549: 'Starter output circuit power supply malfunction',
    ﾎﾞﾃﾞｰ_P1548: '-',
    ﾎﾞﾃﾞｰ_P1546: 'Starter output malfunction',
    ﾎﾞﾃﾞｰ_P1545: 'Starter operating life Judgment',
    ﾎﾞﾃﾞｰ_P1544: 'ERS OFF switch circuit malfunction',
    ﾎﾞﾃﾞｰ_P1543: 'Engine hood switch circuit malfunction',
    ﾎﾞﾃﾞｰ_P1541: 'Brake vacuum sensor circuit range/performance problem',
    ﾎﾞﾃﾞｰ_P0400: 'EGR valve (function)',
    ﾎﾞﾃﾞｰ_P0368: 'EX. cam angle sensor signal high',
    ﾎﾞﾃﾞｰ_P0367: 'EX. cam angle sensor signal low',
    ﾎﾞﾃﾞｰ_P0350: 'Ignition system (Primary)',
    ﾎﾞﾃﾞｰ_P0343: 'IN. cam angle sensor signal high',
    ﾎﾞﾃﾞｰ_P0342: 'IN. cam angle sensor signal low',
    ﾎﾞﾃﾞｰ_P0339: 'Crank angle sensor signal flash open',
    ﾎﾞﾃﾞｰ_P0338: 'Crank angle sensor signal malfunction high',
    ﾎﾞﾃﾞｰ_P0337: 'Crank angle sensor signal malfunction low',
    ﾎﾞﾃﾞｰ_P0325: 'Vibrating-type knock sensor signal',
    ﾎﾞﾃﾞｰ_P0308: 'Misfire',
    ﾎﾞﾃﾞｰ_P0268: 'Injector BAT Short (Cylinder #3)',
    ﾎﾞﾃﾞｰ_P0267: 'Injector GND Short (Cylinder #3)',
    ﾎﾞﾃﾞｰ_P0265: 'Injector BAT Short (Cylinder #2)',
    ﾎﾞﾃﾞｰ_P0264: 'Injector GND Short (Cylinder #2)',
    ﾎﾞﾃﾞｰ_P0262: 'Injector BAT Short (Cylinder #1)',
    ﾎﾞﾃﾞｰ_P0261: 'Injector GND Short (Cylinder #1)',
    ﾎﾞﾃﾞｰ_P0221: 'Throttle sensor (sub) system',
    ﾎﾞﾃﾞｰ_P0220: 'Throttle position sensor (sub) open',
    ﾎﾞﾃﾞｰ_P0195: 'Engine oil temperature sensor signal',
    ﾎﾞﾃﾞｰ_P0190: 'Fuel pressure sensor',
    ﾎﾞﾃﾞｰ_P0180: 'Fuel temperature sensor',
    ﾎﾞﾃﾞｰ_P0140: 'Oxygen sensor (rear) Open',
    ﾎﾞﾃﾞｰ_B278C: 'Communication with Power supply ECU',
    ﾎﾞﾃﾞｰ_B2786: 'Communication with Steering lock',
    ﾎﾞﾃﾞｰ_B2785: 'Communication with LIN',
    ﾎﾞﾃﾞｰ_B2430: 'LH light control computer failure',
    ﾎﾞﾃﾞｰ_B2420: 'Leveling ECU failure',
    ﾎﾞﾃﾞｰ_B2414: 'Steering angle sensor signal failure',
    ﾎﾞﾃﾞｰ_B1506: 'Earth (open)',
    ﾎﾞﾃﾞｰ_B1505: 'Oil pressure switch (open)',
    ﾎﾞﾃﾞｰ_B1504: 'Communication with Steering switch',
    ﾎﾞﾃﾞｰ_B1503: 'Exhasut emission temperature',
    ﾎﾞﾃﾞｰ_B1502: 'Multi function switch (open)',
    ﾎﾞﾃﾞｰ_B1501: 'Fuel sendor gauge (open)',
    ﾎﾞﾃﾞｰ_B1500: 'Fuel sendor gauge system',
    ｿﾅｰ_B24E2: 'Stereo camera learning data mismatching',
    ｿﾅｰ_B24DD: 'RH front turn light source failure',
    ｿﾅｰ_B24DC: 'LH front turn light source failure',
    ｿﾅｰ_B24DB: 'Right and left headlamp data mismatching',
    ｿﾅｰ_B24DA: 'Combination SW failure',
    ｿﾅｰ_B24D6: 'VSC signal failure',
    ｿﾅｰ_B24D5: 'Leveling motor signal failure',
    ｿﾅｰ_B24D4: 'Leveling motor power source failure',
    ｿﾅｰ_B24D3: 'Initialization learning failure',
    ｿﾅｰ_B24D2: 'Wheel speed sensor failure',
    ｿﾅｰ_B24D1: 'Vehicle speed data failure',
    ｿﾅｰ_B24D0: 'Acceleration sensor failure',
    ｿﾅｰ_B2452: 'Initialization data failure',
    ｿﾅｰ_B2450: 'Sensor initialization not completed',
    ｿﾅｰ_B2441: 'RH light control computer communication failure',
    ｿﾅｰ_B2440: 'LH light control computer communication failure',
    ｿﾅｰ_B243A: 'RH head lamp light source failure',
    ｿﾅｰ_B2439: 'LH head lamp light source failure',
    ｿﾅｰ_B2435: 'Stereo camera failure',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2881: 'Electrical card key (Rolling code) discord',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2880: 'Electrical card key (Smart cord) discord',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2795: 'Transponder discord',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2794: 'Transponder fault',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2793: 'Immobilizer amplifier fault',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2792: 'Immobilizer coil fault',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2789: 'EFI ECU communication error (No response)',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ_B2431: 'RH light control computer failure',
    ｽﾃﾚｵｶﾒﾗ_U1112: 'Combination SW communication failure',
    ｽﾃﾚｵｶﾒﾗ_U1111: 'CAN communication failure (Fr sonar ECU)',
    ｽﾃﾚｵｶﾒﾗ_U1110: 'CAN communication failure (SNR ECU)',
    ｽﾃﾚｵｶﾒﾗ_U1104: 'CAS signal failure',
    ｽﾃﾚｵｶﾒﾗ_U1103: 'CAN communication error (Eco IDLE)',
    ｽﾃﾚｵｶﾒﾗ_U1000: 'CAN communication failure (Register fixation)',
    ｽﾃﾚｵｶﾒﾗ_U045A: 'Parking assist system learning incomplete',
    ｽﾃﾚｵｶﾒﾗ_U0423: 'Meter speed failure',
    ｽﾃﾚｵｶﾒﾗ_U0418: 'ACC system learning incomplete',
    ｽﾃﾚｵｶﾒﾗ_U0405: 'ACC SW internal malfunction',
    ｽﾃﾚｵｶﾒﾗ_U0401: 'EFI signal failure',
    ｽﾃﾚｵｶﾒﾗ_U0305: 'ACC internal CPU malfunction',
    ｽﾃﾚｵｶﾒﾗ_U0300: 'ACC valiant failure',
    ｽﾃﾚｵｶﾒﾗ_U023A: 'Stereo camera communication failure',
    ｽﾃﾚｵｶﾒﾗ_U0159: 'CAN communication failure',
    ｽﾃﾚｵｶﾒﾗ_U0155: 'Meter ECU ommunication error',
    ｽﾃﾚｵｶﾒﾗ_U0142: 'Body ECU communication failure',
    ｽﾃﾚｵｶﾒﾗ_U0131: 'CAN communication error (EPS)',
    ｽﾃﾚｵｶﾒﾗ_U0129: 'ABS/VSC communication failure',
    ｽﾃﾚｵｶﾒﾗ_U0122: '0.0',
    ｽﾃﾚｵｶﾒﾗ_U0101: 'AT/CVT ECU communication error',
    ｽﾃﾚｵｶﾒﾗ_U0100: 'EFI ECU communication error',
    ｽﾃﾚｵｶﾒﾗ_U0090: 'Abnormal CAN bus sleep',
    ｽﾃﾚｵｶﾒﾗ_U0076: 'Abnormal communication with CAN gateway (Bus 6)',
    ｽﾃﾚｵｶﾒﾗ_U0075: 'Abnormal communication with CAN gateway (Bus 5)',
    ｽﾃﾚｵｶﾒﾗ_U0074: 'Abnormal communication with CAN gateway (Bus 4)',
    ｽﾃﾚｵｶﾒﾗ_U0073: 'Abnormal communication with CAN gateway (Bus 1)',
    ｽﾃﾚｵｶﾒﾗ_U0038: 'Abnormal communication with CAN gateway (Bus 2)',
    ｽﾃﾚｵｶﾒﾗ_C1AF0: 'Not complete area inspection(Back side)',
    ｽﾃﾚｵｶﾒﾗ_C1AEF: '0.0',
    ｽﾃﾚｵｶﾒﾗ_C1AED: '0.0',
    ｽﾃﾚｵｶﾒﾗ_C1AEC: '0.0',
    ｽﾃﾚｵｶﾒﾗ_C1AE9: 'Rear right sonar error',
    ｽﾃﾚｵｶﾒﾗ_C1AE8: '0.0',
    ｽﾃﾚｵｶﾒﾗ_C1AE7: '0.0',
    ｽﾃﾚｵｶﾒﾗ_C1AE6: 'Rear left sonar error',
    ｽﾃﾚｵｶﾒﾗ_C1AE4: 'Front right sonar error',
    ｽﾃﾚｵｶﾒﾗ_C1AE3: '0.0',
    ｽﾃﾚｵｶﾒﾗ_C1AE2: '0.0',
    ｽﾃﾚｵｶﾒﾗ_C1AE1: 'Front left sonar error',
    ｽﾃﾚｵｶﾒﾗ_C1A79: 'SNR ECU error',
    ｽﾃﾚｵｶﾒﾗ_B2451: '0.0',
    ｽﾃﾚｵｶﾒﾗ_B1244: 'Auto light sensor malfunction',
    ｽﾃﾚｵｶﾒﾗ_B1011: 'Turn lamp information not learned',
    ｽﾃﾚｵｶﾒﾗ_B1007: 'RF IC error',
    ｽﾃﾚｵｶﾒﾗ_B1004: 'CAN gateway startup failure',
    ｽﾃﾚｵｶﾒﾗ_B1002: 'LF ASIC  heat error',
    ｽﾃﾚｵｶﾒﾗ_B1001: 'LF ASIC communication error',
    ｽﾃﾚｵｶﾒﾗ_B1000: 'ECU internal error',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U1000: 'Communication with A/T (Send)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0233: 'Lost Communication with  BSM (Master)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0232: 'Lost Communication with  BSM (Slave)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0156: 'Communication with Speedo meter (Receive)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0131: 'Communication with EPS (Receive)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0123: 'Abnormal communication with Yaw rate sensor',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0121: 'Communication with ABS (Receive)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0115: 'Communication with ION (Receive)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0100: 'Abnormal communication with EFI',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0073: 'Abnormal communication with CAN',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_U0001: 'Communication with CAN',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2A00: 'A/F sensor (front) circuit slow response',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P287A: 'Abnormal Shift control',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2879: 'Abnormal Reverse brake OFF control',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2878: 'Abnormal Forward clutch(C1) OFF control',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2877: 'Abnormal Forward clutch(C2) OFF control',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2876: 'Abnormal Reverse brake ON control',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2875: 'Abnormal Forward clutch(C1) ON control',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2874: 'Abnormal Forward clutch(C2) ON control',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2822: 'SLP solenoid system malfunction',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2770: 'SL solenoid (high short)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2769: 'SL solenoid (low short)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2764: 'DSU solenoid system malfunction (Low)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2763: 'DSU solenoid system malfunction (High)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2747: 'Secondary pulley revolution sensor malfunction',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2716: 'SLT solenoid system malfunction',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2501: 'Alternator mulfunction',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2307: 'Ignition Coil BAT Short (IG3)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2306: 'Ignition Coil GND Short (IG3)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2304: 'Ignition Coil BAT Short (IG2)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2303: 'Ignition Coil GND Short (IG2)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2301: 'Ignition Coil BAT Short (IG1)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P2300: 'Ignition Coil GND Short (IG1)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P21E1: 'Injector BAT Short (Cylinder #3-3)',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ_P21E0: 'Injector GND Short (Cylinder #3-2)',
    ERS_U1116: 'CAN communication error (BODY ECU)',
    ERS_U1103: 'Communication with ERS (Receive)',
    ERS_U0129: 'Communication with ABS (Receive)',
    ERS_U0100: 'Communication with EFI (Receive)',
    ERS_P287B: 'Abnormal Clutch switching',
    ERS_P0773: 'A/T LUC solenoid circuit(short,open)',
    ERS_P0768: 'A/T duty solenoid circuit(short,open)',
    ERS_P0763: 'A/T solenoid No.3 circuit(short,open)',
    ERS_P0758: 'A/T solenoid No.2 circuit(short,open)',
    ERS_P0753: 'A/T solenoid No.1 circuit(short,open)',
    ERS_P0748: 'Liner solenoid No.1 system malfunction',
    ERS_P0743: 'Lock-up solenoid system malfunction',
    ERS_P0741: 'Lock-up revolution speed not coincident',
    ERS_P0730: 'Gear ratio malfunction',
    ERS_P0720: 'A/T output speed sensor circuit',
    ERS_P0717: 'Turbine speed sensor malfunction',
    ERS_P0715: 'A/T input/turbine speed sensor circuit',
    ERS_P0713: 'Oil temperature sensor (high,open)',
    ERS_P0712: 'Oil temperature sensor (low,short)',
    ERS_P0705: 'Shift position SW open/short',
    ERS_P0657: 'Actuator supply voltage circuit open',
    ERS_P0630: 'Serial number unregistered',
    ERS_P062F: 'Internal control module EEPROM error',
    ERS_P0629: 'Fuel Pomp BAT Short',
    ERS_P0628: 'Fuel Pomp GND Short',
    ERS_P0627: 'Fuel Pomp Open',
    ERS_P0620: 'Alternator C-duty signal',
    ERS_P0617: 'Starter relay circuit high',
    ERS_P060E: 'Internal control module throttle position performance',
    ERS_P060D: 'Internal control module accelerator pedal position performance',
    ERS_P060B: 'Internal control module A/D processing performance',
    ERS_P060A: 'Internal control module monitoring processor performance',
    ERS_P0604: 'Random access memory error',
    ERS_P0573: 'Brake pedal SWr open/high',
    ERS_P0572: 'Brake pedal SWr open/low',
    ERS_P0571: 'Brake Switch Signal',
    ERS_P0533: 'Refrigerant Pressure Sensor (High)',
    ERS_P0532: 'Refrigerant Pressure Sensor (Low)',
    ERS_P0512: 'Starter signal system',
    ERS_P0505: 'ISC valve system',
    ERS_P0459: 'Evaporator purge VSV (BAT Short)',
    ERS_P0458: 'Evaporator purge VSV (GND Short)',
    ERS_P0402: 'EGR valve (Excess flow rate)',
    ERS_P0131: 'Oxygen sensor (front) system GND Short',
    ERS_P0130: 'Front oxygen sensor (range,open)',
    ERS_P0110: 'Intake air temperature sensor',
    ERS_P0105: 'Pressure sensor signal',
    ERS_P0103: 'Air flow sensor system open/high',
    ERS_P0102: 'Air flow sensor system open/low',
    ERS_P0100: 'Air flow sensor signal',
    ERS_P0036: 'Oxygen sensor heater (rear) Open',
    ERS_P0030: 'Oxygen (A/F) sensor heater (front) Open',
    ERS_P000A: 'DVVT system responsive malfunction',
    ERS_C2124: 'ID4 data not received',
    ERS_C1264: 'Ejector VSV (Open)',
    ERS_C1263: 'Ejector VSV (GND Short/BAT Short)',
    EPS_C2198: 'Initialization SW error test',
    EPS_C2185: 'ID5 data not received test',
    EPS_C2184: 'ID4 data not received test',
    EPS_C2183: 'ID3 data not received test',
    EPS_C2182: 'ID2 data not received test',
    EPS_C2181: 'ID1 data not received test',
    EPS_C2177: 'Initialization incomplete',
    EPS_C2171: 'ID code unregistered',
    EPS_C2145: 'ID5 transmitter malfunction',
    EPS_C2144: 'ID4 transmitter malfunction',
    EPS_C2143: 'ID3 transmitter malfunction',
    EPS_C2142: 'ID2 transmitter malfunction',
    EPS_C2141: 'ID1 transmitter malfunction',
    EPS_C2126: 'ID code checking',
    EPS_C2125: 'ID5 data not received',
    EPS_C2115: 'ID5 transmitter stop',
    EPS_C2114: 'ID4 transmitter stop',
    EPS_C2113: 'ID3 transmitter stop',
    EPS_C2112: 'ID2 transmitter stop',
    EPS_C2111: 'ID1 transmitter stop',
    EPS_B2896: 'Remote engine starter communication error',
    EPS_B2895: 'Indoor antenna (Center) fault',
    EPS_B2894: 'Indoor antenna (Rear) fault',
    EPS_B2893: 'Indoor antenna (Front) fault',
    EPS_B2892: 'Outside antenna (Back) fault',
    EPS_B2891: 'Outside antenna (Passenger) fault',
    EPS_B2890: 'Outside antenna (Driver) fault',
    EPS_B2882: 'Electrical card key fault',
    EFI_U1103: 'Communication with ERS (Receive)',
    EFI_U1101: 'Abnormal communication with IMG/LSR',
    EFI_U0235: 'Communication error with stereo camera',
    EFI_U0167: 'Communication with Immobilizer system',
    EFI_U0166: 'Communication with PTC heater (Receive)',
    EFI_U0164: 'Communication with A/C (Receive)',
    EFI_U0155: 'Lost Communication with MET',
    EFI_U0151: 'Communication with A/B (Receive)',
    EFI_U0142: 'Lost Communication with BDY',
    EFI_U0129: 'Lost Communication with ABS/VSC',
    EFI_U0126: 'Lost Communication with STR',
    EFI_U0101: 'Lost Communication with AT/CVT',
    EFI_P282B: 'SLS solenoid system malfunction',
    EFI_P2767: 'Primary sheave rotation input fail error',
    EFI_P2759: 'SLU solenoid system malfunction',
    EFI_P2253: 'A/F sensor (front)  negative current circuit high',
    EFI_P2252: 'A/F sensor (front)  negative current circuit low',
    EFI_P2239: 'A/F sensor (front)  positive current circuit high',
    EFI_P2238: 'A/F sensor (front)  positive current circuit low',
    EFI_P2237: 'A/F sensor (front) positive current circuit open',
    EFI_P2226: 'Atmospheric pressure sensor system fail',
    EFI_P21D1: 'Cylinder #3-2 injector system malfunction',
    EFI_P21D0: 'Cylinder #2-2 injector system malfunction',
    EFI_P21CF: 'Cylinder #1-2 injector system malfunction',
    EFI_P2138: 'Accelerator pedal position sensor main/sub voltage correlation',
    EFI_P2135: 'Throttle position sensor main/sub voltage correlation',
    EFI_P2128: 'Accelerator pedal position sensor (sub) open/high',
    EFI_P2127: 'Accelerator pedal position sensor (sub) open/low',
    EFI_P2123: 'Accelerator pedal position sensor (main) open/high',
    EFI_P2122: 'Accelerator pedal position sensor (main) open/low',
    EFI_P2119: 'Throttle actuator control system malfunction',
    EFI_P2111: 'Throttle valve open stuck',
    EFI_P2103: 'Throttle actuator control motor circuit short',
    EFI_P2102: 'Throttle actuator control motor circuit open',
    EFI_P161A: 'Alternator system malfunction',
    EFI_P1607: 'Abnormal CPU',
    EFI_P1605: 'Unstable engine idling',
    EFI_P1604: 'Abnormal engine start',
    EFI_P1603: 'Engine stall',
    EFI_P1550: 'Battery current sensor signal malfunction',
    EFI_P1546: 'Starter control output malfunction',
    EFI_P1130: 'A/F adjuster',
    EFI_P1115: 'A/C outside air temperature sensor',
    EFI_P1105: 'Atmospheric pressure sensor signal',
    EFI_P102D: 'Oxygen sensor heater (rear) performance',
    EFI_P101D: 'Oxygen (A/F) sensor heater (front) performance',
    EFI_P0AC0: 'Battery current sensor signal',
    EFI_P099C: 'SR solenoid system malfunction (High)',
    EFI_P099B: 'SR solenoid system malfunction (Low)',
    EFI_P0967: 'DS2 solenoid faulty (high,open)',
    EFI_P0966: 'DS2 solenoid faulty (low,short)',
    EFI_P0963: 'DS1 solenoid faulty (high,open)',
    EFI_P0962: 'DS1 solenoid faulty (low,short)',
    EFI_P0957: 'Sport mode switch system malfunction',
    EFI_P0942: 'Oil pump motor system fail',
    EFI_P0850: 'Parking/Neutral range signal input',
    EFI_P084D: 'Fluid pressure primary sensor malfunction (High)',
    EFI_P084C: 'Fluid pressure primary sensor malfunction (Low)',
    EFI_P084B: 'Abnormal fluid pressure primary sensor characteristics',
    EFI_P0843: 'Fluid pressure secondary sensor malfunction (High)',
    EFI_P0842: 'Fluid pressure secondary sensor malfunction (Low)',
    EFI_P0841: 'Abnormal fluid pressure secondary sensor characteristics',
    EFI_P0830: 'Clutch switch signal input',
    EFI_P0828: 'Up-down switch malfunction',
    EFI_P0805: 'Clutch stroke sensor circuit malfunction',
    EFI_P0798: 'Liner solenoid No.3 system malfunction',
    EFI_P0778: 'SL2 solenoid system malfunction',
    EFI_P0741: 'Abnormal lock-up solenoid system characteristics',
    EFI_P0725: 'Engine speed input sensor malfunction',
    EFI_P0724: 'Stop lamp switch circuit malfunction',
    EFI_P0722: 'Secondary sheave rotation input malfunction',
    EFI_P0713: 'Fluid temperature sensor malfunction (High)',
    EFI_P0712: 'Fluid temperature sensor malfunction (Low)',
    EFI_P0711: 'Abnormal fluid temperature sensor characteristics',
    EFI_P0710: 'Fluid temperature sensor malfunction',
    EFI_P0706: 'No input of shift position switch',
    EFI_P0705: 'No input or multiplex input of shift position switch',
    EFI_P0642: 'Sensor system volt abnormal',
    EFI_P0622: 'Alternator field duty signal system malfunction',
    EFI_P0607: 'Internal abnormal ECU',
    EFI_P0606: 'ECU Hardware',
    EFI_P0603: 'EEPROM Read/Write malfunction',
    EFI_P0575: 'Internal control module Stop lamp SW performance',
    EFI_P0562: 'Abnormal battery system power supply',
    EFI_P0560: 'Abnormal backup system power supply',
    EFI_P0558: 'Brake booster sensor open/high',
    EFI_P0557: 'Brake booster sensor open/low',
    EFI_P0535: 'Abnormal evaporator temperature sensor',
    EFI_P0530: 'A/C refrigerant pressure sensor system malfunction',
    EFI_P0515: 'Battery temperature sensor system malfunction',
    EFI_P0504: 'Stop lamp switch system malfunction',
    EFI_P0500: 'Abnormal vehicle speed signal malfunction',
    EFI_P0443: 'Evaporator purge VSV system malfunction',
    EFI_P0420: 'Catalyst system efficiency below threshold',
    EFI_P0403: 'EGR valve (short)',
    EFI_P0351: 'Ignition system No.1 cylinder (Primary)',
    EFI_P0340: 'Intake cam angle sensor signal',
    EFI_P0336: 'Crank angle sensor system malfunction',
    EFI_P0335: 'Crank angle sensor signal malfunction',
    EFI_P0218: 'Fluid over temperature condition',
    EFI_P0135: 'Oxygen sensor heater (front) system malfunction',
    EFI_P0134: 'Oxygen sensor (front) feedback malfunction',
    EFI_P0133: 'Front oxygen sensor circuit slow response',
    EFI_P0132: 'Oxygen sensor (front) system BAT Short',
    EFI_P0123: 'Throttle position sensor (main) open/high',
    EFI_P0122: 'Throttle position sensor (main) open/low',
    EFI_P0121: 'Throttle position sensor range malfunction',
    EFI_P0120: 'Throttle position sensor (main) open',
    EFI_P0118: 'Engine coolant temperature sensor system open/high',
    EFI_P0117: 'Engine coolant temperature sensor system open/low',
    EFI_P0116: 'Engine coolant temperature sensor system range/performance',
    EFI_P0115: 'Coolant temperature sensor (short,open)',
    EFI_P0113: 'Intake air temperature sensor system open/high',
    EFI_P0112: 'Intake air temperature sensor system open/low',
    EFI_P0108: 'Pressure sensor system open/high',
    EFI_P0107: 'Pressure sensor system open/low',
    EFI_P0070: 'Abnormal ambient temperature sensor',
    EFI_P0038: 'Oxygen sensor heater (rear) BAT Short',
    EFI_P0037: 'Oxygen sensor heater (rear) GND Short',
    EFI_P0032: 'A/F sensor heater (front) BAT Short',
    EFI_P0031: 'Oxygen (A/F) sensor heater (front) circuit low',
    EFI_P0017: 'EX. DVVT system phase difference/range malfunction',
    EFI_P0016: 'IN. DVVT system phase difference/range malfunction',
    EFI_P0015: 'VVT control (exhaust) :Delay angle faulty',
    EFI_P0014: 'VVT control (exhaust) :Advance angle faulty',
    EFI_P0013: 'EX. oil control valve system open/short malfuction',
    EFI_P0012: 'VVT control (intake) :Delay angle faulty',
    EFI_P0011: 'VVT control :Advance/delay angle faulty',
    EFI_P0010: 'Oil control valve (intake) system',
    EFI_C1AC2: 'BSM module horizontal axis misalignment (Slave)',
    EFI_C1AC1: 'BSM module horizontal axis misalignment (Master)',
    EFI_C1ABC: 'BSM beam axis unadjustment incomplete (Slave)',
    EFI_C1ABB: 'BSM beam axis unadjustment incomplete (Master)',
    EFI_C1AB7: 'BSM module failure (Slave)',
    EFI_C1AB6: 'BSM module failure (Master)',
    EFI_C1AB5: 'Open in mirror indicator (Slave)',
    EFI_C1AB4: 'Open in mirror indicator (Master)',
    EFI_C1AB3: 'Short to GND in mirror indicator (Slave)',
    EFI_C1AB2: 'Short to GND in mirror indicator (Master)',
    EFI_C1AB1: 'Short to +B in mirror indicator (Slave)',
    EFI_C1AB0: 'Short to +B in mirror indicator (Master)',
    EFI_C1A50: 'VSC failure',
    EFI_C1A47: 'Steering sensor signal failure',
    EFI_C1A46: 'Yaw rate sensor signal failure',
    EFI_C1A45: 'Speed sensor signal failure',
    EFI_C1534: 'EEPROM error',
    EFI_C1533: 'Temperature sensor malfunction',
    EFI_C1532: 'ECU malfunction',
    EFI_C1531: 'CPU malfunction',
    EFI_C1524: 'Motor terminal abnormality 2',
    EFI_C1523: 'Motor terminal abnormality 1',
    EFI_C1514: 'Torque sensor power supply abnormal',
    EFI_C1513: 'Torque sensor deviation excessive',
    EFI_C1512: 'Torque sensor2',
    EFI_C1511: 'Torque sensor1',
    EFI_C1417: 'Back-up power voltage high abnormal',
    EFI_C13B4: 'EPB SW open/short circuit',
    EFI_C13B0: 'ECU malfunction',
    EFI_C13AA: 'High temperature detection',
    EFI_C13A7: 'Actuator malfunction',
    EFI_C13A6: 'Motor open/short circuit',
    EFI_C13A5: 'Motor current monitor malfunction',
    EFI_C1396: 'Shift signal (check mode)',
    EFI_C1394: 'Steering angle sensor (check mode)',
    EFI_C1393: 'Yaw rate sensor (check mode)',
    EFI_C1381: 'Abnormal power supply of yaw rate sensor/G sensor',
    EFI_C1380: 'Stop lamp relay system malfunction',
    EFI_C1379: 'DAC SW non-completion(check mode)',
    EFI_C1361: 'Sensor power supply',
    EFI_C1336: 'Zero point calibration of G sensor undone(check mode)',
    EFI_C1329: 'R SW check non-completion (check mode)',
    EFI_C1328: 'Stop lamp SW check non-completion (check mode)',
    EFI_C1327:
      'Zero point calibration of Right/Left G sensor undone(check mode)',
    EFI_C1326: 'N SW check non-completion (check mode)',
    EFI_C1325: 'Auto Brake hold SW check non-completion (check mode)',
    EFI_C1323: 'Wheel speed sensor total check non-completion (check mode)',
    EFI_C1322: 'STS system grounded',
    EFI_C1321: 'Differential lock SW check non-completion (check mode)',
    EFI_C1320: 'Accumulator relief solenoid open/short',
    EFI_C1300: 'ECU inside',
    EFI_C1281: 'Master hydraulic pressure system (check mode)',
    EFI_C1279: 'G sensor (check mode)',
    EFI_C1278: 'Abnormal cycle of RL wheel (check mode)',
    EFI_C1277: 'Abnormal cycle of RR wheel (check mode)',
    EFI_C1276: 'Abnormal cycle of FL wheel (check mode)',
    EFI_C1275: 'Abnormal cycle of FR wheel (check mode)',
    EFI_C1274: 'RL wheel open/short (check mode)',
    EFI_C1273: 'RR wheel open/short (check mode)',
    EFI_C1272: 'FL wheel open/short (check mode)',
    EFI_C1271: 'FR wheel open/short (check mode)',
    EFI_C1270: 'System information memory undone (check mode)',
    EFI_C1251: 'Motor pump system',
    EFI_C1249: 'Stop lamp SW open wire',
    EFI_C1248: 'Differential lock SW open wire',
    EFI_C1246: 'Abnormal signal of master hydraulic sensor',
    EFI_C1245: 'Abnormal signal G sensor',
    EFI_C1244: 'G sensor open wire/shorted',
    EFI_C1241: 'Voltage dropped/over',
    EFI_C1239: 'Abnormal cycle of RL wheel',
    EFI_C1238: 'Abnormal cycle of RR wheel',
    EFI_C1237: 'Following wheel rotor missing',
    EFI_C1236: 'Abnormal cycle of FL wheel',
    EFI_C1235: 'Abnormal cycle of FR wheel',
    EFI_C1233: 'Yaw rate sensor open/short',
    EFI_C1231: 'Steering angle sensor malfunction',
    EFI_C1227: 'Reserver cut solenoid open/short',
    EFI_C1225: 'Master cut solenoid open/short',
    EFI_C1210: 'Zero point calibration of yaw rate sensor undone (check mode)',
    EFI_C1207: 'Shift signal (reverse)',
    EFI_C1203: 'EFI communication circuit malfunction',
    EFI_C1202: 'Master reservoir level',
    EFI_C0365: 'Right/Left G sensor malfunction',
    EFI_C0356: 'EPB test mode (check mode)',
    EFI_C0279: 'Solenoid relay system shorted',
    EFI_C0278: 'Solenoid relay system open',
    EFI_C0273: 'Motor relay system open wire',
    EFI_C0256: 'RL solenoid open/short',
    EFI_C0246: 'RR solenoid open/short',
    EFI_C0236: 'FL solenoid open/short',
    EFI_C0226: 'FR solenoid open/short',
    EFI_C0215: 'RL wheel open wire/shorted',
    EFI_C0210: 'RR wheel open wire/shorted',
    EFI_C0205: 'FL wheel open wire/shorted',
    EFI_C0200: 'FR wheel open wire/shorted',
    EFI_C0051: 'Steering sensor signal malfunction',
    EFI_B1928: 'RR seat L side pretensioner BATT short',
    EFI_B1927: 'RR seat L side pretensioner GND short',
    EFI_B1926: 'RR seat L side pretensioner open',
    EFI_B1925: 'RR seat L side pretensioner short',
    EFI_B1923: 'RR seat R side pretensioner BATT short',
    EFI_B1922: 'RR seat R side pretensioner GND short',
    EFI_B1921: 'RR seat R side pretensioner open',
    EFI_B1920: 'RR seat R side pretensioner short',
    EFI_B1918: 'FR seat L side lap outer pretensioner BATT short',
    EFI_B1917: 'FR seat L side lap outer pretensioner GND short',
    EFI_B1916: 'FR seat L side lap outer pretensioner open',
    EFI_B1915: 'FR seat L side lap outer pretensioner short',
    EFI_B1913: 'FR seat R side lap outer pretensioner BATT short',
    EFI_B1912: 'FR seat R side lap outer pretensioner GND short',
    EFI_B1911: 'FR seat R side lap outer pretensioner open',
    EFI_B1910: 'FR seat R side lap outer pretensioner short',
    EFI_B1908: 'FR seat L side pretensioner BATT short',
    EFI_B1907: 'FR seat L side pretensioner GND short',
    EFI_B1906: 'FR seat L side pretensioner open',
    EFI_B1905: 'FR seat L side pretensioner short',
    EFI_B1903: 'FR seat R side pretensioner BATT short',
    EFI_B1902: 'FR seat R side pretensioner GND short',
    EFI_B1901: 'FR seat R side pretensioner open',
    EFI_B1900: 'FR seat R side pretensioner short',
    EFI_B1868: 'Passenger seat side knee squib BATT short',
    EFI_B1867: 'Passenger seat side knee squib GND short',
    EFI_B1866: 'Passenger seat side knee squib open',
    EFI_B1865: 'Passenger seat side knee squib short',
    EFI_B1863: 'Driver seat side knee squib BATT short',
    EFI_B1862: 'Driver seat side knee squib GND short',
    EFI_B1861: 'Driver seat side knee squib open',
    EFI_B1860: 'Driver seat side knee squib short',
    EFI_B1838: 'FR L side curtain shield squib BATT short',
    EFI_B1837: 'FR L side curtain shield squib GND short',
    EFI_B1836: 'FR L side curtain shield squib open',
    EFI_B1835: 'FR L side curtain shield squib short',
    EFI_B1833: 'FR R side curtain shield squib BATT short',
    EFI_B1832: 'FR R side curtain shield squib GND short',
    EFI_B1831: 'FR R side curtain shield squib open',
    EFI_B1830: 'FR R side curtain shield squib short',
    EFI_B1828: 'FR seat L side lateral collision squib BATT short',
    EFI_B1827: 'FR seat L side lateral collision squib GND short',
    EFI_B1826: 'FR seat L side lateral collision squib open',
    EFI_B1825: 'FR seat L side lateral collision squib short',
    EFI_B1823: 'FR seat R side lateral collision squib BATT short',
    CVT_P21DF: 'Injector BAT Short (Cylinder #2-3)',
    CVT_P21DE: 'Injector GND Short (Cylinder #2-2)',
    CVT_P21DC: 'Injector BAT Short (Cylinder #1-3)',
    CVT_P21DB: 'Injector GND Short (Cylinder #1-2)',
    CVT_P2196: 'A/F sensor (front) signal stuck rich',
    CVT_P2195: 'A/F sensor (front) signal stuck lean',
    CVT_P2125: 'Accelerator pedal position sensor (sub) open',
    CVT_P2121: 'Accelerator pedal position sensor range/performance',
    CVT_P2120: 'Accelerator pedal position sensor (main) open',
    CVT_P2118: 'Throttle actuator power supply malfunction',
    CVT_P2112: 'Throttle valve close stuck',
    CVT_P2109: 'Throttle flow decrease malfunction',
    CVT_P2101: 'Throttle motor circuit',
    CVT_P2100: 'Throttle Motor Open',
    CVT_P1780: 'A/T solenoid switch circuit(short,open)',
    CVT_P1749: 'Abnormal ABS vehicle speed signal',
    CVT_P1731: 'A/T abnormal sensor system power supply',
    CVT_P1730: 'A/T abnormal battery system power supply',
    CVT_P1703: 'A/T lockup revolution not matched',
    CVT_P16A1: 'CVT High load detection',
    CVT_P1656: 'Oil control valve system',
    CVT_P161B: 'Alternator malfunction',
    CVT_P1602: 'Batety voltage (low)',
    CVT_P1601: 'Communication with Immobilizer system',
    CVT_P1600: 'E2PROM Read/Write',
    CVT_P1585: 'G sensor malfunction',
    CVT_P1560: 'Abnormal backup system power supply',
    CVT_P1552: 'Battery current sensor signal malfunction (High)',
    CVT_P1551: 'Battery current sensor signal malfunction (Low)',
    CVT_P1511: 'Stator signal (output)',
    CVT_P1510: 'Starter signal system',
    CVT_P1403: 'Ion electric current system (No.4 cylinder)',
    CVT_P1402: 'Ion electric current system (No.3 cylinder)',
    CVT_P1401: 'Ion electric current system (No.2 cylinder)',
    CVT_P1400: 'Ion electric current system (No.1 cylinder)',
    CVT_P1399: 'Ion electric current system',
    CVT_P1351: 'VVT control :Valve chain timing faulty',
    CVT_P1349: 'VVT control :Advance/delay angle faulty',
    CVT_P1346: 'VVT control :Valve timing faulty',
    CVT_P1300: 'Ion electric current system',
    CVT_P1297: 'A/F adjuster',
    CVT_P1190: 'Fuel pressure regulating system',
    BSMﾏｽﾀ_P0564: 'LKC/DIST SW circuit malfunction',
    BSMﾏｽﾀ_C1A7A: 'Fr sonar pedal misoperation control system failure',
    BSMﾏｽﾀ_C1A79: 'Rr pedal misoperation control system failure',
    BSMﾏｽﾀ_C1A61: 'LKC/LDP torque output degradation',
    BSMﾏｽﾀ_C1A60: 'EPS failure',
    BSMﾏｽﾀ_C1A50: 'VSC failure',
    BSMﾏｽﾀ_C1A41: 'VSC brake signal failure',
    BSMﾏｽﾀ_C1A40: 'EFI ECU failure',
    BSMﾏｽﾀ_C1A23: 'Stereo camera calibration incomplete',
    BSMﾏｽﾀ_C1A22: 'Stereo camera optical axis failure',
    BSMﾏｽﾀ_C1207: 'Back lamp signal disconnection',
    BSMﾏｽﾀ_B3105: 'BODY ECU failure',
    BSMﾏｽﾀ_B3100: 'AT/CVT failure',
    BSMﾏｽﾀ_B3014: 'Stereo camera ECU failure',
    BSMﾏｽﾀ_B24E4: 'Meter vehicle speed signal failure',
    BSMﾏｽﾀ_B24E3: 'Yaw rate sensor failure',
    BSMｽﾚｰﾌﾞ_U0123: 'CAN communication failure (Yaw rate sensor)',
    BSMｽﾚｰﾌﾞ_U0122: 'CAN communication error (VSC)',
    BSMｽﾚｰﾌﾞ_U0100: 'CAN communication error (EFI)',
    BSMｽﾚｰﾌﾞ_U0074:
      'Fr sonar pedal misoperation control system communication failure',
    BSMｽﾚｰﾌﾞ_P1576: 'Steering angle sensor failure',
    BSMｽﾚｰﾌﾞ_P061B: 'LKC torque failure',
    BSMｽﾚｰﾌﾞ_P0593: 'LKC SET SW fixation failure',
    BSMｽﾚｰﾌﾞ_P0589: 'LKC SW check incomplete',
    BSMｽﾚｰﾌﾞ_P0581: 'ACC SW fixation detection',
    BSMｽﾚｰﾌﾞ_P0579: 'ACC SW multiple input failure',
    BSMｽﾚｰﾌﾞ_P0571: 'Stop lamp relay failure  ',
    BSMｽﾚｰﾌﾞ_P056B: 'DIST SW check incomplete',
    BSMｽﾚｰﾌﾞ_P0568: 'SET SW check incomplete',
    BSMｽﾚｰﾌﾞ_P0567: 'RES SW check incomplete',
    BSMｽﾚｰﾌﾞ_P0566: 'CANSEL SW check incomplete',
    BSMｽﾚｰﾌﾞ_P0565: 'MAIN SW check incomplete',
    'ABS/VSC_U1103': 'Eco IDLE CAN communications fault',
    'ABS/VSC_U0164': 'Auto A/C communications fault',
    'ABS/VSC_U0155': 'Meter communication failure',
    'ABS/VSC_U0151': 'A/B ECU CAN communications fault',
    'ABS/VSC_U0126': 'CAN communication error (Steering angle sensor)',
    'ABS/VSC_U0101': 'CAN communication error (AT/CVT)',
    'ABS/VSC_U0100': 'EFI ECU CAN communications fault',
    'ABS/VSC_U0073': 'CAN communication failure (Bus off)',
    'ABS/VSC_P0401': 'EGR valve (function)',
    'ABS/VSC_P0365': 'EX. cam angle sensor signal malfunction',
    'ABS/VSC_P0354': 'Ignition system No.4 cylinder (Primary)',
    'ABS/VSC_P0353': 'Ignition system No.3 cylinder (Primary)',
    'ABS/VSC_P0352': 'Ignition system No.2 cylinder (Primary)',
    'ABS/VSC_P0328': 'Knock sensor system open/high',
    'ABS/VSC_P0327': 'Knock sensor system open/low',
    'ABS/VSC_P0304': 'Misfire (Cylinder #4)',
    'ABS/VSC_P0303': 'Misfire (Cylinder #3)',
    'ABS/VSC_P0302': 'Misfire (Cylinder #2)',
    'ABS/VSC_P0301': 'Misfire (Cylinder #1)',
    'ABS/VSC_P0300': 'Misfire (Random)',
    'ABS/VSC_P0223': 'Throttle position sensor (sub) open/high',
    'ABS/VSC_P0222': 'Throttle position sensor (sub) open/low',
    'ABS/VSC_P0204': 'Cylinder #4 injector system malfunction',
    'ABS/VSC_P0203': 'Cylinder #3 injector system malfunction',
    'ABS/VSC_P0202': 'Cylinder #2 injector system malfunction',
    'ABS/VSC_P0201': 'Cylinder #1 injector system malfunction',
    'ABS/VSC_P0200': 'Injector circuit',
    'ABS/VSC_P0172': 'Fuel system (rich faulty)',
    'ABS/VSC_P0171': 'Fuel system (lean faulty)',
    'ABS/VSC_P0141': 'Rear oxygen sensor heater signal',
    'ABS/VSC_P0138': 'Oxygen sensor (rear) BAT Short',
    'ABS/VSC_P0137': 'Oxygen sensor (rear) GND Short',
    'ABS/VSC_P0136': 'Rear oxygen sensor (range,open)',
    'ABS/VSC_B2451': 'Stereo camera vehicle type failure',
    'ABS/VSC_B1003': 'CAN gateway internal malfunction',
    'A/B_U1103': 'CAN communication error (IDS)',
    'A/B_U0416': 'CAN communication error (VSC)',
    'A/B_U0243': 'CAN communication error (IPA)',
    'A/B_U023A': 'CAN communication error (SCM)',
    'A/B_U0155': 'CAN communication error (meter)',
    'A/B_U0126': 'CAN communication error (steering sensor)',
    'A/B_U0121': 'CAN communication error (ABS/VSC)',
    'A/B_U0100': 'CAN communication error (EFI)',
    'A/B_P1540': 'Brake vacuum switch/sensor circuit malfunction',
    'A/B_P1535': '-',
    'A/B_P1520': 'Stop lamp switch circuit malfunction',
    'A/B_P1511': 'Starter output malfunction',
    'A/B_P1510': 'Starter input malfunction',
    'A/B_P1115': 'Ambient temperature sensor circuit malfunction',
    'A/B_P1105': 'Barometric pressure sensor circuit malfunction',
    'A/B_P0AC0': 'Battery current sensor input malfunction',
    'A/B_P0850': 'TMN switch input circuit malfunction',
    'A/B_P0830': 'Clutch switch malfunction',
    'A/B_P0810': 'Clutch system malfunction',
    'A/B_P0806': 'Clutch stroke sensor input circuit malfunction',
    'A/B_P0719': 'Stop lamp switch input malfunction',
    'A/B_P0705': 'Shift position multi-input malfunction',
    'A/B_P0555': 'Brake boost pressure sensor circuit malfunction',
    'A/B_P0515': 'Battery temperature sensor circuit malfunction',
    'A/B_P0512': 'Starter input malfunction',
    'A/B_P0500': 'Vehicle speed pulse malfunction',
    'A/B_P0335': 'Engine speed pulse malfunction',
    'A/B_P0070': 'Ambient temperature input malfunction',
    'A/B_C2330': 'Hill start solenoid circuit malfunction',
    'A/B_C2123': 'ID3 data not received',
    'A/B_C2122': 'ID2 data not received',
    'A/B_C2121': 'ID1 data not received',
    'A/B_C1581': 'Assist map un-writing',
    'A/B_C1573': 'Engine speed test not completed',
    'A/B_C1571': 'Vehicle speed test not completed',
    'A/B_C1557': 'Overheat preventive control current limit history',
    'A/B_C1555': 'Motor relay failure',
    'A/B_C1554': 'Power supply relay failure',
    'A/B_C1553': 'Overvoltage',
    'A/B_C1552': 'PIG voltage abnormality',
    'A/B_C1541': 'Vehicle speed abnormality',
    'A/B_C1336': 'G sensor correction malfunction',
    'A/B_C1246': 'Brake master pressure sensor circuit malfunction',
    'A/B_C1244': 'G sensor circuit malfunction',
    'A/B_C1231': 'Steering angle sensor circuit malfunction',
    'A/B_B1822': 'FR seat R side lateral collision squib GND short',
    'A/B_B1821': 'FR seat R side lateral collision squib open',
    'A/B_B1820': 'FR seat R side lateral collision squib short',
    'A/B_B1808': 'Passenger seat frontal collision squib BATT short',
    'A/B_B1807': 'Passenger seat frontal collision squib GND short',
    'A/B_B1806': 'Passenger seat frontal collision squib open',
    'A/B_B1805': 'Passenger seat frontal collision squib short',
    'A/B_B1803': 'Driver seat frontal collision squib BATT short',
    'A/B_B1802': 'Driver seat frontal collision squib GND short',
    'A/B_B1801': 'Driver seat frontal collision squib open',
    'A/B_B1800': 'Driver seat frontal collision squib short',
    'A/B_B1652': 'Connecter half-connecting',
    'A/B_B1651': 'Passenger seat air bag manual on-off switch malfunctioning',
    'A/B_B163F': 'L side C pillar sensor abnormal ID',
    'A/B_B163E': 'R side C pillar sensor abnormal ID',
    'A/B_B1638': 'L side C pillar sensor abnormal initialization',
    'A/B_B1637': 'L side C pillar sensor communication interruption',
    'A/B_B1635': 'L side C pillar sensor malfunctioning',
    'A/B_B1633': 'R side C pillar sensor abnormal initialization',
    'A/B_B1632': 'R side C pillar sensor communication interruption',
    'A/B_B1630': 'R side C pillar sensor malfunctioning',
    'A/B_B162F': 'L side B pillar sensor abnormal ID',
    'A/B_B162E': 'R side B pillar sensor abnormal ID',
    'A/B_B1628': 'L side B pillar sensor abnormal initialization',
    'A/B_B1627': 'L side B pillar sensor communication interruption',
    'A/B_B1625': 'L side B pillar sensor malfunctioning',
    'A/B_B1623': 'R side B pillar sensor abnormal initialization',
    'A/B_B1622': 'R side B pillar sensor communication interruption',
    'A/B_B1620': 'R side B pillar sensor malfunctioning',
    'A/B_B161F': 'L side Fr sensor abnormal ID',
    'A/B_B161E': 'R side Fr sensor abnormal ID',
    'A/B_B1618': 'L side Fr sensor abnormal initialization',
    'A/B_B1617': 'L side Fr sensor communication interruption',
    'A/B_B1615': 'L side Fr sensor malfunctioning',
    'A/B_B1613': 'R side Fr sensor abnormal initialization',
    'A/B_B1612': 'R side Fr sensor communication interruption',
    'A/B_B1610': 'R side Fr sensor malfunctioning',
    'A/B_B1010': 'Airbag ECU vehicle verification malfunction',
    'A/B_B1000': 'Airbag ECU or system malfunction',
  },
};
