import { None, Option } from '@auto/dango-util';
import { VehicleProfile } from '@auto/monaka-client/dist/dashboard';
import { Box } from '@mui/system';
import React from 'react';
import { VehicleDetail as VehicleDetailData } from '../backend/Api';
import { Style } from '../style/Style';
import { Theme } from '../theme/Theme';
import { Toolbar } from './Toolbar';

const draggableWidth = 8;

const styles = Style({
  container: {
    flex: 1,
    maxWidth: `100%`,
    maxHeight: `100%`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  leftBody: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  leftResize: {
    minWidth: `${draggableWidth}px`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    borderRight: `1px solid ${Theme.sideBar.borderColor}`,
    cursor: `col-resize`,
  },
  right: {
    flex: 1,
    maxWidth: `100%`,
    maxHeight: `100%`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  body: {
    flex: 1,
    maxWidth: `100%`,
    maxHeight: `100%`,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
});

export function Layout({
  sideBar,
  showGoToTop,
  showPrintDashboard,
  showPrintSRO,
  showSelectBox,
  showSearchBox,
  vehicleProfile,
  vehicleDetail,
  children,
}: Readonly<{
  sideBar?: React.ReactNode;
  showGoToTop?: boolean;
  showPrintDashboard?: boolean;
  showPrintSRO?: boolean;
  showSelectBox?: boolean;
  showSearchBox?: boolean;
  vehicleProfile?: VehicleProfile;
  vehicleDetail?: VehicleDetailData;
  children: React.ReactNode;
}>): React.ReactElement | null {
  const [sideBarWidth, setSideBarWidth] = React.useState({
    width: 240,
    currentX: None<number>(),
    lastTime: Date.now(),
  });

  return (
    <Box sx={styles.container}>
      {sideBar !== undefined ? (
        <Box
          sx={{
            ...styles.left,
            width: `${sideBarWidth.width}px`,
          }}
        >
          <Box
            sx={{
              ...styles.leftBody,
              width: `${sideBarWidth.width - draggableWidth}px`,
            }}
          >
            {sideBar}
          </Box>
          <div
            style={styles.leftResize as React.CSSProperties}
            onDragStart={e => {
              e.stopPropagation();
              setSideBarWidth({
                ...sideBarWidth,
                currentX: Option(e.clientX),
                lastTime: Date.now(),
              });
            }}
            onDrag={e => {
              e.stopPropagation();
              sideBarWidth.currentX.forEach(cx => {
                const now = Date.now();
                const x = e.clientX;
                if (x > 0 && now - sideBarWidth.lastTime > 50) {
                  const dx = x - cx;
                  setSideBarWidth({
                    ...sideBarWidth,
                    width: sideBarWidth.width + dx,
                    currentX: Option(x),
                    lastTime: now,
                  });
                }
              });
            }}
            onDragEnd={e => {
              e.stopPropagation();
              setSideBarWidth({
                ...sideBarWidth,
                currentX: None(),
                lastTime: Date.now(),
              });
            }}
          />
        </Box>
      ) : null}
      <Box sx={styles.right}>
        <Toolbar
          showGoToTop={showGoToTop}
          showPrintDashboard={showPrintDashboard}
          showPrintSRO={showPrintSRO}
          showSelectBox={showSelectBox}
          showSearchBox={showSearchBox}
          vehicleProfile={vehicleProfile}
          vehicleDetail={vehicleDetail}
        />
        <Box sx={styles.body}>{children}</Box>
      </Box>
    </Box>
  );
}
