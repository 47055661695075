import * as MUI from '@mui/material';
import React from 'react';
import { useIntl } from '../i18n/Intl';
import { defaultLocale } from '../i18n/Locale';
import { MessageKeys } from '../i18n/Message';
import { MUIStyle } from '../style/Style';

export function Chip({
  label,
  sx,
  textSx,
}: Readonly<{
  label: MessageKeys;
  sx?: MUIStyle;
  textSx?: MUIStyle;
}>): React.ReactElement {
  const intl = useIntl(defaultLocale);
  return (
    <MUI.Chip
      label={intl.formatMessageAsString({ id: label })}
      sx={{
        borderRadius: `4px`,
        height: `auto`,
        '& .MuiChip-label': {
          lineHeight: `1em`,
          padding: `8px`,
          ...textSx,
        },
        ...sx,
      }}
    />
  );
}
