import { Box } from '@mui/system';
import React from 'react';
import { AppFrame } from '../../components/AppFrame';

export function ErrorScreen({
  error,
}: Readonly<{
  error: Error;
}>): React.ReactElement {
  return (
    <AppFrame>
      <Box>{error.toString()}</Box>
    </AppFrame>
  );
}
