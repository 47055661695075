import { None, Option, getErrorMessage, identity } from '@auto/dango-util';
import {
  AbnormalityInfo,
  DTCDetail,
  GraphQLResponse,
  ServiceTelemetry,
  VehicleEvent,
  VehicleProfile,
} from '@auto/monaka-client/dist/dashboard';
import React from 'react';
import useSWR from 'swr';
import { AppContext } from '../app/AppContext';
import { SearchParams } from '../app/AppState';
import { usePrivilegedContext } from '../components/Privileged';
import { oneMonthAgo, oneWeekAgo } from '../utils/Date';
import { AuthError, signOut } from './Auth';

export type AbnormalityInfoMap = Readonly<{
  sos: AbnormalityInfo;
  airBag: AbnormalityInfo;
  theftAlert: AbnormalityInfo;
  vehicleWarning: AbnormalityInfo;
}>;

export type VehicleProfileEvent = VehicleProfile &
  Readonly<{ eventId: string; timestamp: Date }>;

export type VehicleDetail = Readonly<{
  dtcDetailList: DTCDetail[];
  serviceTelemetry: ServiceTelemetry;
  eventTelemetry: VehicleEvent[];
}>;

function isGraphQLResponse(err: unknown): err is GraphQLResponse {
  const e = err as GraphQLResponse;
  return (
    e.resultData !== undefined && typeof e.resultData.returnCode === 'number'
  );
}

function wrapError<A>(errmsg: string, f: () => Promise<A>): Promise<A> {
  return f().catch(err => {
    if (isGraphQLResponse(err) && err.resultData.errorCode === '005') {
      signOut();
      return Promise.reject(
        new AuthError(
          err.resultData.errorCode,
          `The token has been expired on ${errmsg}`,
        ),
      );
    } else {
      return Promise.reject(Error(`${errmsg}: ${getErrorMessage(err)}`));
    }
  });
}

export function useAbnormalityInfoMap(): Option<AbnormalityInfoMap> {
  const { client } = usePrivilegedContext();
  const { data } = useSWR(
    `getAbnormalityInfoList`,
    () =>
      wrapError('getAbnormalityInfoList', () =>
        client.getAbnormalityInfoList({
          timestamp: oneWeekAgo(),
        }),
      ),
    { suspense: true },
  );

  return Option(data).map(
    _ =>
      Object.fromEntries(
        _.map(d => [d.eventClassLabel, d]),
      ) as unknown as AbnormalityInfoMap,
  );
}

export function useVehicleProfileList({
  eventClassId,
}: Readonly<{
  eventClassId: number;
}>): VehicleProfileEvent[] {
  const { client } = usePrivilegedContext();
  const { data } = useSWR(
    `getEventClassIdVehicleList-${eventClassId}`,
    async () =>
      (
        await wrapError('getEventClassIdVehicleList', () =>
          client.getEventClassIdVehicleList({
            eventClassId,
            timestamp: oneWeekAgo(),
          }),
        )
      ).reduce(async (fa, v) => {
        const a = await fa;
        const p = await wrapError('getMemberVehicleProfile', () =>
          client.getMemberVehicleProfile({
            vin: v.vin,
          }),
        );

        return p.unwrap(
          _ =>
            a.concat([
              {
                ..._,
                eventId: v.eventId,
                timestamp: v.timestamp,
              },
            ]),
          () => a,
        );
      }, Promise.resolve<VehicleProfileEvent[]>([])),
    { suspense: true },
  );
  return Option(data).getOrElse(() => []);
}

export function useMemberVehicleProfile({
  searchParams,
  onSuccess,
  onFailure,
}: Readonly<{
  searchParams: Option<SearchParams>;
  onSuccess: (result: VehicleProfile) => void;
  onFailure: (error: Error) => void;
}>): void {
  const appContext = React.useContext(AppContext);
  const { client } = usePrivilegedContext();
  const a = useSWR(
    searchParams.unwrap(
      ({ type, value }) => ['useMemberVehicleProfile', type, value],
      () => null,
    ),
    (_, type, value) => {
      switch (type) {
        case 'Vin':
          return wrapError<Option<VehicleProfile>>(
            'getMemberVehicleProfile',
            () => client.getMemberVehicleProfile({ vin: value }),
          );
        case 'RegNo':
          return wrapError<Option<VehicleProfile>>(
            'getMemberVehicleProfile',
            () => client.getMemberVehicleProfile({ vehicleRegNo: value }),
          );
      }
    },
    {
      suspense: true,
    },
  );
  const data = a.data;
  const error = a.error as Error | undefined;

  React.useEffect(() => {
    if (error != null) {
      onFailure(error);
    } else if (data != null) {
      appContext.dispatch(data => ({
        ...data,
        search: None(),
      }));
      Option(data)
        .flatMap(identity)
        .unwrap(onSuccess, () => onFailure(Error(`No MemberVehicleProfile`)));
    }
  }, [data, error, appContext, onSuccess, onFailure]);
}

export function useVehicleDetail({
  vin,
}: Readonly<{ vin: string }>): Option<VehicleDetail> {
  const { client } = usePrivilegedContext();
  const { data } = useSWR(
    `useVehicleDetail-${vin}`,
    () =>
      Promise.all([
        wrapError('getDTCDetailList', () =>
          client.getDTCDetailList({ vin, timestamp: oneMonthAgo() }),
        ),
        wrapError('getServiceTel', () => client.getServiceTel({ vin })),
        wrapError('getEventTel', () =>
          client.getEventTel({ vin, timestamp: oneMonthAgo() }),
        ),
      ])
        .then(([dtcDetailList, serviceTelemetry, eventTelemetry]) => ({
          dtcDetailList,
          serviceTelemetry,
          eventTelemetry,
        }))
        .catch(err =>
          Promise.reject(
            Error(
              `Error occured in useVehicleDetail(${vin}): ${getErrorMessage(
                err,
              )}`,
            ),
          ),
        ),
    { suspense: true },
  );
  return Option(data);
}
