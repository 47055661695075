import { Duration } from '@auto/dango-util';
import { BuildOpt } from '../config/BuildOpt';

export function oneWeekAgo(): Date {
  if (BuildOpt.env === 'debug') return new Date(`2020-01-01T00:00:00Z`);
  else return new Date(Date.now() - Duration.days(7).toMilliSeconds());
}

export function oneMonthAgo(): Date {
  if (BuildOpt.env === 'debug') return new Date(`2020-01-01T00:00:00Z`);
  else return new Date(Date.now() - Duration.days(31).toMilliSeconds());
}

export function isToday(a: Date): boolean {
  const n = new Date();
  return (
    n.getFullYear() === a.getFullYear() &&
    n.getMonth() === a.getMonth() &&
    n.getDate() === a.getDate()
  );
}
