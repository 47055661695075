import { Reducer } from '@auto/dango-store';
import React from 'react';
import { AppState, initialAppState } from './AppState';

export type AppContext = Reducer.ReducerState<AppState>;

export const AppContext = React.createContext<AppContext>({
  state: initialAppState,
  dispatch: Reducer.emptyDispatch<AppState>(),
});
