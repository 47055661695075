import { Box } from '@mui/system';
import React from 'react';
import { MUIStyle, Style } from '../style/Style';
import { Spinner } from './Spinner';

const styles = Style({
  container: {
    flex: 1,
    width: `100%`,
    height: `100%`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as MUIStyle,
});

export function Loading(): React.ReactElement {
  return (
    <Box sx={styles.container}>
      <Spinner />
    </Box>
  );
}
