import { Option } from '@auto/dango-util';
import { DtcCode, DtcConverter, DtcLang } from '../generated/i18n/DtcConverter';
import { EcuCode, EcuConverter, EcuLang } from '../generated/i18n/EcuConverter';
import { Intl } from './Intl';
import { defaultLocale } from './Locale';

function getLang(): string {
  return Option(
    Object.keys(EcuConverter).find(_ => _ === defaultLocale.lang),
  ).getOrElse(() => 'en');
}

export function getEcuMessage(ecuCode: string, intl: Intl): string {
  const lang = getLang() as EcuLang;
  const key = ecuCode as EcuCode;
  return Option(EcuConverter[lang])
    .flatMap(_ => Option(_[key]))
    .getOrElse(() =>
      intl.formatMessageAsString(
        { id: 'vehicleDetailEcuCodeUndefined' },
        { ecuCode },
      ),
    );
}

export function getDtcMessage(
  ecuCode: string,
  dtcCode: string,
  intl: Intl,
): string {
  const lang = getLang() as DtcLang;
  const key = `${ecuCode}_${dtcCode}` as DtcCode;
  return Option(DtcConverter[lang])
    .flatMap(_ => Option(_[key]))
    .getOrElse(() =>
      intl.formatMessageAsString(
        { id: 'vehicleDetailDtcCodeUndefined' },
        { ecuCode, dtcCode },
      ),
    );
}
