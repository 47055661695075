import { Failure, Option, Result, Success } from '@auto/dango-util';
import { DashboardClient } from '@auto/monaka-client/dist/dashboard';
import React, { Suspense } from 'react';
import { AuthError, codeFlow, getAuthToken, getCode } from '../backend/Auth';
import { getClient } from '../backend/Client';
import { getAuthInfo, setAuthInfo } from '../storage/Storage';
import { ErrorBoundary } from './ErrorBoundary';
import { Loading } from './Loading';

type PrivilegedContext = Readonly<{
  client: DashboardClient;
}>;

const PrivilegedContext = React.createContext<Result<PrivilegedContext, Error>>(
  Failure(Error(`Unauthorized`)),
);

export function getPrivileged(): Result<PrivilegedContext, Error> {
  try {
    return getAuthInfo().unwrap<Result<PrivilegedContext, Error>>(
      authInfo =>
        Success({
          client: getClient(authInfo),
        }),
      () => {
        throw Error('You are not authenticated');
      },
    );
  } catch (err) {
    return Failure(err instanceof Error ? err : Error(`Unauthorized`));
  }
}

export function usePrivilegedContext(): PrivilegedContext {
  return React.useContext(PrivilegedContext).getOrElse(err => {
    throw err;
  });
}

export function AuthFlow(): React.ReactElement | null {
  React.useEffect(() => {
    if (window.location.pathname === '/auth-code') {
      getCode().forEach(code => {
        getAuthToken(code)
          .then(token => {
            setAuthInfo(token);
            window.location.href = '/';
          })
          .catch(err => console.log(err));
      });
    } else {
      codeFlow();
    }
  });
  return null;
}

function AuthErrorHandler(err: Error): React.ReactElement | null {
  if (err instanceof AuthError) return AuthFlow();
  else throw err;
}

function Container({
  children,
}: Readonly<{ children: React.ReactNode }>): React.ReactElement | null {
  const data = getPrivileged();

  return Option(data)
    .getOrElse(() => Failure(Error(`Failed to get a PriviligedContext`)))
    .unwrap(
      d => (
        <PrivilegedContext.Provider value={Success(d)}>
          {children}
        </PrivilegedContext.Provider>
      ),
      () => <AuthFlow />,
    );
}

export function Privileged({
  children,
}: Readonly<{ children: React.ReactNode }>): React.ReactElement | null {
  return (
    <ErrorBoundary fallback={AuthErrorHandler}>
      <Suspense fallback={<Loading />}>
        <Container>{children}</Container>
      </Suspense>
    </ErrorBoundary>
  );
}
