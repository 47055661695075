import { Scope } from '@auto/dango-util';

export type Lang = 'en' | 'ja' | 'in' | 'ms';
export type Region = 'JP' | 'MY' | 'ID' | 'TH';

export type Locale = {
  readonly lang: Lang;
  readonly region: Region;
};

export const defaultLocale = Scope(() =>
  parseLocale(window.navigator.language),
);

export function Locale(lang: Lang, region: Region): Locale {
  return {
    lang,
    region,
  };
}

export function parseLocale(locale: string): Locale {
  const xs = locale.replace('-', '_').split('_');
  const lang: Lang = Scope(() => {
    switch (xs[0]) {
      case 'ja':
        return 'ja';
      case 'en':
        return 'en';
      default:
        return 'en';
    }
  });
  const region: Region = Scope(() => {
    switch (xs[1]) {
      case 'JP':
        return 'JP';
      case 'MY':
        return 'MY';
      case 'ID':
        return 'ID';
      case 'TH':
        return 'TH';
      default:
        return 'MY';
    }
  });
  return Locale(lang, region);
}
