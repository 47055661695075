import { None, Option, Some } from '@auto/dango-util';
import { EventIdMap } from '../generated/i18n/EventIdMap';
import { EventId } from '../generated/i18n/Types';
import { Intl } from '../i18n/Intl';

export type EventTitleIcon = Readonly<{
  title: Option<string>;
  icon: Option<string>;
}>;

export function getEventTitleIcon(
  eventId: EventId,
  intl: Intl,
): EventTitleIcon {
  const ids = EventIdMap.get(eventId);
  const title =
    ids === undefined
      ? '-'
      : intl.formatMessageAsString({
          id: ids.dashboard,
        });
  return {
    title: title === '-' ? None() : Some(title),
    icon: Option(ids?.image?.normal),
  };
}
