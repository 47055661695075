import * as MUI from '@mui/material';
import * as MUISystem from '@mui/system';
import React from 'react';
import { Theme } from '../theme/Theme';

function buttonStyle(
  sx?: MUISystem.SystemStyleObject<MUISystem.Theme>,
  textSx?: MUISystem.SystemStyleObject<MUISystem.Theme>,
): MUISystem.SystemStyleObject<MUISystem.Theme> {
  return {
    paddingTop: '2px',
    paddingBottom: '2px',
    textTransform: 'none',
    backgroundColor: Theme.toolbar.refresh.backgroundColor,
    boxShadow: 'none',
    fontWeight: `600`,
    lineHeight: `1em`,
    textAlign: `left`,
    ...sx,
    ...textSx,
    '&:hover': {
      backgroundColor: Theme.toolbar.refresh.backgroundColor,
      boxShadow: 'none',
      fontWeight: `600`,
      ...sx,
      ...textSx,
    },
  };
}

export function TextButton({
  title,
  sx,
  textSx,
  leftIcon,
  onClick,
}: Readonly<{
  title: string;
  sx?: MUISystem.SystemStyleObject<MUISystem.Theme>;
  textSx?: MUISystem.SystemStyleObject<MUISystem.Theme>;
  leftIcon?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}>): React.ReactElement {
  return (
    <MUI.Button
      variant='contained'
      sx={buttonStyle(sx, textSx)}
      startIcon={leftIcon}
      onClick={onClick}
    >
      {title}
    </MUI.Button>
  );
}
