type Env =
  | 'debug'
  | 'local-devjp'
  | 'local-devmy'
  | 'local-qamy'
  | 'devjp'
  | 'devmy'
  | 'qamy'
  | 'stgmy';

type BuildOptMode =
  | 'development' // npm run start (connect to localhost:3000
  | 'production' // npm run build (connect to a external server)
  | 'test';

export type Product = 'daihatsu' | 'perodua';

export const BuildOpt = {
  env: (process.env.REACT_APP_BUILD_OPT as Env | undefined) ?? 'debug',
  mode: process.env.NODE_ENV as BuildOptMode,

  getProduct(): Product {
    switch (BuildOpt.env) {
      case 'debug':
      case 'local-devjp':
      case 'devjp':
        return 'daihatsu';
      case 'local-devmy':
      case 'local-qamy':
      case 'devmy':
      case 'qamy':
      case 'stgmy':
        return 'perodua';
    }
  },

  byProduct<T>(
    switcher: Readonly<{
      [Key in Product]: (product: Product) => T;
    }>,
  ): T {
    const product: Product = BuildOpt.getProduct();
    return switcher[product](product);
  },
} as const;
