type Colors = Readonly<typeof unsafeColors>;

const unsafeColors = {
  mainColor1: '#dd0000',
  buttonColor1: 'orangered',
  buttonColor2: 'gray',
  buttonColor3: 'seagreen',
  buttonColor4: 'dodgerblue',
  buttonColor5: 'royalblue',
};

export const Colors: Colors = unsafeColors;
