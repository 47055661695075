import { Option } from '@auto/dango-util';
import { AuthToken } from '../backend/Auth';

const authInfoKey = 'auth-info';

export function getAuthInfo(): Option<AuthToken> {
  return Option(window.localStorage.getItem(authInfoKey)).map(
    _ => JSON.parse(_) as AuthToken,
  );
}

export function setAuthInfo(a: AuthToken): void {
  window.localStorage.setItem(authInfoKey, JSON.stringify(a));
}

export function deleteAuthInfo(): void {
  window.localStorage.removeItem(authInfoKey);
}
