import { ReportHandler } from 'web-vitals';

const reportWebVitals: (onPerfEntry?: ReportHandler) => void = (
  onPerfEntry?: ReportHandler,
) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals')
      .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        getCLS(onPerfEntry);
        getFID(onPerfEntry);
        getFCP(onPerfEntry);
        getLCP(onPerfEntry);
        getTTFB(onPerfEntry);
      })
      .catch(err => {
        console.log(err);
      });
  }
};

export default reportWebVitals;
