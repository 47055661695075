import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import * as MUISystem from '@mui/system';
import React from 'react';

function sxStyle(
  style?: MUISystem.SystemStyleObject<MUISystem.Theme>,
  inputStyle?: MUISystem.SystemStyleObject<MUISystem.Theme>,
  buttonStyle?: MUISystem.SystemStyleObject<MUISystem.Theme>,
): MUISystem.SystemStyleObject<MUISystem.Theme> {
  return {
    overflow: 'hidden',
    paddingRight: `10px`,
    ...style,
    '& .MuiOutlinedInput-input': {
      padding: '4px',
      ...inputStyle,
    },
    '& .MuiButtonBase-root': {
      borderRadius: 0,
      ...buttonStyle,
    },
    '& .MuiButtonBase-root:hover': {
      borderRadius: 0,
      ...buttonStyle,
    },
    '& .MuiSvgIcon-root': {
      ...buttonStyle,
    },
  };
}

export function TextInput({
  value,
  type,
  sx,
  inputSx,
  buttonSx,
  rightIcon,
  inputMode,
  placeholder,
  focus,
  onClickButton,
  onChange,
  onSubmit,
  onKeyPress,
}: Readonly<{
  value?: string;
  type?: string;
  rightIcon?: React.ReactNode;
  sx?: MUISystem.SystemStyleObject<MUISystem.Theme>;
  inputSx?: MUISystem.SystemStyleObject<MUISystem.Theme>;
  buttonSx?: MUISystem.SystemStyleObject<MUISystem.Theme>;
  inputMode?: React.HTMLAttributes<HTMLInputElement>['inputMode'];
  placeholder?: string;
  focus?: boolean;
  onClickButton?: (e: React.MouseEvent) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: (e: React.FormEvent) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}>): React.ReactElement {
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (focus === true) ref.current?.focus();
    else ref.current?.blur();
  }, [focus]);

  return (
    <OutlinedInput
      inputRef={ref}
      value={value}
      type={type}
      sx={sxStyle(sx, inputSx, buttonSx)}
      inputMode={inputMode}
      placeholder={placeholder}
      onChange={onChange}
      onSubmit={onSubmit}
      onKeyPress={onKeyPress}
      endAdornment={
        rightIcon === undefined ? undefined : (
          <InputAdornment position='end'>
            <IconButton
              // aria-label='toggle password visibility'
              onClick={onClickButton}
              //onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {rightIcon}
              {/* <SearchIcon /> */}
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
}
