import * as R from 'react-router-dom';

type Options<State> = Omit<R.NavigateOptions, 'state'> &
  Readonly<{
    state: State;
  }>;

type NavigateFunction<State> = (to: R.To, options?: Options<State>) => void;

export function useNavigate<State>(): NavigateFunction<State> {
  return R.useNavigate();
}
