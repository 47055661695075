import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../app/AppContext';
import { useMemberVehicleProfile } from '../backend/Api';

export function useSearch(): void {
  const appContext = React.useContext(AppContext);
  const navigate = useNavigate();
  useMemberVehicleProfile({
    searchParams: appContext.state.search,
    onSuccess: state => navigate('/vehicle-detail', { state }),
    onFailure: () =>
      appContext.dispatch(data => ({
        ...data,
        toolbar: {
          ...data.toolbar,
          isError: true,
        },
      })),
  });
}
