import * as Gen from '../generated/i18n/Types';
import { Messages as en } from '../generated/i18n/en_perodua';

export type MessageTable = Gen.MessagesType;
export type MessageKeys = keyof MessageTable;
export type MessageId = Readonly<{
  [Key in MessageKeys]: Key;
}>;

export const MessageId: MessageId = Object.keys(en).reduce((o, k) => {
  const _o = o as { [k: string]: string };
  _o[k] = k;
  return o;
}, {} as MessageId);
