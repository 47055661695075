import { Box } from '@mui/system';
import React from 'react';
import { Style } from '../../style/Style';

const styles = Style({
  row: {
    display: `flex`,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
});

export function Row({
  children,
}: Readonly<{ children: React.ReactNode }>): React.ReactElement {
  return <Box sx={styles.row}> {children} </Box>;
}
