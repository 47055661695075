import {
  VehicleEvent,
  VehicleProfile,
} from '@auto/monaka-client/dist/dashboard';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import labelmake from 'labelmake';
import { TemplateSchema } from 'labelmake/dist/types/type';
import { VehicleDetail as VehicleDetailData } from '../backend/Api';
import { pdfData } from './pdfData';

dayjs.extend(utc);
dayjs.extend(timezone);

type SROData = {
  vehiclenumber: string;
  model: string;
  chassisnumber: string;
  enginenumber: string;
  odometer: string;
  fuellevel: string;
  enginelamp: {
    isOk: boolean;
    message?: string;
  };
  automatic_transmission: {
    isOk: boolean;
    message?: string;
  };
  air_bag: {
    isOk: boolean;
    message?: string;
  };
  engine_temperature: {
    isOk: boolean;
    message?: string;
  };
  anti_brake_system: {
    isOk: boolean;
    message?: string;
  };
  electric_power_steering: {
    isOk: boolean;
    message?: string;
  };
  engine_oil: {
    isOk: boolean;
    message?: string;
  };
  date1: string;
  date2: string;
  date3: string;
  date4: string;
};

const offsetY = 1.12;
const indicatorWidth = 23.96;
const indicatorHight = [18.65, 18.7, 21.28, 18.65];
const indicatorX = [79.06, 179.62];
const indicatorY = [
  101.12 + offsetY,
  119.94 + offsetY,
  138.82 + offsetY,
  160.3 + offsetY,
];

export const GeneratePdf = async (data: SROData): Promise<void> => {
  const schema: { [key: string]: TemplateSchema } = {
    vehiclenumber: {
      type: 'text',
      position: { x: 12.33, y: 31.89 + offsetY },
      width: 86.5,
      height: 8.11,
      alignment: 'center',
      fontSize: 14,
      characterSpacing: 0,
      lineHeight: 1,
    },
    model: {
      type: 'text',
      position: { x: 13.76, y: 46.83 + offsetY },
      width: 83.41,
      height: 5.93,
      alignment: 'center',
      fontSize: 14,
      characterSpacing: 0,
      lineHeight: 1,
    },
    chassisnumber: {
      type: 'text',
      position: { x: 111.76, y: 31.57 + offsetY },
      width: 86.5,
      height: 8.11,
      alignment: 'center',
      fontSize: 14,
      characterSpacing: 0,
      lineHeight: 1,
    },
    enginenumber: {
      type: 'text',
      position: { x: 113.18, y: 46.78 + offsetY },
      width: 83.41,
      height: 5.93,
      alignment: 'center',
      fontSize: 14,
      characterSpacing: 0,
      lineHeight: 1,
    },
    odometer: {
      type: 'text',
      position: { x: 46.51, y: 84.88 + offsetY },
      width: 39.75,
      height: 5.93,
      alignment: 'center',
      fontSize: 14,
      characterSpacing: 0,
      lineHeight: 1,
    },
    fuellevel: {
      type: 'text',
      position: { x: 147.27, y: 84.83 + offsetY },
      width: 39.75,
      height: 5.93,
      alignment: 'center',
      fontSize: 14,
      characterSpacing: 0,
      lineHeight: 1,
    },

    date1: {
      type: 'text',
      position: { x: 176.06, y: 7.94 + offsetY },
      width: 24.14,
      height: 5.67,
      alignment: 'left',
      fontSize: 6,
      characterSpacing: 0,
      lineHeight: 2,
    },
    date2: {
      type: 'text',
      position: { x: 172.29, y: 55.78 + offsetY },
      width: 30.49,
      height: 5.4,
      alignment: 'left',
      fontSize: 6,
      characterSpacing: 0,
      lineHeight: 2,
    },
    date3: {
      type: 'text',
      position: { x: 172.03, y: 79.01 + offsetY },
      width: 30.49,
      height: 5.4,
      alignment: 'left',
      fontSize: 6,
      characterSpacing: 0,
      lineHeight: 2,
    },
    date4: {
      type: 'text',
      position: { x: 172.56, y: 92.45 + offsetY },
      width: 30.49,
      height: 5.4,
      alignment: 'left',
      fontSize: 6,
      characterSpacing: 0,
      lineHeight: 2,
    },
  };

  const input: { [key: string]: string } = {
    vehiclenumber: data.vehiclenumber || '',
    model: data.model,
    chassisnumber: data.chassisnumber,
    enginenumber: data.enginenumber,
    odometer: data.odometer,
    fuellevel: data.fuellevel,
    date1: data.date1,
    date2: data.date2,
    date3: data.date3,
    date4: data.date4,
  };

  const getSchemaBaseElement = (
    key: string,
    xindex: number,
    yindex: number,
    isOK = false,
    message = '',
  ): { [key: string]: TemplateSchema } => {
    const lineHeight = message === '' ? 4 : 2;
    const data: TemplateSchema = {
      type: 'text',
      position: {
        x: indicatorX[xindex],
        y: indicatorY[yindex],
      },
      width: indicatorWidth,
      height: indicatorHight[yindex],
      alignment: 'center',
      fontSize: 12,
      characterSpacing: 0,
      lineHeight,
    };
    if (!isOK) {
      data.backgroundColor = '#bfbfbf';
    }
    return {
      [key]: data,
    };
  };

  const getSchemaMessageElement = (
    key: string,
    xindex: number,
    yindex: number,
  ): { [key: string]: TemplateSchema } => {
    const woffset = 2;
    const data: TemplateSchema = {
      type: 'text',
      position: {
        x: indicatorX[xindex] + woffset / 2,
        y: indicatorY[yindex] + 6.6,
      },
      width: indicatorWidth - woffset,
      height: indicatorHight[yindex],
      alignment: 'center',
      fontSize: 8,
      characterSpacing: 0,
      lineHeight: 0,
    };

    return {
      [key + '_message']: data,
    };
  };
  const getInputBaseElement = (
    key: string,
    isOK: boolean,
  ): { [key: string]: string } => {
    return {
      [key]: isOK ? 'OK' : 'NG',
    };
  };
  const getInputMessageElement = (
    key: string,
    message: string,
  ): { [key: string]: string } => {
    return {
      [key + '_message']: message,
    };
  };

  const updateElement = (
    key: string,
    xindex: number,
    yindex: number,
    isOK = false,
    message = '',
  ): void => {
    Object.assign(
      schema,
      getSchemaBaseElement(key, xindex, yindex, isOK, message),
    );
    Object.assign(input, getInputBaseElement(key, isOK));
    if (message !== '') {
      Object.assign(schema, getSchemaMessageElement(key, xindex, yindex));
      Object.assign(input, getInputMessageElement(key, message));
    }
  };

  updateElement(
    'enginelamp',
    0,
    0,
    data.enginelamp.isOk,
    data.enginelamp.message,
  );
  updateElement(
    'automatic_transmission',
    0,
    1,
    data.automatic_transmission.isOk,
    data.automatic_transmission.message,
  );
  updateElement('air_bag', 0, 2, data.air_bag.isOk, data.air_bag.message);
  updateElement(
    'engine_temperature',
    0,
    3,
    data.engine_temperature.isOk,
    data.engine_temperature.message,
  );
  updateElement(
    'anti_brake_system',
    1,
    0,
    data.anti_brake_system.isOk,
    data.anti_brake_system.message,
  );
  updateElement(
    'electric_power_steering',
    1,
    1,
    data.electric_power_steering.isOk,
    data.electric_power_steering.message,
  );
  updateElement(
    'engine_oil',
    1,
    2,
    data.engine_oil.isOk,
    data.engine_oil.message,
  );

  const schemas = [schema];

  const basePdf = 'data:application/pdf;base64,' + pdfData;
  const pdf = await labelmake({
    template: {
      basePdf,
      schemas,
    },
    inputs: [input],
  });
  const blob = new Blob([pdf.buffer], { type: 'application/pdf' });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `sro.pdf`;
  link.click();
};

const array2Obj = (
  eventTelemetry: VehicleEvent[],
): { [key: string]: VehicleEvent } => {
  return eventTelemetry.reduce(
    (tmp: { [key: string]: VehicleEvent }, ele: VehicleEvent) => {
      if (tmp[ele.eventId] === undefined) {
        tmp[ele.eventId] = ele;
      } else {
        if (ele.tripId > tmp[ele.eventId].timestamp) {
          tmp[ele.eventId] = ele;
        }
      }
      return tmp;
    },
    {},
  );
};

// 一ヶ月以内に警告ありの場合のtimestamp
const getMessage = (tripId: Date | undefined) => {
  if (tripId === undefined) {
    return '';
  }
  const lastMonth = dayjs().add(-1, 'month');
  const date = dayjs(tripId);
  if (date > lastMonth) {
    const format = 'DD/MM/YYYY@hh:mm a';
    const now = dayjs(tripId).tz('Asia/Kuala_Lumpur');
    const nowText = now.format(format);
    return `Last Turn on: \n(${nowText})`;
  }
  return '';
};

const sameTime = (date1: Date | undefined, date2: Date | undefined) => {
  if (!date1 || !date2) {
    return false;
  }
  return date1.getTime() === date2.getTime();
};
const getLampData = (event: VehicleEvent | undefined, tripId: Date) => {
  // ng is tripId and event.TripId
  const isOK = !sameTime(event?.tripId, tripId);
  const message = event?.tripId !== undefined ? getMessage(event?.tripId) : '';
  return {
    isOk: isOK,
    message: message,
  };
};
const convTime = (event: VehicleEvent | undefined) => {
  return event?.tripId ? event.tripId.getTime() : 0;
};

const getLampData2 = (
  event1: VehicleEvent | undefined,
  event2: VehicleEvent | undefined,
  tripId: Date,
) => {
  const isOK = !(
    sameTime(event1?.tripId, tripId) || sameTime(event2?.tripId, tripId)
  );

  const message =
    event1?.tripId !== undefined || event2?.tripId !== undefined
      ? getMessage(
          convTime(event1) > convTime(event2) ? event1?.tripId : event2?.tripId,
        )
      : '';
  return {
    isOk: isOK,
    message: message,
  };
};

export const downloadSamplePdf = async (
  vehicleProfile?: VehicleProfile,
  vehicleDetail?: VehicleDetailData,
): Promise<void> => {
  if (!vehicleProfile || !vehicleDetail) {
    return;
  }
  const { serviceTelemetry } = vehicleDetail;
  const events = array2Obj(vehicleDetail.eventTelemetry);

  // https://day.js.org/docs/en/display/format
  const format = 'DD/MM/YYYY@hh:mm a';
  const now = dayjs().tz('Asia/Kuala_Lumpur');
  const nowText = now.format(format);

  const date = dayjs(serviceTelemetry.tripId)
    .tz('Asia/Kuala_Lumpur')
    .format(format);

  // https://dmc-dsc.backlog.com/view/XEVO_DMC-318
  const tripId = serviceTelemetry.tripId;

  return await GeneratePdf({
    vehiclenumber: vehicleProfile.vehicleRegNo,
    chassisnumber: vehicleProfile.vin,
    model: vehicleProfile.model,
    enginenumber: vehicleProfile.engineNo,
    odometer: String(vehicleDetail.serviceTelemetry.odo),
    fuellevel: String(vehicleDetail.serviceTelemetry.flSeg),
    enginelamp: getLampData(events['202'], tripId),
    automatic_transmission: getLampData(events['206'], tripId),
    air_bag: getLampData(events['306'], tripId),
    engine_temperature: getLampData(events['205'], tripId),
    anti_brake_system: getLampData(events['304'], tripId),
    electric_power_steering: getLampData2(events['307'], events['308'], tripId),
    engine_oil: getLampData(events['302'], tripId),
    date1: nowText,
    date2: date,
    date3: date,
    date4: date,
  });
  return;
};
