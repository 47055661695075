/* eslint-disable no-irregular-whitespace */
import { MessageIds, MessagesType } from './Types';

export type Messages = MessagesType;

export const Messages: Messages = {
  footerCopyright: `Copyright ©Daihatsu & Perodua`,
  headerTitle: `Local Center`,
  homeAirBagTitle: `Air Bag Deployment`,
  homeButtonTitle: `As of now {count} Units`,
  homeSOSTitle: `SOS`,
  homeTheftAlertTitle: `Theft Alert (DCM Removal, Immobilizer Alerm)`,
  homeVehicleWarningTitle: `Vehicle Warning`,
  signOut: `Sign Out`,
  signinButtonTitle: `Login`,
  signinPanelTitle: `Login`,
  signinPlaceholderPassword: `Password`,
  signinPlaceholderUserId: `ID`,
  toolbarGoToTop: `Go to TOP page`,
  toolbarPrintDashboard: `Print Dash board`,
  toolbarPrintSRO: `Print SRO`,
  toolbarRefreshTitle: `Refresh`,
  toolbarSelectReg: `Registration No.`,
  toolbarSelectVin: `VIN`,
  vehicleDetailBasicInfoTitle: `<Basic Information>`,
  vehicleDetailBodyColor: `Body color :`,
  vehicleDetailChipDriver: `Driver`,
  vehicleDetailChipOwner: `Owner`,
  vehicleDetailCusId: `Customer ID :`,
  vehicleDetailDTCHeaderDTCID: `DTC ID`,
  vehicleDetailDTCHeaderDate: `date`,
  vehicleDetailDTCHeaderDetail: `Detail`,
  vehicleDetailDTCHeaderECU: `ECU`,
  vehicleDetailDTCHeaderFFD: `FFD`,
  vehicleDetailDTCNew: `New`,
  vehicleDetailDTCTitle: `<DTC/FFD List>`,
  vehicleDetailDtcCodeUndefined: `undefined({ecuCode}_{dtcCode})`,
  vehicleDetailEcuCodeUndefined: `undefined({ecuCode})`,
  vehicleDetailLocationAsOf: `As of`,
  vehicleDetailLocationErrorMsg: `Unable to get vehicle location`,
  vehicleDetailLocationTitle: `<Latest location>`,
  vehicleDetailModel: `Model :`,
  vehicleDetailName: `Name : `,
  vehicleDetailPAA: `PAA status :`,
  vehicleDetailPhone: `Phone No. :`,
  vehicleDetailRegNo: `Registration No. :`,
  vehicleDetailRegYear: `Registration Year :`,
  vehicleDetailTitle: `Vehicle Warning`,
  vehicleDetailVehicleStatusAve: `Ave. Fuel Consumption :`,
  vehicleDetailVehicleStatusDistance: `Distance Traveled :`,
  vehicleDetailVehicleStatusFuel: `Fuel level :`,
  vehicleDetailVehicleStatusRange: `Driving Range :`,
  vehicleDetailVehicleStatusTitle: `<Vehicle Status>`,
  vehicleDetailVin: `VIN :`,
  vehicleDetailWarningLampHeaderDate: `date`,
  vehicleDetailWarningLampHeaderDetail: `Detail`,
  vehicleDetailWarningLampHeaderTime: `time`,
  vehicleDetailWarningLampTitle: `<Warning Lamp Status>`,
  vehicleListCustomerIC: `Customer IC`,
  vehicleListCustomerInfo: `Customer Info.`,
  vehicleListCustomerName: `Name`,
  vehicleListCustomerPhone: `Phone`,
  vehicleListModel: `Model`,
  vehicleListPAA: `PAA Stats`,
  vehicleListPAAMember: `Member`,
  vehicleListPAANonMember: `Non-Member`,
  vehicleListRegNo: `Registration No.`,
  vehicleListTime: `Time Stamp`,
  vehicleListTitle: `<Vehicle List>`,
  vehicleListVin: `VIN`,
  vehicleListWarning: `Warning Status`,
  vehicleListWarningUndefined: `undefined({eventId})`,

  Event_completeCarRegistrationEventName: `おクルマの登録完了`,
  Event_completeCarRegistrationWarningTitle: `-`,
  Event_completeCarRegistrationWarningHint: `-`,
  Event_completeCarRegistrationNotificationTitle: `Daihatsu Connect`,
  Event_completeCarRegistrationNotificationBody: `Car registration is complete.`,
  Event_completeCarRegistrationHome: `-`,
  Event_completeCarRegistrationDialogTitle: `Car registration completed`,
  Event_completeCarRegistrationDialogBody: `Registration of {model} is completed. It can be used with "{userType}".`,
  Event_completeCarRegistrationDialogAction: `-`,
  Event_completeCarRegistrationDialogAction2: `-`,
  Event_completeCarRegistrationDialogClose: `OK`,
  Event_completeCarRegistrationAlertTitle: `-`,
  Event_completeCarRegistrationAlertBody: `-`,
  Event_completeCarRegistrationLocation: `-`,
  Event_completeCarRegistrationRemoteControl: `-`,
  Event_completeCarRegistrationDashboard: `-`,
  Event_waitingForCarRegistrationEventName: `おクルマの登録許可待ち`,
  Event_waitingForCarRegistrationWarningTitle: `-`,
  Event_waitingForCarRegistrationWarningHint: `-`,
  Event_waitingForCarRegistrationNotificationTitle: `-`,
  Event_waitingForCarRegistrationNotificationBody: `-`,
  Event_waitingForCarRegistrationHome: `-`,
  Event_waitingForCarRegistrationDialogTitle: `Waiting for car registration`,
  Event_waitingForCarRegistrationDialogBody: `Registration is completed when the owner of the car permits "Add shared user". (Permission within 24 hours is required)`,
  Event_waitingForCarRegistrationDialogAction: `-`,
  Event_waitingForCarRegistrationDialogAction2: `-`,
  Event_waitingForCarRegistrationDialogClose: `OK`,
  Event_waitingForCarRegistrationAlertTitle: `-`,
  Event_waitingForCarRegistrationAlertBody: `-`,
  Event_waitingForCarRegistrationLocation: `-`,
  Event_waitingForCarRegistrationRemoteControl: `-`,
  Event_waitingForCarRegistrationDashboard: `-`,
  Event_addSharedUserEventName: `共有ユーザー追加`,
  Event_addSharedUserWarningTitle: `-`,
  Event_addSharedUserWarningHint: `-`,
  Event_addSharedUserNotificationTitle: `Daihatsu Connect`,
  Event_addSharedUserNotificationBody: `Add a shared user. Please allow sharing.`,
  Event_addSharedUserHome: `-`,
  Event_addSharedUserDialogTitle: `Add Shared user`,
  Event_addSharedUserDialogBody: `Share {carName} to {name} with the delegation of "{userType}".`,
  Event_addSharedUserDialogAction: `To approve`,
  Event_addSharedUserDialogAction2: `Not allowed`,
  Event_addSharedUserDialogClose: `-`,
  Event_addSharedUserAlertTitle: `-`,
  Event_addSharedUserAlertBody: `-`,
  Event_addSharedUserLocation: `-`,
  Event_addSharedUserRemoteControl: `-`,
  Event_addSharedUserDashboard: `-`,
  Event_sosCallManualEventName: `緊急通報(手動)`,
  Event_sosCallManualWarningTitle: `SOS Call (Manual) warning light`,
  Event_sosCallManualWarningHint: `-`,
  Event_sosCallManualNotificationTitle: `Emergency Alert`,
  Event_sosCallManualNotificationBody: `SOS Call (Manual) has been made`,
  Event_sosCallManualHome: `SOS Call (Manual) has been made`,
  Event_sosCallManualDialogTitle: `Emergency Alert`,
  Event_sosCallManualDialogBody: `{model} \nSOS Call (Manual) has been made`,
  Event_sosCallManualDialogAction: `Emergency Alert`,
  Event_sosCallManualDialogAction2: `-`,
  Event_sosCallManualDialogClose: `OK`,
  Event_sosCallManualAlertTitle: `SOS Call (Manual) has been made`,
  Event_sosCallManualAlertBody: `SOS Call (Manual) has been made`,
  Event_sosCallManualLocation: `-`,
  Event_sosCallManualRemoteControl: `-`,
  Event_sosCallManualDashboard: `SOS Call (Manual) warning light`,
  Event_sosCallAutoEventName: `緊急通報(自動)`,
  Event_sosCallAutoWarningTitle: `SOS Call (Auto) warning light`,
  Event_sosCallAutoWarningHint: `-`,
  Event_sosCallAutoNotificationTitle: `Emergency Alert`,
  Event_sosCallAutoNotificationBody: `SOS Call (Auto) has been made`,
  Event_sosCallAutoHome: `SOS Call (Auto) has been made`,
  Event_sosCallAutoDialogTitle: `Emergency Alert`,
  Event_sosCallAutoDialogBody: `{model} \nSOS Call (Auto) has been made`,
  Event_sosCallAutoDialogAction: `Emergency Alert`,
  Event_sosCallAutoDialogAction2: `-`,
  Event_sosCallAutoDialogClose: `OK`,
  Event_sosCallAutoAlertTitle: `SOS Call (Auto) has been made`,
  Event_sosCallAutoAlertBody: `SOS Call (Auto) has been made`,
  Event_sosCallAutoLocation: `-`,
  Event_sosCallAutoRemoteControl: `-`,
  Event_sosCallAutoDashboard: `SOS Call (Auto) warning light`,
  Event_lowDriveBatteryAlert3EventName: `駆動用電池の機能低下`,
  Event_lowDriveBatteryAlert3WarningTitle: `Low Drive battery warning light`,
  Event_lowDriveBatteryAlert3WarningHint: `Contact an authorised Daihatsu Service Centre immediately`,
  Event_lowDriveBatteryAlert3NotificationTitle: `Emergency Alert`,
  Event_lowDriveBatteryAlert3NotificationBody: `Low Drive battery function has been detected`,
  Event_lowDriveBatteryAlert3Home: `Low Drive battery function has been detected`,
  Event_lowDriveBatteryAlert3DialogTitle: `Emergency Alert`,
  Event_lowDriveBatteryAlert3DialogBody: `{model} \nLow Drive battery function has been detected`,
  Event_lowDriveBatteryAlert3DialogAction: `Emergency Alert`,
  Event_lowDriveBatteryAlert3DialogAction2: `-`,
  Event_lowDriveBatteryAlert3DialogClose: `OK`,
  Event_lowDriveBatteryAlert3AlertTitle: `Low Drive battery function has been detected`,
  Event_lowDriveBatteryAlert3AlertBody: `Low Drive battery function has been detected`,
  Event_lowDriveBatteryAlert3Location: `-`,
  Event_lowDriveBatteryAlert3RemoteControl: `-`,
  Event_lowDriveBatteryAlert3Dashboard: `Low Drive battery warning light`,
  Event_lowDriveBatteryAlert2EventName: `駆動用電池の機能低下`,
  Event_lowDriveBatteryAlert2WarningTitle: `Low Drive battery warning light`,
  Event_lowDriveBatteryAlert2WarningHint: `Contact an authorised Daihatsu Service Centre immediately`,
  Event_lowDriveBatteryAlert2NotificationTitle: `Emergency Alert`,
  Event_lowDriveBatteryAlert2NotificationBody: `Low Drive battery function has been detected`,
  Event_lowDriveBatteryAlert2Home: `Low Drive battery function has been detected`,
  Event_lowDriveBatteryAlert2DialogTitle: `Emergency Alert`,
  Event_lowDriveBatteryAlert2DialogBody: `{model} \nLow Drive battery function has been detected`,
  Event_lowDriveBatteryAlert2DialogAction: `Emergency Alert`,
  Event_lowDriveBatteryAlert2DialogAction2: `-`,
  Event_lowDriveBatteryAlert2DialogClose: `OK`,
  Event_lowDriveBatteryAlert2AlertTitle: `Low Drive battery function has been detected`,
  Event_lowDriveBatteryAlert2AlertBody: `Low Drive battery function has been detected`,
  Event_lowDriveBatteryAlert2Location: `-`,
  Event_lowDriveBatteryAlert2RemoteControl: `-`,
  Event_lowDriveBatteryAlert2Dashboard: `Low Drive battery warning light`,
  Event_lowDriveBatteryAlert1EventName: `駆動用電池の機能低下`,
  Event_lowDriveBatteryAlert1WarningTitle: `Low Drive battery warning light`,
  Event_lowDriveBatteryAlert1WarningHint: `Contact an authorised Daihatsu Service Centre immediately`,
  Event_lowDriveBatteryAlert1NotificationTitle: `Emergency Alert`,
  Event_lowDriveBatteryAlert1NotificationBody: `Low Drive battery function has been detected`,
  Event_lowDriveBatteryAlert1Home: `Low Drive battery function has been detected`,
  Event_lowDriveBatteryAlert1DialogTitle: `Emergency Alert`,
  Event_lowDriveBatteryAlert1DialogBody: `{model} \nLow Drive battery function has been detected`,
  Event_lowDriveBatteryAlert1DialogAction: `Emergency Alert`,
  Event_lowDriveBatteryAlert1DialogAction2: `-`,
  Event_lowDriveBatteryAlert1DialogClose: `OK`,
  Event_lowDriveBatteryAlert1AlertTitle: `Low Drive battery function has been detected`,
  Event_lowDriveBatteryAlert1AlertBody: `Low Drive battery function has been detected`,
  Event_lowDriveBatteryAlert1Location: `-`,
  Event_lowDriveBatteryAlert1RemoteControl: `-`,
  Event_lowDriveBatteryAlert1Dashboard: `Low Drive battery warning light`,
  Event_backSensorAlertEventName: `バックソナー故障`,
  Event_backSensorAlertWarningTitle: `Back sonar warning light`,
  Event_backSensorAlertWarningHint: `Contact an authorised Daihatsu Service Centre`,
  Event_backSensorAlertNotificationTitle: `Emergency Alert`,
  Event_backSensorAlertNotificationBody: `Back sonar error has been detected`,
  Event_backSensorAlertHome: `Back sonar error has been detected`,
  Event_backSensorAlertDialogTitle: `Emergency Alert`,
  Event_backSensorAlertDialogBody: `{model} \nBack sonar error has been detected`,
  Event_backSensorAlertDialogAction: `Emergency Alert`,
  Event_backSensorAlertDialogAction2: `-`,
  Event_backSensorAlertDialogClose: `OK`,
  Event_backSensorAlertAlertTitle: `Back sonar error has been detected`,
  Event_backSensorAlertAlertBody: `Back sonar error has been detected`,
  Event_backSensorAlertLocation: `-`,
  Event_backSensorAlertRemoteControl: `-`,
  Event_backSensorAlertDashboard: `Back sonar warning light`,
  Event_cornerSensorFrontAlertEventName: `コーナーセンサー故障`,
  Event_cornerSensorFrontAlertWarningTitle: `Corner sensor warning light`,
  Event_cornerSensorFrontAlertWarningHint: `Contact an authorised Daihatsu Service Centre`,
  Event_cornerSensorFrontAlertNotificationTitle: `Emergency Alert`,
  Event_cornerSensorFrontAlertNotificationBody: `Corner sensor error has been detected`,
  Event_cornerSensorFrontAlertHome: `Corner sensor error has been detected`,
  Event_cornerSensorFrontAlertDialogTitle: `Emergency Alert`,
  Event_cornerSensorFrontAlertDialogBody: `{model} \nCorner sensor error has been detected`,
  Event_cornerSensorFrontAlertDialogAction: `Emergency Alert`,
  Event_cornerSensorFrontAlertDialogAction2: `-`,
  Event_cornerSensorFrontAlertDialogClose: `OK`,
  Event_cornerSensorFrontAlertAlertTitle: `Corner sensor error has been detected`,
  Event_cornerSensorFrontAlertAlertBody: `Corner sensor error has been detected`,
  Event_cornerSensorFrontAlertLocation: `-`,
  Event_cornerSensorFrontAlertRemoteControl: `-`,
  Event_cornerSensorFrontAlertDashboard: `Corner sensor warning light`,
  Event_cornerSensorRearAlertEventName: `コーナーセンサー故障`,
  Event_cornerSensorRearAlertWarningTitle: `Corner sensor warning light`,
  Event_cornerSensorRearAlertWarningHint: `Contact an authorised Daihatsu Service Centre`,
  Event_cornerSensorRearAlertNotificationTitle: `Emergency Alert`,
  Event_cornerSensorRearAlertNotificationBody: `Corner sensor error has been detected`,
  Event_cornerSensorRearAlertHome: `Corner sensor error has been detected`,
  Event_cornerSensorRearAlertDialogTitle: `Emergency Alert`,
  Event_cornerSensorRearAlertDialogBody: `{model} \nCorner sensor error has been detected`,
  Event_cornerSensorRearAlertDialogAction: `Emergency Alert`,
  Event_cornerSensorRearAlertDialogAction2: `-`,
  Event_cornerSensorRearAlertDialogClose: `OK`,
  Event_cornerSensorRearAlertAlertTitle: `Corner sensor error has been detected`,
  Event_cornerSensorRearAlertAlertBody: `Corner sensor error has been detected`,
  Event_cornerSensorRearAlertLocation: `-`,
  Event_cornerSensorRearAlertRemoteControl: `-`,
  Event_cornerSensorRearAlertDashboard: `Corner sensor warning light`,
  Event_cornerSensorAllAlertEventName: `コーナーセンサー故障`,
  Event_cornerSensorAllAlertWarningTitle: `Corner sensor warning light`,
  Event_cornerSensorAllAlertWarningHint: `Contact an authorised Daihatsu Service Centre`,
  Event_cornerSensorAllAlertNotificationTitle: `Emergency Alert`,
  Event_cornerSensorAllAlertNotificationBody: `Corner sensor error has been detected`,
  Event_cornerSensorAllAlertHome: `Corner sensor error has been detected`,
  Event_cornerSensorAllAlertDialogTitle: `Emergency Alert`,
  Event_cornerSensorAllAlertDialogBody: `{model} \nCorner sensor error has been detected`,
  Event_cornerSensorAllAlertDialogAction: `Emergency Alert`,
  Event_cornerSensorAllAlertDialogAction2: `-`,
  Event_cornerSensorAllAlertDialogClose: `OK`,
  Event_cornerSensorAllAlertAlertTitle: `Corner sensor error has been detected`,
  Event_cornerSensorAllAlertAlertBody: `Corner sensor error has been detected`,
  Event_cornerSensorAllAlertLocation: `-`,
  Event_cornerSensorAllAlertRemoteControl: `-`,
  Event_cornerSensorAllAlertDashboard: `Corner sensor warning light`,
  Event_acousticVehicleSystemAlertEventName: `車両接近通報スピーカー回路故障`,
  Event_acousticVehicleSystemAlertWarningTitle: `Acoustic vehicle alerting system warning light`,
  Event_acousticVehicleSystemAlertWarningHint: `Contact an authorised Daihatsu Service Centre`,
  Event_acousticVehicleSystemAlertNotificationTitle: `Emergency Alert`,
  Event_acousticVehicleSystemAlertNotificationBody: `Acoustic vehicle alerting system error has been detected`,
  Event_acousticVehicleSystemAlertHome: `Acoustic vehicle alerting system error has been detected`,
  Event_acousticVehicleSystemAlertDialogTitle: `Emergency Alert`,
  Event_acousticVehicleSystemAlertDialogBody: `{model} \nAcoustic vehicle alerting system error has been detected`,
  Event_acousticVehicleSystemAlertDialogAction: `Emergency Alert`,
  Event_acousticVehicleSystemAlertDialogAction2: `-`,
  Event_acousticVehicleSystemAlertDialogClose: `OK`,
  Event_acousticVehicleSystemAlertAlertTitle: `Acoustic vehicle alerting system error has been detected`,
  Event_acousticVehicleSystemAlertAlertBody: `Acoustic vehicle alerting system error has been detected`,
  Event_acousticVehicleSystemAlertLocation: `-`,
  Event_acousticVehicleSystemAlertRemoteControl: `-`,
  Event_acousticVehicleSystemAlertDashboard: `Acoustic vehicle alerting system warning light`,
  Event_bsmAlertEventName: `BSM故障`,
  Event_bsmAlertWarningTitle: `BSM warning light`,
  Event_bsmAlertWarningHint: `Contact an authorised Daihatsu Service Centre`,
  Event_bsmAlertNotificationTitle: `Emergency Alert`,
  Event_bsmAlertNotificationBody: `BSM error has been detected`,
  Event_bsmAlertHome: `BSM error has been detected`,
  Event_bsmAlertDialogTitle: `Emergency Alert`,
  Event_bsmAlertDialogBody: `{model} \nBSM error has been detected`,
  Event_bsmAlertDialogAction: `Emergency Alert`,
  Event_bsmAlertDialogAction2: `-`,
  Event_bsmAlertDialogClose: `OK`,
  Event_bsmAlertAlertTitle: `BSM error has been detected`,
  Event_bsmAlertAlertBody: `BSM error has been detected`,
  Event_bsmAlertLocation: `-`,
  Event_bsmAlertRemoteControl: `-`,
  Event_bsmAlertDashboard: `BSM warning light`,
  Event_lkcSystemAlertEventName: `LKCシステム故障`,
  Event_lkcSystemAlertWarningTitle: `LKC warning light`,
  Event_lkcSystemAlertWarningHint: `Contact an authorised Daihatsu Service Centre`,
  Event_lkcSystemAlertNotificationTitle: `Emergency Alert`,
  Event_lkcSystemAlertNotificationBody: `LKC error has been detected`,
  Event_lkcSystemAlertHome: `LKC error has been detected`,
  Event_lkcSystemAlertDialogTitle: `Emergency Alert`,
  Event_lkcSystemAlertDialogBody: `{model} \nLKC error has been detected`,
  Event_lkcSystemAlertDialogAction: `Emergency Alert`,
  Event_lkcSystemAlertDialogAction2: `-`,
  Event_lkcSystemAlertDialogClose: `OK`,
  Event_lkcSystemAlertAlertTitle: `LKC error has been detected`,
  Event_lkcSystemAlertAlertBody: `LKC error has been detected`,
  Event_lkcSystemAlertLocation: `-`,
  Event_lkcSystemAlertRemoteControl: `-`,
  Event_lkcSystemAlertDashboard: `LKC warning light`,
  Event_accBreakAlertEventName: `ACCブレーキ保持中故障`,
  Event_accBreakAlertWarningTitle: `ACC break warning light`,
  Event_accBreakAlertWarningHint: `Contact an authorised Daihatsu Service Centre`,
  Event_accBreakAlertNotificationTitle: `Emergency Alert`,
  Event_accBreakAlertNotificationBody: `System error while retaining ACC break has been detected`,
  Event_accBreakAlertHome: `System error while retaining ACC break has been detected`,
  Event_accBreakAlertDialogTitle: `Emergency Alert`,
  Event_accBreakAlertDialogBody: `{model} \nSystem error while retaining ACC break has been detected`,
  Event_accBreakAlertDialogAction: `Emergency Alert`,
  Event_accBreakAlertDialogAction2: `-`,
  Event_accBreakAlertDialogClose: `OK`,
  Event_accBreakAlertAlertTitle: `System error while retaining ACC break has been detected`,
  Event_accBreakAlertAlertBody: `System error while retaining ACC break has been detected`,
  Event_accBreakAlertLocation: `-`,
  Event_accBreakAlertRemoteControl: `-`,
  Event_accBreakAlertDashboard: `ACC break warning light`,
  Event_rearDiffLockAlertEventName: `リアデフロック異常`,
  Event_rearDiffLockAlertWarningTitle: `Rear differential lock warning light`,
  Event_rearDiffLockAlertWarningHint: `Contact an authorised Daihatsu Service Centre`,
  Event_rearDiffLockAlertNotificationTitle: `Emergency Alert`,
  Event_rearDiffLockAlertNotificationBody: `Rear differential lock error has been detected`,
  Event_rearDiffLockAlertHome: `Rear differential lock error has been detected`,
  Event_rearDiffLockAlertDialogTitle: `Emergency Alert`,
  Event_rearDiffLockAlertDialogBody: `{model} \nRear differential lock error has been detected`,
  Event_rearDiffLockAlertDialogAction: `Emergency Alert`,
  Event_rearDiffLockAlertDialogAction2: `-`,
  Event_rearDiffLockAlertDialogClose: `OK`,
  Event_rearDiffLockAlertAlertTitle: `Rear differential lock error has been detected`,
  Event_rearDiffLockAlertAlertBody: `Rear differential lock error has been detected`,
  Event_rearDiffLockAlertLocation: `-`,
  Event_rearDiffLockAlertRemoteControl: `-`,
  Event_rearDiffLockAlertDashboard: `Rear differential lock warning light`,
  Event_dummyAirbagAlertEventName: `ダミーエアバッグ展開`,
  Event_dummyAirbagAlertWarningTitle: `-`,
  Event_dummyAirbagAlertWarningHint: `-`,
  Event_dummyAirbagAlertNotificationTitle: `Emergency Alert`,
  Event_dummyAirbagAlertNotificationBody: `[Dummy]Airbag deployment has been detected`,
  Event_dummyAirbagAlertHome: `[Dummy] Airbag deployment has been detected`,
  Event_dummyAirbagAlertDialogTitle: `Emergency Alert`,
  Event_dummyAirbagAlertDialogBody: `{model} \nAirbag deployment has been detected`,
  Event_dummyAirbagAlertDialogAction: `Emergency Alert`,
  Event_dummyAirbagAlertDialogAction2: `-`,
  Event_dummyAirbagAlertDialogClose: `OK`,
  Event_dummyAirbagAlertAlertTitle: `[Dummy]Airbag deployment has been detected`,
  Event_dummyAirbagAlertAlertBody: `[Dummy]Airbag deployment has been detected`,
  Event_dummyAirbagAlertLocation: `-`,
  Event_dummyAirbagAlertRemoteControl: `-`,
  Event_dummyAirbagAlertDashboard: `[Dummy] Airbag deployment has been detected`,
  Event_remoteCmdFailedConditionNotSatisfiedEventName: `リモートコマンド条件非成立`,
  Event_remoteCmdFailedConditionNotSatisfiedWarningTitle: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedWarningHint: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedNotificationTitle: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedNotificationBody: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedHome: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedDialogTitle: `Remote Control`,
  Event_remoteCmdFailedConditionNotSatisfiedDialogBody: `The operation was interrupted because the remote operation was not ready for execution. Please operate again after it becomes operable.`,
  Event_remoteCmdFailedConditionNotSatisfiedDialogAction: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedDialogAction2: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedDialogClose: `OK`,
  Event_remoteCmdFailedConditionNotSatisfiedAlertTitle: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedAlertBody: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedLocation: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedRemoteControl: `-`,
  Event_remoteCmdFailedConditionNotSatisfiedDashboard: `-`,
  Event_remoteCmdAcceptedEventName: `リモートコマンド受付完了`,
  Event_remoteCmdAcceptedWarningTitle: `-`,
  Event_remoteCmdAcceptedWarningHint: `-`,
  Event_remoteCmdAcceptedNotificationTitle: `-`,
  Event_remoteCmdAcceptedNotificationBody: `-`,
  Event_remoteCmdAcceptedHome: `-`,
  Event_remoteCmdAcceptedDialogTitle: `Remote Control`,
  Event_remoteCmdAcceptedDialogBody: `It can take seconds to minutes to complete your operation.`,
  Event_remoteCmdAcceptedDialogAction: `-`,
  Event_remoteCmdAcceptedDialogAction2: `-`,
  Event_remoteCmdAcceptedDialogClose: `OK`,
  Event_remoteCmdAcceptedAlertTitle: `-`,
  Event_remoteCmdAcceptedAlertBody: `-`,
  Event_remoteCmdAcceptedLocation: `-`,
  Event_remoteCmdAcceptedRemoteControl: `-`,
  Event_remoteCmdAcceptedDashboard: `-`,
  Event_commonNetworkErrorEventName: `通信エラー`,
  Event_commonNetworkErrorWarningTitle: `-`,
  Event_commonNetworkErrorWarningHint: `-`,
  Event_commonNetworkErrorNotificationTitle: `-`,
  Event_commonNetworkErrorNotificationBody: `-`,
  Event_commonNetworkErrorHome: `-`,
  Event_commonNetworkErrorDialogTitle: `-`,
  Event_commonNetworkErrorDialogBody: `Network error. Please try again later.`,
  Event_commonNetworkErrorDialogAction: `-`,
  Event_commonNetworkErrorDialogAction2: `-`,
  Event_commonNetworkErrorDialogClose: `OK`,
  Event_commonNetworkErrorAlertTitle: `-`,
  Event_commonNetworkErrorAlertBody: `-`,
  Event_commonNetworkErrorLocation: `-`,
  Event_commonNetworkErrorRemoteControl: `-`,
  Event_commonNetworkErrorDashboard: `-`,
  Event_getGpsErrorEventName: `位置情報取得エラー`,
  Event_getGpsErrorWarningTitle: `-`,
  Event_getGpsErrorWarningHint: `-`,
  Event_getGpsErrorNotificationTitle: `-`,
  Event_getGpsErrorNotificationBody: `-`,
  Event_getGpsErrorHome: `-`,
  Event_getGpsErrorDialogTitle: `Location Service`,
  Event_getGpsErrorDialogBody: `The location information of the smartphone could not be obtained. Would you like to get it again?`,
  Event_getGpsErrorDialogAction: `Retry`,
  Event_getGpsErrorDialogAction2: `-`,
  Event_getGpsErrorDialogClose: `Dismiss`,
  Event_getGpsErrorAlertTitle: `-`,
  Event_getGpsErrorAlertBody: `-`,
  Event_getGpsErrorLocation: `-`,
  Event_getGpsErrorRemoteControl: `-`,
  Event_getGpsErrorDashboard: `-`,
  Event_remoteCmdCenterFailEventName: `リモートコマンド受付エラー`,
  Event_remoteCmdCenterFailWarningTitle: `-`,
  Event_remoteCmdCenterFailWarningHint: `-`,
  Event_remoteCmdCenterFailNotificationTitle: `-`,
  Event_remoteCmdCenterFailNotificationBody: `-`,
  Event_remoteCmdCenterFailHome: `-`,
  Event_remoteCmdCenterFailDialogTitle: `Remote Control`,
  Event_remoteCmdCenterFailDialogBody: `The operation could not be accepted. Please wait a while and try again.`,
  Event_remoteCmdCenterFailDialogAction: `-`,
  Event_remoteCmdCenterFailDialogAction2: `-`,
  Event_remoteCmdCenterFailDialogClose: `OK`,
  Event_remoteCmdCenterFailAlertTitle: `-`,
  Event_remoteCmdCenterFailAlertBody: `-`,
  Event_remoteCmdCenterFailLocation: `-`,
  Event_remoteCmdCenterFailRemoteControl: `-`,
  Event_remoteCmdCenterFailDashboard: `-`,
  Event_remoteCmdDuplicatedEventName: `リモートコマンド重複エラー`,
  Event_remoteCmdDuplicatedWarningTitle: `-`,
  Event_remoteCmdDuplicatedWarningHint: `-`,
  Event_remoteCmdDuplicatedNotificationTitle: `-`,
  Event_remoteCmdDuplicatedNotificationBody: `-`,
  Event_remoteCmdDuplicatedHome: `-`,
  Event_remoteCmdDuplicatedDialogTitle: `Remote Control`,
  Event_remoteCmdDuplicatedDialogBody: `The operation was interrupted because it is accepting other operations. Please wait a while and try again.`,
  Event_remoteCmdDuplicatedDialogAction: `-`,
  Event_remoteCmdDuplicatedDialogAction2: `-`,
  Event_remoteCmdDuplicatedDialogClose: `OK`,
  Event_remoteCmdDuplicatedAlertTitle: `-`,
  Event_remoteCmdDuplicatedAlertBody: `-`,
  Event_remoteCmdDuplicatedLocation: `-`,
  Event_remoteCmdDuplicatedRemoteControl: `-`,
  Event_remoteCmdDuplicatedDashboard: `-`,
  Event_serviceReminderAlertTireEventName: `メンテナンス(タイヤ空気圧)`,
  Event_serviceReminderAlertTireWarningTitle: `-`,
  Event_serviceReminderAlertTireWarningHint: `-`,
  Event_serviceReminderAlertTireNotificationTitle: `Service reminder`,
  Event_serviceReminderAlertTireNotificationBody: `It is necessary to check or adjust tire pressure.`,
  Event_serviceReminderAlertTireHome: `-`,
  Event_serviceReminderAlertTireDialogTitle: `Service reminder`,
  Event_serviceReminderAlertTireDialogBody: `It is necessary to check and adjust the tire pressure.`,
  Event_serviceReminderAlertTireDialogAction: `Service reminder`,
  Event_serviceReminderAlertTireDialogAction2: `-`,
  Event_serviceReminderAlertTireDialogClose: `OK`,
  Event_serviceReminderAlertTireAlertTitle: `-`,
  Event_serviceReminderAlertTireAlertBody: `-`,
  Event_serviceReminderAlertTireLocation: `-`,
  Event_serviceReminderAlertTireRemoteControl: `-`,
  Event_serviceReminderAlertTireDashboard: `-`,
  Event_serviceReminderAlertBrakeRrEventName: `メンテナンス(ブレーキパッドＲr)`,
  Event_serviceReminderAlertBrakeRrWarningTitle: `-`,
  Event_serviceReminderAlertBrakeRrWarningHint: `-`,
  Event_serviceReminderAlertBrakeRrNotificationTitle: `Service reminder`,
  Event_serviceReminderAlertBrakeRrNotificationBody: `It is necessary to inspect or replace the rear brake pads.`,
  Event_serviceReminderAlertBrakeRrHome: `-`,
  Event_serviceReminderAlertBrakeRrDialogTitle: `Service reminder`,
  Event_serviceReminderAlertBrakeRrDialogBody: `The rear brake pads need to be inspected and replaced.`,
  Event_serviceReminderAlertBrakeRrDialogAction: `Service reminder`,
  Event_serviceReminderAlertBrakeRrDialogAction2: `-`,
  Event_serviceReminderAlertBrakeRrDialogClose: `OK`,
  Event_serviceReminderAlertBrakeRrAlertTitle: `-`,
  Event_serviceReminderAlertBrakeRrAlertBody: `-`,
  Event_serviceReminderAlertBrakeRrLocation: `-`,
  Event_serviceReminderAlertBrakeRrRemoteControl: `-`,
  Event_serviceReminderAlertBrakeRrDashboard: `-`,
  Event_serviceReminderAlertBrakeFrEventName: `メンテナンス(ブレーキパッドFr)`,
  Event_serviceReminderAlertBrakeFrWarningTitle: `-`,
  Event_serviceReminderAlertBrakeFrWarningHint: `-`,
  Event_serviceReminderAlertBrakeFrNotificationTitle: `Service reminder`,
  Event_serviceReminderAlertBrakeFrNotificationBody: `It is necessary to inspect or replace the front brake pads.`,
  Event_serviceReminderAlertBrakeFrHome: `-`,
  Event_serviceReminderAlertBrakeFrDialogTitle: `Service reminder`,
  Event_serviceReminderAlertBrakeFrDialogBody: `The front brake pads need to be inspected and replaced.`,
  Event_serviceReminderAlertBrakeFrDialogAction: `Service reminder`,
  Event_serviceReminderAlertBrakeFrDialogAction2: `-`,
  Event_serviceReminderAlertBrakeFrDialogClose: `OK`,
  Event_serviceReminderAlertBrakeFrAlertTitle: `-`,
  Event_serviceReminderAlertBrakeFrAlertBody: `-`,
  Event_serviceReminderAlertBrakeFrLocation: `-`,
  Event_serviceReminderAlertBrakeFrRemoteControl: `-`,
  Event_serviceReminderAlertBrakeFrDashboard: `-`,
  Event_serviceReminderAlertBrakeOilEventName: `メンテナンス(ブレーキオイル)`,
  Event_serviceReminderAlertBrakeOilWarningTitle: `-`,
  Event_serviceReminderAlertBrakeOilWarningHint: `-`,
  Event_serviceReminderAlertBrakeOilNotificationTitle: `Service reminder`,
  Event_serviceReminderAlertBrakeOilNotificationBody: `It is necessary to inspect or replace the brake oil.`,
  Event_serviceReminderAlertBrakeOilHome: `-`,
  Event_serviceReminderAlertBrakeOilDialogTitle: `Service reminder`,
  Event_serviceReminderAlertBrakeOilDialogBody: `Brake fluid needs to be inspected and replaced.`,
  Event_serviceReminderAlertBrakeOilDialogAction: `Service reminder`,
  Event_serviceReminderAlertBrakeOilDialogAction2: `-`,
  Event_serviceReminderAlertBrakeOilDialogClose: `OK`,
  Event_serviceReminderAlertBrakeOilAlertTitle: `-`,
  Event_serviceReminderAlertBrakeOilAlertBody: `-`,
  Event_serviceReminderAlertBrakeOilLocation: `-`,
  Event_serviceReminderAlertBrakeOilRemoteControl: `-`,
  Event_serviceReminderAlertBrakeOilDashboard: `-`,
  Event_serviceReminderAlertBatteryEventName: `メンテナンス(バッテリー)`,
  Event_serviceReminderAlertBatteryWarningTitle: `-`,
  Event_serviceReminderAlertBatteryWarningHint: `-`,
  Event_serviceReminderAlertBatteryNotificationTitle: `Service reminder`,
  Event_serviceReminderAlertBatteryNotificationBody: `It is necessary to checke or replace the battery.`,
  Event_serviceReminderAlertBatteryHome: `-`,
  Event_serviceReminderAlertBatteryDialogTitle: `Service reminder`,
  Event_serviceReminderAlertBatteryDialogBody: `The battery needs to be inspected and replaced.`,
  Event_serviceReminderAlertBatteryDialogAction: `Service reminder`,
  Event_serviceReminderAlertBatteryDialogAction2: `-`,
  Event_serviceReminderAlertBatteryDialogClose: `OK`,
  Event_serviceReminderAlertBatteryAlertTitle: `-`,
  Event_serviceReminderAlertBatteryAlertBody: `-`,
  Event_serviceReminderAlertBatteryLocation: `-`,
  Event_serviceReminderAlertBatteryRemoteControl: `-`,
  Event_serviceReminderAlertBatteryDashboard: `-`,
  Event_serviceReminderAlertEngOilEventName: `メンテナンス(エンジンオイル)`,
  Event_serviceReminderAlertEngOilWarningTitle: `-`,
  Event_serviceReminderAlertEngOilWarningHint: `-`,
  Event_serviceReminderAlertEngOilNotificationTitle: `Service reminder`,
  Event_serviceReminderAlertEngOilNotificationBody: `It is necessary to checked or replace the engine oil.`,
  Event_serviceReminderAlertEngOilHome: `-`,
  Event_serviceReminderAlertEngOilDialogTitle: `Service reminder`,
  Event_serviceReminderAlertEngOilDialogBody: `It is necessary to check and change the engine oil.`,
  Event_serviceReminderAlertEngOilDialogAction: `Service reminder`,
  Event_serviceReminderAlertEngOilDialogAction2: `-`,
  Event_serviceReminderAlertEngOilDialogClose: `OK`,
  Event_serviceReminderAlertEngOilAlertTitle: `-`,
  Event_serviceReminderAlertEngOilAlertBody: `-`,
  Event_serviceReminderAlertEngOilLocation: `-`,
  Event_serviceReminderAlertEngOilRemoteControl: `-`,
  Event_serviceReminderAlertEngOilDashboard: `-`,
  Event_serviceReminderAlertEventName: `点検`,
  Event_serviceReminderAlertWarningTitle: `-`,
  Event_serviceReminderAlertWarningHint: `-`,
  Event_serviceReminderAlertNotificationTitle: `Service reminder`,
  Event_serviceReminderAlertNotificationBody: `The inspection is approaching.`,
  Event_serviceReminderAlertHome: `-`,
  Event_serviceReminderAlertDialogTitle: `Service reminder`,
  Event_serviceReminderAlertDialogBody: `Inspection is approaching.`,
  Event_serviceReminderAlertDialogAction: `Service reminder`,
  Event_serviceReminderAlertDialogAction2: `-`,
  Event_serviceReminderAlertDialogClose: `OK`,
  Event_serviceReminderAlertAlertTitle: `-`,
  Event_serviceReminderAlertAlertBody: `-`,
  Event_serviceReminderAlertLocation: `-`,
  Event_serviceReminderAlertRemoteControl: `-`,
  Event_serviceReminderAlertDashboard: `-`,
  Event_engineProhibitEventName: `エンジン再始動不可の状態`,
  Event_engineProhibitWarningTitle: `-`,
  Event_engineProhibitWarningHint: `-`,
  Event_engineProhibitNotificationTitle: `-`,
  Event_engineProhibitNotificationBody: `-`,
  Event_engineProhibitHome: `Engine Prohibit mode enable`,
  Event_engineProhibitDialogTitle: `-`,
  Event_engineProhibitDialogBody: `-`,
  Event_engineProhibitDialogAction: `-`,
  Event_engineProhibitDialogAction2: `-`,
  Event_engineProhibitDialogClose: `-`,
  Event_engineProhibitAlertTitle: `-`,
  Event_engineProhibitAlertBody: `-`,
  Event_engineProhibitLocation: `-`,
  Event_engineProhibitRemoteControl: `-`,
  Event_engineProhibitDashboard: `-`,
  Event_trackingOnEventName: `追跡モードがONの状態`,
  Event_trackingOnWarningTitle: `-`,
  Event_trackingOnWarningHint: `-`,
  Event_trackingOnNotificationTitle: `-`,
  Event_trackingOnNotificationBody: `-`,
  Event_trackingOnHome: `Tracking mode enable`,
  Event_trackingOnDialogTitle: `-`,
  Event_trackingOnDialogBody: `-`,
  Event_trackingOnDialogAction: `-`,
  Event_trackingOnDialogAction2: `-`,
  Event_trackingOnDialogClose: `-`,
  Event_trackingOnAlertTitle: `-`,
  Event_trackingOnAlertBody: `-`,
  Event_trackingOnLocation: `-`,
  Event_trackingOnRemoteControl: `-`,
  Event_trackingOnDashboard: `-`,
  Event_ukkariDoorEventName: `うっかり通知　ドアロック`,
  Event_ukkariDoorWarningTitle: `-`,
  Event_ukkariDoorWarningHint: `-`,
  Event_ukkariDoorNotificationTitle: `Ukkari Alert`,
  Event_ukkariDoorNotificationBody: `Door Unlock`,
  Event_ukkariDoorHome: `-`,
  Event_ukkariDoorDialogTitle: `Ukkari Alert`,
  Event_ukkariDoorDialogBody: `{model} \n Door Unlock`,
  Event_ukkariDoorDialogAction: `Vehicle status`,
  Event_ukkariDoorDialogAction2: `-`,
  Event_ukkariDoorDialogClose: `OK`,
  Event_ukkariDoorAlertTitle: `-`,
  Event_ukkariDoorAlertBody: `-`,
  Event_ukkariDoorLocation: `-`,
  Event_ukkariDoorRemoteControl: `-`,
  Event_ukkariDoorDashboard: `-`,
  Event_ukkariLightEventName: `うっかり通知　ヘッドライト`,
  Event_ukkariLightWarningTitle: `-`,
  Event_ukkariLightWarningHint: `-`,
  Event_ukkariLightNotificationTitle: `Ukkari Alert`,
  Event_ukkariLightNotificationBody: `Head Light ON`,
  Event_ukkariLightHome: `-`,
  Event_ukkariLightDialogTitle: `Ukkari Alert`,
  Event_ukkariLightDialogBody: `{model} \n Head Light ON`,
  Event_ukkariLightDialogAction: `Vehicle status`,
  Event_ukkariLightDialogAction2: `-`,
  Event_ukkariLightDialogClose: `OK`,
  Event_ukkariLightAlertTitle: `-`,
  Event_ukkariLightAlertBody: `-`,
  Event_ukkariLightLocation: `-`,
  Event_ukkariLightRemoteControl: `-`,
  Event_ukkariLightDashboard: `-`,
  Event_ukkariEngProhibitEventName: `うっかり通知　エンジン再始動不可`,
  Event_ukkariEngProhibitWarningTitle: `-`,
  Event_ukkariEngProhibitWarningHint: `-`,
  Event_ukkariEngProhibitNotificationTitle: `Ukkari Alert`,
  Event_ukkariEngProhibitNotificationBody: `Engine Prohibit mode enable`,
  Event_ukkariEngProhibitHome: `-`,
  Event_ukkariEngProhibitDialogTitle: `Ukkari Alert`,
  Event_ukkariEngProhibitDialogBody: `{model} \n Engine Prohibit mode enable`,
  Event_ukkariEngProhibitDialogAction: `Vehicle status`,
  Event_ukkariEngProhibitDialogAction2: `-`,
  Event_ukkariEngProhibitDialogClose: `OK`,
  Event_ukkariEngProhibitAlertTitle: `-`,
  Event_ukkariEngProhibitAlertBody: `-`,
  Event_ukkariEngProhibitLocation: `-`,
  Event_ukkariEngProhibitRemoteControl: `-`,
  Event_ukkariEngProhibitDashboard: `-`,
  Event_ukkariHazardEventName: `うっかり通知　ハザードランプ`,
  Event_ukkariHazardWarningTitle: `-`,
  Event_ukkariHazardWarningHint: `-`,
  Event_ukkariHazardNotificationTitle: `Ukkari Alert`,
  Event_ukkariHazardNotificationBody: `Hazard ON`,
  Event_ukkariHazardHome: `-`,
  Event_ukkariHazardDialogTitle: `Ukkari Alert`,
  Event_ukkariHazardDialogBody: `{model} \n Hazard ON`,
  Event_ukkariHazardDialogAction: `Vehicle status`,
  Event_ukkariHazardDialogAction2: `-`,
  Event_ukkariHazardDialogClose: `OK`,
  Event_ukkariHazardAlertTitle: `-`,
  Event_ukkariHazardAlertBody: `-`,
  Event_ukkariHazardLocation: `-`,
  Event_ukkariHazardRemoteControl: `-`,
  Event_ukkariHazardDashboard: `-`,
  Event_ukkariAirconEventName: `うっかり通知　エアコン`,
  Event_ukkariAirconWarningTitle: `-`,
  Event_ukkariAirconWarningHint: `-`,
  Event_ukkariAirconNotificationTitle: `Ukkari Alert`,
  Event_ukkariAirconNotificationBody: `A/C ON`,
  Event_ukkariAirconHome: `-`,
  Event_ukkariAirconDialogTitle: `Ukkari Alert`,
  Event_ukkariAirconDialogBody: `{model} \n A/C ON`,
  Event_ukkariAirconDialogAction: `Vehicle status`,
  Event_ukkariAirconDialogAction2: `-`,
  Event_ukkariAirconDialogClose: `OK`,
  Event_ukkariAirconAlertTitle: `-`,
  Event_ukkariAirconAlertBody: `-`,
  Event_ukkariAirconLocation: `-`,
  Event_ukkariAirconRemoteControl: `-`,
  Event_ukkariAirconDashboard: `-`,
  Event_ukkariEngineEventName: `うっかり通知　エンジン`,
  Event_ukkariEngineWarningTitle: `-`,
  Event_ukkariEngineWarningHint: `-`,
  Event_ukkariEngineNotificationTitle: `Ukkari Alert`,
  Event_ukkariEngineNotificationBody: `Engine ON`,
  Event_ukkariEngineHome: `-`,
  Event_ukkariEngineDialogTitle: `Ukkari Alert`,
  Event_ukkariEngineDialogBody: `{model} \n Engine ON`,
  Event_ukkariEngineDialogAction: `Vehicle status`,
  Event_ukkariEngineDialogAction2: `-`,
  Event_ukkariEngineDialogClose: `OK`,
  Event_ukkariEngineAlertTitle: `-`,
  Event_ukkariEngineAlertBody: `-`,
  Event_ukkariEngineLocation: `-`,
  Event_ukkariEngineRemoteControl: `-`,
  Event_ukkariEngineDashboard: `-`,
  Event_ukkariEventName: `うっかり通知`,
  Event_ukkariWarningTitle: `-`,
  Event_ukkariWarningHint: `-`,
  Event_ukkariNotificationTitle: `Ukkari Alert`,
  Event_ukkariNotificationBody: `Detect forgotten operation`,
  Event_ukkariHome: `-`,
  Event_ukkariDialogTitle: `Ukkari Alert`,
  Event_ukkariDialogBody: `{model} \n Detect forgotten operation`,
  Event_ukkariDialogAction: `Vehicle status`,
  Event_ukkariDialogAction2: `-`,
  Event_ukkariDialogClose: `OK`,
  Event_ukkariAlertTitle: `-`,
  Event_ukkariAlertBody: `-`,
  Event_ukkariLocation: `-`,
  Event_ukkariRemoteControl: `-`,
  Event_ukkariDashboard: `-`,
  Event_remoteEngineProhibitOffFailEventName: `エンジン再始動不可の解除に失敗。`,
  Event_remoteEngineProhibitOffFailWarningTitle: `-`,
  Event_remoteEngineProhibitOffFailWarningHint: `-`,
  Event_remoteEngineProhibitOffFailNotificationTitle: `Remote Control`,
  Event_remoteEngineProhibitOffFailNotificationBody: `Turn off Engine Prohibit is fail`,
  Event_remoteEngineProhibitOffFailHome: `-`,
  Event_remoteEngineProhibitOffFailDialogTitle: `Remote Control`,
  Event_remoteEngineProhibitOffFailDialogBody: `{model} \n Turn off Engine Prohibt is fail`,
  Event_remoteEngineProhibitOffFailDialogAction: `Vehicle status`,
  Event_remoteEngineProhibitOffFailDialogAction2: `-`,
  Event_remoteEngineProhibitOffFailDialogClose: `OK`,
  Event_remoteEngineProhibitOffFailAlertTitle: `-`,
  Event_remoteEngineProhibitOffFailAlertBody: `-`,
  Event_remoteEngineProhibitOffFailLocation: `-`,
  Event_remoteEngineProhibitOffFailRemoteControl: `Turn off Engine Prohibit is fail`,
  Event_remoteEngineProhibitOffFailDashboard: `-`,
  Event_remoteEngineProhibitOffSuccessEventName: `エンジン再始動不可を解除する。`,
  Event_remoteEngineProhibitOffSuccessWarningTitle: `-`,
  Event_remoteEngineProhibitOffSuccessWarningHint: `-`,
  Event_remoteEngineProhibitOffSuccessNotificationTitle: `Remote Control`,
  Event_remoteEngineProhibitOffSuccessNotificationBody: `Turn off Engine Prohibit`,
  Event_remoteEngineProhibitOffSuccessHome: `-`,
  Event_remoteEngineProhibitOffSuccessDialogTitle: `-`,
  Event_remoteEngineProhibitOffSuccessDialogBody: `-`,
  Event_remoteEngineProhibitOffSuccessDialogAction: `-`,
  Event_remoteEngineProhibitOffSuccessDialogAction2: `-`,
  Event_remoteEngineProhibitOffSuccessDialogClose: `-`,
  Event_remoteEngineProhibitOffSuccessAlertTitle: `-`,
  Event_remoteEngineProhibitOffSuccessAlertBody: `-`,
  Event_remoteEngineProhibitOffSuccessLocation: `-`,
  Event_remoteEngineProhibitOffSuccessRemoteControl: `Turn off Engine Prohibit`,
  Event_remoteEngineProhibitOffSuccessDashboard: `-`,
  Event_remoteEngineProhibitOnFailEventName: `エンジン再始動不可に失敗`,
  Event_remoteEngineProhibitOnFailWarningTitle: `-`,
  Event_remoteEngineProhibitOnFailWarningHint: `-`,
  Event_remoteEngineProhibitOnFailNotificationTitle: `Remote Control`,
  Event_remoteEngineProhibitOnFailNotificationBody: `Turn on Engine Prohibit is fail`,
  Event_remoteEngineProhibitOnFailHome: `-`,
  Event_remoteEngineProhibitOnFailDialogTitle: `Remote Control`,
  Event_remoteEngineProhibitOnFailDialogBody: `{model} \n Turn on Engine Prohibt is fail`,
  Event_remoteEngineProhibitOnFailDialogAction: `Vehicle status`,
  Event_remoteEngineProhibitOnFailDialogAction2: `-`,
  Event_remoteEngineProhibitOnFailDialogClose: `OK`,
  Event_remoteEngineProhibitOnFailAlertTitle: `-`,
  Event_remoteEngineProhibitOnFailAlertBody: `-`,
  Event_remoteEngineProhibitOnFailLocation: `-`,
  Event_remoteEngineProhibitOnFailRemoteControl: `Turn on Engine Prohibit is fail`,
  Event_remoteEngineProhibitOnFailDashboard: `-`,
  Event_remoteEngineProhibitOnSuccessEventName: `エンジン再始動不可にする。`,
  Event_remoteEngineProhibitOnSuccessWarningTitle: `-`,
  Event_remoteEngineProhibitOnSuccessWarningHint: `-`,
  Event_remoteEngineProhibitOnSuccessNotificationTitle: `Remote Control`,
  Event_remoteEngineProhibitOnSuccessNotificationBody: `Turn on Engine Prohibit`,
  Event_remoteEngineProhibitOnSuccessHome: `-`,
  Event_remoteEngineProhibitOnSuccessDialogTitle: `-`,
  Event_remoteEngineProhibitOnSuccessDialogBody: `-`,
  Event_remoteEngineProhibitOnSuccessDialogAction: `-`,
  Event_remoteEngineProhibitOnSuccessDialogAction2: `-`,
  Event_remoteEngineProhibitOnSuccessDialogClose: `-`,
  Event_remoteEngineProhibitOnSuccessAlertTitle: `-`,
  Event_remoteEngineProhibitOnSuccessAlertBody: `-`,
  Event_remoteEngineProhibitOnSuccessLocation: `-`,
  Event_remoteEngineProhibitOnSuccessRemoteControl: `Turn on Engine Prohibit`,
  Event_remoteEngineProhibitOnSuccessDashboard: `-`,
  Event_remoteWifiResetFailEventName: `Wi-Fi設定のリセットに失敗。`,
  Event_remoteWifiResetFailWarningTitle: `-`,
  Event_remoteWifiResetFailWarningHint: `-`,
  Event_remoteWifiResetFailNotificationTitle: `Remote Control`,
  Event_remoteWifiResetFailNotificationBody: `Wi-Fi Reset is fail`,
  Event_remoteWifiResetFailHome: `-`,
  Event_remoteWifiResetFailDialogTitle: `Remote Control`,
  Event_remoteWifiResetFailDialogBody: `{model} \nWi-Fi Reset is fail`,
  Event_remoteWifiResetFailDialogAction: `Wi-Fi Monitor`,
  Event_remoteWifiResetFailDialogAction2: `-`,
  Event_remoteWifiResetFailDialogClose: `OK`,
  Event_remoteWifiResetFailAlertTitle: `-`,
  Event_remoteWifiResetFailAlertBody: `-`,
  Event_remoteWifiResetFailLocation: `-`,
  Event_remoteWifiResetFailRemoteControl: `Wi-Fi Reset is fail`,
  Event_remoteWifiResetFailDashboard: `-`,
  Event_remoteWifiResetSuccessEventName: `Wi-Fi設定をリセットする。`,
  Event_remoteWifiResetSuccessWarningTitle: `-`,
  Event_remoteWifiResetSuccessWarningHint: `-`,
  Event_remoteWifiResetSuccessNotificationTitle: `-`,
  Event_remoteWifiResetSuccessNotificationBody: `-`,
  Event_remoteWifiResetSuccessHome: `-`,
  Event_remoteWifiResetSuccessDialogTitle: `-`,
  Event_remoteWifiResetSuccessDialogBody: `-`,
  Event_remoteWifiResetSuccessDialogAction: `-`,
  Event_remoteWifiResetSuccessDialogAction2: `-`,
  Event_remoteWifiResetSuccessDialogClose: `-`,
  Event_remoteWifiResetSuccessAlertTitle: `-`,
  Event_remoteWifiResetSuccessAlertBody: `-`,
  Event_remoteWifiResetSuccessLocation: `-`,
  Event_remoteWifiResetSuccessRemoteControl: `-`,
  Event_remoteWifiResetSuccessDashboard: `-`,
  Event_remoteWifiOffFailEventName: `Wi-Fi設定をOFFに失敗。`,
  Event_remoteWifiOffFailWarningTitle: `-`,
  Event_remoteWifiOffFailWarningHint: `-`,
  Event_remoteWifiOffFailNotificationTitle: `Remote Control`,
  Event_remoteWifiOffFailNotificationBody: `Wi-Fi disable is fail`,
  Event_remoteWifiOffFailHome: `-`,
  Event_remoteWifiOffFailDialogTitle: `Remote Control`,
  Event_remoteWifiOffFailDialogBody: `{model} \nWi-Fi disable is fail`,
  Event_remoteWifiOffFailDialogAction: `Wi-Fi Monitor`,
  Event_remoteWifiOffFailDialogAction2: `-`,
  Event_remoteWifiOffFailDialogClose: `OK`,
  Event_remoteWifiOffFailAlertTitle: `-`,
  Event_remoteWifiOffFailAlertBody: `-`,
  Event_remoteWifiOffFailLocation: `-`,
  Event_remoteWifiOffFailRemoteControl: `Wi-Fi disable is fail`,
  Event_remoteWifiOffFailDashboard: `-`,
  Event_remoteWifiOffSuccessEventName: `Wi-Fi設定をOFFにする。`,
  Event_remoteWifiOffSuccessWarningTitle: `-`,
  Event_remoteWifiOffSuccessWarningHint: `-`,
  Event_remoteWifiOffSuccessNotificationTitle: `-`,
  Event_remoteWifiOffSuccessNotificationBody: `-`,
  Event_remoteWifiOffSuccessHome: `-`,
  Event_remoteWifiOffSuccessDialogTitle: `-`,
  Event_remoteWifiOffSuccessDialogBody: `-`,
  Event_remoteWifiOffSuccessDialogAction: `-`,
  Event_remoteWifiOffSuccessDialogAction2: `-`,
  Event_remoteWifiOffSuccessDialogClose: `-`,
  Event_remoteWifiOffSuccessAlertTitle: `-`,
  Event_remoteWifiOffSuccessAlertBody: `-`,
  Event_remoteWifiOffSuccessLocation: `-`,
  Event_remoteWifiOffSuccessRemoteControl: `Wi-Fi disabled`,
  Event_remoteWifiOffSuccessDashboard: `-`,
  Event_remoteWifiOnFailEventName: `Wi-Fi設定をONに失敗。`,
  Event_remoteWifiOnFailWarningTitle: `-`,
  Event_remoteWifiOnFailWarningHint: `-`,
  Event_remoteWifiOnFailNotificationTitle: `Remote Control`,
  Event_remoteWifiOnFailNotificationBody: `Wi-Fi enable is fail`,
  Event_remoteWifiOnFailHome: `-`,
  Event_remoteWifiOnFailDialogTitle: `Remote Control`,
  Event_remoteWifiOnFailDialogBody: `{model} \nWi-Fi enable is fail`,
  Event_remoteWifiOnFailDialogAction: `Wi-Fi Monitor`,
  Event_remoteWifiOnFailDialogAction2: `-`,
  Event_remoteWifiOnFailDialogClose: `OK`,
  Event_remoteWifiOnFailAlertTitle: `-`,
  Event_remoteWifiOnFailAlertBody: `-`,
  Event_remoteWifiOnFailLocation: `-`,
  Event_remoteWifiOnFailRemoteControl: `Wi-Fi enable is fail`,
  Event_remoteWifiOnFailDashboard: `-`,
  Event_remoteWifiOnSuccessEventName: `Wi-Fi設定をONにする。`,
  Event_remoteWifiOnSuccessWarningTitle: `-`,
  Event_remoteWifiOnSuccessWarningHint: `-`,
  Event_remoteWifiOnSuccessNotificationTitle: `-`,
  Event_remoteWifiOnSuccessNotificationBody: `-`,
  Event_remoteWifiOnSuccessHome: `-`,
  Event_remoteWifiOnSuccessDialogTitle: `-`,
  Event_remoteWifiOnSuccessDialogBody: `-`,
  Event_remoteWifiOnSuccessDialogAction: `-`,
  Event_remoteWifiOnSuccessDialogAction2: `-`,
  Event_remoteWifiOnSuccessDialogClose: `-`,
  Event_remoteWifiOnSuccessAlertTitle: `-`,
  Event_remoteWifiOnSuccessAlertBody: `-`,
  Event_remoteWifiOnSuccessLocation: `-`,
  Event_remoteWifiOnSuccessRemoteControl: `Wi-Fi enabled`,
  Event_remoteWifiOnSuccessDashboard: `-`,
  Event_remoteGeofenceOffFailEventName: `ジオフェンスを解除に失敗。`,
  Event_remoteGeofenceOffFailWarningTitle: `-`,
  Event_remoteGeofenceOffFailWarningHint: `-`,
  Event_remoteGeofenceOffFailNotificationTitle: `Remote Control`,
  Event_remoteGeofenceOffFailNotificationBody: `Geofence disable is fail`,
  Event_remoteGeofenceOffFailHome: `-`,
  Event_remoteGeofenceOffFailDialogTitle: `Remote Control`,
  Event_remoteGeofenceOffFailDialogBody: `{model} \nGeofence disable is fail`,
  Event_remoteGeofenceOffFailDialogAction: `Vehicle location`,
  Event_remoteGeofenceOffFailDialogAction2: `-`,
  Event_remoteGeofenceOffFailDialogClose: `OK`,
  Event_remoteGeofenceOffFailAlertTitle: `-`,
  Event_remoteGeofenceOffFailAlertBody: `-`,
  Event_remoteGeofenceOffFailLocation: `-`,
  Event_remoteGeofenceOffFailRemoteControl: `Geofence disable is fail`,
  Event_remoteGeofenceOffFailDashboard: `-`,
  Event_remoteGeofenceOffSuccessEventName: `ジオフェンスを解除する。`,
  Event_remoteGeofenceOffSuccessWarningTitle: `-`,
  Event_remoteGeofenceOffSuccessWarningHint: `-`,
  Event_remoteGeofenceOffSuccessNotificationTitle: `-`,
  Event_remoteGeofenceOffSuccessNotificationBody: `-`,
  Event_remoteGeofenceOffSuccessHome: `-`,
  Event_remoteGeofenceOffSuccessDialogTitle: `-`,
  Event_remoteGeofenceOffSuccessDialogBody: `-`,
  Event_remoteGeofenceOffSuccessDialogAction: `-`,
  Event_remoteGeofenceOffSuccessDialogAction2: `-`,
  Event_remoteGeofenceOffSuccessDialogClose: `-`,
  Event_remoteGeofenceOffSuccessAlertTitle: `-`,
  Event_remoteGeofenceOffSuccessAlertBody: `-`,
  Event_remoteGeofenceOffSuccessLocation: `-`,
  Event_remoteGeofenceOffSuccessRemoteControl: `-`,
  Event_remoteGeofenceOffSuccessDashboard: `-`,
  Event_remoteGeofenceChangeFailEventName: `ジオフェンスの設定を変更に失敗。`,
  Event_remoteGeofenceChangeFailWarningTitle: `-`,
  Event_remoteGeofenceChangeFailWarningHint: `-`,
  Event_remoteGeofenceChangeFailNotificationTitle: `Remote Control`,
  Event_remoteGeofenceChangeFailNotificationBody: `Geofence setting change is fail`,
  Event_remoteGeofenceChangeFailHome: `-`,
  Event_remoteGeofenceChangeFailDialogTitle: `Remote Control`,
  Event_remoteGeofenceChangeFailDialogBody: `{model} \nGeofence setting change is fail`,
  Event_remoteGeofenceChangeFailDialogAction: `Vehicle location`,
  Event_remoteGeofenceChangeFailDialogAction2: `-`,
  Event_remoteGeofenceChangeFailDialogClose: `OK`,
  Event_remoteGeofenceChangeFailAlertTitle: `-`,
  Event_remoteGeofenceChangeFailAlertBody: `-`,
  Event_remoteGeofenceChangeFailLocation: `-`,
  Event_remoteGeofenceChangeFailRemoteControl: `Geofence setting change is fail`,
  Event_remoteGeofenceChangeFailDashboard: `-`,
  Event_remoteGeofenceChangeSuccessEventName: `ジオフェンスの設定を変更する。`,
  Event_remoteGeofenceChangeSuccessWarningTitle: `-`,
  Event_remoteGeofenceChangeSuccessWarningHint: `-`,
  Event_remoteGeofenceChangeSuccessNotificationTitle: `-`,
  Event_remoteGeofenceChangeSuccessNotificationBody: `-`,
  Event_remoteGeofenceChangeSuccessHome: `-`,
  Event_remoteGeofenceChangeSuccessDialogTitle: `-`,
  Event_remoteGeofenceChangeSuccessDialogBody: `-`,
  Event_remoteGeofenceChangeSuccessDialogAction: `-`,
  Event_remoteGeofenceChangeSuccessDialogAction2: `-`,
  Event_remoteGeofenceChangeSuccessDialogClose: `-`,
  Event_remoteGeofenceChangeSuccessAlertTitle: `-`,
  Event_remoteGeofenceChangeSuccessAlertBody: `-`,
  Event_remoteGeofenceChangeSuccessLocation: `-`,
  Event_remoteGeofenceChangeSuccessRemoteControl: `-`,
  Event_remoteGeofenceChangeSuccessDashboard: `-`,
  Event_remoteGeofenceOnFailEventName: `ジオフェンスを設定に失敗`,
  Event_remoteGeofenceOnFailWarningTitle: `-`,
  Event_remoteGeofenceOnFailWarningHint: `-`,
  Event_remoteGeofenceOnFailNotificationTitle: `Remote Control`,
  Event_remoteGeofenceOnFailNotificationBody: `Geofence enable is fail`,
  Event_remoteGeofenceOnFailHome: `-`,
  Event_remoteGeofenceOnFailDialogTitle: `Remote Control`,
  Event_remoteGeofenceOnFailDialogBody: `{model} \nGeofence enable is fail`,
  Event_remoteGeofenceOnFailDialogAction: `Vehicle location`,
  Event_remoteGeofenceOnFailDialogAction2: `-`,
  Event_remoteGeofenceOnFailDialogClose: `OK`,
  Event_remoteGeofenceOnFailAlertTitle: `-`,
  Event_remoteGeofenceOnFailAlertBody: `-`,
  Event_remoteGeofenceOnFailLocation: `-`,
  Event_remoteGeofenceOnFailRemoteControl: `Geofence enable is fail`,
  Event_remoteGeofenceOnFailDashboard: `-`,
  Event_remoteGeofenceOnSuccessEventName: `ジオフェンスを設定する。`,
  Event_remoteGeofenceOnSuccessWarningTitle: `-`,
  Event_remoteGeofenceOnSuccessWarningHint: `-`,
  Event_remoteGeofenceOnSuccessNotificationTitle: `-`,
  Event_remoteGeofenceOnSuccessNotificationBody: `-`,
  Event_remoteGeofenceOnSuccessHome: `-`,
  Event_remoteGeofenceOnSuccessDialogTitle: `-`,
  Event_remoteGeofenceOnSuccessDialogBody: `-`,
  Event_remoteGeofenceOnSuccessDialogAction: `-`,
  Event_remoteGeofenceOnSuccessDialogAction2: `-`,
  Event_remoteGeofenceOnSuccessDialogClose: `-`,
  Event_remoteGeofenceOnSuccessAlertTitle: `-`,
  Event_remoteGeofenceOnSuccessAlertBody: `-`,
  Event_remoteGeofenceOnSuccessLocation: `-`,
  Event_remoteGeofenceOnSuccessRemoteControl: `-`,
  Event_remoteGeofenceOnSuccessDashboard: `-`,
  Event_remoteTrackingOffFailEventName: `追跡モードオフ失敗`,
  Event_remoteTrackingOffFailWarningTitle: `-`,
  Event_remoteTrackingOffFailWarningHint: `-`,
  Event_remoteTrackingOffFailNotificationTitle: `Remote Control`,
  Event_remoteTrackingOffFailNotificationBody: `Disable tracking mode is fail`,
  Event_remoteTrackingOffFailHome: `-`,
  Event_remoteTrackingOffFailDialogTitle: `Remote Control`,
  Event_remoteTrackingOffFailDialogBody: `{model} \nDisable tracking mode is fail`,
  Event_remoteTrackingOffFailDialogAction: `Vehicle location`,
  Event_remoteTrackingOffFailDialogAction2: `-`,
  Event_remoteTrackingOffFailDialogClose: `OK`,
  Event_remoteTrackingOffFailAlertTitle: `-`,
  Event_remoteTrackingOffFailAlertBody: `-`,
  Event_remoteTrackingOffFailLocation: `-`,
  Event_remoteTrackingOffFailRemoteControl: `Disable tracking mode is fail`,
  Event_remoteTrackingOffFailDashboard: `-`,
  Event_remoteTrackingOffSuccessEventName: `追跡モードオフ`,
  Event_remoteTrackingOffSuccessWarningTitle: `-`,
  Event_remoteTrackingOffSuccessWarningHint: `-`,
  Event_remoteTrackingOffSuccessNotificationTitle: `Remote Control`,
  Event_remoteTrackingOffSuccessNotificationBody: `Disable tracking mode`,
  Event_remoteTrackingOffSuccessHome: `-`,
  Event_remoteTrackingOffSuccessDialogTitle: `-`,
  Event_remoteTrackingOffSuccessDialogBody: `-`,
  Event_remoteTrackingOffSuccessDialogAction: `-`,
  Event_remoteTrackingOffSuccessDialogAction2: `-`,
  Event_remoteTrackingOffSuccessDialogClose: `-`,
  Event_remoteTrackingOffSuccessAlertTitle: `-`,
  Event_remoteTrackingOffSuccessAlertBody: `-`,
  Event_remoteTrackingOffSuccessLocation: `-`,
  Event_remoteTrackingOffSuccessRemoteControl: `Disable tracking mode`,
  Event_remoteTrackingOffSuccessDashboard: `-`,
  Event_remoteTrackingOnFailEventName: `追跡モードオン失敗`,
  Event_remoteTrackingOnFailWarningTitle: `-`,
  Event_remoteTrackingOnFailWarningHint: `-`,
  Event_remoteTrackingOnFailNotificationTitle: `Remote Control`,
  Event_remoteTrackingOnFailNotificationBody: `Tracking mode enabled is fail`,
  Event_remoteTrackingOnFailHome: `-`,
  Event_remoteTrackingOnFailDialogTitle: `Remote Control`,
  Event_remoteTrackingOnFailDialogBody: `{model} \nTracking mode enabled is fail`,
  Event_remoteTrackingOnFailDialogAction: `Vehicle location`,
  Event_remoteTrackingOnFailDialogAction2: `-`,
  Event_remoteTrackingOnFailDialogClose: `OK`,
  Event_remoteTrackingOnFailAlertTitle: `-`,
  Event_remoteTrackingOnFailAlertBody: `-`,
  Event_remoteTrackingOnFailLocation: `-`,
  Event_remoteTrackingOnFailRemoteControl: `Tracking mode enabled is fail`,
  Event_remoteTrackingOnFailDashboard: `-`,
  Event_remoteTrackingOnSuccessEventName: `追跡モードオン`,
  Event_remoteTrackingOnSuccessWarningTitle: `-`,
  Event_remoteTrackingOnSuccessWarningHint: `-`,
  Event_remoteTrackingOnSuccessNotificationTitle: `Remote Control`,
  Event_remoteTrackingOnSuccessNotificationBody: `Tracking mode enabled`,
  Event_remoteTrackingOnSuccessHome: `-`,
  Event_remoteTrackingOnSuccessDialogTitle: `-`,
  Event_remoteTrackingOnSuccessDialogBody: `-`,
  Event_remoteTrackingOnSuccessDialogAction: `-`,
  Event_remoteTrackingOnSuccessDialogAction2: `-`,
  Event_remoteTrackingOnSuccessDialogClose: `-`,
  Event_remoteTrackingOnSuccessAlertTitle: `-`,
  Event_remoteTrackingOnSuccessAlertBody: `-`,
  Event_remoteTrackingOnSuccessLocation: `-`,
  Event_remoteTrackingOnSuccessRemoteControl: `Tracking mode enabled`,
  Event_remoteTrackingOnSuccessDashboard: `-`,
  Event_remoteEngineOffFailEventName: `エンジンオフ失敗`,
  Event_remoteEngineOffFailWarningTitle: `-`,
  Event_remoteEngineOffFailWarningHint: `-`,
  Event_remoteEngineOffFailNotificationTitle: `Remote Control`,
  Event_remoteEngineOffFailNotificationBody: `Turn off Engine is fail`,
  Event_remoteEngineOffFailHome: `-`,
  Event_remoteEngineOffFailDialogTitle: `Remote Control`,
  Event_remoteEngineOffFailDialogBody: `{model} \nTurn off Engine is fail`,
  Event_remoteEngineOffFailDialogAction: `Vehicle status`,
  Event_remoteEngineOffFailDialogAction2: `-`,
  Event_remoteEngineOffFailDialogClose: `OK`,
  Event_remoteEngineOffFailAlertTitle: `-`,
  Event_remoteEngineOffFailAlertBody: `-`,
  Event_remoteEngineOffFailLocation: `-`,
  Event_remoteEngineOffFailRemoteControl: `Turn off Engine is fail`,
  Event_remoteEngineOffFailDashboard: `-`,
  Event_remoteEngineOffSuccessEventName: `エンジンオフ`,
  Event_remoteEngineOffSuccessWarningTitle: `-`,
  Event_remoteEngineOffSuccessWarningHint: `-`,
  Event_remoteEngineOffSuccessNotificationTitle: `Remote Control`,
  Event_remoteEngineOffSuccessNotificationBody: `Turn off Engine`,
  Event_remoteEngineOffSuccessHome: `-`,
  Event_remoteEngineOffSuccessDialogTitle: `-`,
  Event_remoteEngineOffSuccessDialogBody: `-`,
  Event_remoteEngineOffSuccessDialogAction: `-`,
  Event_remoteEngineOffSuccessDialogAction2: `-`,
  Event_remoteEngineOffSuccessDialogClose: `-`,
  Event_remoteEngineOffSuccessAlertTitle: `-`,
  Event_remoteEngineOffSuccessAlertBody: `-`,
  Event_remoteEngineOffSuccessLocation: `-`,
  Event_remoteEngineOffSuccessRemoteControl: `Turn off Engine`,
  Event_remoteEngineOffSuccessDashboard: `-`,
  Event_remoteEngineOnFailEventName: `エンジンオン失敗`,
  Event_remoteEngineOnFailWarningTitle: `-`,
  Event_remoteEngineOnFailWarningHint: `-`,
  Event_remoteEngineOnFailNotificationTitle: `Remote Control`,
  Event_remoteEngineOnFailNotificationBody: `Turn on Engine is fail`,
  Event_remoteEngineOnFailHome: `-`,
  Event_remoteEngineOnFailDialogTitle: `Remote Control`,
  Event_remoteEngineOnFailDialogBody: `{model} \nTurn on Engine is fail`,
  Event_remoteEngineOnFailDialogAction: `Vehicle status`,
  Event_remoteEngineOnFailDialogAction2: `-`,
  Event_remoteEngineOnFailDialogClose: `OK`,
  Event_remoteEngineOnFailAlertTitle: `-`,
  Event_remoteEngineOnFailAlertBody: `-`,
  Event_remoteEngineOnFailLocation: `-`,
  Event_remoteEngineOnFailRemoteControl: `Turn on Engine is fail`,
  Event_remoteEngineOnFailDashboard: `-`,
  Event_remoteEngineOnSuccessEventName: `エンジンオン`,
  Event_remoteEngineOnSuccessWarningTitle: `-`,
  Event_remoteEngineOnSuccessWarningHint: `-`,
  Event_remoteEngineOnSuccessNotificationTitle: `Remote Control`,
  Event_remoteEngineOnSuccessNotificationBody: `Turn on Engine`,
  Event_remoteEngineOnSuccessHome: `-`,
  Event_remoteEngineOnSuccessDialogTitle: `-`,
  Event_remoteEngineOnSuccessDialogBody: `-`,
  Event_remoteEngineOnSuccessDialogAction: `-`,
  Event_remoteEngineOnSuccessDialogAction2: `-`,
  Event_remoteEngineOnSuccessDialogClose: `-`,
  Event_remoteEngineOnSuccessAlertTitle: `-`,
  Event_remoteEngineOnSuccessAlertBody: `-`,
  Event_remoteEngineOnSuccessLocation: `-`,
  Event_remoteEngineOnSuccessRemoteControl: `Turn on Engine`,
  Event_remoteEngineOnSuccessDashboard: `-`,
  Event_remoteACOffFailEventName: `エアコンオフ失敗`,
  Event_remoteACOffFailWarningTitle: `-`,
  Event_remoteACOffFailWarningHint: `-`,
  Event_remoteACOffFailNotificationTitle: `Remote Control`,
  Event_remoteACOffFailNotificationBody: `Turn off A/C is fail`,
  Event_remoteACOffFailHome: `-`,
  Event_remoteACOffFailDialogTitle: `Remote Control`,
  Event_remoteACOffFailDialogBody: `{model} \nTurn off A/C is fail`,
  Event_remoteACOffFailDialogAction: `Vehicle status`,
  Event_remoteACOffFailDialogAction2: `-`,
  Event_remoteACOffFailDialogClose: `OK`,
  Event_remoteACOffFailAlertTitle: `-`,
  Event_remoteACOffFailAlertBody: `-`,
  Event_remoteACOffFailLocation: `-`,
  Event_remoteACOffFailRemoteControl: `Turn off A/C is fail`,
  Event_remoteACOffFailDashboard: `-`,
  Event_remoteACOffSuccessEventName: `エアコンオフ`,
  Event_remoteACOffSuccessWarningTitle: `-`,
  Event_remoteACOffSuccessWarningHint: `-`,
  Event_remoteACOffSuccessNotificationTitle: `Remote Control`,
  Event_remoteACOffSuccessNotificationBody: `Turn off A/C`,
  Event_remoteACOffSuccessHome: `-`,
  Event_remoteACOffSuccessDialogTitle: `-`,
  Event_remoteACOffSuccessDialogBody: `-`,
  Event_remoteACOffSuccessDialogAction: `-`,
  Event_remoteACOffSuccessDialogAction2: `-`,
  Event_remoteACOffSuccessDialogClose: `-`,
  Event_remoteACOffSuccessAlertTitle: `-`,
  Event_remoteACOffSuccessAlertBody: `-`,
  Event_remoteACOffSuccessLocation: `-`,
  Event_remoteACOffSuccessRemoteControl: `Turn off A/C`,
  Event_remoteACOffSuccessDashboard: `-`,
  Event_remoteACOnFailEventName: `エアコンオン失敗`,
  Event_remoteACOnFailWarningTitle: `-`,
  Event_remoteACOnFailWarningHint: `-`,
  Event_remoteACOnFailNotificationTitle: `Remote Control`,
  Event_remoteACOnFailNotificationBody: `Turn on A/C is fail`,
  Event_remoteACOnFailHome: `-`,
  Event_remoteACOnFailDialogTitle: `Remote Control`,
  Event_remoteACOnFailDialogBody: `{model} \nTurn on A/C is fail`,
  Event_remoteACOnFailDialogAction: `Vehicle status`,
  Event_remoteACOnFailDialogAction2: `-`,
  Event_remoteACOnFailDialogClose: `OK`,
  Event_remoteACOnFailAlertTitle: `-`,
  Event_remoteACOnFailAlertBody: `-`,
  Event_remoteACOnFailLocation: `-`,
  Event_remoteACOnFailRemoteControl: `Turn on A/C is fail`,
  Event_remoteACOnFailDashboard: `-`,
  Event_remoteACOnSuccessEventName: `エアコンオン`,
  Event_remoteACOnSuccessWarningTitle: `-`,
  Event_remoteACOnSuccessWarningHint: `-`,
  Event_remoteACOnSuccessNotificationTitle: `Remote Control`,
  Event_remoteACOnSuccessNotificationBody: `Turn on A/C`,
  Event_remoteACOnSuccessHome: `-`,
  Event_remoteACOnSuccessDialogTitle: `-`,
  Event_remoteACOnSuccessDialogBody: `-`,
  Event_remoteACOnSuccessDialogAction: `-`,
  Event_remoteACOnSuccessDialogAction2: `-`,
  Event_remoteACOnSuccessDialogClose: `-`,
  Event_remoteACOnSuccessAlertTitle: `-`,
  Event_remoteACOnSuccessAlertBody: `-`,
  Event_remoteACOnSuccessLocation: `-`,
  Event_remoteACOnSuccessRemoteControl: `Turn on A/C`,
  Event_remoteACOnSuccessDashboard: `-`,
  Event_remoteHazardOffFailEventName: `ハザードランプ消灯失敗`,
  Event_remoteHazardOffFailWarningTitle: `-`,
  Event_remoteHazardOffFailWarningHint: `-`,
  Event_remoteHazardOffFailNotificationTitle: `Remote Control`,
  Event_remoteHazardOffFailNotificationBody: `Turn off Hazard Lights is fail`,
  Event_remoteHazardOffFailHome: `-`,
  Event_remoteHazardOffFailDialogTitle: `Remote Control`,
  Event_remoteHazardOffFailDialogBody: `{model} \nTurn off Hazard Lights is fail`,
  Event_remoteHazardOffFailDialogAction: `Vehicle status`,
  Event_remoteHazardOffFailDialogAction2: `-`,
  Event_remoteHazardOffFailDialogClose: `OK`,
  Event_remoteHazardOffFailAlertTitle: `-`,
  Event_remoteHazardOffFailAlertBody: `-`,
  Event_remoteHazardOffFailLocation: `-`,
  Event_remoteHazardOffFailRemoteControl: `Turn off Hazard Lights is fail`,
  Event_remoteHazardOffFailDashboard: `-`,
  Event_remoteHazardOffSuccessEventName: `ハザードランプ消灯`,
  Event_remoteHazardOffSuccessWarningTitle: `-`,
  Event_remoteHazardOffSuccessWarningHint: `-`,
  Event_remoteHazardOffSuccessNotificationTitle: `Remote Control`,
  Event_remoteHazardOffSuccessNotificationBody: `Turn off Hazard Lights`,
  Event_remoteHazardOffSuccessHome: `-`,
  Event_remoteHazardOffSuccessDialogTitle: `-`,
  Event_remoteHazardOffSuccessDialogBody: `-`,
  Event_remoteHazardOffSuccessDialogAction: `-`,
  Event_remoteHazardOffSuccessDialogAction2: `-`,
  Event_remoteHazardOffSuccessDialogClose: `-`,
  Event_remoteHazardOffSuccessAlertTitle: `-`,
  Event_remoteHazardOffSuccessAlertBody: `-`,
  Event_remoteHazardOffSuccessLocation: `-`,
  Event_remoteHazardOffSuccessRemoteControl: `Turn off Hazard Lights`,
  Event_remoteHazardOffSuccessDashboard: `-`,
  Event_remoteHazardOnFailEventName: `ハザードランプ点灯失敗`,
  Event_remoteHazardOnFailWarningTitle: `-`,
  Event_remoteHazardOnFailWarningHint: `-`,
  Event_remoteHazardOnFailNotificationTitle: `Remote Control`,
  Event_remoteHazardOnFailNotificationBody: `Turn on Hazard Lights is fail`,
  Event_remoteHazardOnFailHome: `-`,
  Event_remoteHazardOnFailDialogTitle: `Remote Control`,
  Event_remoteHazardOnFailDialogBody: `{model} \nTurn on Hazard Lights is fail`,
  Event_remoteHazardOnFailDialogAction: `Vehicle status`,
  Event_remoteHazardOnFailDialogAction2: `-`,
  Event_remoteHazardOnFailDialogClose: `OK`,
  Event_remoteHazardOnFailAlertTitle: `-`,
  Event_remoteHazardOnFailAlertBody: `-`,
  Event_remoteHazardOnFailLocation: `-`,
  Event_remoteHazardOnFailRemoteControl: `Turn on Hazard Lights is fail`,
  Event_remoteHazardOnFailDashboard: `-`,
  Event_remoteHazardOnSuccessEventName: `ハザードランプ点灯`,
  Event_remoteHazardOnSuccessWarningTitle: `-`,
  Event_remoteHazardOnSuccessWarningHint: `-`,
  Event_remoteHazardOnSuccessNotificationTitle: `Remote Control`,
  Event_remoteHazardOnSuccessNotificationBody: `Turn on Hazard Lights`,
  Event_remoteHazardOnSuccessHome: `-`,
  Event_remoteHazardOnSuccessDialogTitle: `-`,
  Event_remoteHazardOnSuccessDialogBody: `-`,
  Event_remoteHazardOnSuccessDialogAction: `-`,
  Event_remoteHazardOnSuccessDialogAction2: `-`,
  Event_remoteHazardOnSuccessDialogClose: `-`,
  Event_remoteHazardOnSuccessAlertTitle: `-`,
  Event_remoteHazardOnSuccessAlertBody: `-`,
  Event_remoteHazardOnSuccessLocation: `-`,
  Event_remoteHazardOnSuccessRemoteControl: `Turn on Hazard Lights`,
  Event_remoteHazardOnSuccessDashboard: `-`,
  Event_remoteHeadLightOffFailEventName: `ヘッドライト消灯失敗`,
  Event_remoteHeadLightOffFailWarningTitle: `-`,
  Event_remoteHeadLightOffFailWarningHint: `-`,
  Event_remoteHeadLightOffFailNotificationTitle: `Remote Control`,
  Event_remoteHeadLightOffFailNotificationBody: `Turn off Head Lights is fail`,
  Event_remoteHeadLightOffFailHome: `-`,
  Event_remoteHeadLightOffFailDialogTitle: `Remote Control`,
  Event_remoteHeadLightOffFailDialogBody: `{model} \nTurn off Head Lights is fail`,
  Event_remoteHeadLightOffFailDialogAction: `Vehicle status`,
  Event_remoteHeadLightOffFailDialogAction2: `-`,
  Event_remoteHeadLightOffFailDialogClose: `OK`,
  Event_remoteHeadLightOffFailAlertTitle: `-`,
  Event_remoteHeadLightOffFailAlertBody: `-`,
  Event_remoteHeadLightOffFailLocation: `-`,
  Event_remoteHeadLightOffFailRemoteControl: `Turn off Head Lights is fail`,
  Event_remoteHeadLightOffFailDashboard: `-`,
  Event_remoteHeadLightOffSuccessEventName: `ヘッドライト消灯`,
  Event_remoteHeadLightOffSuccessWarningTitle: `-`,
  Event_remoteHeadLightOffSuccessWarningHint: `-`,
  Event_remoteHeadLightOffSuccessNotificationTitle: `Remote Control`,
  Event_remoteHeadLightOffSuccessNotificationBody: `Turn off Head Lights`,
  Event_remoteHeadLightOffSuccessHome: `-`,
  Event_remoteHeadLightOffSuccessDialogTitle: `-`,
  Event_remoteHeadLightOffSuccessDialogBody: `-`,
  Event_remoteHeadLightOffSuccessDialogAction: `-`,
  Event_remoteHeadLightOffSuccessDialogAction2: `-`,
  Event_remoteHeadLightOffSuccessDialogClose: `-`,
  Event_remoteHeadLightOffSuccessAlertTitle: `-`,
  Event_remoteHeadLightOffSuccessAlertBody: `-`,
  Event_remoteHeadLightOffSuccessLocation: `-`,
  Event_remoteHeadLightOffSuccessRemoteControl: `Turn off Head Lights`,
  Event_remoteHeadLightOffSuccessDashboard: `-`,
  Event_remoteHeadLightOnFailEventName: `ヘッドライト点灯失敗`,
  Event_remoteHeadLightOnFailWarningTitle: `-`,
  Event_remoteHeadLightOnFailWarningHint: `-`,
  Event_remoteHeadLightOnFailNotificationTitle: `Remote Control`,
  Event_remoteHeadLightOnFailNotificationBody: `Turn on Head Lights is fail`,
  Event_remoteHeadLightOnFailHome: `-`,
  Event_remoteHeadLightOnFailDialogTitle: `Remote Control`,
  Event_remoteHeadLightOnFailDialogBody: `{model} \nTurn on Head Lights is fail`,
  Event_remoteHeadLightOnFailDialogAction: `Vehicle status`,
  Event_remoteHeadLightOnFailDialogAction2: `-`,
  Event_remoteHeadLightOnFailDialogClose: `OK`,
  Event_remoteHeadLightOnFailAlertTitle: `-`,
  Event_remoteHeadLightOnFailAlertBody: `-`,
  Event_remoteHeadLightOnFailLocation: `-`,
  Event_remoteHeadLightOnFailRemoteControl: `Turn on Head Lights is fail`,
  Event_remoteHeadLightOnFailDashboard: `-`,
  Event_remoteHeadLightOnSuccessEventName: `ヘッドライト点灯`,
  Event_remoteHeadLightOnSuccessWarningTitle: `-`,
  Event_remoteHeadLightOnSuccessWarningHint: `-`,
  Event_remoteHeadLightOnSuccessNotificationTitle: `Remote Control`,
  Event_remoteHeadLightOnSuccessNotificationBody: `Turn on Head Lights`,
  Event_remoteHeadLightOnSuccessHome: `-`,
  Event_remoteHeadLightOnSuccessDialogTitle: `-`,
  Event_remoteHeadLightOnSuccessDialogBody: `-`,
  Event_remoteHeadLightOnSuccessDialogAction: `-`,
  Event_remoteHeadLightOnSuccessDialogAction2: `-`,
  Event_remoteHeadLightOnSuccessDialogClose: `-`,
  Event_remoteHeadLightOnSuccessAlertTitle: `-`,
  Event_remoteHeadLightOnSuccessAlertBody: `-`,
  Event_remoteHeadLightOnSuccessLocation: `-`,
  Event_remoteHeadLightOnSuccessRemoteControl: `Turn on Head Lights`,
  Event_remoteHeadLightOnSuccessDashboard: `-`,
  Event_remoteDoorLockedFailEventName: `ドアの解除失敗`,
  Event_remoteDoorLockedFailWarningTitle: `-`,
  Event_remoteDoorLockedFailWarningHint: `-`,
  Event_remoteDoorLockedFailNotificationTitle: `Remote Control`,
  Event_remoteDoorLockedFailNotificationBody: `Doors Lock is fail`,
  Event_remoteDoorLockedFailHome: `-`,
  Event_remoteDoorLockedFailDialogTitle: `Remote Control`,
  Event_remoteDoorLockedFailDialogBody: `{model} \nDoors Lock is fail`,
  Event_remoteDoorLockedFailDialogAction: `Vehicle status`,
  Event_remoteDoorLockedFailDialogAction2: `-`,
  Event_remoteDoorLockedFailDialogClose: `OK`,
  Event_remoteDoorLockedFailAlertTitle: `-`,
  Event_remoteDoorLockedFailAlertBody: `-`,
  Event_remoteDoorLockedFailLocation: `-`,
  Event_remoteDoorLockedFailRemoteControl: `Doors Lock is fail`,
  Event_remoteDoorLockedFailDashboard: `-`,
  Event_remoteDoorLockedSuccessEventName: `ドアを施錠`,
  Event_remoteDoorLockedSuccessWarningTitle: `-`,
  Event_remoteDoorLockedSuccessWarningHint: `-`,
  Event_remoteDoorLockedSuccessNotificationTitle: `Remote Control`,
  Event_remoteDoorLockedSuccessNotificationBody: `Locked Doors`,
  Event_remoteDoorLockedSuccessHome: `-`,
  Event_remoteDoorLockedSuccessDialogTitle: `-`,
  Event_remoteDoorLockedSuccessDialogBody: `-`,
  Event_remoteDoorLockedSuccessDialogAction: `-`,
  Event_remoteDoorLockedSuccessDialogAction2: `-`,
  Event_remoteDoorLockedSuccessDialogClose: `-`,
  Event_remoteDoorLockedSuccessAlertTitle: `-`,
  Event_remoteDoorLockedSuccessAlertBody: `-`,
  Event_remoteDoorLockedSuccessLocation: `-`,
  Event_remoteDoorLockedSuccessRemoteControl: `Locked Doors`,
  Event_remoteDoorLockedSuccessDashboard: `-`,
  Event_carResgitrationFailEventName: `車の登録に失敗。`,
  Event_carResgitrationFailWarningTitle: `-`,
  Event_carResgitrationFailWarningHint: `-`,
  Event_carResgitrationFailNotificationTitle: `Car registration`,
  Event_carResgitrationFailNotificationBody: `Car registrartion is fail`,
  Event_carResgitrationFailHome: `-`,
  Event_carResgitrationFailDialogTitle: `Car registration`,
  Event_carResgitrationFailDialogBody: `{model} \nCar registrartion is fail`,
  Event_carResgitrationFailDialogAction: `Car Registration`,
  Event_carResgitrationFailDialogAction2: `-`,
  Event_carResgitrationFailDialogClose: `OK`,
  Event_carResgitrationFailAlertTitle: `-`,
  Event_carResgitrationFailAlertBody: `-`,
  Event_carResgitrationFailLocation: `-`,
  Event_carResgitrationFailRemoteControl: `Car registrartion is fail`,
  Event_carResgitrationFailDashboard: `-`,
  Event_carResgitrationSuccessEventName: `車を登録する。`,
  Event_carResgitrationSuccessWarningTitle: `-`,
  Event_carResgitrationSuccessWarningHint: `-`,
  Event_carResgitrationSuccessNotificationTitle: `-`,
  Event_carResgitrationSuccessNotificationBody: `-`,
  Event_carResgitrationSuccessHome: `-`,
  Event_carResgitrationSuccessDialogTitle: `Successful !`,
  Event_carResgitrationSuccessDialogBody: `Your registration has been successful. Safe drive! Now you can stop the engine.`,
  Event_carResgitrationSuccessDialogAction: `-`,
  Event_carResgitrationSuccessDialogAction2: `-`,
  Event_carResgitrationSuccessDialogClose: `OK`,
  Event_carResgitrationSuccessAlertTitle: `-`,
  Event_carResgitrationSuccessAlertBody: `-`,
  Event_carResgitrationSuccessLocation: `-`,
  Event_carResgitrationSuccessRemoteControl: `-`,
  Event_carResgitrationSuccessDashboard: `-`,
  Event_keyFreeAlert4EventName: `キーフリー警告灯点滅４`,
  Event_keyFreeAlert4WarningTitle: `Key-free warning light`,
  Event_keyFreeAlert4WarningHint: `The light goes on and buzzer sounds when
the ignition switch is turned “ON” and goes
off a few seconds later.
It shows that
• Smart key is not in the vehicle or;
• Smart key battery is low or;
• Smart entry malfunction or;
• Steering lock remain
Always have the smart key with you and
replace the battery with a new one if
necessary.`,
  Event_keyFreeAlert4NotificationTitle: `Emergency Alert`,
  Event_keyFreeAlert4NotificationBody: `Smartkey battery low`,
  Event_keyFreeAlert4Home: `Smartkey battery low`,
  Event_keyFreeAlert4DialogTitle: `Emergency Alert`,
  Event_keyFreeAlert4DialogBody: `{model} \nSmartkey battery low`,
  Event_keyFreeAlert4DialogAction: `Emergency Alert`,
  Event_keyFreeAlert4DialogAction2: `-`,
  Event_keyFreeAlert4DialogClose: `OK`,
  Event_keyFreeAlert4AlertTitle: `Smartkey battery low`,
  Event_keyFreeAlert4AlertBody: `Smartkey battery low`,
  Event_keyFreeAlert4Location: `-`,
  Event_keyFreeAlert4RemoteControl: `-`,
  Event_keyFreeAlert4Dashboard: `Key-free warning light`,
  Event_towAwayAlertEventName: `レッカー移動検知`,
  Event_towAwayAlertWarningTitle: `-`,
  Event_towAwayAlertWarningHint: `-`,
  Event_towAwayAlertNotificationTitle: `Emergency Alert`,
  Event_towAwayAlertNotificationBody: `Tow car movement (transportation movement) detected`,
  Event_towAwayAlertHome: `Tow car movement (transportation movement) detected`,
  Event_towAwayAlertDialogTitle: `Emergency Alert`,
  Event_towAwayAlertDialogBody: `{model} \nTow car movement (transportation movement) detected`,
  Event_towAwayAlertDialogAction: `Emergency Alert`,
  Event_towAwayAlertDialogAction2: `-`,
  Event_towAwayAlertDialogClose: `OK`,
  Event_towAwayAlertAlertTitle: `Tow car movement (transportation movement) detected`,
  Event_towAwayAlertAlertBody: `Tow car movement (transportation movement) detected`,
  Event_towAwayAlertLocation: `-`,
  Event_towAwayAlertRemoteControl: `-`,
  Event_towAwayAlertDashboard: `-`,
  Event_geofenceInAlertEventName: `ジオフェンス逸脱帰還`,
  Event_geofenceInAlertWarningTitle: `-`,
  Event_geofenceInAlertWarningHint: `-`,
  Event_geofenceInAlertNotificationTitle: `Emergency Alert`,
  Event_geofenceInAlertNotificationBody: `Back to the geofence area`,
  Event_geofenceInAlertHome: `Back to the geofence area`,
  Event_geofenceInAlertDialogTitle: `Emergency Alert`,
  Event_geofenceInAlertDialogBody: `{model} \nBack to the geofence area`,
  Event_geofenceInAlertDialogAction: `Emergency Alert`,
  Event_geofenceInAlertDialogAction2: `-`,
  Event_geofenceInAlertDialogClose: `OK`,
  Event_geofenceInAlertAlertTitle: `Back to the geofence area`,
  Event_geofenceInAlertAlertBody: `Back to the geofence area`,
  Event_geofenceInAlertLocation: `-`,
  Event_geofenceInAlertRemoteControl: `-`,
  Event_geofenceInAlertDashboard: `-`,
  Event_geofenceOutAlertEventName: `ジオフェンス逸脱`,
  Event_geofenceOutAlertWarningTitle: `-`,
  Event_geofenceOutAlertWarningHint: `-`,
  Event_geofenceOutAlertNotificationTitle: `Emergency Alert`,
  Event_geofenceOutAlertNotificationBody: `Leave the geofence area`,
  Event_geofenceOutAlertHome: `Leave the geofence area`,
  Event_geofenceOutAlertDialogTitle: `Emergency Alert`,
  Event_geofenceOutAlertDialogBody: `{model} \nLeave the geofence area`,
  Event_geofenceOutAlertDialogAction: `Emergency Alert`,
  Event_geofenceOutAlertDialogAction2: `-`,
  Event_geofenceOutAlertDialogClose: `OK`,
  Event_geofenceOutAlertAlertTitle: `Leave the geofence area`,
  Event_geofenceOutAlertAlertBody: `Leave the geofence area`,
  Event_geofenceOutAlertLocation: `Leave the predetermined area`,
  Event_geofenceOutAlertRemoteControl: `-`,
  Event_geofenceOutAlertDashboard: `-`,
  Event_removeDcmAlertEventName: `盗難検知DCM取り外し`,
  Event_removeDcmAlertWarningTitle: `-`,
  Event_removeDcmAlertWarningHint: `-`,
  Event_removeDcmAlertNotificationTitle: `Emergency Alert`,
  Event_removeDcmAlertNotificationBody: `DCM has been removed. Your car may be stolen.`,
  Event_removeDcmAlertHome: `DCM has been removed. Your car may be stolen.`,
  Event_removeDcmAlertDialogTitle: `Emergency Alert`,
  Event_removeDcmAlertDialogBody: `{model} \nDCM has been removed. Your car may be stolen.`,
  Event_removeDcmAlertDialogAction: `Emergency Alert`,
  Event_removeDcmAlertDialogAction2: `-`,
  Event_removeDcmAlertDialogClose: `OK`,
  Event_removeDcmAlertAlertTitle: `DCM has been removed. Your car may be stolen.`,
  Event_removeDcmAlertAlertBody: `DCM has been removed. Your car may be stolen.`,
  Event_removeDcmAlertLocation: `-`,
  Event_removeDcmAlertRemoteControl: `-`,
  Event_removeDcmAlertDashboard: `DCM has been removed. Your car may be stolen.`,
  Event_alarmAlertEventName: `盗難検知アラーム発砲`,
  Event_alarmAlertWarningTitle: `-`,
  Event_alarmAlertWarningHint: `-`,
  Event_alarmAlertNotificationTitle: `Emergency Alert`,
  Event_alarmAlertNotificationBody: `Anti-theft alert is warning`,
  Event_alarmAlertHome: `Anti-theft alert is warning`,
  Event_alarmAlertDialogTitle: `Emergency Alert`,
  Event_alarmAlertDialogBody: `{model} \nAnti-theft alert is warning`,
  Event_alarmAlertDialogAction: `Emergency Alert`,
  Event_alarmAlertDialogAction2: `-`,
  Event_alarmAlertDialogClose: `OK`,
  Event_alarmAlertAlertTitle: `Anti-theft alert is warning`,
  Event_alarmAlertAlertBody: `Anti-theft alert is warning`,
  Event_alarmAlertLocation: `-`,
  Event_alarmAlertRemoteControl: `-`,
  Event_alarmAlertDashboard: `Anti-theft alert is warning`,
  Event_systemIGOffAlertEventName: `システムIG SW ON`,
  Event_systemIGOffAlertWarningTitle: `-`,
  Event_systemIGOffAlertWarningHint: `-`,
  Event_systemIGOffAlertNotificationTitle: `-`,
  Event_systemIGOffAlertNotificationBody: `-`,
  Event_systemIGOffAlertHome: `-`,
  Event_systemIGOffAlertDialogTitle: `-`,
  Event_systemIGOffAlertDialogBody: `-`,
  Event_systemIGOffAlertDialogAction: `-`,
  Event_systemIGOffAlertDialogAction2: `-`,
  Event_systemIGOffAlertDialogClose: `-`,
  Event_systemIGOffAlertAlertTitle: `-`,
  Event_systemIGOffAlertAlertBody: `-`,
  Event_systemIGOffAlertLocation: `-`,
  Event_systemIGOffAlertRemoteControl: `-`,
  Event_systemIGOffAlertDashboard: `-`,
  Event_systemIGOnAlertEventName: `システムIG SW ON`,
  Event_systemIGOnAlertWarningTitle: `-`,
  Event_systemIGOnAlertWarningHint: `-`,
  Event_systemIGOnAlertNotificationTitle: `-`,
  Event_systemIGOnAlertNotificationBody: `-`,
  Event_systemIGOnAlertHome: `-`,
  Event_systemIGOnAlertDialogTitle: `-`,
  Event_systemIGOnAlertDialogBody: `-`,
  Event_systemIGOnAlertDialogAction: `-`,
  Event_systemIGOnAlertDialogAction2: `-`,
  Event_systemIGOnAlertDialogClose: `-`,
  Event_systemIGOnAlertAlertTitle: `-`,
  Event_systemIGOnAlertAlertBody: `-`,
  Event_systemIGOnAlertLocation: `-`,
  Event_systemIGOnAlertRemoteControl: `-`,
  Event_systemIGOnAlertDashboard: `-`,
  Event_userIGOffAlertEventName: `ユーザ　IG SW OFF`,
  Event_userIGOffAlertWarningTitle: `-`,
  Event_userIGOffAlertWarningHint: `-`,
  Event_userIGOffAlertNotificationTitle: `-`,
  Event_userIGOffAlertNotificationBody: `-`,
  Event_userIGOffAlertHome: `-`,
  Event_userIGOffAlertDialogTitle: `-`,
  Event_userIGOffAlertDialogBody: `-`,
  Event_userIGOffAlertDialogAction: `-`,
  Event_userIGOffAlertDialogAction2: `-`,
  Event_userIGOffAlertDialogClose: `-`,
  Event_userIGOffAlertAlertTitle: `-`,
  Event_userIGOffAlertAlertBody: `-`,
  Event_userIGOffAlertLocation: `-`,
  Event_userIGOffAlertRemoteControl: `-`,
  Event_userIGOffAlertDashboard: `-`,
  Event_userIGOnAlertEventName: `ユーザ IG SW ON`,
  Event_userIGOnAlertWarningTitle: `-`,
  Event_userIGOnAlertWarningHint: `-`,
  Event_userIGOnAlertNotificationTitle: `Vehicle Alert`,
  Event_userIGOnAlertNotificationBody: `Ignition is on.`,
  Event_userIGOnAlertHome: `Ignition is on.`,
  Event_userIGOnAlertDialogTitle: `Vehicle Alert`,
  Event_userIGOnAlertDialogBody: `{model} \nIgnition is on.`,
  Event_userIGOnAlertDialogAction: `Emergency Alert`,
  Event_userIGOnAlertDialogAction2: `-`,
  Event_userIGOnAlertDialogClose: `OK`,
  Event_userIGOnAlertAlertTitle: `Ignition is on.`,
  Event_userIGOnAlertAlertBody: `Ignition is on.`,
  Event_userIGOnAlertLocation: `-`,
  Event_userIGOnAlertRemoteControl: `-`,
  Event_userIGOnAlertDashboard: `-`,
  Event_elePowSteOreAlertEventName: `電動パワーステアリング警告灯点灯（オレンジ）`,
  Event_elePowSteOreAlertWarningTitle: `Power steering warning light`,
  Event_elePowSteOreAlertWarningHint: `In the normal functioning system, the light
goes on when the ignition switch is turned
“ON”, and then the light goes out when the
engine is started.
This light goes on when any abnormality is
encountered in the power steering with the
engine running.
The indicator illuminates yellow colour and
the buzzer sounds. It shows that the power
steering;
• Low battery voltage
• System overheating
The indicator illuminates red colour and
the buzzer sounds. It shows that the power
steering has a malfunction.
CAUTION
In cases where the light remains
extinguished even when the ignition
switch is turned “ON”, or in cases
where the light remains illuminated
during the engine running, have your
vehicle checked immediately at an
authorised Daihatsu Service Centre.`,
  Event_elePowSteOreAlertNotificationTitle: `Emergency Alert`,
  Event_elePowSteOreAlertNotificationBody: `Functional error of power steering has been detected`,
  Event_elePowSteOreAlertHome: `Functional error of power steering has been detected`,
  Event_elePowSteOreAlertDialogTitle: `Emergency Alert`,
  Event_elePowSteOreAlertDialogBody: `{model} \nFunctional error of power steering has been detected`,
  Event_elePowSteOreAlertDialogAction: `Emergency Alert`,
  Event_elePowSteOreAlertDialogAction2: `-`,
  Event_elePowSteOreAlertDialogClose: `OK`,
  Event_elePowSteOreAlertAlertTitle: `Functional error of power steering has been detected`,
  Event_elePowSteOreAlertAlertBody: `Functional error of power steering has been detected`,
  Event_elePowSteOreAlertLocation: `-`,
  Event_elePowSteOreAlertRemoteControl: `-`,
  Event_elePowSteOreAlertDashboard: `Power steering warning light`,
  Event_afsWarnAlretEventName: `AFSウォーニング情報`,
  Event_afsWarnAlretWarningTitle: `Head lights warning light`,
  Event_afsWarnAlretWarningHint: `A malfunction of all or part of the headlights 
has been detected.`,
  Event_afsWarnAlretNotificationTitle: `Emergency Alert`,
  Event_afsWarnAlretNotificationBody: `Adaptive front lighting system error has been detected`,
  Event_afsWarnAlretHome: `Adaptive front lighting system error has been detected`,
  Event_afsWarnAlretDialogTitle: `Emergency Alert`,
  Event_afsWarnAlretDialogBody: `{model} \nAdaptive front lighting system error has been detected`,
  Event_afsWarnAlretDialogAction: `Emergency Alert`,
  Event_afsWarnAlretDialogAction2: `-`,
  Event_afsWarnAlretDialogClose: `OK`,
  Event_afsWarnAlretAlertTitle: `Adaptive front lighting system error has been detected`,
  Event_afsWarnAlretAlertBody: `Adaptive front lighting system error has been detected`,
  Event_afsWarnAlretLocation: `-`,
  Event_afsWarnAlretRemoteControl: `-`,
  Event_afsWarnAlretDashboard: `Head lights warning light`,
  Event_fourWheelSysAlertEventName: `4WDシステム異常`,
  Event_fourWheelSysAlertWarningTitle: `Transmission warning light`,
  Event_fourWheelSysAlertWarningHint: `Transmission warning light goes
on if the electronic transmission control (ECAT)
should malfunction, either when the engine
is running or stopped.
This light will go on when the ignition switch
is turned “ON” for initial check and go out a
few seconds later.
WARNING
Do not continue driving when the
warning light goes on. Have the
system checked by an authorised
Daihatsu Service Centre immediately.`,
  Event_fourWheelSysAlertNotificationTitle: `Emergency Alert`,
  Event_fourWheelSysAlertNotificationBody: `4WD system error has been detected`,
  Event_fourWheelSysAlertHome: `4WD system error has been detected`,
  Event_fourWheelSysAlertDialogTitle: `Emergency Alert`,
  Event_fourWheelSysAlertDialogBody: `{model} \n4WD system error has been detected`,
  Event_fourWheelSysAlertDialogAction: `Emergency Alert`,
  Event_fourWheelSysAlertDialogAction2: `-`,
  Event_fourWheelSysAlertDialogClose: `OK`,
  Event_fourWheelSysAlertAlertTitle: `4WD system error has been detected`,
  Event_fourWheelSysAlertAlertBody: `4WD system error has been detected`,
  Event_fourWheelSysAlertLocation: `-`,
  Event_fourWheelSysAlertRemoteControl: `-`,
  Event_fourWheelSysAlertDashboard: `Transmission warning light`,
  Event_autoHighBeamAlertEventName: `オートハイビーム故障`,
  Event_autoHighBeamAlertWarningTitle: `Head lights warning light`,
  Event_autoHighBeamAlertWarningHint: `A malfunction of all or part of the headlights 
has been detected.`,
  Event_autoHighBeamAlertNotificationTitle: `Emergency Alert`,
  Event_autoHighBeamAlertNotificationBody: `Auto head light error has been detected`,
  Event_autoHighBeamAlertHome: `Auto head light error has been detected`,
  Event_autoHighBeamAlertDialogTitle: `Emergency Alert`,
  Event_autoHighBeamAlertDialogBody: `{model} \nAuto head light error has been detected`,
  Event_autoHighBeamAlertDialogAction: `Emergency Alert`,
  Event_autoHighBeamAlertDialogAction2: `-`,
  Event_autoHighBeamAlertDialogClose: `OK`,
  Event_autoHighBeamAlertAlertTitle: `Auto head light error has been detected`,
  Event_autoHighBeamAlertAlertBody: `Auto head light error has been detected`,
  Event_autoHighBeamAlertLocation: `-`,
  Event_autoHighBeamAlertRemoteControl: `-`,
  Event_autoHighBeamAlertDashboard: `Head lights warning light`,
  Event_keyFreeAlertEventName: `キーフリー警告灯点灯`,
  Event_keyFreeAlertWarningTitle: `Key-free warning light`,
  Event_keyFreeAlertWarningHint: `The light goes on and buzzer sounds when
the ignition switch is turned “ON” and goes
off a few seconds later.
It shows that
• Smart key is not in the vehicle or;
• Smart key battery is low or;
• Smart entry malfunction or;
• Steering lock remain
Always have the smart key with you and
replace the battery with a new one if
necessary.`,
  Event_keyFreeAlertNotificationTitle: `Emergency Alert`,
  Event_keyFreeAlertNotificationBody: `Smart entry malfunction`,
  Event_keyFreeAlertHome: `Smart entry malfunction`,
  Event_keyFreeAlertDialogTitle: `Emergency Alert`,
  Event_keyFreeAlertDialogBody: `{model} \nSmart entry malfunction`,
  Event_keyFreeAlertDialogAction: `Emergency Alert`,
  Event_keyFreeAlertDialogAction2: `-`,
  Event_keyFreeAlertDialogClose: `OK`,
  Event_keyFreeAlertAlertTitle: `Smart entry malfunction`,
  Event_keyFreeAlertAlertBody: `Smart entry malfunction`,
  Event_keyFreeAlertLocation: `-`,
  Event_keyFreeAlertRemoteControl: `-`,
  Event_keyFreeAlertDashboard: `Key-free warning light`,
  Event_ecoIdelOffAlertEventName: `eco IDLE OFF表示灯点灯１`,
  Event_ecoIdelOffAlertWarningTitle: `Eco Idle system warning light`,
  Event_ecoIdelOffAlertWarningHint: `An idling stop failure was detected. 
There is no problem with normal driving, 
but the idling stop may not work.`,
  Event_ecoIdelOffAlertNotificationTitle: `Emergency Alert`,
  Event_ecoIdelOffAlertNotificationBody: `Eco idle system error has been detected`,
  Event_ecoIdelOffAlertHome: `Eco idle system error has been detected`,
  Event_ecoIdelOffAlertDialogTitle: `Emergency Alert`,
  Event_ecoIdelOffAlertDialogBody: `{model} \nEco idle system error has been detected`,
  Event_ecoIdelOffAlertDialogAction: `Emergency Alert`,
  Event_ecoIdelOffAlertDialogAction2: `-`,
  Event_ecoIdelOffAlertDialogClose: `OK`,
  Event_ecoIdelOffAlertAlertTitle: `Eco idle system error has been detected`,
  Event_ecoIdelOffAlertAlertBody: `Eco idle system error has been detected`,
  Event_ecoIdelOffAlertLocation: `-`,
  Event_ecoIdelOffAlertRemoteControl: `-`,
  Event_ecoIdelOffAlertDashboard: `Eco Idle system warning light`,
  Event_brakeHoldAlertEventName: `ブレーキホールド警告`,
  Event_brakeHoldAlertWarningTitle: `Parking brake warning light`,
  Event_brakeHoldAlertWarningHint: `The parking brake warning light goes on
when the parking brake is applied. In additon,
a warning buzzer will sound if the vehicle
speed exceeds 5 km/h.`,
  Event_brakeHoldAlertNotificationTitle: `Emergency Alert`,
  Event_brakeHoldAlertNotificationBody: `The parking brake error has been detected`,
  Event_brakeHoldAlertHome: `The parking brake error has been detected`,
  Event_brakeHoldAlertDialogTitle: `Emergency Alert`,
  Event_brakeHoldAlertDialogBody: `{model} \nThe parking brake error has been detected`,
  Event_brakeHoldAlertDialogAction: `Emergency Alert`,
  Event_brakeHoldAlertDialogAction2: `-`,
  Event_brakeHoldAlertDialogClose: `OK`,
  Event_brakeHoldAlertAlertTitle: `The parking brake error has been detected`,
  Event_brakeHoldAlertAlertBody: `The parking brake error has been detected`,
  Event_brakeHoldAlertLocation: `-`,
  Event_brakeHoldAlertRemoteControl: `-`,
  Event_brakeHoldAlertDashboard: `Parking brake warning light`,
  Event_smaashiErrAlertEventName: `スマアシ故障点灯`,
  Event_smaashiErrAlertWarningTitle: `Advanced Safety Assist (ASA) error
warning light (if equipped)`,
  Event_smaashiErrAlertWarningHint: `The Advanced Safety Assist (ASA) error
warning light will turn on when the ASA
function cannot work due to system failure.`,
  Event_smaashiErrAlertNotificationTitle: `Emergency Alert`,
  Event_smaashiErrAlertNotificationBody: `Advanced Safety Assist(ASA) error has been detected`,
  Event_smaashiErrAlertHome: `Advanced Safety Assist(ASA) error has been detected`,
  Event_smaashiErrAlertDialogTitle: `Emergency Alert`,
  Event_smaashiErrAlertDialogBody: `{model} \nAdvanced Safety Assist(ASA) error has been detected`,
  Event_smaashiErrAlertDialogAction: `Emergency Alert`,
  Event_smaashiErrAlertDialogAction2: `-`,
  Event_smaashiErrAlertDialogClose: `OK`,
  Event_smaashiErrAlertAlertTitle: `Advanced Safety Assist(ASA) error has been detected`,
  Event_smaashiErrAlertAlertBody: `Advanced Safety Assist(ASA) error has been detected`,
  Event_smaashiErrAlertLocation: `-`,
  Event_smaashiErrAlertRemoteControl: `-`,
  Event_smaashiErrAlertDashboard: `Advanced Safety Assist (ASA) error
warning light (if equipped)`,
  Event_lowBeamLevelErrAlertEventName: `ロービーム・オートレベリング警告灯点灯`,
  Event_lowBeamLevelErrAlertWarningTitle: `Head lights warning light`,
  Event_lowBeamLevelErrAlertWarningHint: `A malfunction of all or part of the headlights 
has been detected.`,
  Event_lowBeamLevelErrAlertNotificationTitle: `Emergency Alert`,
  Event_lowBeamLevelErrAlertNotificationBody: `Stary light has been detected`,
  Event_lowBeamLevelErrAlertHome: `Head light error has been detected`,
  Event_lowBeamLevelErrAlertDialogTitle: `Emergency Alert`,
  Event_lowBeamLevelErrAlertDialogBody: `{model} \nHead light error has been detected`,
  Event_lowBeamLevelErrAlertDialogAction: `Emergency Alert`,
  Event_lowBeamLevelErrAlertDialogAction2: `-`,
  Event_lowBeamLevelErrAlertDialogClose: `OK`,
  Event_lowBeamLevelErrAlertAlertTitle: `Head light error has been detected`,
  Event_lowBeamLevelErrAlertAlertBody: `Head light error has been detected`,
  Event_lowBeamLevelErrAlertLocation: `-`,
  Event_lowBeamLevelErrAlertRemoteControl: `-`,
  Event_lowBeamLevelErrAlertDashboard: `Head lights warning light`,
  Event_headLampSysAlertEventName: `ヘッドランプシステム警告（ADB故障）`,
  Event_headLampSysAlertWarningTitle: `Head lights warning light`,
  Event_headLampSysAlertWarningHint: `A malfunction of all or part of the headlights 
has been detected.`,
  Event_headLampSysAlertNotificationTitle: `Emergency Alert`,
  Event_headLampSysAlertNotificationBody: `Head light system error has been detected`,
  Event_headLampSysAlertHome: `Head light system error has been detected`,
  Event_headLampSysAlertDialogTitle: `Emergency Alert`,
  Event_headLampSysAlertDialogBody: `{model} \nHead light system error has been detected`,
  Event_headLampSysAlertDialogAction: `Emergency Alert`,
  Event_headLampSysAlertDialogAction2: `-`,
  Event_headLampSysAlertDialogClose: `OK`,
  Event_headLampSysAlertAlertTitle: `Head light system error has been detected`,
  Event_headLampSysAlertAlertBody: `Head light system error has been detected`,
  Event_headLampSysAlertLocation: `-`,
  Event_headLampSysAlertRemoteControl: `-`,
  Event_headLampSysAlertDashboard: `Head lights warning light`,
  Event_bosErrAlertEventName: `BOS故障`,
  Event_bosErrAlertWarningTitle: `Advanced Safety Assist (ASA) error
warning light (if equipped)`,
  Event_bosErrAlertWarningHint: `The Advanced Safety Assist (ASA) error
warning light will turn on when the ASA
function cannot work due to system failure.`,
  Event_bosErrAlertNotificationTitle: `Emergency Alert`,
  Event_bosErrAlertNotificationBody: `BOS error has been detected`,
  Event_bosErrAlertHome: `BOS error has been detected`,
  Event_bosErrAlertDialogTitle: `Emergency Alert`,
  Event_bosErrAlertDialogBody: `{model} \nBOS error has been detected`,
  Event_bosErrAlertDialogAction: `Emergency Alert`,
  Event_bosErrAlertDialogAction2: `-`,
  Event_bosErrAlertDialogClose: `OK`,
  Event_bosErrAlertAlertTitle: `BOS error has been detected`,
  Event_bosErrAlertAlertBody: `BOS error has been detected`,
  Event_bosErrAlertLocation: `-`,
  Event_bosErrAlertRemoteControl: `-`,
  Event_bosErrAlertDashboard: `Advanced Safety Assist (ASA) error
warning light (if equipped)`,
  Event_dscErrAlertEventName: `DSC故障`,
  Event_dscErrAlertWarningTitle: `Advanced Safety Assist (ASA) error
warning light (if equipped)`,
  Event_dscErrAlertWarningHint: `The Advanced Safety Assist (ASA) error
warning light will turn on when the ASA
function cannot work due to system failure.`,
  Event_dscErrAlertNotificationTitle: `Emergency Alert`,
  Event_dscErrAlertNotificationBody: `DSC error has been detected`,
  Event_dscErrAlertHome: `DSC error has been detected`,
  Event_dscErrAlertDialogTitle: `Emergency Alert`,
  Event_dscErrAlertDialogBody: `{model} \nDSC error has been detected`,
  Event_dscErrAlertDialogAction: `Emergency Alert`,
  Event_dscErrAlertDialogAction2: `-`,
  Event_dscErrAlertDialogClose: `OK`,
  Event_dscErrAlertAlertTitle: `DSC error has been detected`,
  Event_dscErrAlertAlertBody: `DSC error has been detected`,
  Event_dscErrAlertLocation: `-`,
  Event_dscErrAlertRemoteControl: `-`,
  Event_dscErrAlertDashboard: `Advanced Safety Assist (ASA) error
warning light (if equipped)`,
  Event_vscTscAlertEventName: `VSC&TRC警告灯点灯`,
  Event_vscTscAlertWarningTitle: `Advanced Safety Assist (ASA) error
warning light (if equipped)`,
  Event_vscTscAlertWarningHint: `The Advanced Safety Assist (ASA) error
warning light will turn on when the ASA
function cannot work due to system failure.`,
  Event_vscTscAlertNotificationTitle: `Emergency Alert`,
  Event_vscTscAlertNotificationBody: `VSC error has been detected`,
  Event_vscTscAlertHome: `VSC error has been detected`,
  Event_vscTscAlertDialogTitle: `Emergency Alert`,
  Event_vscTscAlertDialogBody: `{model} \nVSC error has been detected`,
  Event_vscTscAlertDialogAction: `Emergency Alert`,
  Event_vscTscAlertDialogAction2: `-`,
  Event_vscTscAlertDialogClose: `OK`,
  Event_vscTscAlertAlertTitle: `VSC error has been detected`,
  Event_vscTscAlertAlertBody: `VSC error has been detected`,
  Event_vscTscAlertLocation: `-`,
  Event_vscTscAlertRemoteControl: `-`,
  Event_vscTscAlertDashboard: `Advanced Safety Assist (ASA) error
warning light (if equipped)`,
  Event_absAlertEventName: `ABS警告灯点灯`,
  Event_absAlertWarningTitle: `ABS (Anti-lock Braking System)
warning light`,
  Event_absAlertWarningHint: `This light will go on when the ignition switch is
turned “ON” and goes out a few seconds later.
The following conditions indicate that the
ABS (Anti-lock braking system) is possibly
malfunctioning.
• When the ignition switch is turned “ON”,
the light fails to go on or remains in an
illuminated state.
• After the light has extinguished, it lights
up again.
• The light illuminates while driving.
Have the system checked by an authorised
Daihatsu Service Centre immediately.
WARNING
If the ABS warning light and
the brake warning light remains
illuminated and turn off, there is a
possibility that the braking force would
drop drastically. Immediately stop your
vehicle at a safe place and contact an
authorised Daihatsu Service Centre.
CAUTION
When the ABS warning light and
the brake warning light turns on at
the same time, the anti-lock brake
system may be malfunctioned. In
addition, there is a possibility that the
vehicle becomes unstable when hard
braking is applied. Immediately park your
car at a safe place and contact an
authorised Daihatsu Service Centre.`,
  Event_absAlertNotificationTitle: `Emergency Alert`,
  Event_absAlertNotificationBody: `ABS system error has been detected`,
  Event_absAlertHome: `ABS system error has been detected`,
  Event_absAlertDialogTitle: `Emergency Alert`,
  Event_absAlertDialogBody: `{model} \nABS system error has been detected`,
  Event_absAlertDialogAction: `Emergency Alert`,
  Event_absAlertDialogAction2: `-`,
  Event_absAlertDialogClose: `OK`,
  Event_absAlertAlertTitle: `ABS system error has been detected`,
  Event_absAlertAlertBody: `ABS system error has been detected`,
  Event_absAlertLocation: `-`,
  Event_absAlertRemoteControl: `-`,
  Event_absAlertDashboard: `ABS (Anti-lock Braking System)
warning light`,
  Event_parkBrakeErrorEventName: `パーキングブレーキ故障`,
  Event_parkBrakeErrorWarningTitle: `Parking brake warning light`,
  Event_parkBrakeErrorWarningHint: `The parking brake warning light goes on
when the parking brake is applied. In additon,
a warning buzzer will sound if the vehicle
speed exceeds 5 km/h.`,
  Event_parkBrakeErrorNotificationTitle: `Emergency Alert`,
  Event_parkBrakeErrorNotificationBody: `Parking Brake error has been detected`,
  Event_parkBrakeErrorHome: `Parking Brake error has been detected`,
  Event_parkBrakeErrorDialogTitle: `Emergency Alert`,
  Event_parkBrakeErrorDialogBody: `{model} \nParking Brake error has been detected`,
  Event_parkBrakeErrorDialogAction: `Emergency Alert`,
  Event_parkBrakeErrorDialogAction2: `-`,
  Event_parkBrakeErrorDialogClose: `OK`,
  Event_parkBrakeErrorAlertTitle: `Parking Brake error has been detected`,
  Event_parkBrakeErrorAlertBody: `Parking Brake error has been detected`,
  Event_parkBrakeErrorLocation: `-`,
  Event_parkBrakeErrorRemoteControl: `-`,
  Event_parkBrakeErrorDashboard: `Parking brake warning light`,
  Event_cvtAlertEventName: `CVT警告灯点滅`,
  Event_cvtAlertWarningTitle: `Transmission warning light`,
  Event_cvtAlertWarningHint: `Transmission warning light goes
on if the electronic transmission control (ECAT)
should malfunction, either when the engine
is running or stopped.
This light will go on when the ignition switch
is turned “ON” for initial check and go out a
few seconds later.
WARNING
Do not continue driving when the
warning light goes on. Have the
system checked by an authorised
Daihatsu Service Centre immediately.`,
  Event_cvtAlertNotificationTitle: `Emergency Alert`,
  Event_cvtAlertNotificationBody: `CVT system error has been detected`,
  Event_cvtAlertHome: `CVT system error has been detected`,
  Event_cvtAlertDialogTitle: `Emergency Alert`,
  Event_cvtAlertDialogBody: `{model} \nCVT system error has been detected`,
  Event_cvtAlertDialogAction: `Emergency Alert`,
  Event_cvtAlertDialogAction2: `-`,
  Event_cvtAlertDialogClose: `OK`,
  Event_cvtAlertAlertTitle: `CVT system error has been detected`,
  Event_cvtAlertAlertBody: `CVT system error has been detected`,
  Event_cvtAlertLocation: `-`,
  Event_cvtAlertRemoteControl: `-`,
  Event_cvtAlertDashboard: `Transmission warning light`,
  Event_Engine1AlertEventName: `エンジン警告点滅①`,
  Event_Engine1AlertWarningTitle: `Malfunction indicator light (MIL)`,
  Event_Engine1AlertWarningHint: `If the engine electronic control system malfunction while the engine is running, the MIL goes on or blinks.
CAUTION
If the MIL goes on, immediately have the vehicle checked by an authorised Daihatsu Service Centre.`,
  Event_Engine1AlertNotificationTitle: `Emergency Alert`,
  Event_Engine1AlertNotificationBody: `Engine malfunction error has been detected`,
  Event_Engine1AlertHome: `Engine malfunction error has been detected`,
  Event_Engine1AlertDialogTitle: `Emergency Alert`,
  Event_Engine1AlertDialogBody: `{model} \nEngine malfunction error has been detected`,
  Event_Engine1AlertDialogAction: `Emergency Alert`,
  Event_Engine1AlertDialogAction2: `-`,
  Event_Engine1AlertDialogClose: `OK`,
  Event_Engine1AlertAlertTitle: `Engine malfunction error has been detected`,
  Event_Engine1AlertAlertBody: `Engine malfunction error has been detected`,
  Event_Engine1AlertLocation: `-`,
  Event_Engine1AlertRemoteControl: `-`,
  Event_Engine1AlertDashboard: `Malfunction indicator light (MIL)`,
  Event_srsAirbagAlertEventName: `SRSエアバッグ警告灯点灯`,
  Event_srsAirbagAlertWarningTitle: `Air bag warning light`,
  Event_srsAirbagAlertWarningHint: `When any air bag malfunction is detected, the
air bag warning light goes on, thereby warning
the driver of the malfunction of the system.
This light will go on when the ignition switch
is turned “ON” for initial check and go out a
few second later.
CAUTION
When the following conditions appear,
have the system checked for these
conditions by an authorised Daihatsu
Service Centre immediately.
• The warning light does not illuminate
immediately after the ignition switch
is turned “ON”.
• The warning light remains illuminated
while the engine is running.
• The warning light remains illuminated
even if 6 seconds have elapsed after
the ignition switch was turned “ON”.
• The warning light flashes even if
6 seconds have elapsed after the
ignition switch was turned “ON”.`,
  Event_srsAirbagAlertNotificationTitle: `Emergency Alert`,
  Event_srsAirbagAlertNotificationBody: `SRS air bug system error has been detected`,
  Event_srsAirbagAlertHome: `SRS air bug system error has been detected`,
  Event_srsAirbagAlertDialogTitle: `Emergency Alert`,
  Event_srsAirbagAlertDialogBody: `{model} \nSRS air bug system error has been detected`,
  Event_srsAirbagAlertDialogAction: `Emergency Alert`,
  Event_srsAirbagAlertDialogAction2: `-`,
  Event_srsAirbagAlertDialogClose: `OK`,
  Event_srsAirbagAlertAlertTitle: `SRS air bug system error has been detected`,
  Event_srsAirbagAlertAlertBody: `SRS air bug system error has been detected`,
  Event_srsAirbagAlertLocation: `-`,
  Event_srsAirbagAlertRemoteControl: `-`,
  Event_srsAirbagAlertDashboard: `Air bag warning light`,
  Event_elePowSteRedAlertEventName: `電動パワースタリング警告灯点灯（赤色）`,
  Event_elePowSteRedAlertWarningTitle: `Power steering warning light`,
  Event_elePowSteRedAlertWarningHint: `In the normal functioning system, the light
goes on when the ignition switch is turned
“ON”, and then the light goes out when the
engine is started.
This light goes on when any abnormality is
encountered in the power steering with the
engine running.
The indicator illuminates yellow colour and
the buzzer sounds. It shows that the power
steering;
• Low battery voltage
• System overheating
The indicator illuminates red colour and
the buzzer sounds. It shows that the power
steering has a malfunction.
CAUTION
In cases where the light remains
extinguished even when the ignition
switch is turned “ON”, or in cases
where the light remains illuminated
during the engine running, have your
vehicle checked immediately at an
authorised Daihatsu Service Centre.`,
  Event_elePowSteRedAlertNotificationTitle: `Emergency Alert`,
  Event_elePowSteRedAlertNotificationBody: `Power steering system error has been detected`,
  Event_elePowSteRedAlertHome: `Power steering system error has been detected`,
  Event_elePowSteRedAlertDialogTitle: `Emergency Alert`,
  Event_elePowSteRedAlertDialogBody: `{model} \nPower steering system error has been detected`,
  Event_elePowSteRedAlertDialogAction: `Emergency Alert`,
  Event_elePowSteRedAlertDialogAction2: `-`,
  Event_elePowSteRedAlertDialogClose: `OK`,
  Event_elePowSteRedAlertAlertTitle: `Power steering system error has been detected`,
  Event_elePowSteRedAlertAlertBody: `Power steering system error has been detected`,
  Event_elePowSteRedAlertLocation: `-`,
  Event_elePowSteRedAlertRemoteControl: `-`,
  Event_elePowSteRedAlertDashboard: `Power steering warning light`,
  Event_batteryAlertEventName: `充電警告灯点灯`,
  Event_batteryAlertWarningTitle: `Battery charge warning light`,
  Event_batteryAlertWarningHint: `Battery charge warning light`,
  Event_batteryAlertNotificationTitle: `Emergency Alert`,
  Event_batteryAlertNotificationBody: `Battery error has been detected`,
  Event_batteryAlertHome: `Battery error has been detected`,
  Event_batteryAlertDialogTitle: `Emergency Alert`,
  Event_batteryAlertDialogBody: `{model} \nBattery error has been detected`,
  Event_batteryAlertDialogAction: `Emergency Alert`,
  Event_batteryAlertDialogAction2: `-`,
  Event_batteryAlertDialogClose: `OK`,
  Event_batteryAlertAlertTitle: `Battery error has been detected`,
  Event_batteryAlertAlertBody: `Battery error has been detected`,
  Event_batteryAlertLocation: `-`,
  Event_batteryAlertRemoteControl: `-`,
  Event_batteryAlertDashboard: `Battery charge warning light`,
  Event_waterTempAlertEventName: `水温警告灯（赤色）点滅`,
  Event_waterTempAlertWarningTitle: `Water temperature warning light`,
  Event_waterTempAlertWarningHint: `This light will go on in red colour for 3 seconds
when the ignition switch is turned “ON”.
Based on the actual engine cooling water
temperature, this light will change to blue,
remain red or go out.
This light flashes when the engine cooling
water temperature rises abnormally while
the engine is running. The light will go on if
the engine cooling water temperature rises
further.
CAUTION
Do not continue driving if the warning
light flashes. (See “Engine overheating”).`,
  Event_waterTempAlertNotificationTitle: `Emergency Alert`,
  Event_waterTempAlertNotificationBody: `Engine may be over heated`,
  Event_waterTempAlertHome: `Engine may be over heated`,
  Event_waterTempAlertDialogTitle: `Emergency Alert`,
  Event_waterTempAlertDialogBody: `{model} \nEngine may be over heated`,
  Event_waterTempAlertDialogAction: `Emergency Alert`,
  Event_waterTempAlertDialogAction2: `-`,
  Event_waterTempAlertDialogClose: `OK`,
  Event_waterTempAlertAlertTitle: `Engine may be over heated`,
  Event_waterTempAlertAlertBody: `Engine may be over heated`,
  Event_waterTempAlertLocation: `-`,
  Event_waterTempAlertRemoteControl: `-`,
  Event_waterTempAlertDashboard: `Water temperature warning light`,
  Event_oilAlertEventName: `油圧警告灯点灯`,
  Event_oilAlertWarningTitle: `Oil pressure warning light`,
  Event_oilAlertWarningHint: `If the lubrication system malfunction while the engine is running, the warning light goes on.
CAUTION
Turn the engine off if the warning light goes on. Failure to do so may cause severe
engine damage.Have the system checked by an authorised Daihatsu Service Centre immediately.`,
  Event_oilAlertNotificationTitle: `Emergency Alert`,
  Event_oilAlertNotificationBody: `Low oil pressure has been detected`,
  Event_oilAlertHome: `Low oil pressure has been detected`,
  Event_oilAlertDialogTitle: `Emergency Alert`,
  Event_oilAlertDialogBody: `{model} \nLow oil pressure has been detected`,
  Event_oilAlertDialogAction: `Emergency Alert`,
  Event_oilAlertDialogAction2: `-`,
  Event_oilAlertDialogClose: `OK`,
  Event_oilAlertAlertTitle: `Low oil pressure has been detected`,
  Event_oilAlertAlertBody: `Low oil pressure has been detected`,
  Event_oilAlertLocation: `-`,
  Event_oilAlertRemoteControl: `-`,
  Event_oilAlertDashboard: `Oil pressure warning light`,
  Event_brakeSysAlertEventName: `ブレーキシステム異常`,
  Event_brakeSysAlertWarningTitle: `Brake warning light`,
  Event_brakeSysAlertWarningHint: `Brake warning light also serving for EBD
(Electronic Brake-force Distribution)
The brake warning light will go on when the
ignition switch is turned “ON” and goes out
a few seconds later.
When the EBD control is malfunctioning, the
brake warning light goes on.
CAUTION
• If the light goes on while running, most
likely the brake fluid level has dropped,
resulting in poor braking. When the
effectiveness of the brake has dropped,
immediately park your vehicle at a safe
place by depressing the brake pedal
strongly. Then, contact an authorised
Daihatsu Service Centre.
• When both this light and the anti-lock
brake system warning light go on at
the same time, the anti-lock brake
system (ABS) may be malfunctioning. In
addition, there is a possibility that the
vehicle becomes unstable when hard
braking is applied. Immediately park
your car at a safe place and contact
an authorised Daihatsu Service Centre.
• When an abnormal noise (squeaking
noise, etc.) is always emitted during the
braking period, most likely the brake
pad has been worn out. Have your car
checked immediately by an authorised
Daihatsu Service Centre.
WARNING
Do not continue driving with the
warning light on. Failure to do so could
cause an accident.
Have the brakes checked by an
authorised Daihatsu Service Centre
immediately.
If the brake fluid level drops to near the “MIN”
line of the brake fluid reserve tank while the
engine is running, the warning light goes
on and the buzzer sounds after the vehicle
speed exceeds more than 5 km/h.
Add the brake fluid, if necessary, and check
the fluid leakage.
If the warning light goes on, the brake system
requires increased force and the stopping
distance will be increased.`,
  Event_brakeSysAlertNotificationTitle: `Emergency Alert`,
  Event_brakeSysAlertNotificationBody: `Brake system error has been detected`,
  Event_brakeSysAlertHome: `Brake system error has been detected`,
  Event_brakeSysAlertDialogTitle: `Emergency Alert`,
  Event_brakeSysAlertDialogBody: `{model} \nBrake system error has been detected`,
  Event_brakeSysAlertDialogAction: `Emergency Alert`,
  Event_brakeSysAlertDialogAction2: `-`,
  Event_brakeSysAlertDialogClose: `OK`,
  Event_brakeSysAlertAlertTitle: `Brake system error has been detected`,
  Event_brakeSysAlertAlertBody: `Brake system error has been detected`,
  Event_brakeSysAlertLocation: `-`,
  Event_brakeSysAlertRemoteControl: `-`,
  Event_brakeSysAlertDashboard: `Brake warning light`,
  Event_brakeFluAlertEventName: `ブレーキ液液量不足`,
  Event_brakeFluAlertWarningTitle: `Brake warning light`,
  Event_brakeFluAlertWarningHint: `Brake warning light also serving for EBD
(Electronic Brake-force Distribution)
The brake warning light will go on when the
ignition switch is turned “ON” and goes out
a few seconds later.
When the EBD control is malfunctioning, the
brake warning light goes on.
CAUTION
• If the light goes on while running, most
likely the brake fluid level has dropped,
resulting in poor braking. When the
effectiveness of the brake has dropped,
immediately park your vehicle at a safe
place by depressing the brake pedal
strongly. Then, contact an authorised
Daihatsu Service Centre.
• When both this light and the anti-lock
brake system warning light go on at
the same time, the anti-lock brake
system (ABS) may be malfunctioning. In
addition, there is a possibility that the
vehicle becomes unstable when hard
braking is applied. Immediately park
your car at a safe place and contact
an authorised Daihatsu Service Centre.
• When an abnormal noise (squeaking
noise, etc.) is always emitted during the
braking period, most likely the brake
pad has been worn out. Have your car
checked immediately by an authorised
Daihatsu Service Centre.
WARNING
Do not continue driving with the
warning light on. Failure to do so could
cause an accident.
Have the brakes checked by an
authorised Daihatsu Service Centre
immediately.
If the brake fluid level drops to near the “MIN”
line of the brake fluid reserve tank while the
engine is running, the warning light goes
on and the buzzer sounds after the vehicle
speed exceeds more than 5 km/h.
Add the brake fluid, if necessary, and check
the fluid leakage.
If the warning light goes on, the brake system
requires increased force and the stopping
distance will be increased.`,
  Event_brakeFluAlertNotificationTitle: `Emergency Alert`,
  Event_brakeFluAlertNotificationBody: `Low Brake oil volume has been detected`,
  Event_brakeFluAlertHome: `Low Brake oil volume has been detected`,
  Event_brakeFluAlertDialogTitle: `Emergency Alert`,
  Event_brakeFluAlertDialogBody: `{model} \nLow Brake oil volume has been detected`,
  Event_brakeFluAlertDialogAction: `Emergency Alert`,
  Event_brakeFluAlertDialogAction2: `-`,
  Event_brakeFluAlertDialogClose: `OK`,
  Event_brakeFluAlertAlertTitle: `Low Brake oil volume has been detected`,
  Event_brakeFluAlertAlertBody: `Low Brake oil volume has been detected`,
  Event_brakeFluAlertLocation: `-`,
  Event_brakeFluAlertRemoteControl: `-`,
  Event_brakeFluAlertDashboard: `Brake warning light`,
  Event_epbAlertEventName: `EPB緊急停止`,
  Event_epbAlertWarningTitle: `EPB emergency stop has been detected`,
  Event_epbAlertWarningHint: `Vehicle stopped due to EPB (electric parking brake).`,
  Event_epbAlertNotificationTitle: `Emergency Alert`,
  Event_epbAlertNotificationBody: `EPB emergency stop has been detected`,
  Event_epbAlertHome: `EPB emergency stop has been detected`,
  Event_epbAlertDialogTitle: `Emergency Alert`,
  Event_epbAlertDialogBody: `{model} \nEPB emergency stop has been detected`,
  Event_epbAlertDialogAction: `Emergency Alert`,
  Event_epbAlertDialogAction2: `-`,
  Event_epbAlertDialogClose: `OK`,
  Event_epbAlertAlertTitle: `EPB emergency stop has been detected`,
  Event_epbAlertAlertBody: `EPB emergency stop has been detected`,
  Event_epbAlertLocation: `-`,
  Event_epbAlertRemoteControl: `-`,
  Event_epbAlertDashboard: `EPB emergency stop has been detected`,
  Event_airbagAlertEventName: `エアバッグ展開`,
  Event_airbagAlertWarningTitle: `-`,
  Event_airbagAlertWarningHint: `-`,
  Event_airbagAlertNotificationTitle: `Emergency Alert`,
  Event_airbagAlertNotificationBody: `Airbag deployment has been detected`,
  Event_airbagAlertHome: `Airbag deployment has been detected`,
  Event_airbagAlertDialogTitle: `Emergency Alert`,
  Event_airbagAlertDialogBody: `{model} \nAirbag deployment has been detected`,
  Event_airbagAlertDialogAction: `Emergency Alert`,
  Event_airbagAlertDialogAction2: `-`,
  Event_airbagAlertDialogClose: `OK`,
  Event_airbagAlertAlertTitle: `Airbag deployment has been detected`,
  Event_airbagAlertAlertBody: `Airbag deployment has been detected`,
  Event_airbagAlertLocation: `-`,
  Event_airbagAlertRemoteControl: `-`,
  Event_airbagAlertDashboard: `Airbag deployment has been detected`,
};

export function getMessage(messageId: MessageIds): string {
  type IndexedMessages = {
    readonly [key: string]: string;
  };
  const ms = Messages as unknown as IndexedMessages;
  return ms[messageId];
}
