import { DashboardClient } from '@auto/monaka-client/dist/dashboard';
import { Config } from '../config/Config';
import { AuthToken } from './Auth';

export function getClient(authInfo: AuthToken): DashboardClient {
  return new DashboardClient({
    url: Config.client.url,
    region: Config.client.region,
    idToken: authInfo.id_token,
    locale: Config.client.locale,
  });
}
