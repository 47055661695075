import { None, Option, Some, getErrorMessage } from '@auto/dango-util';
import React from 'react';

type Props = Readonly<{
  fallback: (err: Error) => React.ReactNode;
  children: React.ReactNode;
}>;

type State = Readonly<{
  error: Option<Error>;
}>;

export class ErrorBoundary extends React.Component<Props, State> {
  public state: State = { error: None() };

  public static getDerivedStateFromError(error: unknown): State {
    return {
      error: Some(
        error instanceof Error ? error : Error(getErrorMessage(error)),
      ),
    };
  }

  public render(): React.ReactNode {
    return this.state.error.unwrap(
      error => this.props.fallback(error),
      () => this.props.children,
    );
  }
}
