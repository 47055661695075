import { Box } from '@mui/system';
import React from 'react';
import { useIntl } from '../i18n/Intl';
import { defaultLocale } from '../i18n/Locale';
import { MessageId } from '../i18n/Message';
import { Style } from '../style/Style';
import { Theme } from '../theme/Theme';

const styles = Style({
  container: {
    padding: `8px`,
    paddingBottom: `4px`,
    paddingLeft: `32px`,
    borderBottom: `solid 6px ${Theme.header.borderColor}`,
    backgroundColor: Theme.header.backgroundColor,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleText: {
    color: Theme.header.fontColor,
    fontWeight: `800`,
    fontSize: `24px`,
  },
});

export function Header(): React.ReactElement {
  const intl = useIntl(defaultLocale);
  const Text = Box;

  return (
    <Box sx={styles.container}>
      <Text sx={styles.titleText}>
        {intl.formatMessageAsString({ id: MessageId.headerTitle })}
      </Text>
    </Box>
  );
}
