import * as Icons from '../icons/Icons';
import { EventId, EventIdMapValue, EventIdMap as IdMap } from './Types';

export type EventIdMap = IdMap;

const unsafeEventIdMap = new Map<EventId, EventIdMapValue>();

unsafeEventIdMap.set('1051', {
  eventId: '1051',

  eventName: 'Event_sosCallManualEventName',

  warning: {
    title: 'Event_sosCallManualWarningTitle',
    hint: 'Event_sosCallManualWarningHint',
    order: 41,
  },
  notification: {
    title: 'Event_sosCallManualNotificationTitle',
    body: 'Event_sosCallManualNotificationBody',
  },
  home: 'Event_sosCallManualHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_sosCallManualDialogTitle',
    body: 'Event_sosCallManualDialogBody',
    action: 'Event_sosCallManualDialogAction',
    action2: 'Event_sosCallManualDialogAction2',
    close: 'Event_sosCallManualDialogClose',
  },
  alert: {
    title: 'Event_sosCallManualAlertTitle',
    body: 'Event_sosCallManualAlertBody',
  },
  location: 'Event_sosCallManualLocation',
  remoteControl: 'Event_sosCallManualRemoteControl',
  dashboard: 'Event_sosCallManualDashboard',

  image: {
    normal: Icons.ic_sos_normal,
    active: Icons.ic_sos_active,
    white: Icons.ic_sos_white,
  },
});

unsafeEventIdMap.set('1050', {
  eventId: '1050',

  eventName: 'Event_sosCallAutoEventName',

  warning: {
    title: 'Event_sosCallAutoWarningTitle',
    hint: 'Event_sosCallAutoWarningHint',
    order: 40,
  },
  notification: {
    title: 'Event_sosCallAutoNotificationTitle',
    body: 'Event_sosCallAutoNotificationBody',
  },
  home: 'Event_sosCallAutoHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_sosCallAutoDialogTitle',
    body: 'Event_sosCallAutoDialogBody',
    action: 'Event_sosCallAutoDialogAction',
    action2: 'Event_sosCallAutoDialogAction2',
    close: 'Event_sosCallAutoDialogClose',
  },
  alert: {
    title: 'Event_sosCallAutoAlertTitle',
    body: 'Event_sosCallAutoAlertBody',
  },
  location: 'Event_sosCallAutoLocation',
  remoteControl: 'Event_sosCallAutoRemoteControl',
  dashboard: 'Event_sosCallAutoDashboard',

  image: {
    normal: Icons.ic_sos_normal,
    active: Icons.ic_sos_active,
    white: Icons.ic_sos_white,
  },
});

unsafeEventIdMap.set('619', {
  eventId: '619',

  eventName: 'Event_lowDriveBatteryAlert3EventName',

  warning: {
    title: 'Event_lowDriveBatteryAlert3WarningTitle',
    hint: 'Event_lowDriveBatteryAlert3WarningHint',
    order: 39,
  },
  notification: {
    title: 'Event_lowDriveBatteryAlert3NotificationTitle',
    body: 'Event_lowDriveBatteryAlert3NotificationBody',
  },
  home: 'Event_lowDriveBatteryAlert3Home',
  dialog: {
    type: 'Confirm',
    title: 'Event_lowDriveBatteryAlert3DialogTitle',
    body: 'Event_lowDriveBatteryAlert3DialogBody',
    action: 'Event_lowDriveBatteryAlert3DialogAction',
    action2: 'Event_lowDriveBatteryAlert3DialogAction2',
    close: 'Event_lowDriveBatteryAlert3DialogClose',
  },
  alert: {
    title: 'Event_lowDriveBatteryAlert3AlertTitle',
    body: 'Event_lowDriveBatteryAlert3AlertBody',
  },
  location: 'Event_lowDriveBatteryAlert3Location',
  remoteControl: 'Event_lowDriveBatteryAlert3RemoteControl',
  dashboard: 'Event_lowDriveBatteryAlert3Dashboard',

  image: {
    normal: Icons.ic_lowdrivebattery_normal,
    active: Icons.ic_lowdrivebattery_active,
    white: Icons.ic_lowdrivebattery_white,
  },
});

unsafeEventIdMap.set('618', {
  eventId: '618',

  eventName: 'Event_lowDriveBatteryAlert2EventName',

  warning: {
    title: 'Event_lowDriveBatteryAlert2WarningTitle',
    hint: 'Event_lowDriveBatteryAlert2WarningHint',
    order: 38,
  },
  notification: {
    title: 'Event_lowDriveBatteryAlert2NotificationTitle',
    body: 'Event_lowDriveBatteryAlert2NotificationBody',
  },
  home: 'Event_lowDriveBatteryAlert2Home',
  dialog: {
    type: 'Confirm',
    title: 'Event_lowDriveBatteryAlert2DialogTitle',
    body: 'Event_lowDriveBatteryAlert2DialogBody',
    action: 'Event_lowDriveBatteryAlert2DialogAction',
    action2: 'Event_lowDriveBatteryAlert2DialogAction2',
    close: 'Event_lowDriveBatteryAlert2DialogClose',
  },
  alert: {
    title: 'Event_lowDriveBatteryAlert2AlertTitle',
    body: 'Event_lowDriveBatteryAlert2AlertBody',
  },
  location: 'Event_lowDriveBatteryAlert2Location',
  remoteControl: 'Event_lowDriveBatteryAlert2RemoteControl',
  dashboard: 'Event_lowDriveBatteryAlert2Dashboard',

  image: {
    normal: Icons.ic_lowdrivebattery_normal,
    active: Icons.ic_lowdrivebattery_active,
    white: Icons.ic_lowdrivebattery_white,
  },
});

unsafeEventIdMap.set('617', {
  eventId: '617',

  eventName: 'Event_lowDriveBatteryAlert1EventName',

  warning: {
    title: 'Event_lowDriveBatteryAlert1WarningTitle',
    hint: 'Event_lowDriveBatteryAlert1WarningHint',
    order: 37,
  },
  notification: {
    title: 'Event_lowDriveBatteryAlert1NotificationTitle',
    body: 'Event_lowDriveBatteryAlert1NotificationBody',
  },
  home: 'Event_lowDriveBatteryAlert1Home',
  dialog: {
    type: 'Confirm',
    title: 'Event_lowDriveBatteryAlert1DialogTitle',
    body: 'Event_lowDriveBatteryAlert1DialogBody',
    action: 'Event_lowDriveBatteryAlert1DialogAction',
    action2: 'Event_lowDriveBatteryAlert1DialogAction2',
    close: 'Event_lowDriveBatteryAlert1DialogClose',
  },
  alert: {
    title: 'Event_lowDriveBatteryAlert1AlertTitle',
    body: 'Event_lowDriveBatteryAlert1AlertBody',
  },
  location: 'Event_lowDriveBatteryAlert1Location',
  remoteControl: 'Event_lowDriveBatteryAlert1RemoteControl',
  dashboard: 'Event_lowDriveBatteryAlert1Dashboard',

  image: {
    normal: Icons.ic_lowdrivebattery_normal,
    active: Icons.ic_lowdrivebattery_active,
    white: Icons.ic_lowdrivebattery_white,
  },
});

unsafeEventIdMap.set('515', {
  eventId: '515',

  eventName: 'Event_backSensorAlertEventName',

  warning: {
    title: 'Event_backSensorAlertWarningTitle',
    hint: 'Event_backSensorAlertWarningHint',
    order: 36,
  },
  notification: {
    title: 'Event_backSensorAlertNotificationTitle',
    body: 'Event_backSensorAlertNotificationBody',
  },
  home: 'Event_backSensorAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_backSensorAlertDialogTitle',
    body: 'Event_backSensorAlertDialogBody',
    action: 'Event_backSensorAlertDialogAction',
    action2: 'Event_backSensorAlertDialogAction2',
    close: 'Event_backSensorAlertDialogClose',
  },
  alert: {
    title: 'Event_backSensorAlertAlertTitle',
    body: 'Event_backSensorAlertAlertBody',
  },
  location: 'Event_backSensorAlertLocation',
  remoteControl: 'Event_backSensorAlertRemoteControl',
  dashboard: 'Event_backSensorAlertDashboard',

  image: {
    normal: Icons.ic_backsonar_normal,
    active: Icons.ic_backsonar_active,
    white: Icons.ic_backsonar_white,
  },
});

unsafeEventIdMap.set('514', {
  eventId: '514',

  eventName: 'Event_cornerSensorFrontAlertEventName',

  warning: {
    title: 'Event_cornerSensorFrontAlertWarningTitle',
    hint: 'Event_cornerSensorFrontAlertWarningHint',
    order: 35,
  },
  notification: {
    title: 'Event_cornerSensorFrontAlertNotificationTitle',
    body: 'Event_cornerSensorFrontAlertNotificationBody',
  },
  home: 'Event_cornerSensorFrontAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_cornerSensorFrontAlertDialogTitle',
    body: 'Event_cornerSensorFrontAlertDialogBody',
    action: 'Event_cornerSensorFrontAlertDialogAction',
    action2: 'Event_cornerSensorFrontAlertDialogAction2',
    close: 'Event_cornerSensorFrontAlertDialogClose',
  },
  alert: {
    title: 'Event_cornerSensorFrontAlertAlertTitle',
    body: 'Event_cornerSensorFrontAlertAlertBody',
  },
  location: 'Event_cornerSensorFrontAlertLocation',
  remoteControl: 'Event_cornerSensorFrontAlertRemoteControl',
  dashboard: 'Event_cornerSensorFrontAlertDashboard',

  image: {
    normal: Icons.ic_cornersensor_front_normal,
    active: Icons.ic_cornersensor_front_active,
    white: Icons.ic_cornersensor_front_white,
  },
});

unsafeEventIdMap.set('513', {
  eventId: '513',

  eventName: 'Event_cornerSensorRearAlertEventName',

  warning: {
    title: 'Event_cornerSensorRearAlertWarningTitle',
    hint: 'Event_cornerSensorRearAlertWarningHint',
    order: 34,
  },
  notification: {
    title: 'Event_cornerSensorRearAlertNotificationTitle',
    body: 'Event_cornerSensorRearAlertNotificationBody',
  },
  home: 'Event_cornerSensorRearAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_cornerSensorRearAlertDialogTitle',
    body: 'Event_cornerSensorRearAlertDialogBody',
    action: 'Event_cornerSensorRearAlertDialogAction',
    action2: 'Event_cornerSensorRearAlertDialogAction2',
    close: 'Event_cornerSensorRearAlertDialogClose',
  },
  alert: {
    title: 'Event_cornerSensorRearAlertAlertTitle',
    body: 'Event_cornerSensorRearAlertAlertBody',
  },
  location: 'Event_cornerSensorRearAlertLocation',
  remoteControl: 'Event_cornerSensorRearAlertRemoteControl',
  dashboard: 'Event_cornerSensorRearAlertDashboard',

  image: {
    normal: Icons.ic_cornersensor_rear_normal,
    active: Icons.ic_cornersensor_rear_active,
    white: Icons.ic_cornersensor_rear_white,
  },
});

unsafeEventIdMap.set('512', {
  eventId: '512',

  eventName: 'Event_cornerSensorAllAlertEventName',

  warning: {
    title: 'Event_cornerSensorAllAlertWarningTitle',
    hint: 'Event_cornerSensorAllAlertWarningHint',
    order: 33,
  },
  notification: {
    title: 'Event_cornerSensorAllAlertNotificationTitle',
    body: 'Event_cornerSensorAllAlertNotificationBody',
  },
  home: 'Event_cornerSensorAllAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_cornerSensorAllAlertDialogTitle',
    body: 'Event_cornerSensorAllAlertDialogBody',
    action: 'Event_cornerSensorAllAlertDialogAction',
    action2: 'Event_cornerSensorAllAlertDialogAction2',
    close: 'Event_cornerSensorAllAlertDialogClose',
  },
  alert: {
    title: 'Event_cornerSensorAllAlertAlertTitle',
    body: 'Event_cornerSensorAllAlertAlertBody',
  },
  location: 'Event_cornerSensorAllAlertLocation',
  remoteControl: 'Event_cornerSensorAllAlertRemoteControl',
  dashboard: 'Event_cornerSensorAllAlertDashboard',

  image: {
    normal: Icons.ic_cornersensor_all_normal,
    active: Icons.ic_cornersensor_all_active,
    white: Icons.ic_cornersensor_all_white,
  },
});

unsafeEventIdMap.set('511', {
  eventId: '511',

  eventName: 'Event_acousticVehicleSystemAlertEventName',

  warning: {
    title: 'Event_acousticVehicleSystemAlertWarningTitle',
    hint: 'Event_acousticVehicleSystemAlertWarningHint',
    order: 32,
  },
  notification: {
    title: 'Event_acousticVehicleSystemAlertNotificationTitle',
    body: 'Event_acousticVehicleSystemAlertNotificationBody',
  },
  home: 'Event_acousticVehicleSystemAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_acousticVehicleSystemAlertDialogTitle',
    body: 'Event_acousticVehicleSystemAlertDialogBody',
    action: 'Event_acousticVehicleSystemAlertDialogAction',
    action2: 'Event_acousticVehicleSystemAlertDialogAction2',
    close: 'Event_acousticVehicleSystemAlertDialogClose',
  },
  alert: {
    title: 'Event_acousticVehicleSystemAlertAlertTitle',
    body: 'Event_acousticVehicleSystemAlertAlertBody',
  },
  location: 'Event_acousticVehicleSystemAlertLocation',
  remoteControl: 'Event_acousticVehicleSystemAlertRemoteControl',
  dashboard: 'Event_acousticVehicleSystemAlertDashboard',

  image: {
    normal: Icons.ic_acoustic_normal,
    active: Icons.ic_acoustic_active,
    white: Icons.ic_acoustic_white,
  },
});

unsafeEventIdMap.set('510', {
  eventId: '510',

  eventName: 'Event_bsmAlertEventName',

  warning: {
    title: 'Event_bsmAlertWarningTitle',
    hint: 'Event_bsmAlertWarningHint',
    order: 31,
  },
  notification: {
    title: 'Event_bsmAlertNotificationTitle',
    body: 'Event_bsmAlertNotificationBody',
  },
  home: 'Event_bsmAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_bsmAlertDialogTitle',
    body: 'Event_bsmAlertDialogBody',
    action: 'Event_bsmAlertDialogAction',
    action2: 'Event_bsmAlertDialogAction2',
    close: 'Event_bsmAlertDialogClose',
  },
  alert: {
    title: 'Event_bsmAlertAlertTitle',
    body: 'Event_bsmAlertAlertBody',
  },
  location: 'Event_bsmAlertLocation',
  remoteControl: 'Event_bsmAlertRemoteControl',
  dashboard: 'Event_bsmAlertDashboard',

  image: {
    normal: Icons.ic_bsm_normal,
    active: Icons.ic_bsm_active,
    white: Icons.ic_bsm_white,
  },
});

unsafeEventIdMap.set('508', {
  eventId: '508',

  eventName: 'Event_lkcSystemAlertEventName',

  warning: {
    title: 'Event_lkcSystemAlertWarningTitle',
    hint: 'Event_lkcSystemAlertWarningHint',
    order: 30,
  },
  notification: {
    title: 'Event_lkcSystemAlertNotificationTitle',
    body: 'Event_lkcSystemAlertNotificationBody',
  },
  home: 'Event_lkcSystemAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_lkcSystemAlertDialogTitle',
    body: 'Event_lkcSystemAlertDialogBody',
    action: 'Event_lkcSystemAlertDialogAction',
    action2: 'Event_lkcSystemAlertDialogAction2',
    close: 'Event_lkcSystemAlertDialogClose',
  },
  alert: {
    title: 'Event_lkcSystemAlertAlertTitle',
    body: 'Event_lkcSystemAlertAlertBody',
  },
  location: 'Event_lkcSystemAlertLocation',
  remoteControl: 'Event_lkcSystemAlertRemoteControl',
  dashboard: 'Event_lkcSystemAlertDashboard',

  image: {
    normal: Icons.ic_lkcsystem_normal,
    active: Icons.ic_lkcsystem_active,
    white: Icons.ic_lkcsystem_white,
  },
});

unsafeEventIdMap.set('410', {
  eventId: '410',

  eventName: 'Event_accBreakAlertEventName',

  warning: {
    title: 'Event_accBreakAlertWarningTitle',
    hint: 'Event_accBreakAlertWarningHint',
    order: 29,
  },
  notification: {
    title: 'Event_accBreakAlertNotificationTitle',
    body: 'Event_accBreakAlertNotificationBody',
  },
  home: 'Event_accBreakAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_accBreakAlertDialogTitle',
    body: 'Event_accBreakAlertDialogBody',
    action: 'Event_accBreakAlertDialogAction',
    action2: 'Event_accBreakAlertDialogAction2',
    close: 'Event_accBreakAlertDialogClose',
  },
  alert: {
    title: 'Event_accBreakAlertAlertTitle',
    body: 'Event_accBreakAlertAlertBody',
  },
  location: 'Event_accBreakAlertLocation',
  remoteControl: 'Event_accBreakAlertRemoteControl',
  dashboard: 'Event_accBreakAlertDashboard',

  image: {
    normal: Icons.ic_accbrake_normal,
    active: Icons.ic_accbrake_active,
    white: Icons.ic_accbrake_white,
  },
});

unsafeEventIdMap.set('232', {
  eventId: '232',

  eventName: 'Event_rearDiffLockAlertEventName',

  warning: {
    title: 'Event_rearDiffLockAlertWarningTitle',
    hint: 'Event_rearDiffLockAlertWarningHint',
    order: 28,
  },
  notification: {
    title: 'Event_rearDiffLockAlertNotificationTitle',
    body: 'Event_rearDiffLockAlertNotificationBody',
  },
  home: 'Event_rearDiffLockAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_rearDiffLockAlertDialogTitle',
    body: 'Event_rearDiffLockAlertDialogBody',
    action: 'Event_rearDiffLockAlertDialogAction',
    action2: 'Event_rearDiffLockAlertDialogAction2',
    close: 'Event_rearDiffLockAlertDialogClose',
  },
  alert: {
    title: 'Event_rearDiffLockAlertAlertTitle',
    body: 'Event_rearDiffLockAlertAlertBody',
  },
  location: 'Event_rearDiffLockAlertLocation',
  remoteControl: 'Event_rearDiffLockAlertRemoteControl',
  dashboard: 'Event_rearDiffLockAlertDashboard',

  image: {
    normal: Icons.ic_reardifflock_normal,
    active: Icons.ic_reardifflock_active,
    white: Icons.ic_reardifflock_white,
  },
});

unsafeEventIdMap.set('9999', {
  eventId: '9999',

  eventName: 'Event_dummyAirbagAlertEventName',

  warning: {
    title: 'Event_dummyAirbagAlertWarningTitle',
    hint: 'Event_dummyAirbagAlertWarningHint',
    order: 27,
  },
  notification: {
    title: 'Event_dummyAirbagAlertNotificationTitle',
    body: 'Event_dummyAirbagAlertNotificationBody',
  },
  home: 'Event_dummyAirbagAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_dummyAirbagAlertDialogTitle',
    body: 'Event_dummyAirbagAlertDialogBody',
    action: 'Event_dummyAirbagAlertDialogAction',
    action2: 'Event_dummyAirbagAlertDialogAction2',
    close: 'Event_dummyAirbagAlertDialogClose',
  },
  alert: {
    title: 'Event_dummyAirbagAlertAlertTitle',
    body: 'Event_dummyAirbagAlertAlertBody',
  },
  location: 'Event_dummyAirbagAlertLocation',
  remoteControl: 'Event_dummyAirbagAlertRemoteControl',
  dashboard: 'Event_dummyAirbagAlertDashboard',

  image: {
    normal: Icons.ic_srs_normal,
    active: Icons.ic_srs_active,
    white: Icons.ic_srs_white,
  },
});

unsafeEventIdMap.set('1040', {
  eventId: '1040',

  eventName: 'Event_ukkariEventName',

  warning: {
    title: 'Event_ukkariWarningTitle',
    hint: 'Event_ukkariWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_ukkariNotificationTitle',
    body: 'Event_ukkariNotificationBody',
  },
  home: 'Event_ukkariHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_ukkariDialogTitle',
    body: 'Event_ukkariDialogBody',
    action: 'Event_ukkariDialogAction',
    action2: 'Event_ukkariDialogAction2',
    close: 'Event_ukkariDialogClose',
  },
  alert: {
    title: 'Event_ukkariAlertTitle',
    body: 'Event_ukkariAlertBody',
  },
  location: 'Event_ukkariLocation',
  remoteControl: 'Event_ukkariRemoteControl',
  dashboard: 'Event_ukkariDashboard',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeEventIdMap.set('504', {
  eventId: '504',

  eventName: 'Event_keyFreeAlert4EventName',

  warning: {
    title: 'Event_keyFreeAlert4WarningTitle',
    hint: 'Event_keyFreeAlert4WarningHint',
    order: 20,
  },
  notification: {
    title: 'Event_keyFreeAlert4NotificationTitle',
    body: 'Event_keyFreeAlert4NotificationBody',
  },
  home: 'Event_keyFreeAlert4Home',
  dialog: {
    type: 'Confirm',
    title: 'Event_keyFreeAlert4DialogTitle',
    body: 'Event_keyFreeAlert4DialogBody',
    action: 'Event_keyFreeAlert4DialogAction',
    action2: 'Event_keyFreeAlert4DialogAction2',
    close: 'Event_keyFreeAlert4DialogClose',
  },
  alert: {
    title: 'Event_keyFreeAlert4AlertTitle',
    body: 'Event_keyFreeAlert4AlertBody',
  },
  location: 'Event_keyFreeAlert4Location',
  remoteControl: 'Event_keyFreeAlert4RemoteControl',
  dashboard: 'Event_keyFreeAlert4Dashboard',

  image: {
    normal: Icons.ic_keybattery_normal,
    active: Icons.ic_keybattery_active,
    white: Icons.ic_keybattery_white,
  },
});

unsafeEventIdMap.set('1031', {
  eventId: '1031',

  eventName: 'Event_towAwayAlertEventName',

  warning: {
    title: 'Event_towAwayAlertWarningTitle',
    hint: 'Event_towAwayAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_towAwayAlertNotificationTitle',
    body: 'Event_towAwayAlertNotificationBody',
  },
  home: 'Event_towAwayAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_towAwayAlertDialogTitle',
    body: 'Event_towAwayAlertDialogBody',
    action: 'Event_towAwayAlertDialogAction',
    action2: 'Event_towAwayAlertDialogAction2',
    close: 'Event_towAwayAlertDialogClose',
  },
  alert: {
    title: 'Event_towAwayAlertAlertTitle',
    body: 'Event_towAwayAlertAlertBody',
  },
  location: 'Event_towAwayAlertLocation',
  remoteControl: 'Event_towAwayAlertRemoteControl',
  dashboard: 'Event_towAwayAlertDashboard',

  image: {
    normal: Icons.ic_tow_normal,
    active: undefined,
    white: Icons.ic_tow_white,
  },
});

unsafeEventIdMap.set('1022', {
  eventId: '1022',

  eventName: 'Event_geofenceInAlertEventName',

  warning: {
    title: 'Event_geofenceInAlertWarningTitle',
    hint: 'Event_geofenceInAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_geofenceInAlertNotificationTitle',
    body: 'Event_geofenceInAlertNotificationBody',
  },
  home: 'Event_geofenceInAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_geofenceInAlertDialogTitle',
    body: 'Event_geofenceInAlertDialogBody',
    action: 'Event_geofenceInAlertDialogAction',
    action2: 'Event_geofenceInAlertDialogAction2',
    close: 'Event_geofenceInAlertDialogClose',
  },
  alert: {
    title: 'Event_geofenceInAlertAlertTitle',
    body: 'Event_geofenceInAlertAlertBody',
  },
  location: 'Event_geofenceInAlertLocation',
  remoteControl: 'Event_geofenceInAlertRemoteControl',
  dashboard: 'Event_geofenceInAlertDashboard',

  image: {
    normal: Icons.ic_geofence_normal,
    active: undefined,
    white: Icons.ic_geofence_white,
  },
});

unsafeEventIdMap.set('1021', {
  eventId: '1021',

  eventName: 'Event_geofenceOutAlertEventName',

  warning: {
    title: 'Event_geofenceOutAlertWarningTitle',
    hint: 'Event_geofenceOutAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_geofenceOutAlertNotificationTitle',
    body: 'Event_geofenceOutAlertNotificationBody',
  },
  home: 'Event_geofenceOutAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_geofenceOutAlertDialogTitle',
    body: 'Event_geofenceOutAlertDialogBody',
    action: 'Event_geofenceOutAlertDialogAction',
    action2: 'Event_geofenceOutAlertDialogAction2',
    close: 'Event_geofenceOutAlertDialogClose',
  },
  alert: {
    title: 'Event_geofenceOutAlertAlertTitle',
    body: 'Event_geofenceOutAlertAlertBody',
  },
  location: 'Event_geofenceOutAlertLocation',
  remoteControl: 'Event_geofenceOutAlertRemoteControl',
  dashboard: 'Event_geofenceOutAlertDashboard',

  image: {
    normal: Icons.ic_geofence_normal,
    active: undefined,
    white: Icons.ic_geofence_white,
  },
});

unsafeEventIdMap.set('1011', {
  eventId: '1011',

  eventName: 'Event_removeDcmAlertEventName',

  warning: {
    title: 'Event_removeDcmAlertWarningTitle',
    hint: 'Event_removeDcmAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_removeDcmAlertNotificationTitle',
    body: 'Event_removeDcmAlertNotificationBody',
  },
  home: 'Event_removeDcmAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_removeDcmAlertDialogTitle',
    body: 'Event_removeDcmAlertDialogBody',
    action: 'Event_removeDcmAlertDialogAction',
    action2: 'Event_removeDcmAlertDialogAction2',
    close: 'Event_removeDcmAlertDialogClose',
  },
  alert: {
    title: 'Event_removeDcmAlertAlertTitle',
    body: 'Event_removeDcmAlertAlertBody',
  },
  location: 'Event_removeDcmAlertLocation',
  remoteControl: 'Event_removeDcmAlertRemoteControl',
  dashboard: 'Event_removeDcmAlertDashboard',

  image: {
    normal: Icons.ic_dcm_normal,
    active: undefined,
    white: Icons.ic_dcm_white,
  },
});

unsafeEventIdMap.set('1010', {
  eventId: '1010',

  eventName: 'Event_alarmAlertEventName',

  warning: {
    title: 'Event_alarmAlertWarningTitle',
    hint: 'Event_alarmAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_alarmAlertNotificationTitle',
    body: 'Event_alarmAlertNotificationBody',
  },
  home: 'Event_alarmAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_alarmAlertDialogTitle',
    body: 'Event_alarmAlertDialogBody',
    action: 'Event_alarmAlertDialogAction',
    action2: 'Event_alarmAlertDialogAction2',
    close: 'Event_alarmAlertDialogClose',
  },
  alert: {
    title: 'Event_alarmAlertAlertTitle',
    body: 'Event_alarmAlertAlertBody',
  },
  location: 'Event_alarmAlertLocation',
  remoteControl: 'Event_alarmAlertRemoteControl',
  dashboard: 'Event_alarmAlertDashboard',

  image: {
    normal: Icons.ic_alarm_normal,
    active: undefined,
    white: Icons.ic_alarm_white,
  },
});

unsafeEventIdMap.set('1002', {
  eventId: '1002',

  eventName: 'Event_systemIGOffAlertEventName',

  warning: {
    title: 'Event_systemIGOffAlertWarningTitle',
    hint: 'Event_systemIGOffAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_systemIGOffAlertNotificationTitle',
    body: 'Event_systemIGOffAlertNotificationBody',
  },
  home: 'Event_systemIGOffAlertHome',
  dialog: {
    type: 'None',
    title: 'Event_systemIGOffAlertDialogTitle',
    body: 'Event_systemIGOffAlertDialogBody',
    action: 'Event_systemIGOffAlertDialogAction',
    action2: 'Event_systemIGOffAlertDialogAction2',
    close: 'Event_systemIGOffAlertDialogClose',
  },
  alert: {
    title: 'Event_systemIGOffAlertAlertTitle',
    body: 'Event_systemIGOffAlertAlertBody',
  },
  location: 'Event_systemIGOffAlertLocation',
  remoteControl: 'Event_systemIGOffAlertRemoteControl',
  dashboard: 'Event_systemIGOffAlertDashboard',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeEventIdMap.set('1001', {
  eventId: '1001',

  eventName: 'Event_systemIGOnAlertEventName',

  warning: {
    title: 'Event_systemIGOnAlertWarningTitle',
    hint: 'Event_systemIGOnAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_systemIGOnAlertNotificationTitle',
    body: 'Event_systemIGOnAlertNotificationBody',
  },
  home: 'Event_systemIGOnAlertHome',
  dialog: {
    type: 'None',
    title: 'Event_systemIGOnAlertDialogTitle',
    body: 'Event_systemIGOnAlertDialogBody',
    action: 'Event_systemIGOnAlertDialogAction',
    action2: 'Event_systemIGOnAlertDialogAction2',
    close: 'Event_systemIGOnAlertDialogClose',
  },
  alert: {
    title: 'Event_systemIGOnAlertAlertTitle',
    body: 'Event_systemIGOnAlertAlertBody',
  },
  location: 'Event_systemIGOnAlertLocation',
  remoteControl: 'Event_systemIGOnAlertRemoteControl',
  dashboard: 'Event_systemIGOnAlertDashboard',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeEventIdMap.set('102', {
  eventId: '102',

  eventName: 'Event_userIGOffAlertEventName',

  warning: {
    title: 'Event_userIGOffAlertWarningTitle',
    hint: 'Event_userIGOffAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_userIGOffAlertNotificationTitle',
    body: 'Event_userIGOffAlertNotificationBody',
  },
  home: 'Event_userIGOffAlertHome',
  dialog: {
    type: 'None',
    title: 'Event_userIGOffAlertDialogTitle',
    body: 'Event_userIGOffAlertDialogBody',
    action: 'Event_userIGOffAlertDialogAction',
    action2: 'Event_userIGOffAlertDialogAction2',
    close: 'Event_userIGOffAlertDialogClose',
  },
  alert: {
    title: 'Event_userIGOffAlertAlertTitle',
    body: 'Event_userIGOffAlertAlertBody',
  },
  location: 'Event_userIGOffAlertLocation',
  remoteControl: 'Event_userIGOffAlertRemoteControl',
  dashboard: 'Event_userIGOffAlertDashboard',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeEventIdMap.set('101', {
  eventId: '101',

  eventName: 'Event_userIGOnAlertEventName',

  warning: {
    title: 'Event_userIGOnAlertWarningTitle',
    hint: 'Event_userIGOnAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_userIGOnAlertNotificationTitle',
    body: 'Event_userIGOnAlertNotificationBody',
  },
  home: 'Event_userIGOnAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_userIGOnAlertDialogTitle',
    body: 'Event_userIGOnAlertDialogBody',
    action: 'Event_userIGOnAlertDialogAction',
    action2: 'Event_userIGOnAlertDialogAction2',
    close: 'Event_userIGOnAlertDialogClose',
  },
  alert: {
    title: 'Event_userIGOnAlertAlertTitle',
    body: 'Event_userIGOnAlertAlertBody',
  },
  location: 'Event_userIGOnAlertLocation',
  remoteControl: 'Event_userIGOnAlertRemoteControl',
  dashboard: 'Event_userIGOnAlertDashboard',

  image: {
    normal: Icons.ic_engine_normal,
    active: Icons.ic_engine_active,
    white: Icons.ic_engine_white,
  },
});

unsafeEventIdMap.set('308', {
  eventId: '308',

  eventName: 'Event_elePowSteOreAlertEventName',

  warning: {
    title: 'Event_elePowSteOreAlertWarningTitle',
    hint: 'Event_elePowSteOreAlertWarningHint',
    order: 16,
  },
  notification: {
    title: 'Event_elePowSteOreAlertNotificationTitle',
    body: 'Event_elePowSteOreAlertNotificationBody',
  },
  home: 'Event_elePowSteOreAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_elePowSteOreAlertDialogTitle',
    body: 'Event_elePowSteOreAlertDialogBody',
    action: 'Event_elePowSteOreAlertDialogAction',
    action2: 'Event_elePowSteOreAlertDialogAction2',
    close: 'Event_elePowSteOreAlertDialogClose',
  },
  alert: {
    title: 'Event_elePowSteOreAlertAlertTitle',
    body: 'Event_elePowSteOreAlertAlertBody',
  },
  location: 'Event_elePowSteOreAlertLocation',
  remoteControl: 'Event_elePowSteOreAlertRemoteControl',
  dashboard: 'Event_elePowSteOreAlertDashboard',

  image: {
    normal: Icons.ic_steering_normal,
    active: Icons.ic_steering_active,
    white: Icons.ic_steering_white,
  },
});

unsafeEventIdMap.set('613', {
  eventId: '613',

  eventName: 'Event_afsWarnAlretEventName',

  warning: {
    title: 'Event_afsWarnAlretWarningTitle',
    hint: 'Event_afsWarnAlretWarningHint',
    order: 23,
  },
  notification: {
    title: 'Event_afsWarnAlretNotificationTitle',
    body: 'Event_afsWarnAlretNotificationBody',
  },
  home: 'Event_afsWarnAlretHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_afsWarnAlretDialogTitle',
    body: 'Event_afsWarnAlretDialogBody',
    action: 'Event_afsWarnAlretDialogAction',
    action2: 'Event_afsWarnAlretDialogAction2',
    close: 'Event_afsWarnAlretDialogClose',
  },
  alert: {
    title: 'Event_afsWarnAlretAlertTitle',
    body: 'Event_afsWarnAlretAlertBody',
  },
  location: 'Event_afsWarnAlretLocation',
  remoteControl: 'Event_afsWarnAlretRemoteControl',
  dashboard: 'Event_afsWarnAlretDashboard',

  image: {
    normal: Icons.ic_afs_normal,
    active: Icons.ic_afs_active,
    white: Icons.ic_afs_white,
  },
});

unsafeEventIdMap.set('213', {
  eventId: '213',

  eventName: 'Event_fourWheelSysAlertEventName',

  warning: {
    title: 'Event_fourWheelSysAlertWarningTitle',
    hint: 'Event_fourWheelSysAlertWarningHint',
    order: 9,
  },
  notification: {
    title: 'Event_fourWheelSysAlertNotificationTitle',
    body: 'Event_fourWheelSysAlertNotificationBody',
  },
  home: 'Event_fourWheelSysAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_fourWheelSysAlertDialogTitle',
    body: 'Event_fourWheelSysAlertDialogBody',
    action: 'Event_fourWheelSysAlertDialogAction',
    action2: 'Event_fourWheelSysAlertDialogAction2',
    close: 'Event_fourWheelSysAlertDialogClose',
  },
  alert: {
    title: 'Event_fourWheelSysAlertAlertTitle',
    body: 'Event_fourWheelSysAlertAlertBody',
  },
  location: 'Event_fourWheelSysAlertLocation',
  remoteControl: 'Event_fourWheelSysAlertRemoteControl',
  dashboard: 'Event_fourWheelSysAlertDashboard',

  image: {
    normal: Icons.ic_4wd_normal,
    active: Icons.ic_4wd_active,
    white: Icons.ic_4wd_white,
  },
});

unsafeEventIdMap.set('615', {
  eventId: '615',

  eventName: 'Event_autoHighBeamAlertEventName',

  warning: {
    title: 'Event_autoHighBeamAlertWarningTitle',
    hint: 'Event_autoHighBeamAlertWarningHint',
    order: 24,
  },
  notification: {
    title: 'Event_autoHighBeamAlertNotificationTitle',
    body: 'Event_autoHighBeamAlertNotificationBody',
  },
  home: 'Event_autoHighBeamAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_autoHighBeamAlertDialogTitle',
    body: 'Event_autoHighBeamAlertDialogBody',
    action: 'Event_autoHighBeamAlertDialogAction',
    action2: 'Event_autoHighBeamAlertDialogAction2',
    close: 'Event_autoHighBeamAlertDialogClose',
  },
  alert: {
    title: 'Event_autoHighBeamAlertAlertTitle',
    body: 'Event_autoHighBeamAlertAlertBody',
  },
  location: 'Event_autoHighBeamAlertLocation',
  remoteControl: 'Event_autoHighBeamAlertRemoteControl',
  dashboard: 'Event_autoHighBeamAlertDashboard',

  image: {
    normal: Icons.ic_autohighbeam_normal,
    active: Icons.ic_autohighbeam_active,
    white: Icons.ic_autohighbeam_white,
  },
});

unsafeEventIdMap.set('505', {
  eventId: '505',

  eventName: 'Event_keyFreeAlertEventName',

  warning: {
    title: 'Event_keyFreeAlertWarningTitle',
    hint: 'Event_keyFreeAlertWarningHint',
    order: 21,
  },
  notification: {
    title: 'Event_keyFreeAlertNotificationTitle',
    body: 'Event_keyFreeAlertNotificationBody',
  },
  home: 'Event_keyFreeAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_keyFreeAlertDialogTitle',
    body: 'Event_keyFreeAlertDialogBody',
    action: 'Event_keyFreeAlertDialogAction',
    action2: 'Event_keyFreeAlertDialogAction2',
    close: 'Event_keyFreeAlertDialogClose',
  },
  alert: {
    title: 'Event_keyFreeAlertAlertTitle',
    body: 'Event_keyFreeAlertAlertBody',
  },
  location: 'Event_keyFreeAlertLocation',
  remoteControl: 'Event_keyFreeAlertRemoteControl',
  dashboard: 'Event_keyFreeAlertDashboard',

  image: {
    normal: Icons.ic_keyfree_normal,
    active: Icons.ic_keyfree_active,
    white: Icons.ic_keyfree_white,
  },
});

unsafeEventIdMap.set('210', {
  eventId: '210',

  eventName: 'Event_ecoIdelOffAlertEventName',

  warning: {
    title: 'Event_ecoIdelOffAlertWarningTitle',
    hint: 'Event_ecoIdelOffAlertWarningHint',
    order: 6,
  },
  notification: {
    title: 'Event_ecoIdelOffAlertNotificationTitle',
    body: 'Event_ecoIdelOffAlertNotificationBody',
  },
  home: 'Event_ecoIdelOffAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_ecoIdelOffAlertDialogTitle',
    body: 'Event_ecoIdelOffAlertDialogBody',
    action: 'Event_ecoIdelOffAlertDialogAction',
    action2: 'Event_ecoIdelOffAlertDialogAction2',
    close: 'Event_ecoIdelOffAlertDialogClose',
  },
  alert: {
    title: 'Event_ecoIdelOffAlertAlertTitle',
    body: 'Event_ecoIdelOffAlertAlertBody',
  },
  location: 'Event_ecoIdelOffAlertLocation',
  remoteControl: 'Event_ecoIdelOffAlertRemoteControl',
  dashboard: 'Event_ecoIdelOffAlertDashboard',

  image: {
    normal: Icons.ic_idling_normal,
    active: Icons.ic_idling_active,
    white: Icons.ic_idling_white,
  },
});

unsafeEventIdMap.set('310', {
  eventId: '310',

  eventName: 'Event_brakeHoldAlertEventName',

  warning: {
    title: 'Event_brakeHoldAlertWarningTitle',
    hint: 'Event_brakeHoldAlertWarningHint',
    order: 18,
  },
  notification: {
    title: 'Event_brakeHoldAlertNotificationTitle',
    body: 'Event_brakeHoldAlertNotificationBody',
  },
  home: 'Event_brakeHoldAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_brakeHoldAlertDialogTitle',
    body: 'Event_brakeHoldAlertDialogBody',
    action: 'Event_brakeHoldAlertDialogAction',
    action2: 'Event_brakeHoldAlertDialogAction2',
    close: 'Event_brakeHoldAlertDialogClose',
  },
  alert: {
    title: 'Event_brakeHoldAlertAlertTitle',
    body: 'Event_brakeHoldAlertAlertBody',
  },
  location: 'Event_brakeHoldAlertLocation',
  remoteControl: 'Event_brakeHoldAlertRemoteControl',
  dashboard: 'Event_brakeHoldAlertDashboard',

  image: {
    normal: Icons.ic_brakehold_normal,
    active: Icons.ic_brakehold_active,
    white: Icons.ic_brakehold_white,
  },
});

unsafeEventIdMap.set('401', {
  eventId: '401',

  eventName: 'Event_smaashiErrAlertEventName',

  warning: {
    title: 'Event_smaashiErrAlertWarningTitle',
    hint: 'Event_smaashiErrAlertWarningHint',
    order: 19,
  },
  notification: {
    title: 'Event_smaashiErrAlertNotificationTitle',
    body: 'Event_smaashiErrAlertNotificationBody',
  },
  home: 'Event_smaashiErrAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_smaashiErrAlertDialogTitle',
    body: 'Event_smaashiErrAlertDialogBody',
    action: 'Event_smaashiErrAlertDialogAction',
    action2: 'Event_smaashiErrAlertDialogAction2',
    close: 'Event_smaashiErrAlertDialogClose',
  },
  alert: {
    title: 'Event_smaashiErrAlertAlertTitle',
    body: 'Event_smaashiErrAlertAlertBody',
  },
  location: 'Event_smaashiErrAlertLocation',
  remoteControl: 'Event_smaashiErrAlertRemoteControl',
  dashboard: 'Event_smaashiErrAlertDashboard',

  image: {
    normal: Icons.ic_smartassist_normal,
    active: Icons.ic_smartassist_active,
    white: Icons.ic_smartassist_white,
  },
});

unsafeEventIdMap.set('610', {
  eventId: '610',

  eventName: 'Event_lowBeamLevelErrAlertEventName',

  warning: {
    title: 'Event_lowBeamLevelErrAlertWarningTitle',
    hint: 'Event_lowBeamLevelErrAlertWarningHint',
    order: 22,
  },
  notification: {
    title: 'Event_lowBeamLevelErrAlertNotificationTitle',
    body: 'Event_lowBeamLevelErrAlertNotificationBody',
  },
  home: 'Event_lowBeamLevelErrAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_lowBeamLevelErrAlertDialogTitle',
    body: 'Event_lowBeamLevelErrAlertDialogBody',
    action: 'Event_lowBeamLevelErrAlertDialogAction',
    action2: 'Event_lowBeamLevelErrAlertDialogAction2',
    close: 'Event_lowBeamLevelErrAlertDialogClose',
  },
  alert: {
    title: 'Event_lowBeamLevelErrAlertAlertTitle',
    body: 'Event_lowBeamLevelErrAlertAlertBody',
  },
  location: 'Event_lowBeamLevelErrAlertLocation',
  remoteControl: 'Event_lowBeamLevelErrAlertRemoteControl',
  dashboard: 'Event_lowBeamLevelErrAlertDashboard',

  image: {
    normal: Icons.ic_headlamp_normal,
    active: Icons.ic_headlamp_active,
    white: Icons.ic_headlamp_white,
  },
});

unsafeEventIdMap.set('616', {
  eventId: '616',

  eventName: 'Event_headLampSysAlertEventName',

  warning: {
    title: 'Event_headLampSysAlertWarningTitle',
    hint: 'Event_headLampSysAlertWarningHint',
    order: 25,
  },
  notification: {
    title: 'Event_headLampSysAlertNotificationTitle',
    body: 'Event_headLampSysAlertNotificationBody',
  },
  home: 'Event_headLampSysAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_headLampSysAlertDialogTitle',
    body: 'Event_headLampSysAlertDialogBody',
    action: 'Event_headLampSysAlertDialogAction',
    action2: 'Event_headLampSysAlertDialogAction2',
    close: 'Event_headLampSysAlertDialogClose',
  },
  alert: {
    title: 'Event_headLampSysAlertAlertTitle',
    body: 'Event_headLampSysAlertAlertBody',
  },
  location: 'Event_headLampSysAlertLocation',
  remoteControl: 'Event_headLampSysAlertRemoteControl',
  dashboard: 'Event_headLampSysAlertDashboard',

  image: {
    normal: Icons.ic_headlampsys_normal,
    active: Icons.ic_headlampsys_active,
    white: Icons.ic_headlampsys_white,
  },
});

unsafeEventIdMap.set('212', {
  eventId: '212',

  eventName: 'Event_bosErrAlertEventName',

  warning: {
    title: 'Event_bosErrAlertWarningTitle',
    hint: 'Event_bosErrAlertWarningHint',
    order: 8,
  },
  notification: {
    title: 'Event_bosErrAlertNotificationTitle',
    body: 'Event_bosErrAlertNotificationBody',
  },
  home: 'Event_bosErrAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_bosErrAlertDialogTitle',
    body: 'Event_bosErrAlertDialogBody',
    action: 'Event_bosErrAlertDialogAction',
    action2: 'Event_bosErrAlertDialogAction2',
    close: 'Event_bosErrAlertDialogClose',
  },
  alert: {
    title: 'Event_bosErrAlertAlertTitle',
    body: 'Event_bosErrAlertAlertBody',
  },
  location: 'Event_bosErrAlertLocation',
  remoteControl: 'Event_bosErrAlertRemoteControl',
  dashboard: 'Event_bosErrAlertDashboard',

  image: {
    normal: Icons.ic_bos_normal,
    active: Icons.ic_bos_active,
    white: Icons.ic_bos_white,
  },
});

unsafeEventIdMap.set('211', {
  eventId: '211',

  eventName: 'Event_dscErrAlertEventName',

  warning: {
    title: 'Event_dscErrAlertWarningTitle',
    hint: 'Event_dscErrAlertWarningHint',
    order: 7,
  },
  notification: {
    title: 'Event_dscErrAlertNotificationTitle',
    body: 'Event_dscErrAlertNotificationBody',
  },
  home: 'Event_dscErrAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_dscErrAlertDialogTitle',
    body: 'Event_dscErrAlertDialogBody',
    action: 'Event_dscErrAlertDialogAction',
    action2: 'Event_dscErrAlertDialogAction2',
    close: 'Event_dscErrAlertDialogClose',
  },
  alert: {
    title: 'Event_dscErrAlertAlertTitle',
    body: 'Event_dscErrAlertAlertBody',
  },
  location: 'Event_dscErrAlertLocation',
  remoteControl: 'Event_dscErrAlertRemoteControl',
  dashboard: 'Event_dscErrAlertDashboard',

  image: {
    normal: Icons.ic_dsc_normal,
    active: Icons.ic_dsc_active,
    white: Icons.ic_dsc_white,
  },
});

unsafeEventIdMap.set('305', {
  eventId: '305',

  eventName: 'Event_vscTscAlertEventName',

  warning: {
    title: 'Event_vscTscAlertWarningTitle',
    hint: 'Event_vscTscAlertWarningHint',
    order: 13,
  },
  notification: {
    title: 'Event_vscTscAlertNotificationTitle',
    body: 'Event_vscTscAlertNotificationBody',
  },
  home: 'Event_vscTscAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_vscTscAlertDialogTitle',
    body: 'Event_vscTscAlertDialogBody',
    action: 'Event_vscTscAlertDialogAction',
    action2: 'Event_vscTscAlertDialogAction2',
    close: 'Event_vscTscAlertDialogClose',
  },
  alert: {
    title: 'Event_vscTscAlertAlertTitle',
    body: 'Event_vscTscAlertAlertBody',
  },
  location: 'Event_vscTscAlertLocation',
  remoteControl: 'Event_vscTscAlertRemoteControl',
  dashboard: 'Event_vscTscAlertDashboard',

  image: {
    normal: Icons.ic_vsc_normal,
    active: Icons.ic_vsc_active,
    white: Icons.ic_vsc_white,
  },
});

unsafeEventIdMap.set('304', {
  eventId: '304',

  eventName: 'Event_absAlertEventName',

  warning: {
    title: 'Event_absAlertWarningTitle',
    hint: 'Event_absAlertWarningHint',
    order: 12,
  },
  notification: {
    title: 'Event_absAlertNotificationTitle',
    body: 'Event_absAlertNotificationBody',
  },
  home: 'Event_absAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_absAlertDialogTitle',
    body: 'Event_absAlertDialogBody',
    action: 'Event_absAlertDialogAction',
    action2: 'Event_absAlertDialogAction2',
    close: 'Event_absAlertDialogClose',
  },
  alert: {
    title: 'Event_absAlertAlertTitle',
    body: 'Event_absAlertAlertBody',
  },
  location: 'Event_absAlertLocation',
  remoteControl: 'Event_absAlertRemoteControl',
  dashboard: 'Event_absAlertDashboard',

  image: {
    normal: Icons.ic_abs_normal,
    active: Icons.ic_abs_active,
    white: Icons.ic_abs_white,
  },
});

unsafeEventIdMap.set('309', {
  eventId: '309',

  eventName: 'Event_parkBrakeErrorEventName',

  warning: {
    title: 'Event_parkBrakeErrorWarningTitle',
    hint: 'Event_parkBrakeErrorWarningHint',
    order: 17,
  },
  notification: {
    title: 'Event_parkBrakeErrorNotificationTitle',
    body: 'Event_parkBrakeErrorNotificationBody',
  },
  home: 'Event_parkBrakeErrorHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_parkBrakeErrorDialogTitle',
    body: 'Event_parkBrakeErrorDialogBody',
    action: 'Event_parkBrakeErrorDialogAction',
    action2: 'Event_parkBrakeErrorDialogAction2',
    close: 'Event_parkBrakeErrorDialogClose',
  },
  alert: {
    title: 'Event_parkBrakeErrorAlertTitle',
    body: 'Event_parkBrakeErrorAlertBody',
  },
  location: 'Event_parkBrakeErrorLocation',
  remoteControl: 'Event_parkBrakeErrorRemoteControl',
  dashboard: 'Event_parkBrakeErrorDashboard',

  image: {
    normal: Icons.ic_parkingbrake_normal,
    active: Icons.ic_parkingbrake_active,
    white: Icons.ic_parkingbrake_white,
  },
});

unsafeEventIdMap.set('206', {
  eventId: '206',

  eventName: 'Event_cvtAlertEventName',

  warning: {
    title: 'Event_cvtAlertWarningTitle',
    hint: 'Event_cvtAlertWarningHint',
    order: 5,
  },
  notification: {
    title: 'Event_cvtAlertNotificationTitle',
    body: 'Event_cvtAlertNotificationBody',
  },
  home: 'Event_cvtAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_cvtAlertDialogTitle',
    body: 'Event_cvtAlertDialogBody',
    action: 'Event_cvtAlertDialogAction',
    action2: 'Event_cvtAlertDialogAction2',
    close: 'Event_cvtAlertDialogClose',
  },
  alert: {
    title: 'Event_cvtAlertAlertTitle',
    body: 'Event_cvtAlertAlertBody',
  },
  location: 'Event_cvtAlertLocation',
  remoteControl: 'Event_cvtAlertRemoteControl',
  dashboard: 'Event_cvtAlertDashboard',

  image: {
    normal: Icons.ic_cvt_normal,
    active: Icons.ic_cvt_active,
    white: Icons.ic_cvt_white,
  },
});

unsafeEventIdMap.set('202', {
  eventId: '202',

  eventName: 'Event_Engine1AlertEventName',

  warning: {
    title: 'Event_Engine1AlertWarningTitle',
    hint: 'Event_Engine1AlertWarningHint',
    order: 1,
  },
  notification: {
    title: 'Event_Engine1AlertNotificationTitle',
    body: 'Event_Engine1AlertNotificationBody',
  },
  home: 'Event_Engine1AlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_Engine1AlertDialogTitle',
    body: 'Event_Engine1AlertDialogBody',
    action: 'Event_Engine1AlertDialogAction',
    action2: 'Event_Engine1AlertDialogAction2',
    close: 'Event_Engine1AlertDialogClose',
  },
  alert: {
    title: 'Event_Engine1AlertAlertTitle',
    body: 'Event_Engine1AlertAlertBody',
  },
  location: 'Event_Engine1AlertLocation',
  remoteControl: 'Event_Engine1AlertRemoteControl',
  dashboard: 'Event_Engine1AlertDashboard',

  image: {
    normal: Icons.ic_engine_normal,
    active: Icons.ic_engine_active,
    white: Icons.ic_engine_white,
  },
});

unsafeEventIdMap.set('306', {
  eventId: '306',

  eventName: 'Event_srsAirbagAlertEventName',

  warning: {
    title: 'Event_srsAirbagAlertWarningTitle',
    hint: 'Event_srsAirbagAlertWarningHint',
    order: 14,
  },
  notification: {
    title: 'Event_srsAirbagAlertNotificationTitle',
    body: 'Event_srsAirbagAlertNotificationBody',
  },
  home: 'Event_srsAirbagAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_srsAirbagAlertDialogTitle',
    body: 'Event_srsAirbagAlertDialogBody',
    action: 'Event_srsAirbagAlertDialogAction',
    action2: 'Event_srsAirbagAlertDialogAction2',
    close: 'Event_srsAirbagAlertDialogClose',
  },
  alert: {
    title: 'Event_srsAirbagAlertAlertTitle',
    body: 'Event_srsAirbagAlertAlertBody',
  },
  location: 'Event_srsAirbagAlertLocation',
  remoteControl: 'Event_srsAirbagAlertRemoteControl',
  dashboard: 'Event_srsAirbagAlertDashboard',

  image: {
    normal: Icons.ic_srs_normal,
    active: Icons.ic_srs_active,
    white: Icons.ic_srs_white,
  },
});

unsafeEventIdMap.set('307', {
  eventId: '307',

  eventName: 'Event_elePowSteRedAlertEventName',

  warning: {
    title: 'Event_elePowSteRedAlertWarningTitle',
    hint: 'Event_elePowSteRedAlertWarningHint',
    order: 15,
  },
  notification: {
    title: 'Event_elePowSteRedAlertNotificationTitle',
    body: 'Event_elePowSteRedAlertNotificationBody',
  },
  home: 'Event_elePowSteRedAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_elePowSteRedAlertDialogTitle',
    body: 'Event_elePowSteRedAlertDialogBody',
    action: 'Event_elePowSteRedAlertDialogAction',
    action2: 'Event_elePowSteRedAlertDialogAction2',
    close: 'Event_elePowSteRedAlertDialogClose',
  },
  alert: {
    title: 'Event_elePowSteRedAlertAlertTitle',
    body: 'Event_elePowSteRedAlertAlertBody',
  },
  location: 'Event_elePowSteRedAlertLocation',
  remoteControl: 'Event_elePowSteRedAlertRemoteControl',
  dashboard: 'Event_elePowSteRedAlertDashboard',

  image: {
    normal: Icons.ic_powersteering_normal,
    active: Icons.ic_powersteering_active,
    white: Icons.ic_powersteering_white,
  },
});

unsafeEventIdMap.set('204', {
  eventId: '204',

  eventName: 'Event_batteryAlertEventName',

  warning: {
    title: 'Event_batteryAlertWarningTitle',
    hint: 'Event_batteryAlertWarningHint',
    order: 3,
  },
  notification: {
    title: 'Event_batteryAlertNotificationTitle',
    body: 'Event_batteryAlertNotificationBody',
  },
  home: 'Event_batteryAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_batteryAlertDialogTitle',
    body: 'Event_batteryAlertDialogBody',
    action: 'Event_batteryAlertDialogAction',
    action2: 'Event_batteryAlertDialogAction2',
    close: 'Event_batteryAlertDialogClose',
  },
  alert: {
    title: 'Event_batteryAlertAlertTitle',
    body: 'Event_batteryAlertAlertBody',
  },
  location: 'Event_batteryAlertLocation',
  remoteControl: 'Event_batteryAlertRemoteControl',
  dashboard: 'Event_batteryAlertDashboard',

  image: {
    normal: Icons.ic_charging_normal,
    active: Icons.ic_charging_active,
    white: Icons.ic_charging_white,
  },
});

unsafeEventIdMap.set('205', {
  eventId: '205',

  eventName: 'Event_waterTempAlertEventName',

  warning: {
    title: 'Event_waterTempAlertWarningTitle',
    hint: 'Event_waterTempAlertWarningHint',
    order: 4,
  },
  notification: {
    title: 'Event_waterTempAlertNotificationTitle',
    body: 'Event_waterTempAlertNotificationBody',
  },
  home: 'Event_waterTempAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_waterTempAlertDialogTitle',
    body: 'Event_waterTempAlertDialogBody',
    action: 'Event_waterTempAlertDialogAction',
    action2: 'Event_waterTempAlertDialogAction2',
    close: 'Event_waterTempAlertDialogClose',
  },
  alert: {
    title: 'Event_waterTempAlertAlertTitle',
    body: 'Event_waterTempAlertAlertBody',
  },
  location: 'Event_waterTempAlertLocation',
  remoteControl: 'Event_waterTempAlertRemoteControl',
  dashboard: 'Event_waterTempAlertDashboard',

  image: {
    normal: Icons.ic_coolant_normal,
    active: Icons.ic_coolant_active,
    white: Icons.ic_coolant_white,
  },
});

unsafeEventIdMap.set('203', {
  eventId: '203',

  eventName: 'Event_oilAlertEventName',

  warning: {
    title: 'Event_oilAlertWarningTitle',
    hint: 'Event_oilAlertWarningHint',
    order: 2,
  },
  notification: {
    title: 'Event_oilAlertNotificationTitle',
    body: 'Event_oilAlertNotificationBody',
  },
  home: 'Event_oilAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_oilAlertDialogTitle',
    body: 'Event_oilAlertDialogBody',
    action: 'Event_oilAlertDialogAction',
    action2: 'Event_oilAlertDialogAction2',
    close: 'Event_oilAlertDialogClose',
  },
  alert: {
    title: 'Event_oilAlertAlertTitle',
    body: 'Event_oilAlertAlertBody',
  },
  location: 'Event_oilAlertLocation',
  remoteControl: 'Event_oilAlertRemoteControl',
  dashboard: 'Event_oilAlertDashboard',

  image: {
    normal: Icons.ic_oil_normal,
    active: Icons.ic_oil_active,
    white: Icons.ic_oil_white,
  },
});

unsafeEventIdMap.set('301', {
  eventId: '301',

  eventName: 'Event_brakeSysAlertEventName',

  warning: {
    title: 'Event_brakeSysAlertWarningTitle',
    hint: 'Event_brakeSysAlertWarningHint',
    order: 10,
  },
  notification: {
    title: 'Event_brakeSysAlertNotificationTitle',
    body: 'Event_brakeSysAlertNotificationBody',
  },
  home: 'Event_brakeSysAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_brakeSysAlertDialogTitle',
    body: 'Event_brakeSysAlertDialogBody',
    action: 'Event_brakeSysAlertDialogAction',
    action2: 'Event_brakeSysAlertDialogAction2',
    close: 'Event_brakeSysAlertDialogClose',
  },
  alert: {
    title: 'Event_brakeSysAlertAlertTitle',
    body: 'Event_brakeSysAlertAlertBody',
  },
  location: 'Event_brakeSysAlertLocation',
  remoteControl: 'Event_brakeSysAlertRemoteControl',
  dashboard: 'Event_brakeSysAlertDashboard',

  image: {
    normal: Icons.ic_brakesystem_normal,
    active: Icons.ic_brakesystem_active,
    white: Icons.ic_brakesystem_white,
  },
});

unsafeEventIdMap.set('302', {
  eventId: '302',

  eventName: 'Event_brakeFluAlertEventName',

  warning: {
    title: 'Event_brakeFluAlertWarningTitle',
    hint: 'Event_brakeFluAlertWarningHint',
    order: 11,
  },
  notification: {
    title: 'Event_brakeFluAlertNotificationTitle',
    body: 'Event_brakeFluAlertNotificationBody',
  },
  home: 'Event_brakeFluAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_brakeFluAlertDialogTitle',
    body: 'Event_brakeFluAlertDialogBody',
    action: 'Event_brakeFluAlertDialogAction',
    action2: 'Event_brakeFluAlertDialogAction2',
    close: 'Event_brakeFluAlertDialogClose',
  },
  alert: {
    title: 'Event_brakeFluAlertAlertTitle',
    body: 'Event_brakeFluAlertAlertBody',
  },
  location: 'Event_brakeFluAlertLocation',
  remoteControl: 'Event_brakeFluAlertRemoteControl',
  dashboard: 'Event_brakeFluAlertDashboard',

  image: {
    normal: Icons.ic_brakefluid_normal,
    active: Icons.ic_brakefluid_active,
    white: Icons.ic_brakefluid_white,
  },
});

unsafeEventIdMap.set('002', {
  eventId: '002',

  eventName: 'Event_epbAlertEventName',

  warning: {
    title: 'Event_epbAlertWarningTitle',
    hint: 'Event_epbAlertWarningHint',
    order: 26,
  },
  notification: {
    title: 'Event_epbAlertNotificationTitle',
    body: 'Event_epbAlertNotificationBody',
  },
  home: 'Event_epbAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_epbAlertDialogTitle',
    body: 'Event_epbAlertDialogBody',
    action: 'Event_epbAlertDialogAction',
    action2: 'Event_epbAlertDialogAction2',
    close: 'Event_epbAlertDialogClose',
  },
  alert: {
    title: 'Event_epbAlertAlertTitle',
    body: 'Event_epbAlertAlertBody',
  },
  location: 'Event_epbAlertLocation',
  remoteControl: 'Event_epbAlertRemoteControl',
  dashboard: 'Event_epbAlertDashboard',

  image: {
    normal: Icons.ic_epb_normal,
    active: Icons.ic_epb_active,
    white: Icons.ic_epb_white,
  },
});

unsafeEventIdMap.set('001', {
  eventId: '001',

  eventName: 'Event_airbagAlertEventName',

  warning: {
    title: 'Event_airbagAlertWarningTitle',
    hint: 'Event_airbagAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_airbagAlertNotificationTitle',
    body: 'Event_airbagAlertNotificationBody',
  },
  home: 'Event_airbagAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_airbagAlertDialogTitle',
    body: 'Event_airbagAlertDialogBody',
    action: 'Event_airbagAlertDialogAction',
    action2: 'Event_airbagAlertDialogAction2',
    close: 'Event_airbagAlertDialogClose',
  },
  alert: {
    title: 'Event_airbagAlertAlertTitle',
    body: 'Event_airbagAlertAlertBody',
  },
  location: 'Event_airbagAlertLocation',
  remoteControl: 'Event_airbagAlertRemoteControl',
  dashboard: 'Event_airbagAlertDashboard',

  image: {
    normal: Icons.ic_srs_normal,
    active: Icons.ic_srs_active,
    white: Icons.ic_srs_white,
  },
});

export const EventIdMap: EventIdMap = {
  get: (key: EventId) => unsafeEventIdMap.get(key) as EventIdMapValue,

  keys: [
    '1051',
    '1050',
    '619',
    '618',
    '617',
    '515',
    '514',
    '513',
    '512',
    '511',
    '510',
    '508',
    '410',
    '232',
    '9999',
    '1040',
    '504',
    '1031',
    '1022',
    '1021',
    '1011',
    '1010',
    '1002',
    '1001',
    '102',
    '101',
    '308',
    '613',
    '213',
    '615',
    '505',
    '210',
    '310',
    '401',
    '610',
    '616',
    '212',
    '211',
    '305',
    '304',
    '309',
    '206',
    '202',
    '306',
    '307',
    '204',
    '205',
    '203',
    '301',
    '302',
    '002',
    '001',
  ],
};
