import * as MUI from '@mui/material';
import { SelectChangeEvent } from '@mui/material';
import * as MUISystem from '@mui/system';
import React from 'react';

const selectStyle: MUISystem.SxProps<MUISystem.Theme> = {
  width: `10em`,
  fontSize: `1rem`,
  backgroundColor: 'white',
  '& .MuiSelect-select': {
    padding: '4px',
  },
};

export function Select({
  defaultValue,
  onChange,
  children,
}: Readonly<{
  defaultValue?: string;
  onChange?: (e: SelectChangeEvent) => void;
  children?: React.ReactNode;
}>): React.ReactElement {
  return (
    <MUI.Select
      defaultValue={defaultValue}
      sx={selectStyle}
      onChange={onChange}
    >
      {children}
    </MUI.Select>
  );
}
