import { Option } from '@auto/dango-util';
import React from 'react';

export function usePrevious<A>(value: A): Option<A> {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = React.useRef<A>();

  // Store current value in ref
  React.useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return Option(ref.current);
}
