import { VehicleProfile } from '@auto/monaka-client/dist/dashboard';
import { Box } from '@mui/system';
import React from 'react';
import { Chip } from '../../components/Chip';
import { IntlText } from '../../components/Intl';
import { useIntl } from '../../i18n/Intl';
import { defaultLocale } from '../../i18n/Locale';
import { MessageId } from '../../i18n/Message';
import { Style } from '../../style/Style';
import { Theme } from '../../theme/Theme';
import { Row } from './Row';

const styles = Style({
  container: {
    padding: `8px`,
    //maxWidth: `12em`,
    maxHeight: `100%`,
    overflow: 'auto',
    lineHeight: `1.5em`,
    fontWeight: `800`,
  },
  chipOwner: {
    backgroundColor: Theme.vehicleDetail.chip.owner.backgroundColor,
    color: Theme.vehicleDetail.chip.owner.fontColor,
  },
  chipDriver: {
    backgroundColor: Theme.vehicleDetail.chip.driver.backgroundColor,
    color: Theme.vehicleDetail.chip.driver.fontColor,
  },
  gap: {
    height: `1em`,
  },
});

function Driver({
  name,
  customerId,
  phoneNo,
}: Readonly<{
  name: string;
  customerId: string;
  phoneNo: string;
}>): React.ReactElement {
  return (
    <Box>
      <Row>
        <IntlText id={MessageId.vehicleDetailName} />
        <Box>{name}</Box>
      </Row>
      <Row>
        <IntlText id={MessageId.vehicleDetailCusId} />
        <Box>{customerId}</Box>
      </Row>
      <Row>
        <IntlText id={MessageId.vehicleDetailPhone} />
        <Box>{phoneNo}</Box>
      </Row>
    </Box>
  );
}

export function SideBar({
  vehicleProfile,
}: {
  vehicleProfile: VehicleProfile;
}): React.ReactElement {
  const intl = useIntl(defaultLocale);
  return (
    <Box sx={styles.container}>
      <Box>
        <Row>
          <IntlText id={MessageId.vehicleDetailBasicInfoTitle} />
        </Row>
        <Row>
          <IntlText id={MessageId.vehicleDetailVin} />
          <Box>{vehicleProfile.vin}</Box>
        </Row>
        <Row>
          <IntlText id={MessageId.vehicleDetailRegNo} />
          <Box>{vehicleProfile.vehicleRegNo}</Box>
        </Row>
        <Row>
          <IntlText id={MessageId.vehicleDetailRegYear} />
          <Box>{vehicleProfile.regDate}</Box>
        </Row>
        <Row>
          <IntlText id={MessageId.vehicleDetailModel} />
          <Box>{vehicleProfile.model}</Box>
        </Row>
        <Row>
          <IntlText id={MessageId.vehicleDetailBodyColor} />
          <Box>{vehicleProfile.frmId}</Box>
        </Row>
        <Row>
          <IntlText id={MessageId.vehicleDetailPAA} />
          <Box>
            {vehicleProfile.paaStatus === 'subscribed'
              ? intl.formatMessageAsString({
                  id: MessageId.vehicleListPAAMember,
                })
              : intl.formatMessageAsString({
                  id: MessageId.vehicleListPAANonMember,
                })}
          </Box>
        </Row>
      </Box>

      {vehicleProfile.memberInfoList.map((m, key) => (
        <Box key={key}>
          <Box sx={styles.gap} />

          <Chip
            label={
              m.ownerStatus === 'owner'
                ? MessageId.vehicleDetailChipOwner
                : MessageId.vehicleDetailChipDriver
            }
            sx={
              m.ownerStatus === 'owner' ? styles.chipOwner : styles.chipDriver
            }
          />

          <Box sx={styles.gap} />

          <Driver
            name={m.fullName}
            customerId={m.icNo}
            phoneNo={m.phoneMobile}
          />
        </Box>
      ))}
    </Box>
  );
}
