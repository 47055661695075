import { Option } from '@auto/dango-util';
import * as R from 'react-router-dom';

type Location<State> = Omit<R.Location, 'state'> &
  Readonly<{
    state: Option<State>;
  }>;

export function useLocation<State>(): Location<State> {
  const l = R.useLocation();
  return {
    ...l,
    state: Option<State>(l.state as State),
  };
}
