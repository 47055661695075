export type EcuLang = 'en' | 'ja';

export type EcuCode =
  | 'BSMﾏｽﾀ'
  | 'PTC'
  | 'SCM'
  | 'EFI'
  | 'CGW'
  | 'BSM(M)'
  | 'E4WD'
  | 'BSMｽﾚｰﾌﾞ'
  | 'CVT'
  | 'EPS'
  | 'PSDR'
  | 'ERS'
  | 'PSDL'
  | 'ｽﾃﾚｵｶﾒﾗ'
  | 'ﾎﾞﾃﾞｰ'
  | 'CMR'
  | 'BDY'
  | 'SNR'
  | 'LVL'
  | 'ECO'
  | 'ｿﾅｰ'
  | 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ'
  | 'BSM(S)'
  | 'ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ'
  | 'VCU'
  | 'ABS/VSC'
  | 'MET'
  | 'AAC'
  | 'ﾒｰﾀ'
  | 'A/B';

export type EcuConverter = Readonly<{
  [lang in EcuLang]: Readonly<{
    [code in EcuCode]: string;
  }>;
}>;

export const EcuConverter: EcuConverter = {
  ja: {
    ﾒｰﾀ: 'ﾒｰﾀ',
    ﾎﾞﾃﾞｰ: 'ﾎﾞﾃﾞｰ',
    ｿﾅｰ: 'ｿﾅｰ',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ: 'ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ',
    ｽﾃﾚｵｶﾒﾗ: 'ｽﾃﾚｵｶﾒﾗ',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ: 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ',
    VCU: 'VCU',
    SNR: 'ｿﾅｰ',
    SCM: 'ｽﾃﾚｵｶﾒﾗ',
    PTC: 'PTC',
    PSDR: 'PSDR',
    PSDL: 'PSDL',
    MET: 'ﾒｰﾀ',
    LVL: 'ｵｰﾄﾚﾍﾞﾘﾝｸﾞ',
    ERS: 'ERS',
    EPS: 'EPS',
    EFI: 'EFI',
    ECO: 'ECO IDLE',
    E4WD: '4WD',
    CVT: 'CVT',
    CMR: 'ﾓﾆﾀｶﾒﾗ',
    CGW: 'ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ',
    BSMﾏｽﾀ: 'BSMﾏｽﾀ',
    BSMｽﾚｰﾌﾞ: 'BSMｽﾚｰﾌﾞ',
    'BSM(S)': 'BSMｽﾚｰﾌﾞ',
    'BSM(M)': 'BSMﾏｽﾀ',
    BDY: 'ﾎﾞﾃﾞｰ',
    'ABS/VSC': 'ABS/VSC',
    AAC: 'ｵｰﾄA/C',
    'A/B': 'A/B',
  },
  en: {
    ﾒｰﾀ: 'METER',
    ﾎﾞﾃﾞｰ: 'BODY',
    ｿﾅｰ: 'SONAR',
    ｾﾝﾄﾗﾙｹﾞｰﾄｳｪｲ: 'CENTRAL GATEWAY',
    ｽﾃﾚｵｶﾒﾗ: 'STEREO CAMERA',
    ｵｰﾄﾚﾍﾞﾘﾝｸﾞ: 'AUTO LEVELING',
    VCU: 'VCU',
    SNR: 'SONAR',
    SCM: 'STEREO CAMERA',
    PTC: 'PTC',
    PSDR: 'PSDR',
    PSDL: 'PSDR',
    MET: 'METER',
    LVL: 'AUTO LEVELING',
    ERS: 'ERS',
    EPS: 'EPS',
    EFI: 'EFI',
    ECO: 'ECO IDLE',
    E4WD: '4WD',
    CVT: 'CVT',
    CMR: 'MONITOR CAMERA',
    CGW: 'CENTRAL GATEWAY',
    BSMﾏｽﾀ: 'BSM MASTER',
    BSMｽﾚｰﾌﾞ: 'BSM SLAVE',
    'BSM(S)': 'BSM SLAVE',
    'BSM(M)': 'BSM MASTER',
    BDY: 'BODY',
    'ABS/VSC': 'ABS/VSC',
    AAC: 'AUTO A/C',
    'A/B': 'A/B',
  },
};
